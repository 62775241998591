import { Cell, Header } from '@tanstack/table-core'
import { VNode } from 'snabbdom'

type Renderable<TProp> =
  | VNode | string
  | ((props: TProp) => any)

function flexRender<TProps extends Record<string, any>>(
  Comp: Renderable<TProps> | undefined,
  props: TProps
): VNode | null {
  return !Comp ? null : typeof Comp === 'function' ? (
    Comp(props)
  ) : (
    Comp
  )
}

export function renderCell(cell: Cell<any, any>) {
  return cell.getIsAggregated()
    ? flexRender(cell.column.columnDef.aggregatedCell ?? cell.column.columnDef.cell, cell.getContext())
    :  flexRender(cell.column.columnDef.cell, cell.getContext())
}

export function renderHeader(header: Header<any, any>) {
  return flexRender(header.column.columnDef.header, header.getContext())
}
