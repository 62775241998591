export type Case<TCase extends string, TValue> = { case: TCase; value: TValue }
export type EmptyCase<TCase extends string> = { case: TCase }

const genericDiscriminator = (discriminatorKey: string) => (caseOrUnion: any, c?: any): any => {
  if (!caseOrUnion) {
    return genericDiscriminator(discriminatorKey)
  }
  if (typeof caseOrUnion === 'string') {
    return (union: any) => union[discriminatorKey] === caseOrUnion
  }

  return caseOrUnion[discriminatorKey] === c
}

export function discriminator<TD extends string>(discriminatorKey: TD) {
  return <C extends string>(c: C) => <TU extends { [P in TD]: string }>(union: TU): union is TU & { [P in TD]: typeof c } =>
    genericDiscriminator(discriminatorKey)(union, c)
}

export const isCase = discriminator('case')

export const caseOr = <T, T1 extends T, T2 extends T>(d1: ((x: T) => x is T1), d2: ((x: T) => x is T2)): ((x: T) => x is T1 | T2) => (x: T): x is T1 | T2 => d1(x) || d2(x)

export const matchBy = <DiscriminatorKey extends PropertyKey>(discriminator: DiscriminatorKey) =>
  <
    Value extends { [_ in DiscriminatorKey]: PropertyKey },
    TMap extends {
      [key in Value[DiscriminatorKey]]: (x: Value & { [_ in DiscriminatorKey]: key }) => any
    }>(
    value: Value,
    handlers: TMap): TMap extends { [_: string]: (...args: any[]) => infer TOut } ? TOut : never =>
    handlers[value[discriminator]](value as any)
