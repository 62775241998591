import { combineLoadables, isLoaded, isLoadError, isLoading, K, loadError, Manager, mergeSinks, ReturnType2, Unloaded, value } from '../../generic'
import { jsx } from 'h'
import { DERIVED, dynamicModel, IGNORE, INIT, pageSinkTemplate, toOutput } from '../../infrastructure'
import { Button, capture, click, cn, Danger, events, Form, Input, inputs, Label, renderLoadable, TextArea } from '../../ui'
import { projectProblemRenderConfig, renderConfigurationResponse } from './renderers'
import { PrimPageSources } from './types'
import { newProjectResource } from '../resources'
import { LoadableFromCreatable } from '@/dataServices'

const emptyState = {
  input: {
    key: '',
    name: '',
    description: '',
    apiKey: '',
    password: '',
    username: ''
  },
  createState: Unloaded as LoadableFromCreatable<ReturnType2<typeof newProjectResource>>
}

type ViewState = typeof emptyState

const intent = (sources: PrimPageSources) => ({
  ...capture(inputs({
    '#new-project-key': null,
    '#new-project-name': null,
    '#new-project-description': null,
    '#new-project-tia-username': null,
    '#new-project-tia-password': null,
    '#new-project-tia-apikey': null
  }), events({
    '#create-project': click
  }))(sources),
  projectCreated: sources.primState.newProject.response,
  projects: sources.primState.projects.value
})

const { get, set } = Manager<ViewState>()
const canCreate = get(state => !!(state.input.key && state.input.name && state.input.description && state.input.apiKey && state.input.username && state.input.password))

const model = (intents: ReturnType<typeof intent>, sources: PrimPageSources<ViewState>) =>
  dynamicModel(sources.state.stream)(
    pageSinkTemplate,
    intents,
    {
      [INIT]: K(emptyState),
      '#new-project-key': set(s => s.input.key),
      '#new-project-name': set(s => s.input.name),
      '#new-project-description': set(s => s.input.description),
      '#new-project-tia-username': set(s => s.input.username),
      '#new-project-tia-password': set(s => s.input.password),
      '#new-project-tia-apikey': set(s => s.input.apiKey),
      '#create-project': toOutput('HTTP')(state =>
        sources.primState.newProject.create({
          key: state.input.key,
          projectDescriptionInput:
            {
              name: state.input.name,
              description: state.input.description
            },
          tiaCredentials:
            {
              apiKey: state.input.apiKey,
              password: state.input.password,
              username: state.input.username
            }
        })),
      projectCreated: {
        state: loadableProject => set(s => s.createState)(loadableProject),
        output: loadableProject => _ =>
          isLoaded(loadableProject)
            ? {
              HTTP: [sources.primState.projects.refresh]
            }
            : {}
      },
      projects: {
        output: loadableProjects => state => {
          const values = combineLoadables(loadableProjects, state.createState)
          if (isLoaded(values)) {
            const [projects, projectConfiguration] = value(values)
            if (projects.some(p => p.key === projectConfiguration.info.key)) {
              return { router: [sources.parentRouter.createHref(`/projects/${projectConfiguration.info.key}/configuration`)] }
            }
          } else if (isLoadError(values)) {
            throw new Error(`Failed to load projects: ${JSON.stringify(loadError(values))}`)
          }
          return {}
        }
      },
      [DERIVED]: IGNORE
    }
  )

const view = (state: ViewState) => <div>
  <Form className={cn(isLoading(state.createState) ? 'stale' : '', 'pt-3')}>
    {renderLoadable(state.createState, {
      loaded: () => null,
      loading: () => null,
      error: err => <Danger className="mb-3">{renderConfigurationResponse(projectProblemRenderConfig)(err)}</Danger>
    })}

    <Label className="required" for="new-project-key">Project key</Label>
    <Input className="mb-3" id="new-project-key" value={state.input.key} />
    <Label className="required" for="new-project-name">Project name</Label>
    <Input className="mb-3" id="new-project-name" value={state.input.name} />
    <Label className="required" for="new-project-description">Description</Label>
    <TextArea className="mb-3" id="new-project-description" value={state.input.description} />
    <Label className="required" for="new-project-tia-username">TIA API username</Label>
    <Input className="mb-3" id="new-project-tia-username" value={state.input.username} />
    <Label className="required" for="new-project-tia-password">TIA API password</Label>
    <Input className="mb-3" id="new-project-tia-password" type="password" value={state.input.password} autocomplete="off" />
    <Label className="required" for="new-project-tia-apikey">TIA API key</Label>
    <Input className="mb-3" id="new-project-tia-apikey" type="password" value={state.input.apiKey} autocomplete="off" />
    <Button id="create-project" type="submit" context="primary" disabled={!canCreate(state)}>Create project</Button>
  </Form>
</div>

export const CreateProject = (sources: PrimPageSources<ViewState>) => {
  const sinks = model(intent(sources), sources)

  return mergeSinks({
    DOM: sources.state.stream.map(view)
  }, sinks)
}
