const SELECTED = Symbol('SELECTED')
const SELECTABLE = Symbol('SELECTABLE')
const DESELECTABLE = Symbol('DESELECTABLE')

interface Value<T> {
  readonly value: T
}

type SelectedBase<T> = Value<T> & { [SELECTED]: true }
export type NotDeselectable<T> = SelectedBase<T>
export type Deselectable<T> = SelectedBase<T> & {
  [DESELECTABLE]: true
}
export type Selected<T> = SelectedBase<T> | Deselectable<T>

type UnselectedBase<T> = Value<T> & { [SELECTED]: false }
export type NotSelectable<T> = UnselectedBase<T>
export type SelectableUnselected<T> = UnselectedBase<T> & {
  [SELECTABLE]: true
}
export type Unselected<T> = UnselectedBase<T> | SelectableUnselected<T>

export type Selectable<T> = Selected<T> | Unselected<T>

export type ToggleableSelectable<T> = SelectableUnselected<T> | Deselectable<T>
export type UntoggleableSelectable<T> = NotSelectable<T> | NotDeselectable<T>

export function select<T>(unselected: SelectableUnselected<T>, deselectable?: true): Deselectable<T>
export function select<T>(unselected: SelectableUnselected<T>, deselectable: false): NotDeselectable<T>
export function select<T>(unselected: SelectableUnselected<T>, deselectable = true) {
  return deselectable ? { value: unselected.value, [SELECTED]: true, [DESELECTABLE]: true } : { value: unselected.value, [SELECTED]: true }
}

export function deselect<T>(deselectable: Deselectable<T>, canBeSelected?: true): SelectableUnselected<T>
export function deselect<T>(deselectable: Deselectable<T>, canBeSelected: false): NotSelectable<T>
export function deselect<T>(deselectable: Deselectable<T>, canBeSelected = true) {
  return canBeSelected ? { value: deselectable.value, [SELECTED]: false, [SELECTABLE]: true } : { value: deselectable.value, [SELECTED]: false }
}

export function selectable<T>(value: T, selected: true, deselectable?: true): Deselectable<T>
export function selectable<T>(value: T, selected: true, deselectable: false): NotDeselectable<T>
export function selectable<T>(value: T, selected: false, canBeSelected?: true): SelectableUnselected<T>
export function selectable<T>(value: T, selected: false, canBeSelected: false): NotSelectable<T>
export function selectable<T>(value: T, selected: boolean, canToggle: boolean): Selectable<T>
export function selectable<T>(value: T, selected: boolean, canToggle = true) {
  return selected
    ? canToggle ? { value, [SELECTED]: true, [DESELECTABLE]: true } : { value, [SELECTED]: false }
    : canToggle ? { value, [SELECTED]: false, [SELECTABLE]: true } : { value, [SELECTED]: false }
}

export function toggleable<T>(notSelectable: NotSelectable<T>): SelectableUnselected<T>
export function toggleable<T>(notDeselectable: NotDeselectable<T>): Deselectable<T>
export function toggleable<T>(readonlySelectable: NotSelectable<T> | NotDeselectable<T>): UntoggleableSelectable<T>
export function toggleable<T>({ value, [SELECTED]: selected }: NotSelectable<T> | NotDeselectable<T>) {
  return selected ? { value, [SELECTED]: selected, [DESELECTABLE]: true } : { value, [SELECTED]: selected, [SELECTABLE]: true }
}

export function untoggleable<T>(unselected: SelectableUnselected<T>): NotSelectable<T>
export function untoggleable<T>(deselectable: Deselectable<T>): NotDeselectable<T>
export function untoggleable<T>(toggleableSelectable: Selectable<T> | Deselectable<T>): ToggleableSelectable<T>
export function untoggleable<T>({ value, [SELECTED]: selected }: Selectable<T> | Deselectable<T>) {
  return { value, [SELECTED]: selected }
}

export function isSelected<T>(s: Selectable<T>): s is Selected<T> {
  return s[SELECTED]
}

export function isSelectable<T>(s: Selectable<T>): s is SelectableUnselected<T> {
  return (s as SelectableUnselected<T>)[SELECTABLE]
}

export function isDeselectable<T>(s: Selectable<T>): s is Deselectable<T> {
  return (s as Deselectable<T>)[DESELECTABLE]
}

export function isToggleable<T>(s: SelectedBase<T>): s is Deselectable<T>
export function isToggleable<T>(unselected: UnselectedBase<T>): unselected is SelectableUnselected<T>
export function isToggleable<T>(s: SelectedBase<T> | UnselectedBase<T>): s is Deselectable<T> | SelectableUnselected<T>
export function isToggleable<T>(s: SelectedBase<T> | UnselectedBase<T>) {
  return isSelectable(s) || isDeselectable(s)
}

export function isUntoggleable<T>(s: SelectedBase<T>): s is NotDeselectable<T>
export function isUntoggleable<T>(unselected: UnselectedBase<T>): unselected is NotSelectable<T>
export function isUntoggleable<T>(s: SelectedBase<T> | UnselectedBase<T>): s is NotDeselectable<T> | NotSelectable<T>
export function isUntoggleable<T>(s: SelectedBase<T> | UnselectedBase<T>) {
  return !isSelectable(s) && !isDeselectable(s)
}
