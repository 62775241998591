import xs from 'xstream'
import { isLoaded, Manager, Unloaded, value } from '../../../../generic'
import { jsx } from 'h'
import { DERIVED, dynamicIntent, dynamicModel, IGNORE, INIT, pageSinkTemplate, SINKS } from '../../../../infrastructure'
import { Button, blur, capture, click, Danger, events, Input, inputs, renderLoadable, Select, Warning, Label } from '../../../../ui'
import { AzureBacklogConfiguration, dummyCredential, emptyAzureBacklogMappingConfig, isLevel3OrHigher } from '../../../model'
import { backlogConfiguration, azureProjectsResource, directAzureProjectsResource, azureWorkItemFieldsResource } from '../../../resources'
import { ProjectPageSources } from '../../common'
import { azureDevOpsConfigInvalidRenderConfig, insufficientRightsRenderConfig, renderConfigurationResponse, renderProblemFor, requestErrorRenderConfig, setBacklogConfigurationProblemRenderConfig, unhandledApiExceptionRenderConfig, unhandledAzureDevOpsProblemRenderConfig, wrongLevelProblemRenderConfig } from '../../renderers'
import { AzureConfigurationViewState, ConfigurationContext } from '../viewModel'
import { handlePutBacklogConfigurationResponse } from './model'

type State = AzureConfigurationViewState

export const azureConfigurationViewState = (context: ConfigurationContext, backlogConfigurationId: string, azureConfig: AzureBacklogConfiguration | null): State => {
  return {
    context: context,
    backlogConfigurationId: backlogConfigurationId,
    backlogMappingConfig: emptyAzureBacklogMappingConfig,
    projectsResponse: Unloaded,
    backlogFields: Unloaded,
    saveResponse: Unloaded,
    organisation: azureConfig?.organization ?? '',
    primKnowsPat: azureConfig?.pat === dummyCredential,
    pat: azureConfig?.pat ?? '',
    selectedProjectId: azureConfig?.projectId ?? ''
  }
}

type Sources = ProjectPageSources<State>

const intent = (sources: Sources) =>
  dynamicIntent<State>()(
    pageSinkTemplate,
    {
      ...capture(inputs({
        '#azure-organisation': null,
        '#azure-pat': null,
        '#azure-project': null
      }), events({
        '.save': click
      }))(sources),
      patBlur: blur(sources.DOM, '#azure-pat'),
      organizationBlur: blur(sources.DOM, '#azure-organisation'),
      projectsResponse: {
        discriminator: state => state.organisation && (state.pat || state.primKnowsPat) ? `${state.organisation}|${state.pat ?? ''}` : IGNORE,
        value: state => {
          const resource = state.pat && state.pat !== dummyCredential
            ? directAzureProjectsResource(sources.HTTP)(state.pat)(state.organisation)
            : azureProjectsResource(sources.apiHost + '/api')(sources.HTTP)(sources.projectKey)(state.backlogConfigurationId) // as xs<Loadable<null | TeamProjectReference[]>>;

          return ({
            intent: resource.value,
            [SINKS]: { HTTP: xs.of(resource.get) }
          })
        }
      },
      fieldsResponse: {
        discriminator: state => state.organisation && state.primKnowsPat ? `${state.organisation}|${state.pat}` : IGNORE,
        value: state => {
          const backlogFields = azureWorkItemFieldsResource(sources.apiHost + '/api')(sources.HTTP)(sources.projectKey)(state.backlogConfigurationId)
          return ({
            intent: backlogFields.value,
            [SINKS]: { HTTP: xs.of(backlogFields.get) }
          })
        }
      },
      receivePutResponse: {
        discriminator: state => state.backlogConfigurationId,
        value: state => backlogConfiguration(sources.apiHost + '/api')(sources.HTTP)(sources.projectKey)(state.backlogConfigurationId).putResponse
      },
    })

const { set } = Manager<State>()

const getProjectRequest = (sources: Sources) => (state: State) =>
  state.organisation
    ? state.pat && state.pat !== dummyCredential
      ? { HTTP: [directAzureProjectsResource(sources.HTTP)(state.pat)(state.organisation).get] }
      : state.primKnowsPat
        ? { HTTP: [azureProjectsResource(sources.apiHost + '/api')(sources.HTTP)(sources.projectKey)(state.backlogConfigurationId).get] }
        : {}
    : {}

const setOnBlur = set(state => state.pat)((pat, state) => !pat && state.primKnowsPat ? dummyCredential : pat)

const model = (intents: ReturnType<typeof intent>, sources: Sources) =>
  dynamicModel(sources.state.stream)(
    pageSinkTemplate,
    intents, {
      [INIT]: IGNORE,
      '#azure-organisation': set(state => state.organisation),
      organizationBlur: {
        output: _ => state => getProjectRequest(sources)(state)
      },
      '#azure-pat': set(state => state.pat),
      patBlur: {
        state: _ => setOnBlur,
        output: _ => state =>
          state.pat
            ? getProjectRequest(sources)(setOnBlur(state))
            : {}
      },
      '#azure-project': set(state => state.selectedProjectId),
      '.save': {
        output: _ => state => ({
          HTTP: [
            backlogConfiguration(sources.apiHost + '/api')(sources.HTTP)(sources.projectKey)(state.backlogConfigurationId).put({
              case: 'AzureBacklogConfiguration',
              fields: [{
                organization: state.organisation,
                pat: state.pat,
                projectId: state.selectedProjectId,
                mappingConfig: state.backlogMappingConfig }]})
          ]
        })
      },
      projectsResponse: response => state => set(state => state.projectsResponse)(response)(state),
      fieldsResponse: set(state => state.backlogFields),
      receivePutResponse: {
        state: response => set(state => state.saveResponse)(response as any),
        output: handlePutBacklogConfigurationResponse(sources)
      },
      [DERIVED]: set(state => state.selectedProjectId)(
        (selectedProjectId, state) => {
          if (!isLoaded(state.projectsResponse) || selectedProjectId) {
            return selectedProjectId
          }

          const maybeProjects = value(state.projectsResponse)

          if (!maybeProjects) {
            return ''
          }

          return maybeProjects[0]?.id || ''
        })
    })

const view = (state: State) =>
  <div>
    {!isLevel3OrHigher(state.context.configuration.levelConfiguration)
      ? <Warning>You cannot configure the backlog if the project level is lower than level 3.</Warning>
      : null
    }
    <div className="mb-3">
      <Label className="required" for="azure-organisation">Organisation</Label>
      <Input id="azure-organisation" value={state.organisation} aria-describedby="azure-organisation-help" />
      <small id="azure-organisation-help" className="form-text text-muted">
        The organization name is the path part that comes after the hostname of Azure DevOps: https://dev.azure.com/{'{organization}'}/my-project
      </small>
    </div>
    <div className="mb-3">
      <Label className="required" for="azure-pat">Personal access token</Label>
      <Input type="password" id="azure-pat" aria-describedby="azure-organisation-help" value={state.pat} autocomplete="off" />
      <small id="azure-pat-help" className="form-text text-muted">
        <a style={{textDecoration: 'underline'}} href="https://docs.microsoft.com/en-us/azure/devops/organizations/accounts/use-personal-access-tokens-to-authenticate">
          Learn how to create a PAT for Azure DevOps.
        </a>
        &nbsp;Required scopes are "Project and Team (Read)" and "Work Items (Read)".
      </small>
    </div>
    <div className="mb-3">
      <Label className="required" for="azure-project">Project</Label>
      {renderLoadable(state.projectsResponse, {
        unloaded: () => <Select disabled={true}></Select>,
        loading: () => <Select disabled={true}></Select>,
        loaded: projects =>
          projects
            ? <Select id="azure-project">
              {projects.map(p => <option key={p.id} selected={p.id === state.selectedProjectId} value={p.id}>{p.name}</option>)}
            </Select>
            : <Danger>
              It looks like your personal access tokens does not have the required scopes associated with it.
              Make sure the "Project and Team (Read)" and "Work Items (Read)" scopes are associated with the token.</Danger>,
        error: problem => <Danger>
          {renderProblemFor(problem)({
            ...requestErrorRenderConfig,
            ...insufficientRightsRenderConfig,
            ...unhandledApiExceptionRenderConfig,
            ...wrongLevelProblemRenderConfig,
            ...azureDevOpsConfigInvalidRenderConfig,
            ...unhandledAzureDevOpsProblemRenderConfig
          })}
        </Danger>
      })}
    </div>
    <Button context="primary" className="save">Save</Button>
    {renderLoadable(state.saveResponse, {
      loaded: () => null,
      error: problem => <Danger>{renderConfigurationResponse(setBacklogConfigurationProblemRenderConfig)(problem)}</Danger>
    })}

  </div>

export const AzureConfiguration = (sources: Sources) => ({
  ...model(intent(sources), sources),
  DOM: sources.state.stream.map(view)
})
