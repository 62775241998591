import { HTTPSource, RequestOptions } from '@cycle/http'
import { MemoryStream } from 'xstream'
import { handleLatest, toLoadable } from '../../dataServices'
import { GettableResource } from '../../dataServices/types'
import { K, Loadable, isArray } from '../../generic'
import { TimesheetResponse, UnprocessableEntry } from '../model/timesheet'
import { handleError, resultOrError, simpleResult } from './resourceUtil'
import { InsufficientRights, MemberNotFoundProblem, ProjectNotFoundProblem, RequestError, TiaSyncProblem, UnhandledApiExceptionProblem, UserTimesheetProblem, insufficientRights } from '../model'

export type TimeSheetResource =
  GettableResource<TimesheetResponse, RequestError | InsufficientRights | ProjectNotFoundProblem | MemberNotFoundProblem | UnhandledApiExceptionProblem | UserTimesheetProblem[]>
  & {
    sync: RequestOptions
    syncResponse: MemoryStream<Loadable<UnprocessableEntry[], RequestError | InsufficientRights | ProjectNotFoundProblem | MemberNotFoundProblem | TiaSyncProblem | UnhandledApiExceptionProblem | UserTimesheetProblem[]>>
  }
// TODO: response types for failures
export const userTimesheetResource = (root: string) => (HTTP: HTTPSource) => (projectKey: string, tiaUserId: string, month: string): TimeSheetResource => {
  const getCategory = `get-project-timesheet-${projectKey}-${tiaUserId}-${month}`
  const syncCategory = `sync-project-timesheet-${projectKey}-${tiaUserId}-${month}`

  return {
    name: getCategory,
    get: {
      category: getCategory,
      url: `${root}/projects/${projectKey}/timesheets/${tiaUserId}`,
      query: {
        month: month
      },
      method: 'GET'
    },
    sync: {
      category: syncCategory,
      url: `${root}/projects/${projectKey}/timesheets/${tiaUserId}/sync`,
      query: {
        month: month
      },
      method: 'POST'
    },
    value: toLoadable(handleLatest(HTTP)(getCategory)({
      success: simpleResult<TimesheetResponse>,
      failure: {
        403: K(insufficientRights),
        404: simpleResult<ProjectNotFoundProblem | MemberNotFoundProblem>,
        409: simpleResult<UserTimesheetProblem[]>,
        500: response =>
          isArray(response.body)
            ? response.body as UserTimesheetProblem[]
            : resultOrError<TiaSyncProblem | UnhandledApiExceptionProblem>(response)
      },
      error: handleError
    })),
    syncResponse: toLoadable(handleLatest(HTTP)(syncCategory)({
      success: simpleResult<UnprocessableEntry[]>,
      failure: {
        403: K(insufficientRights),
        404: simpleResult<ProjectNotFoundProblem | MemberNotFoundProblem>,
        409: simpleResult<UserTimesheetProblem[]>,
        500: response =>
          isArray(response.body)
            ? response.body as UserTimesheetProblem[]
            : resultOrError<UnhandledApiExceptionProblem>(response)
      },
      error: handleError
    }))
  }
}
