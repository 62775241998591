import xs from 'xstream'
import { Config, loadConfig } from '../config'
import { DriverSinkType } from './driverUtil'

export const LOAD_CONFIG = 'LOAD_CONFIG'

const configPromise = loadConfig()
const config = xs.createWithMemory<Config>()

export const configDriver = (sink: xs<typeof LOAD_CONFIG>) => {
  const loadConfig = () =>
    configPromise.then(
      value => config.shamefullySendNext(value),
      err => config.shamefullySendError(err))

  loadConfig()

  sink.subscribe({
    next: loadConfig
  })

  return {
    config
  }
}

export type ConfigDriver = typeof configDriver
export type ConfigSource = ReturnType<ConfigDriver>
export type ConfigSink = DriverSinkType<ConfigDriver>
