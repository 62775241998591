import { RouterSource } from 'cyclic-router'
import { jsx } from 'h'
import { ElementFactory } from './bootstrap'
import { cn, Props, Renderable } from './util'

const normalize = (path: string) => path.replace('//', '/')

export const isSubPath = (router: RouterSource, path: string) => {
  return (path === '' || path === '/')
    ? normalize(router.createHref(path + '/')) === normalize(window.location.pathname)
    : new RegExp(`^${router.createHref(path).replace('/', '\\/')}($|\\/)`).test(window.location.pathname)
}
export const createHref = (router: RouterSource, path: string) => router.createHref(('/' + path).replace('//', '/'))

export type LinkProps = { href: string }
export const linkCreator = (router: RouterSource) => (props: Props<LinkProps>, content: Renderable) => {
  return (
    <a {...props} className={cn(props.className || '', { active: isSubPath(router, props.href) })} href={createHref(router, props.href)}>
      {content}
    </a>
  )
}

export type LinkFactory = ElementFactory<LinkProps>
