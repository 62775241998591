import { UserState } from '../../../authentication/userProvider'
import { ErrorType, Loadable, ReturnType2, Selectable, StreamType } from '../../../generic'
import { ProjectConfiguration, ProjectMember, RateItem, TimeEntryMappingConfig, JiraIssueLinkDirection, AzureBacklogMappingConfig, Level5ProjectConfiguration, RequestError, InsufficientRights, AdapterProblem, TaskSettingsProblem, TimeTrackerConfigurationProblem, WrongLevelProblem, UnhandledApiExceptionProblem, AzureDevOpsConfigurationInvalid, UnhandledAzureDevOpsProblem } from '../../model'
import { BacklogConfigurationResource, JiraIssueFieldsResource, JiraIssueLinksResource, JiraIssueTypesResource, JiraProjectsResource, ProjectConfigurationResource, PutProjectMembersConfigurationResource, ReportParametersResource, SetTaskMappingConfigurationResource, SetTaskSettingsConfigurationResource, SetWorkItemMappingConfigurationResource, SprintsResource, TimeTrackerConfigurationResource, UpdateProjectResource, newProjectResource } from '../../resources'
import { PrimAzureWorkItemFieldsResource, TeamProjectReference } from '../../resources/azure'
import { BacklogWorkItem, BacklogWorkItemsResource, BacklogWorkItemsResponse } from '../../resources/backlog'
import { ClockifyProjectsResponse, ClockifyWorkspaceResponse, ClockifyProjectsFromProjectResource, ClockifyWorkspacesFromProjectResource, ClockifyWorkspacesFromClockifyResource, ClockifyProjectsFromClockifyResource } from '../../resources/clockify'
import { RolesResource, RolesResponse } from '../../resources/roles'
import { TiaUser, TiaUsersResource, TiaUsersResponse } from '../../resources/tiaUsers'
import { TimeTrackerTask, TimeTrackerTasksResource } from '../../resources/timeTrackerTasks'
import { TimeTrackerUser, TimeTrackerUsersResource, TimeTrackerUsersResponse } from '../../resources/timeTrackerUsers'
import { RedmineIssuesResponse } from '../../resources/redmineViaClient'
import { AutoSuggestState } from '../autoSuggest'
import { OrdersViewState } from './orders/viewModel'
import { CredentialsState } from './backlog/redmineViaClientConfiguration/credentials'
import { TiaCodesResource, TiaTimesheetCode } from '../../resources/tiaTimesheetCodes'
import { PaymoProjectsResponse, PaymoProjectsFromProjectResource, PaymoProjectsFromPaymoResource } from '../../resources/paymo'
import { State as HideExpiredState } from './configurationTable/hideExpired'
import { Table } from '@tanstack/table-core'
import { LoadableFromCreatable, LoadableFromGettable, LoadableFromPuttable } from '@/dataServices'

export type DisplayProjectMember = {
  timeTrackerId: string | null
  cronosUsername: string
  cronosAdObjectId: string
  tiaUserId: string
  tiaUsername: string
  displayName: string
  tiaFirstName: string
  tiaLastName: string
  employerName: string
  timeTrackerUserInput: string
  role: string | null
}

export enum States {
  Loading = 'Loading',
  Loaded = 'Loaded',
  Error = 'Error'
}

export type LoadingModel = {
  case: States.Loading
  key: string
  user: UserState
  adminMode: boolean
  data: Loadable<LoadedModel>
  tiaUsers: LoadableFromGettable<TiaUsersResource>
  tiaCodes: Loadable<Record<string, TiaTimesheetCode>, ErrorType<StreamType<TiaCodesResource['value']>>>
  projectConfiguration: LoadableFromGettable<ProjectConfigurationResource>
  timeTrackerUsers: LoadableFromGettable<TimeTrackerUsersResource>
  roles: LoadableFromGettable<RolesResource>
}

export type ProjectViewState = {
  input: {
    key: string
    name: string
    description: string
  }
  createProjectResponse: LoadableFromCreatable<ReturnType2<typeof newProjectResource>>
}

export type ProjectDescriptionConfigurationViewState = {
  context: ConfigurationContext
  name: string
  description: string
  originalUsername: string
  username: string
  password: string
  apiKey: string
  putProjectDescriptionConfigurationResponse: LoadableFromPuttable<UpdateProjectResource>
}

export type LastConfiguredProjectInfo<TId> =
  { id: TId
    name: string
    colour: string
    state: string }

type SharedTimeTrackerConfigurationViewState<TId> = {
  context: ConfigurationContext
  timeEntryMappingConfig: TimeEntryMappingConfig
  timeTrackerConfigurationUpdateResponse: StreamType<TimeTrackerConfigurationResource['putResponse']>
  lastConfiguredProjectInformation: Map<TId, LastConfiguredProjectInfo<TId>>
  missingProjects: LastConfiguredProjectInfo<TId>[]
}

export type ClockifyConfigurationViewState = SharedTimeTrackerConfigurationViewState<string> & {
  apiKey: string
  workspaces: Loadable<ClockifyWorkspaceResponse, ErrorType<StreamType<ClockifyWorkspacesFromProjectResource['value']>> | ErrorType<StreamType<ClockifyWorkspacesFromClockifyResource['value']>>>
  selectedWorkspace: string | null
  projects: Loadable<ClockifyProjectsResponse, ErrorType<StreamType<ClockifyProjectsFromProjectResource['value']>> | ErrorType<StreamType<ClockifyProjectsFromClockifyResource['value']>>>
  selectedProjects: Set<string>
}

export type PaymoConfigurationViewState = SharedTimeTrackerConfigurationViewState<number> & {
  apiKey: string
  projectsResponse: Loadable<PaymoProjectsResponse, ErrorType<StreamType<PaymoProjectsFromProjectResource['value']>> | ErrorType<StreamType<PaymoProjectsFromPaymoResource['value']>>>
  selectedProjects: Set<number>
}

export type TimeTrackerConfigurationViewState =
  (
    | {
      type: 'chooseTimeTrackerType'
      context: ConfigurationContext
    }
    | (({
      type: 'clockify'
      state: ClockifyConfigurationViewState
    }
    | {
      type: 'paymo'
      state: PaymoConfigurationViewState
    }) & {
      deleteResponse: StreamType<TimeTrackerConfigurationResource['deleteResponse']>
    }))
  & {
    adminMode: boolean
  }

export type MembersViewState = {
  context: ConfigurationContext
  timeTrackerUsers: TimeTrackerUsersResponse | ErrorType<StreamType<TimeTrackerUsersResource['value']>> | null
  roles: RolesResponse | null
  tiaUserSuggest: AutoSuggestState<TiaUser>
  newTimeTrackerUserSuggest: AutoSuggestState<TimeTrackerUser>
  projectMembers: readonly DisplayProjectMember[]
  projectMembersConfigurationUpdateResponse:
  Loadable<undefined, [ErrorType<StreamType<PutProjectMembersConfigurationResource['putResponse']>>, readonly DisplayProjectMember[]]>
  newItem: {
    tiaUser: TiaUser | null
    timeTrackerUser: TimeTrackerUser | null
    role: string | null
  }
}

export type MappingTiaTimesheetCode = {
  id: string
  name: string
  writable: boolean
}

export type MissingTiaTimesheetCode = {
  missingTiaCodeId: string
  missingTiaCodeName: string
}

export const isMissingTiaTimesheetCode = (tiaCode: MappingTiaTimesheetCode | MissingTiaTimesheetCode): tiaCode is MissingTiaTimesheetCode =>
  !!(tiaCode as MissingTiaTimesheetCode).missingTiaCodeId

export const tiaCodeId = (tiaCode: MappingTiaTimesheetCode | MissingTiaTimesheetCode) =>
  isMissingTiaTimesheetCode(tiaCode) ? tiaCode.missingTiaCodeId : tiaCode.id

export type MissingProjectMember = {
  missingProjectMemberTiaUser: TiaUser
}

export type MissingTiaUser = {
  missingTiaUserId: string
}

type MaybeProjectMember = ProjectMember | MissingProjectMember | MissingTiaUser | null
export const isMissingProjectMember = (projectMember: MaybeProjectMember): projectMember is MissingProjectMember =>
  !!projectMember && !!(projectMember as MissingProjectMember).missingProjectMemberTiaUser
export const isMissingTiaUser = (projectMember: MaybeProjectMember): projectMember is MissingTiaUser =>
  !!projectMember && !!(projectMember as MissingTiaUser).missingTiaUserId
export const isProjectMember = (projectMember: MaybeProjectMember): projectMember is ProjectMember =>
  !!projectMember && !isMissingTiaUser(projectMember) && !isMissingProjectMember(projectMember)

export type MissingTimeTrackerTask = {
  missingTimeTrackerTaskId: string
}

export const isMissingTimeTrackerTask = (task: TimeTrackerTask | MissingTimeTrackerTask): task is MissingTimeTrackerTask =>
  !!(task as MissingTimeTrackerTask).missingTimeTrackerTaskId

export type MissingBacklogWorkItem = {
  missingBacklogWorkItemId: string
}

export const isMissingBacklogWorkItem = (workItem: BacklogWorkItem | MissingBacklogWorkItem): workItem is MissingBacklogWorkItem =>
  !!(workItem as MissingBacklogWorkItem).missingBacklogWorkItemId

export const backlogItemId = (workItem: string | BacklogWorkItem | MissingBacklogWorkItem) =>
  typeof workItem === 'string'
    ? workItem
    : isMissingBacklogWorkItem(workItem)
      ? workItem.missingBacklogWorkItemId
      : workItem.id

export type TaskMappingItem = {
  task: string | MissingTimeTrackerTask | TimeTrackerTask
  tiaCode: MappingTiaTimesheetCode | MissingTiaTimesheetCode
  projectMembers: ProjectMember[]
  from: Date
  until: Date | null
}


export type TaskMappingViewState = {
  taskMappingsTableKey: Record<string, unknown>
  context: ConfigurationContext
  tasks: Loadable<TimeTrackerTask[]>
  mappings: TaskMappingItem[]
  table: Loadable<Table<TaskMappingItem[]>>
  settings: HideExpiredState
  newItem: {
    task: TimeTrackerTask | null
    taskInput: AutoSuggestState<TimeTrackerTask>
    tiaCode: MappingTiaTimesheetCode | null
    tiaCodeInput: AutoSuggestState<MappingTiaTimesheetCode>
    from: Date | null
    until: Date | null
  }
  putTaskMappingConfigurationResponse: LoadableFromPuttable<SetTaskMappingConfigurationResource>
}

export type WorkItemMappingItem = {
  workItem: string | BacklogWorkItem | MissingBacklogWorkItem
  tiaCode: MappingTiaTimesheetCode | MissingTiaTimesheetCode
  projectMembers: ProjectMember[]
  from: Date
  until: Date | null
  key: number
}

export type WorkItemMappingViewState = {
  context: ConfigurationContext
  workItemsCounter: number
  workItems: LoadableFromGettable<BacklogWorkItemsResource>
  mappings: readonly WorkItemMappingItem[]
  newItem: {
    workItem: BacklogWorkItem | null
    workItemInput: AutoSuggestState<BacklogWorkItem>
    tiaCode: MappingTiaTimesheetCode | null
    tiaCodeInput: AutoSuggestState<MappingTiaTimesheetCode>
    from: Date | null
    until: Date | null
  }
  settings: {
    hideExpired: boolean
    groupBy: ('backlogItem' | 'tiaCode')[]
  }
  putWorkItemMappingConfigurationResponse: LoadableFromPuttable<SetWorkItemMappingConfigurationResource>
}

export type MissingTaskSetting = {
  taskId: string
  backlogItemRequired: boolean
  role: string
  overheadScope: number | null
  missing: true
}

export type TaskSettingItem = {
  taskId: string
  taskName: string
  backlogItemRequired: boolean | null
  role: string | null
  overheadScope: number | null
}

export const isTaskSettingItem = (item: MissingTaskSetting | TaskSettingItem): item is TaskSettingItem => !(item as MissingTaskSetting).missing

export type TaskSettingsViewState = {
  context: ConfigurationContext
  tasks: LoadableFromGettable<TimeTrackerTasksResource>
  settings: readonly (MissingTaskSetting | TaskSettingItem)[]
  roles: RolesResponse | null
  putTaskSettingsResponse: LoadableFromPuttable<SetTaskSettingsConfigurationResource>
  otherTaskSettingsProblems: TaskSettingsProblem[] | null
  taskNotFound: boolean
  unknownResponseError:
  | RequestError
  | InsufficientRights
  | AdapterProblem
  | TimeTrackerConfigurationProblem
  | WrongLevelProblem
  | null
}

export type InputSprint = {
  name: string
  from: Date
  until: Date
  problems: {
    badName: boolean
    badFrom: boolean
    badUntil: boolean
    duplicateSprintName: boolean
    overlappingSprintNames: string[]
  }
}

export type SprintsViewState = {
  context: ConfigurationContext
  sprints: readonly InputSprint[]
  newItem: {
    name: string
    from: Date | null
    until: Date | null
  }
  putSprintConfigurationResponse: LoadableFromPuttable<SprintsResource>
  unknownResponseError: RequestError | InsufficientRights | null
}

export type ReportParametersViewState = {
  context: ConfigurationContext
  reportParameters: Record<string, string>
  putReportParametersResponse: LoadableFromPuttable<ReportParametersResource>
}

export type AzureConfigurationViewState = {
  context: ConfigurationContext
  backlogConfigurationId: string
  backlogMappingConfig: AzureBacklogMappingConfig
  organisation: string
  primKnowsPat: boolean
  pat: string
  selectedProjectId: string
  projectsResponse: Loadable<null | TeamProjectReference[], RequestError | InsufficientRights | WrongLevelProblem | UnhandledApiExceptionProblem | AzureDevOpsConfigurationInvalid | UnhandledAzureDevOpsProblem>
  backlogFields: LoadableFromGettable<PrimAzureWorkItemFieldsResource>
  saveResponse: LoadableFromPuttable<BacklogConfigurationResource>
}

/////////////////////////

export const jiraCloudInstanceType = 'cloud' as const
export const jiraServerInstanceType = 'server' as const
export const jiraInstanceDiscriminator = 'instanceType' as const

export const jiraDefaultHierarchyType = 'default' as const
export const jiraCustomHierarchyType = 'custom' as const

export type JiraAuthenticationInfo =
  {
    [jiraInstanceDiscriminator]: typeof jiraCloudInstanceType
    cloudUsername: string
    cloudToken: string
  } | {
    [jiraInstanceDiscriminator]: typeof jiraServerInstanceType
    serverToken: string
  }

export type JiraAutoIdsInput = {
  hierarchyLinkId: string | null
  epicLinkId: string | null
  parentLinkId: string | null
}

export type JiraParentLinkInput =
  | { type: 'auto'; autoLinkIds: JiraAutoIdsInput }
  | { type: 'parentIssue' }
  | { type: 'epicLink'; customFieldId: string }
  | { type: 'parentLink'; customFieldId: string }
  | { type: 'issueLink'; linkId: string; linkDirection: JiraIssueLinkDirection['case'] }

export type JiraDescendantInput = {
  issueTypeId: string
  parentLinks: readonly JiraParentLinkInput[]
}

export type JiraDefaultHierarchyInput = JiraAutoIdsInput

export type JiraCustomHierarchyInput = {
  topLevelTypeId: string
  definedDescendants: readonly JiraDescendantInput[]
  rest: readonly JiraParentLinkInput[]
}

export type JiraHierarchyInput =
  ({
    type: typeof jiraDefaultHierarchyType
  } & JiraDefaultHierarchyInput)
  |({
    type: typeof jiraCustomHierarchyType
  } & JiraCustomHierarchyInput)

export type JiraMappingDefinitionInput = {
  storyPoints: string | null
  iteration: string | null
}

export type JiraConfigurationViewState = {
  context: ConfigurationContext
  backlogConfigurationId: string
  host: string
  project: string
  primKnowsSecret: boolean
  projects: Loadable<{ id: string; name: string }[], ErrorType<StreamType<JiraProjectsResource['value']>>>
  issueFields: LoadableFromGettable<JiraIssueFieldsResource>
  issueLinks: LoadableFromGettable<JiraIssueLinksResource>
  issueTypes: LoadableFromGettable<JiraIssueTypesResource>
  saveResponse: LoadableFromPuttable<BacklogConfigurationResource>
  hierarchyDefinition: JiraHierarchyInput
  mappingDefinition: JiraMappingDefinitionInput
} & JiraAuthenticationInfo

/////////////////////////

export type RedmineConfigurationProject = {
  id: number
  actualProjectIdsReturnedByRedmine: number[]
  name: string
}

export type ProjectCountInfo = {
  project: RedmineConfigurationProject
  totalCount: number
}

type ProjectIssues = {
  project: RedmineConfigurationProject
  issues: RedmineIssuesResponse
}

export const emptyRedminConfigurationProjectProgress = (project: RedmineConfigurationProject) => ({
  project,
  totalCount: 0,
  received: 0,
  percentageReceived: 0
})

export type ProjectProgress = ReturnType<typeof emptyRedminConfigurationProjectProgress>


export type RedmineViaClientConfigurationViewState = {
  backlogConfigurationId: string
  context: ConfigurationContext
  redmineApiUrl: string
  primKnowsConfig: boolean
  saveResponse: LoadableFromPuttable<BacklogConfigurationResource>
  sync: {
    totalCount: Loadable<number>
    projectCountResults: ProjectCountInfo[]
    countQueriesPending: number
    projectIssues: ProjectIssues[]
    projectProgress: ProjectProgress[]
    percentageIssuesLoaded: number
    credentials: CredentialsState
    showProgressDetails: boolean
    syncing: boolean
    canSync: boolean
    allIssuesLoaded: boolean
    uploadResponse: Loadable<unknown>
    availableProjects: Selectable<RedmineConfigurationProject>[]
  }
}

/////////////////////////

export type BacklogConfigurationsViewState = ({
  type: 'level3Required'
} |
{
  type: 'atLeastLevel3'
  context: ConfigurationContext
  backlogConfigurations: Level5ProjectConfiguration['backlogConfigurations']
}) & { adminMode: boolean }

export type BacklogConfigurationViewState =
  { type: 'initial'
    context: ConfigurationContext }
  |
  { type: 'notFound'
    context: ConfigurationContext }
  |
  { type: 'azure'
    state: AzureConfigurationViewState }
  |
  { type: 'jira'
    state: JiraConfigurationViewState }
  |
  { type: 'redmineViaClient'
    state: RedmineViaClientConfigurationViewState }
  |
  { type: 'new-azure'
    newState: AzureConfigurationViewState }
  |
  { type: 'new-jira'
    newState: JiraConfigurationViewState }
  |
  { type: 'new-redmineViaClient'
    newState: RedmineViaClientConfigurationViewState }

export type ConfigurationContext = {
  key: string
  user: UserState
  adminMode: boolean
  configuration: ProjectConfiguration
  tiaUsers: TiaUsersResponse | ErrorType<StreamType<TiaUsersResource['value']>>
  tiaCodes: Record<string, TiaTimesheetCode> | ErrorType<StreamType<TiaCodesResource['value']>>
}

export type LoadedModel = {
  case: States.Loaded
  key: string
  context: ConfigurationContext

  // child states
  general: ProjectDescriptionConfigurationViewState
  timeTrackerConfigurationViewState: TimeTrackerConfigurationViewState
  membersViewState: MembersViewState
  ordersViewState: OrdersViewState

  taskMappingViewState: TaskMappingViewState
  taskSettingsViewState: TaskSettingsViewState
  workItemMappingViewState: WorkItemMappingViewState
  sprintsViewState: SprintsViewState
  backlogConfigurationsViewState: BacklogConfigurationsViewState
  backlogConfigurationViewState: BacklogConfigurationViewState
  reportViewState: ReportParametersViewState
  // I/O state
  // tiaTimesheetCodesResults: Loadable<TiaTimesheetCodesResponse>
  // projectMembersConfigurationUpdateResponse: projectMembersConfigurationUpdateResponse
  // tiaMappingConfigurationUpdateResponse: Loadable<undefined, [SetTiaMappingConfigurationProblems, readonly DisplayProjectMember[]]>
}

//  const loadables = [ state.projectConfiguration, state.tiaUsers, state.tiaCodes, state.timeTrackerUsers, state.roles ] as const

export type ErrorIndexViewModel = {
  case: States.Error
  key: string
  problem:
  [ LoadingModel['projectConfiguration'],
    // LoadingModel['tiaUsers'],
    // LoadingModel['tiaCodes'],
    // LoadingModel['timeTrackerUsers'],
    LoadingModel['roles'] ] extends readonly Loadable<unknown, infer LoadError>[]
    ? LoadError
    : never

}

export type ViewModel = LoadingModel | LoadedModel | ErrorIndexViewModel

export const getProjectMembersForTiaCode = (rates: RateItem[], members: ProjectMember[], tiaCodeId: string) => {
  const applicableTiaUserIds = rates.filter(tm => tm.tiaCodeId === tiaCodeId).map(tm => tm.tiaUserId)

  return members.filter(m => applicableTiaUserIds.includes(m.tiaUserId))
}
