import { caseFactory, Cases, CaseType, CaseTypeFor, CaseTypeWithFields, CaseTypeWithoutFields, FOption, FTuple, isCaseValue } from '@/fsharp'
import { flatten, id, isArray, unique } from '@/generic'
import { Renderable } from '@/ui'
import { TimeTrackerTask } from '../resources'

// *** Client defined ***

export const InsufficientRights = caseFactory<CaseType<'InsufficientRights'>>()
export const insufficientRights = InsufficientRights.InsufficientRights()
export type InsufficientRights = CaseTypeFor<typeof InsufficientRights>

export const RequestError = caseFactory<CaseType<'RequestError', [string]>>()
export type RequestError = CaseTypeFor<typeof RequestError>

// *** Server defined ***

export const ProjectNotProblem = caseFactory<CaseType<'ProjectNotFound', [projectKey: string]>>()
export type ProjectNotFoundProblem = CaseTypeFor<typeof ProjectNotProblem>

export const MemberNotProblem = caseFactory<CaseType<'MemberNotFound', [tiaUserId: string]>>()
export type MemberNotFoundProblem = CaseTypeFor<typeof MemberNotProblem>


// Any user-code caught unhandled exception results in this problem (sent as a 500 status code)
type GenericUnhandledApiProblem<CaseName extends string> = CaseType<CaseName, [operationId: FOption<string>]>

export type UnhandledApiExceptionProblem = GenericUnhandledApiProblem<'UnhandledExceptionProblem'>

export type UnhandledTiaProblem = GenericUnhandledApiProblem<'UnhandledTiaProblem'>
export type UnhandledClockifyProblem = GenericUnhandledApiProblem<'UnhandledClockifyProblem'>
export type UnhandledPaymoProblem = GenericUnhandledApiProblem<'UnhandledPaymoProblem'>
export type UnhandledAzureDevOpsProblem = GenericUnhandledApiProblem<'UnhandledAzureDevOpsProblem'>
export type UnhandledJiraProblem = GenericUnhandledApiProblem<'UnhandledJiraProblem'>

export type TiaConfigurationInvalid = CaseType<'TiaConfigurationInvalid'>
export type PaymoConfigurationInvalid = CaseType<'PaymoConfigurationInvalid'>
export type ClockifyConfigurationInvalid = CaseType<'ClockifyConfigurationInvalid'>
export type AzureDevOpsConfigurationInvalid = CaseType<'AzureDevOpsConfigurationInvalid', [ backlogConfigurationId: string ]>
export type JiraConfigurationInvalid = CaseType<'JiraConfigurationInvalid', [ backlogConfigurationId: string ]>

export type UnhandledBacklogProblems =
  | CaseType<'UnhandledBacklogProblems', [ operationId: FOption<string>, backlogConfigurationIds: string[] ]>

export type TiaSyncProblem =
  | CaseType<'TiaSyncProblem', [ operationId: FOption<string> ]>

export type SimpleHttpError = [statusCode: number, reason: string, body: string | null]

export type ReadTimeTrackerUsersProblem =
  | CaseType<'WrongLevelProblem', [ WrongLevelProblem ] >
  | CaseType<'UnknownClockifyError', [ SimpleHttpError ] >
  | CaseType<'UnknownPaymoError', [ SimpleHttpError ] >
  | CaseType<'InvalidPaymoConfiguration'>

export type DurationValidityProblem =
  | CaseType<'MissingUntilDate', [Date]> // from date
  | CaseType<'OverlappingDateRange', [Date]> // from date

export type IndexedValidityDurationProblem =
  | CaseType<'MissingUntilDate', [index: number]>
  | CaseType<'OverlappingDateRange', [index: number, overlappingIndex: number]>

export type TimeTrackerConfigurationSettingWarning =
  | CaseType<'UserUnmappedFromTimeTracker', [tiaUserId: string]>

export type TimeTrackerConfigurationSettingProblem =
  | CaseType<'TimeEntryPaddingNotAllowed'>
  | CaseType<'TimeEntryPaddingCannotBeNegative'>

export type TimeTrackerConfigurationProblem =
  | CaseType<'ClockifyConfigurationProblem', [
    | CaseType<'ClockifyAuthorisationProblem'>
    | CaseType<'ClockifyWorkspaceNotFound', [clockifyWorkspaceId: string, lastKnownWorkspaceName: FOption<string>]>
    | CaseType<'ClockifyProjectsNotFound', [Array<[clockifyProjectId: string, lastKnownProjectName: FOption<string>]>]>
  ]>
  | CaseType<'PaymoConfigurationProblem', [
    | CaseType<'PaymoAuthorisationProblem'>
    | CaseType<'PaymoProjectsNotFound', [paymoProjectIds: FOption<number[]>]>
  ]>


export type ProjectProblem =
  | CaseType<'BadCharactersInKey', [string]>
  | CaseType<'MissingProjectFields', [string[]]>
  | CaseType<'TiaConfigInvalid'>
  | CaseType<'DuplicateKey', [string]>

export type SimpleAzureWorkItemParseProblem =
  | CaseType<'MissingId', [url: string]>
  | CaseType<'MissingField', [fieldName: string]>
  | CaseType<'MalformedField', [fieldName: string, value: unknown, exception: unknown]>

export type AzureWorkItemParseProblem =
  SimpleAzureWorkItemParseProblem
  | CaseType<'MultipleAzureWorkItemParseProblems', [problems: SimpleAzureWorkItemParseProblem[]]>

export type JiraWorkItemMappingProblem =
  | CaseType<'MissingField', [fieldName: string]>
  | CaseType<'MalformedField', [targetfieldName: string, sourceFieldName: string, value: string]>

export type BacklogWorkItemMappingProblem =
  | CaseType<'AzureWorkItemMappingProblem', [AzureWorkItemParseProblem]>
  | CaseType<'JiraWorkItemMappingProblem', [JiraWorkItemMappingProblem]>

export type WorkItemResolveProblemDetail =
  // item-level problems
  | CaseType<'ParentNotConfigured'>
  | CaseType<'TopLevelWorkItemCannotHaveParent'>
  | CaseType<'UnexpectedParentType', [parentId: string, parentUrl: string, parentWorkItemType: string]>
  | CaseType<'ParentNotFound', [parentId: string]>
  // tree-level problems
  | CaseType<'InvalidParent', [parentId: string]>

export type ClockifyTimeEntryNormalisationProblem =
  | CaseType<'MissingTask'>

export type PaymoTimeEntryNormalisationProblem =
  | CaseType<'DurationRequired'>

export type TimeEntryMappingProblem =
  | CaseType<'ClockifyTimeEntryNormalisationProblem', [ClockifyTimeEntryNormalisationProblem]>
  | CaseType<'PaymoTimeEntryNormalisationProblem', [PaymoTimeEntryNormalisationProblem]>


export type SetTimeTrackerConfigurationProblem =
  | CaseType<'TimeTrackerConfigurationProblem', [ TimeTrackerConfigurationProblem ]>
  | CaseType<'TimeTrackerConfigurationSettingProblem', [ TimeTrackerConfigurationSettingProblem ]>

// ---- Set Orders

export type RateDurationValidityProblem =
| CaseType<'MissingUntilDate', [index: number]>
| CaseType<'OverlappingDateRange', [index: number, overlappingIndex: number]>

export type OrderProblem =
| CaseType<'BadOrderField', [index: number, fieldNames: ('name' | 'budget')[]]>

export type RateProblem =
  | CaseType<'BadRateField', [index: number, fieldNames: ('TIA code id' | 'TIA user id' | 'daily rate' | 'from date' | 'until date')[]]>
  | CaseType<'TiaCodeNotFound', [index: number, tiaCodeName: string]>
  | CaseType<'ProjectMemberNotFound', [index: number, tiaUserId: string]>
  | CaseType<'RateDurationValidityProblem', [tiaUserId: string, tiaCodeName: string, problem: RateDurationValidityProblem]>

export type SetOrdersProblem =
  | CaseType<'OrderProblems', [ OrderProblem[] ]>
  | CaseType<'RateProblems', [ RateProblem[] ]>
  | CaseType<'TiaAuthorisationFailure'>

// ---- Set TaskMapping

export type TaskMappingProblem =
  | CaseType<'BadTaskMappingField', [fieldNames: string[]]>
  | CaseType<'RateNotFound', [tiaCodeName: string]>
  | CaseType<'TaskNotFound', [taskId: string]>
  | CaseType<'DurationValidityProblem', [taskId: string, tiaCodeName: string, problem: DurationValidityProblem]>

export type SetTaskMappingProblem =
  | CaseType<'TaskMappingProblems', [ TaskMappingProblem[] ]>
  | CaseType<'TimeTrackerConfigurationProblem', [ TimeTrackerConfigurationProblem ]>
  | CaseType<'WrongLevelProblem', [ WrongLevelProblem ]>

// -- Set Task Settings

export type TaskSettingsProblem =
  | CaseType<'BadTaskSettingField', [fieldNames: string[]]>
  | CaseType<'TaskNotFound', [taskId: string]>
  | CaseType<'RoleNotFound', [taskId: string, role: string]>

export type SetTaskSettingsProblem =
  | CaseType<'TaskSettingsProblems', [ TaskSettingsProblem[] ]>
  | CaseType<'TimeTrackerConfigurationProblem', [ TimeTrackerConfigurationProblem ]>
  | CaseType<'WrongLevelProblem', [ WrongLevelProblem ]>

// -- Set Work Item Mapping

export type WorkItemMappingProblem =
  | CaseType<'BadWorkItemMappingField', [fieldNames: string[]]>
  | CaseType<'RateForWorkItemNotFound', [tiaCodeName: string]>
  | CaseType<'WorkItemNotFound', [workItemId: string]>
  | CaseType<'WorkItemValidityDurationProblem', [workItemId: string, tiaCodeName: string, problem: DurationValidityProblem]>

export type SetWorkItemMappingProblem =
  | CaseType<'WorkItemMappingProblems', [ WorkItemMappingProblem[] ]>
  | CaseType<'WrongLevelProblem', [ WrongLevelProblem ]>

// -- Set Project Members

export type ProjectMemberConfigurationProblem =
  | CaseType<'MissingProjectMemberFields', [string[]]>
  | CaseType<'TimeTrackerUserNotFound', [string]>
  | CaseType<'TiaUserNotFound', [string]>
  | CaseType<'DuplicateTiaId', [string]>
  | CaseType<'DuplicateTimeTrackerUserId', [string]>
  | CaseType<'DuplicateCronosAdObjectId', [string]>
  | CaseType<'RoleNotFound', [tiaUserId: string, role: string]>
  | CaseType<'TiaAuthorisationFailure'>
  | CaseType<'TimeTrackerConfigurationProblem', [ TimeTrackerConfigurationProblem ]>

export type SetProjectMembersConfigurationProblem = ProjectMemberConfigurationProblem[]

// -- Set Sprints

export type SprintProblem =
  | CaseType<'BadSprintField', [index: number, fieldNames: ('name' | 'from date' | 'until date')[]]>
  | CaseType<'DuplicateSprintName', [index: number, sprintName: string]>
  | CaseType<'SprintOverlap', [index: number, overlappingSprintIndexes: number[]]> // sprint, overlapping sprint

export type SetSprintsProblem = SprintProblem[]

// -- Set Backlog Configuration

export type AzureConfigurationProblem =
  | CaseType<'AzureAuthorisationFailure'>
  | CaseType<'AzureProjectNotFound', [ azureProjectId: string ]>

export type JiraConfigurationProblem =
  | CaseType<'JiraAuthorisationFailure'>
  | CaseType<'JiraProjectNotFound', [ jiraProjectId: string ]>

export type SetBacklogConfigurationProblem =
  | CaseType<'AzureConfigurationProblem', [ AzureConfigurationProblem ]>
  | CaseType<'JiraConfigurationProblem', [ JiraConfigurationProblem ]>
  | CaseType<'WrongLevelProblem', [ WrongLevelProblem ]>

export type TimeEntryResolveProblemDetail =
  | CaseType<'TaskNotFound'>
  | CaseType<'BacklogItemRequired', [task: TimeTrackerTask]>
  | CaseType<'ProjectMemberNotFound'>

export type UndistributableReason =
  | CaseType<'InvalidScope'>
  | CaseType<'NoSpecificWork'>

export type TimeEntryReportProblemDetail =
  | CaseType<'WorkItemNotFound', [workItemId: string]>
  | CaseType<'NoWorkItemMappingApplicable', [workItemId: string, workItemUrl: string]>
  | CaseType<'MultipleWorkItemMappingsApplicable', [workItemId: string, workItemUrl: string,
    order1Name: string, rate1TiaCodeName: string, order2Name: string, rate2TiaCodeName: string]>
  | CaseType<'NoTaskMappingApplicable'>
  | CaseType<'MultipleTaskMappingsApplicable', [order1Name: string, rate1TiaCodeName: string, order2Name: string, rate2TiaCodeName: string]>
  | CaseType<'OverlappingTimeEntry', [overlappingEntries: FTuple<[string, Date]>[]]>
  | CaseType<'UndistributableTimeEntry', [reason: UndistributableReason]>

export type ConfigurationProblem<T> =
  | CaseType<'ConfigurationProblem', [configurationProblem: T]>

export type WrongLevelProblem =
  | CaseType<'WrongLevel', [ expectedLevel: string ]>

export type WorkItemAdapterProblem =
  | CaseType<'UnknownConnectionProblem', [problem: unknown]>

export type PaymoAdapterError =
    | CaseType<'Unknown', [httpError: SimpleHttpError]>

export type TimeTrackerAdapterProblem =
  | CaseType<'PaymoAdapterProblem', [PaymoAdapterError]>
  | CaseType<'ClockifyAdapterProblem', [httpErrors: SimpleHttpError[]]>

export type AdapterProblem =
  | CaseType<'TimeTrackerAdapterProblem', [ TimeTrackerAdapterProblem ]>
  | CaseType<'WorkItemAdapterProblem', [ WorkItemAdapterProblem ]>
  | CaseType<'TiaAdapterProblem', [ SimpleHttpError ]>

export type UserTimesheetProblem =
  | WrongLevelProblem
  | UnhandledClockifyProblem
  | UnhandledPaymoProblem
  | PaymoConfigurationInvalid
  | UnhandledAzureDevOpsProblem
  | UnhandledJiraProblem

export type Problem<Args extends any[] | never> = CaseType<string, Args>

/// Crucial in these types is the [X] extends [Y] type check pattern.
/// This is behaviourally different from the more natural X extends Y pattern.
/// The difference is in whether the types are handled distributively or not
/// In these cases, since we want to infer a discriminate union that wants to be treated as a whole, we don't want distribute behaviour
export type ProblemRenderConfig<TProblem extends CaseType<string, any>> =
  { [K in Cases<TProblem>]:
    TProblem extends CaseTypeWithFields<K, infer V>
      ? V extends [infer U]
        ? [U] extends [CaseType<string, any>]
          ? ProblemRenderConfig<U>
          : ((...args: V) => Renderable)
        : ((...args: V) => Renderable)
      : TProblem extends CaseTypeWithoutFields<K>
        ? () => Renderable
        : never }

export type ValidationResult<TProblem extends CaseType<string, any>> =
  { [K in Cases<TProblem>]?:
    TProblem extends CaseTypeWithFields<K, infer V>
      ? V extends [infer U] & { length: 1 }
        ? [U] extends [CaseType<string, any>]
          ? ValidationResult<U>
          : {
            [K in keyof V]: V[K] extends infer W ? [W] extends [CaseType<string, any>] ? ValidationResult<W> : V[K] : V[K]
          }
        : {
          [K in keyof V]: V[K] extends infer W ? [W] extends [CaseType<string, any>] ? ValidationResult<W> : V[K] : V[K]
        }
      : TProblem extends CaseTypeWithoutFields<K>
        ? true
        : never }

export type ValidationConfig<TProblem extends CaseType<string, any>> =
  { [K in Cases<TProblem>]?:
    TProblem extends CaseTypeWithFields<K, infer V>
      ? V extends [infer U] & { length: 1 }
        ? [U] extends [CaseType<string, any>]
          ? (ValidationConfig<U> | ((problems: U[]) => any))
          : (problems: V[]) => any
        : (problems: V[]) => any
      : TProblem extends CaseTypeWithoutFields<K>
        ? (problems: TProblem[]) => any
        : never }



export const toValidationResult = <MyProblem extends CaseType<string, any[] | undefined>>(problem: MyProblem): ValidationResult<MyProblem> => {
  const fields: any[] | undefined = (problem as CaseType<string, any[]>).fields
  const singleFieldValue = fields?.length === 1 ? fields[0] : undefined

  const validatedCaseValue = isCaseValue(singleFieldValue) ? toValidationResult(singleFieldValue) : (fields || true)

  return {
    [problem.case]: validatedCaseValue
  } as any
}

export const mergeValidationResults = <Result extends ValidationResult<any>>(results: Result[]): Result => {
  const keys = unique(flatten(results.map(obj => Object.keys(obj))))

  const result: any = {}

  for (const key of keys) {
    const values = results.map(result => result[key]).filter(id)
    if (isArray(values[0])) {
      if (values.length > 1) {
        console.warn('Discarding validation errors')
      }
      result[key] = values[0]
    } else {
      result[key] = mergeValidationResults(values as any[])
    }
  }

  return result
}
