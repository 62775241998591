import { id } from './util'

export function isArray<T extends any[], Y>(o: T | Y): o is T {
  // eslint-disable-next-line no-prototype-builtins
  return o instanceof Array || (!!o && typeof (o as any).length === 'number' && !(o as any).propertyIsEnumerable('length') && typeof (o as any).splice === 'function')
}

export function flatten<T>(ar: T[][]): T[] {
  const x = ar.slice()
  let i = x.length
  while (i--) {
    if (isArray(x[i])) {
      (x as any).splice.apply(x, [i, 1].concat(x[i] as any) as any)
    }
  }
  return (x as any) as T[]
}

export function deepFlatten<T>(ar: T[][]): readonly T[] {
  const x = ar.slice()
  let i = x.length
  while (i--) {
    if (isArray(x[i])) {
      (x as any).splice.apply(x, [i, 1].concat(deepFlatten(x[i] as any) as any) as any)
    }
  }
  return x as any
}

export function groupBy<T, TKey, TResult>(xs: readonly T[], getKey: (x: T) => TKey, toResult: (entry: [TKey, readonly T[]]) => TResult): readonly TResult[] {
  const map = new Map<TKey, T[]>()
  for (const val of xs) {
    const key = getKey(val)
    if (map.has(key)) {
      map.get(key)!.push(val)
    } else {
      map.set(key, [val])
    }
  }

  return Array.from(map.entries(), toResult)
}

export function orderBy<T>(getKey: (x: T) => number, xs: readonly T[]): readonly T[]
export function orderBy<T>(getKey: (x: T) => string, xs: readonly T[]): readonly T[]
export function orderBy<T>(getKey: (x: T) => number | string, xs: readonly T[]): readonly T[] {
  return xs.slice().sort((a, b) => {
    const ka = getKey(a)
    const kb = getKey(b)

    if (typeof ka === 'string' && typeof kb === 'string') {
      return ka < kb ? -1 : ka > kb ? 1 : 0
    }

    if (typeof ka === 'number' && typeof kb === 'number') {
      return ka - kb
    }

    throw new Error('Keys must be both a number or both a string')
  })
}

export function orderByDescending<T>(getKey: (x: T) => number, xs: readonly T[]): readonly T[]
export function orderByDescending<T>(getKey: (x: T) => string, xs: readonly T[]): readonly T[]
export function orderByDescending<T>(getKey: (x: T) => number | string, xs: readonly T[]): readonly T[] {
  return xs.slice().sort((a, b) => {
    const ka = getKey(a)
    const kb = getKey(b)

    if (typeof ka === 'string' && typeof kb === 'string') {
      return kb < ka ? -1 : kb > ka ? 1 : 0
    }

    if (typeof ka === 'number' && typeof kb === 'number') {
      return kb - ka
    }

    throw new Error('Keys must be both a number or both a string')
  })
}

export const orderWith = <T>(comparer: (a: T, b: T) => number) => (xs: readonly T[]) => {
  return xs.slice().sort(comparer)
}

export function nwise<T>(n: number, xs: T[]) {
  return xs
    .reduce(([lastTuple, ...tuples], x, i) => (i % n ? [[...lastTuple, x], ...tuples] : [[x], lastTuple, ...tuples]), [] as T[][])
    .reverse()
    .slice(1)
}

export function pairwise<T>(xs: T[]) {
  return nwise(2, xs) as Array<[T, T]>
}

export const setAt = (i: number) => <T>(x: T) => (xs: T[]) => {
  const copy = xs.slice()
  copy[i] = x
  return copy
}

export const setIn = <T>(p: (x: T) => boolean, f: (x: T) => T) => (xs: readonly T[]) => xs.map(x => p(x) ? f(x) : x) as readonly T[]

export const removeAt = (i: number) => {
  function removeAtIndex<T>(xs: readonly T[]): readonly T[]
  function removeAtIndex<T>(xs: T[]): T[]
  function removeAtIndex(xs: any[] | readonly any[]) {
    const copy = xs.slice()
    copy.splice(i, 1)
    return copy
  }

  return removeAtIndex
}

export const push = <T>(x: T) => (xs: T[]) => {
  const copy = xs.slice()
  copy.push(x)
  return copy
}

export function pop<T>(xs: T[]) {
  return xs.slice(0, xs.length - 1)
}

export const swapItems = (index1: number) => (index2: number) => <T>(items: T[]) => {
  const copy = items.slice()

  copy[index1] = items[index2]
  copy[index2] = items[index1]

  return copy
}

export const moveItemUp = (index: number) => (index ? swapItems(index)(index - 1) : id)
export const moveItemDown = (index: number) => <T>(items: T[]) => (index === items.length - 1 ? items : swapItems(index)(index + 1)(items))

export const append = <T>(x: T) => (xs: readonly T[]) => [...xs, x] as readonly T[]
export const concat = <T>(xs: readonly T[]) => (ys: readonly T[]) => [...ys, ...xs] as readonly T[]
export const remove = <T>(x: T) => (xs: readonly T[]) => removeAt(xs.indexOf(x))(xs)

export function seq(size: number, offset = 0) {
  return Array.from(Array(size)).map((_, i) => offset + i)
}

export function toDictionary<T>(xs: T[], keyFn: (x: T) => string): { [key: string]: T }
export function toDictionary<T, T2>(xs: T[], keyFn: (x: T) => string, valueFn: (x: T) => T2): { [key: string]: T2 }
export function toDictionary<T, T2 = T>(xs: T[], keyFn: (x: T) => string, valueFn: (x: T) => T2 = (id as unknown) as (x: T) => T2) {
  const dict: { [key: string]: T2 } = {}

  for (const x of xs) {
    dict[keyFn(x)] = valueFn(x)
  }

  return dict
}

export function except<T>(source: T[]) {
  return (valuesToExclude: T[]) => {
    const sourceSet = new Set(source)
    for (const valueToExclud of valuesToExclude) {
      sourceSet.delete(valueToExclud)
    }

    return Array.from(sourceSet)
  }
}
