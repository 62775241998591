import { InstancesX } from '@cycle/state'
import { RouterSource } from 'cyclic-router'
import xs from 'xstream'
import { Lens, write } from '../generic'

export const redirectPath = (to: string) => ({ router }: { router: RouterSource }) => {
  return ({ router: xs.of(router.createHref(to)) })
}

export const fromSymmetric = <T, TC>(get: Lens<T, TC>) => ({
  get: get,
  set: (state: T, childState: TC) => write(get)(childState)(state)
})
export const stateScope = <T, TC>(scope: string | { get: (state: T) => TC; set: (state: T, childState: TC) => T }, otherScopes?: string | null) =>
  ({ '*': otherScopes ?? null, state: scope })

export const innerInstances = <Si extends any>(instances: InstancesX<Si>) => (instances as any)._instances$ as (typeof instances)['_instances$']
export const omit = <T, K extends keyof T>(o: T, ...keys: K[]) => {
  const result = { ...o }
  for (const key of keys) {
    delete result[key]
  }
  return result as Omit<T, K>
}
