import isolate from '@cycle/isolate'
import xs, { MemoryStream } from 'xstream'
import dropRepeats from 'xstream/extra/dropRepeats'
import { isApplicationAdmin, isUser, Unknown } from '../../../authentication'
import { C, id, isLoaded, isLoadError, isLoading, K, Loadable, loaded, loadError, loadErrorMessage, loading, Manager, mapError, mapLoaded, mergeSinks, StreamType, toDictionary, Unloaded, Updater, value } from '../../../generic'
import { VNode } from 'snabbdom'
import { jsx } from 'h'
import { DERIVED, dynamicCaseModel, dynamicIntent, IGNORE, INIT, mergePageSinks, pageSinkMergeTemplate, PageSinks, pageSinkTemplate, SinkMergeTemplateItem, withRouterBehaviour } from '../../../infrastructure'
import { Col, Loading, Nav, NavItem, NavLink as NavLinkType, NavLinkCreator, NavSubitem, Row, Danger, Container } from '../../../ui'
import { magicMirror, redirectPath } from '../../../util'
import { isLevel3OrHigher } from '../../model'
import { serviceItemToIntent } from '../../primDataService'
import { ProjectPageSources } from '../common'
import { ExistingBacklogConfigurationComponent, BacklogConfigurationsComponent, BacklogConfigurationSources, backlogConfigurationsViewState, backlogConfigurationViewState, NewBacklogConfigurationSources, NewBacklogConfigurationComponent } from './backlog'
import { GeneralSettingsComponent, projectDescriptionConfigurationViewState } from './generalSettings'
import { issueAfleidingenViewState, WorkItemMappingComponent } from './issueMappings'
import { OrdersConfigurationComponent } from './orders'
import { ReportParametersConfigurationComponent, reportParametersViewState } from './reportParameters'
import { SprintsConfigurationComponent, sprintsViewState } from './sprints'
import { taakAfleidingenViewState, TaskMappingComponent } from './taskMappings'
import { TaskSettingsComponent, taskSettingsViewState } from './taskSettings'
import { MembersComponent as MembersComponent, membersViewState } from './members'
import { TimeTrackerConfigurationComponent, timeTrackerConfigurationViewState } from './timeTracker/timeTrackerConfiguration'
import { ConfigurationContext, DisplayProjectMember, ErrorIndexViewModel, LoadedModel, LoadingModel, States, ViewModel } from './viewModel'
import { GetPowerBITemplatePage } from './reportTemplate'
import { ordersViewState } from './orders/model'
import { PrimPageSources } from '../types'
import { insufficientRightsRenderConfig, projectNotFoundRenderConfig, renderProblem, requestErrorRenderConfig, unhandledApiExceptionRenderConfig } from '../renderers'

type ProjectConfigurationPageSources = ProjectPageSources<ViewModel>
& {
  mirror: {
    saveProjectMembers: ReturnType<typeof MembersComponent>['saveProjectMembers']
  }
}

const defaultViewModel = (key: string): ViewModel => ({
  key: key,
  case: States.Loading,
  user: Unknown,
  adminMode: false,
  data: Unloaded,
  tiaUsers: Unloaded,
  tiaCodes: Unloaded,
  timeTrackerUsers: Unloaded,
  projectConfiguration: Unloaded,
  roles: Unloaded
})

const intent = (sources: ProjectConfigurationPageSources) => dynamicIntent<ViewModel>()(
  pageSinkTemplate, {
    setUser: sources.user,
    setAdminMode: xs.combine(sources.user, sources.query)
      .map(([userState, queryParams]) => isUser(userState) && isApplicationAdmin(userState) && ('admin' in queryParams || 'Admin' in queryParams)),
    loadTiaUsers: {
      discriminator: state => `${state.key} - ${state.case === States.Loading}`,
      value: state => state.case === States.Loading ? serviceItemToIntent(sources.primState.tiaUsers(state.key)) : xs.never()
    },
    loadProjectConfiguration: {
      discriminator: state => state.key,
      value: state => serviceItemToIntent(sources.primState.projectConfiguration(state.key))
    },
    loadTiaCodes: {
      discriminator: state => state.key,
      value: state => serviceItemToIntent(sources.primState.tiaTimesheetCodes(state.key))
    },
    timeTrackerUsers: {
      discriminator: state =>
        state.case === States.Loading
      && isLoaded(state.projectConfiguration)
      && isLevel3OrHigher(value(state.projectConfiguration).levelConfiguration),
      value: (state, needTimeTrackerUsers: boolean) => needTimeTrackerUsers ? serviceItemToIntent(sources.primState.timeTrackerUsers(state.key)) : xs.never()
    },
    saveProjectMembers: sources.mirror.saveProjectMembers,
    putProjectMembersResponse: {
      discriminator: state => state.key,
      value: state => sources.primState.projectMembersConfiguration(state.key).putResponse
    }
  })

const maybeToLoadedState = (state: LoadingModel): ViewModel => {
  if ((isLoaded(state.tiaUsers) || isLoadError(state.tiaUsers))
    && (isLoaded(state.tiaCodes) || isLoadError(state.tiaCodes))
    && isLoaded(state.projectConfiguration)
    && (!isLevel3OrHigher(value(state.projectConfiguration).levelConfiguration) || (isLoaded(state.timeTrackerUsers) || isLoadError(state.timeTrackerUsers)))) {
    const timeTrackerUsers =
      isLoaded(state.timeTrackerUsers)
        ? value(state.timeTrackerUsers)
        : isLoadError(state.timeTrackerUsers)
          ? loadErrorMessage(state.timeTrackerUsers)
          : null
    const roles = isLoaded(state.roles) ? value(state.roles) : null

    const context: ConfigurationContext = {
      key: state.key,
      user: state.user,
      adminMode: state.adminMode,
      configuration: value(state.projectConfiguration),
      tiaUsers: isLoaded(state.tiaUsers) ? value(state.tiaUsers) : loadErrorMessage(state.tiaUsers),
      tiaCodes: isLoaded(state.tiaCodes) ? value(state.tiaCodes) : loadErrorMessage(state.tiaCodes)
    }

    return {
      case: States.Loaded,
      key: state.key,
      context: context,
      general: projectDescriptionConfigurationViewState(context),
      membersViewState: membersViewState(context, timeTrackerUsers, roles),
      timeTrackerConfigurationViewState: timeTrackerConfigurationViewState(context),
      ordersViewState: ordersViewState(context),
      taskMappingViewState: taakAfleidingenViewState(context),
      taskSettingsViewState: taskSettingsViewState(context),
      workItemMappingViewState: issueAfleidingenViewState(context),
      sprintsViewState: sprintsViewState(context),
      backlogConfigurationsViewState: backlogConfigurationsViewState(context),
      backlogConfigurationViewState: backlogConfigurationViewState(context),
      reportViewState: reportParametersViewState(context)
    }
  }

  const loadables = [ state.projectConfiguration, state.roles ] as const

  const maybeError =
    (loadables as readonly Loadable<unknown, ErrorIndexViewModel['problem']>[])
      .reduce(
        (error, loadable) =>
          error
            ? error
            : isLoadError(loadable)
              ? loadErrorMessage(loadable)
              : null,
        null as ErrorIndexViewModel['problem'] | null)

  if (maybeError) {
    return {
      case: States.Error,
      key: state.key,
      problem: maybeError
    }
  }

  return state
}

const { set: setLoading } = Manager<LoadingModel>()
const { set: setLoaded } = Manager<LoadedModel>()

const updateContexts =
  setLoaded(state => state.membersViewState.context)((_, state) => state.context)
    .then(setLoaded(state => state.taskMappingViewState.context)((_, state) => state.context))
    .then(setLoaded(state => state.workItemMappingViewState.context)((_, state) => state.context))

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const then = <T extends any>(updater: Updater<T>) => (u: Updater<T>) => u.then(updater)

const model = (intents: ReturnType<typeof intent>, state$: MemoryStream<ViewModel>, sources: ProjectPageSources<ViewModel>) =>
  dynamicCaseModel(state$)(
    pageSinkTemplate,
    intents, {
      [INIT]: K(defaultViewModel(sources.projectKey)),
      setUser: {
        [States.Loading]: setLoading(state => state.user),
        [States.Loaded]: C(setLoaded(state => state.context.user), then(updateContexts))
      },
      setAdminMode: {
        [States.Loading]: setLoading(state => state.adminMode),
        [States.Loaded]: C(setLoaded(state => state.context.adminMode), then(updateContexts))
      },
      loadProjectConfiguration: {
        [States.Loading]: val => C(setLoading(state => state.projectConfiguration)(val), maybeToLoadedState),
        [States.Loaded]: val => state => isLoading(val)
          ? {
            case: States.Loading,
            key: state.key,
            user: state.context.user,
            adminMode: state.context.adminMode,
            data: loading(loaded(state)),
            tiaUsers: 'case' in state.context.tiaUsers ? loadError(state.context.tiaUsers) : loaded(state.context.tiaUsers),
            tiaCodes: typeof state.context.tiaCodes.case === 'string' ? loadError(state.context.tiaCodes) : loaded(state.context.tiaCodes),
            timeTrackerUsers: Unloaded,
            roles: Unloaded,
            projectConfiguration: val
          }
          : state
      },
      loadTiaUsers: {
        [States.Loading]: val => C(setLoading(state => state.tiaUsers)(val), maybeToLoadedState),
      },
      loadTiaCodes: {
        [States.Loading]: val => C(setLoading(state => state.tiaCodes)(mapLoaded(val, codes => toDictionary(codes, c => c.id, id))), maybeToLoadedState),
      },
      timeTrackerUsers: {
        [States.Loading]: val => C(setLoading(state => state.timeTrackerUsers)(val), maybeToLoadedState),
      },
      saveProjectMembers: {
        [States.Loaded]: {
          output: members => state => ({
            HTTP: [sources.primState.projectMembersConfiguration(state.key).put(members.map(pm => ({
              timeTrackerId: pm.timeTrackerId,
              tiaUserId: pm.tiaUserId,
              displayName: pm.displayName,
              role: pm.role
            })))]
          })
        }
      },
      putProjectMembersResponse: {
        [States.Loaded]: {
          state: response => state =>
            setLoaded(s =>
              s.membersViewState.projectMembersConfigurationUpdateResponse)(
              mapError(response, problems => [problems, state.membersViewState.projectMembers]))(state),
          output: response => _state => ({
            HTTP: isLoaded(response) ? [sources.primState.projectConfiguration(sources.projectKey).refresh] : []
          })
        }},
      [DERIVED]: IGNORE
    })

const configurationNavigation = (NavLink: NavLinkType) =>
  <Nav className="d-inline-block">
    <NavItem><NavLink href="/general">Project configuration</NavLink></NavItem>
    <NavSubitem><NavLink href="/general">General</NavLink></NavSubitem>
    <NavSubitem><NavLink href="/timetracker">Time tracker configuration</NavLink></NavSubitem>
    <NavSubitem><NavLink href="/tasks">Task settings</NavLink></NavSubitem>
    <NavSubitem><NavLink href="/backlog">Backlog configuration</NavLink></NavSubitem>
    <NavItem><NavLink href="/members">Team configuration</NavLink></NavItem>
    <NavSubitem><NavLink href="/members">Members</NavLink></NavSubitem>
    <NavSubitem><NavLink href="/orders">Orders and rates</NavLink></NavSubitem>
    <NavSubitem><NavLink href="/sprints">Sprints</NavLink></NavSubitem>
    <NavItem><NavLink href="/task-based">Derivations</NavLink></NavItem>
    <NavSubitem><NavLink href="/task-based">Task-based</NavLink></NavSubitem>
    <NavSubitem><NavLink href="/backlog-item-based">Backlog item-based</NavLink></NavSubitem>
    <NavItem><NavLink href="/parameters">Report</NavLink></NavItem>
    <NavSubitem><NavLink href="/parameters">Parameters</NavLink></NavSubitem>
    <NavSubitem><NavLink href="/template">Template</NavLink></NavSubitem>
  </Nav>

type MySink = {
  DOM: xs<VNode>
  saveProjectMembers: xs<readonly DisplayProjectMember[]>
}

type ProjectConfigurationPageSinks = PageSinks & { saveProjectMembers?: ReturnType<typeof MembersComponent>['saveProjectMembers'] }
const configurationPageSinkMergeTemplate = {
  ...pageSinkMergeTemplate,
  saveProjectMembers: null as SinkMergeTemplateItem<StreamType<ReturnType<typeof MembersComponent>['saveProjectMembers']>>
}

function ConfigurationInnerPageComponent(sources: ProjectConfigurationPageSources) {
  const NavLink = NavLinkCreator(sources.router)

  // this is VERY ugly - find a better way to deal with sink & page sink templates...

  const loadedOnlyStateScope = (property: keyof (ViewModel & { case: States.Loaded })) =>
    ({
      '*': null,
      state: property
    // state: {
    //   get: (s: ViewModel) => (!s || s.case === States.Loading) ? undefined : s[property] ,
    //   set: (s: ViewModel, ss: (ViewModel & { case: States.Loaded })[typeof property]) => ({ ...s, [property]: ss })
    // }
    })

  const loadedStateScope = (property: keyof (ViewModel & { case: States.Loaded })) =>
    ({
    // ...loadedOnlyStateScope(property),
      '*': property
    })

  const innerPage$ = withRouterBehaviour<ProjectConfigurationPageSinks, ProjectConfigurationPageSources>(sources, {
    '/': redirectPath('/general'),
    '/general': isolate(GeneralSettingsComponent, loadedStateScope('general')),
    '/timetracker': isolate(TimeTrackerConfigurationComponent, loadedOnlyStateScope('timeTrackerConfigurationViewState')),
    '/tasks': isolate(TaskSettingsComponent, loadedOnlyStateScope('taskSettingsViewState')),
    '/backlog-item-based': isolate(WorkItemMappingComponent, loadedStateScope('workItemMappingViewState')),
    '/backlog': isolate(BacklogConfigurationsComponent, loadedStateScope('backlogConfigurationsViewState')),
    '/backlog/:backlogConfigurationId':
      (backlogConfigurationId: string) =>
        (sources: PrimPageSources) =>
          isolate(
            ExistingBacklogConfigurationComponent,
            'backlogConfigurationViewState')({ ...sources, backlogConfigurationId: backlogConfigurationId } as BacklogConfigurationSources),
    '/backlog/new/:type':
        (type: 'azure' | 'jira' | 'redmineViaClient') =>
          (sources: PrimPageSources) =>
            isolate(
              NewBacklogConfigurationComponent,
              'backlogConfigurationViewState')({ ...sources, type: type } as NewBacklogConfigurationSources),
    '/members': isolate(MembersComponent, loadedStateScope('membersViewState')),
    '/orders': isolate(OrdersConfigurationComponent, loadedStateScope('ordersViewState')),
    '/sprints': isolate(SprintsConfigurationComponent, loadedStateScope('sprintsViewState')),
    '/task-based': isolate(TaskMappingComponent, loadedStateScope('taskMappingViewState')),
    '/parameters': isolate(ReportParametersConfigurationComponent, loadedStateScope('reportViewState')),
    '/template': isolate(GetPowerBITemplatePage, loadedStateScope('reportViewState'))
  })

  const pageSinks = mergePageSinks(innerPage$, configurationPageSinkMergeTemplate)

  return {
    ...pageSinks,
    DOM: pageSinks.DOM.map(page => <Row className="page-height">
      <Col className="config-nav" width={2}>
        {configurationNavigation(NavLink)}
      </Col>
      <Col className='mt-3'>
        {page}
      </Col>
    </Row>)
  }
}

export const ProjectConfigurationIndex = magicMirror<MySink, keyof MySink, ProjectConfigurationPageSources>((sources: ProjectConfigurationPageSources) => {
  const sinks = model(intent(sources), sources.state.stream, sources)

  const page$ =
    sources.state.stream
      .map(state => (state.case === States.Loading || state.case === States.Loaded) ? state.case : state.problem)
      .compose(dropRepeats())
      .map(state => {
        if (state === States.Loading) {
          return {
            DOM: xs.of(<Loading />)
          } as ProjectConfigurationPageSinks
        } else if (state === States.Loaded)  {
          return ConfigurationInnerPageComponent(sources)
        } else {
          return {
            DOM: xs.of(<Container><Danger>{renderProblem({
              ...requestErrorRenderConfig,
              ...projectNotFoundRenderConfig,
              ...insufficientRightsRenderConfig,
              ...unhandledApiExceptionRenderConfig
            })(state)}</Danger></Container>)
          } as ProjectConfigurationPageSinks
        }
      })

  const outerPageSinks = mergePageSinks(page$, configurationPageSinkMergeTemplate)

  return mergeSinks({
    ...outerPageSinks,
    router: outerPageSinks.router,
    DOM: outerPageSinks.DOM
  }, sinks)
})
