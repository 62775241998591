import { jsx } from '@/h'
import { createColumnHelper } from '@tanstack/table-core'
import format from 'date-fns/format'
import { Button, Icon, Input, dateInputFormat } from '@/ui'
import { TaskMappingItem, isMissingTiaTimesheetCode, isMissingTimeTrackerTask } from '../viewModel'
import { taskDisplayName } from '../../shared'

const columnHelper = createColumnHelper<TaskMappingItem>()

export const taskMappingsTableColumns = [
  columnHelper.accessor(row => row.task, {
    id: 'task',
    header: 'Task',
    cell: info => {
      const task = info.getValue()
      return typeof task === 'string'
        ? ''
        : isMissingTimeTrackerTask(task)
          ? <div className="large-invalid-feedback">Missing task with id {task.missingTimeTrackerTaskId}</div>
          : taskDisplayName(task)
    }
    // aggregatedCell: info => info.getValue(),
    // aggregationFn: '',
  }),
  columnHelper.accessor(row => row.tiaCode, {
    id: 'tiaCode',
    header: 'TIA-code',
    cell: info => {
      const tiaCode = info.getValue()
      return isMissingTiaTimesheetCode(tiaCode)
        ? <span title={`Missing TIA code with id ${tiaCode.missingTiaCodeId}!`}>{tiaCode.missingTiaCodeName}<Icon icon="alert-triangle" color="red" /></span>
        : tiaCode.writable
          ? tiaCode.name
          : <span title="This TIA code is read only!">{tiaCode.name}<Icon icon="alert-triangle" color="orange" /></span>
    },
    // aggregatedCell: info => <strong>{info.renderValue()}</strong>,
    // aggregationFn: 'sum'
  }),
  columnHelper.accessor(row => row.projectMembers, {
    id: 'projectMembers',
    header: 'Members',
    cell: info => {
      const projectMembers = info.getValue()
      return projectMembers.map(m => m.displayName).join(', ')
    }
  }),
  columnHelper.accessor(row => row.from, {
    id: 'from',
    header: 'From',
    cell: info => {
      const from = info.getValue()
      return <Input type="date" className="from" data-index={info.row.index} value={from ? format(from, dateInputFormat) : ''} />
    }
  }),
  columnHelper.accessor(row => row.until, {
    id: 'until',
    header: 'Until',
    cell: info => {
      const until = info.getValue()
      return <Input type="date" className="until" data-index={info.row.index} value={until ? format(until, dateInputFormat) : ''} />
    }
  }),
  columnHelper.display({
    id: 'delete',
    cell: info => <Button context="danger" className="delete" data-index={info.row.index}><Icon icon="x" /></Button>
  })
]

