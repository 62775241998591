import { InputOrderItem, OrderItem, OrdersViewState, RateItemInputModel, RateItemViewModel } from './viewModel'
import { isLoaded, loaded, Manager, Unloaded } from '../../../../generic'
import { getSuggestedItems, Suggestable } from '../../autoSuggest'
import { ProjectMember } from '../../../model'

export const { get, set } = Manager<OrdersViewState>()

export const KEY_NEW_ORDER = 'neworder'

export const getMembersSuggestables = (projectMembers: ProjectMember[] | null) =>
  projectMembers ? loaded(projectMembers.map((m): Suggestable<typeof m> => ({ display: m.displayName, value: m }))) : Unloaded

export const canAddRate = (order: OrderItem) => (isLoaded(getSuggestedItems(order.newRateItem.tiaCodeInput)) && order.newRateItem.from)

export const toRateItemViewModel = (rateInput: RateItemInputModel, index: number, state: OrdersViewState) : RateItemViewModel => ({
  ...rateInput,
  index: index,
  projectMembers: state.projectMembers,
  tiaUsers: state.tiaUsers
})

export const isValidNumber = (maybeNumber: string | number) => {
  const stringRate = maybeNumber.toString()
  if (stringRate.length > 1 && stringRate.startsWith('0')) {
    return false
  }
  return maybeNumber.toString().match(/^\d*\.?\d+$/)
}

export const canSaveOrder = (order: InputOrderItem) => order.name && order.budget && isValidNumber(order.budget)

const canSaveRates = (rates: readonly RateItemInputModel[]) => (rates.every(r => isValidNumber(r.dailyRate) && r.projectMember))

export const canSaveOrders = (state: OrdersViewState) => (state.orders.every(o => isValidNumber(o.budget)) && state.orders.every(o => canSaveRates(o.rates)))

export const canCopyOrdersForMembers = (state: OrdersViewState) =>
  state.copyTiaUserSource
  && state.copyTiaUserDestination
  && state.copyTiaUserSource !== state.copyTiaUserDestination

export const rateIsValidForDay = (date: Date, rate: RateItemInputModel) =>
  rate.from <= date && (rate.until == null || rate.until >= date)
