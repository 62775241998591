import xs from 'xstream'
import { isArray } from '../generic'
import { DriverSinkType } from './driverUtil'

export function focusSinkDriver(needFocus$: xs<string | HTMLElement | (HTMLElement | string)[]>) {
  needFocus$.addListener({
    next: elSpecs => {
      elSpecs = isArray(elSpecs) ? elSpecs : [elSpecs]
      elSpecs.forEach(_elSpec => {
        // const resolved = typeof elSpec === 'string' ? $(elSpec) : ((elSpec as any).original ? (elSpec as any).original : elSpec)
        // // driver only used by the tia-search functionality which needs a "refocus" to trigger the datalist, in FF anyway
        // // chrome doesn't like it but w/e, it's got an alternative ui
        // resolved.blur()
        // resolved.focus()
      })
    },
    error: () => void 0,
    complete: () => void 0
  })
  return xs.empty()
}

export type FocusSink = DriverSinkType<typeof focusSinkDriver>
