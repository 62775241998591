import { StreamType } from '@/generic'
import { IntentDefinitionWithSinks, SINKS } from '../../infrastructure'
import { once } from '../../util'
import { GettableResource } from './resourceTypes'

export const toIntent = <T extends GettableResource<any, any, any>>(serviceItem: T)
: IntentDefinitionWithSinks<StreamType<(typeof serviceItem)['value']>, { HTTP: null }> =>
  ({
    intent: serviceItem.value as any,
    [SINKS]: {
      HTTP: once(serviceItem.get)
    }
  })
