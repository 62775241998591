import xs from 'xstream'
import { DriverFactorySinkType } from './driverUtil'
import { Modal, Tooltip } from 'bootstrap'

export type ModalOptions = {
  type: 'show' | 'hide'
}

type GenericBootstrapParameters = {
  target: string | Element
}

export type ModalAction = GenericBootstrapParameters & {
  type: 'modal'
  options: ModalOptions
}

export type TooltipAction = GenericBootstrapParameters & {
  type: 'tooltip'
  disableAnimation?: boolean
}

export type BootstrapAction = ModalAction | TooltipAction

export const Action = (action: BootstrapAction) => action

export function makeBootstrapDriver() {
  return (events$: xs<BootstrapAction>) => {
    events$.addListener({
      next: action => {
        switch (action.type) {
          case 'modal': {
            const instance = Modal.getInstance(action.target)
            switch (action.options.type) {
              case 'show': instance?.show()
                break
              case 'hide': instance?.hide()
                break
              default: console.warn('Unrecognised bootstrap modal action', action.options.type)
            }
            break
          }
          case 'tooltip':
            Tooltip.getOrCreateInstance(action.target, { animation: !action.disableAnimation })
            break
          default: console.warn('Unrecognised bootstrap action', action)
        }
      }
    })
  }
}

export type BootstrapSink = DriverFactorySinkType<typeof makeBootstrapDriver>
