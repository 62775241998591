import { MersenneTwister19937, Random } from 'random-js'
import xs from 'xstream'
import { DriverFactorySinkType } from './driverUtil'

export const NUMBER = 'number'
export const GUID = 'guid'
export type RandomNumber = typeof NUMBER
export type GUID = typeof GUID
export type Type = RandomNumber | GUID

export type RandomRequest = {
  category: string
  type: Type
  data?: any
}

export type RandomResponse<TRandom extends Type, TData = any> = {
  category: string
  data: TData
  value: TRandom extends typeof NUMBER ? number : TRandom extends typeof GUID ? string : never
}

export type RandomSource = {
  select: <TRandom extends Type, TData = any>(category: string) => xs<RandomResponse<TRandom, TData>>
}

export function makeRandomDriver(seed?: number) {
  const random = new Random(seed == null ? MersenneTwister19937.autoSeed() : MersenneTwister19937.seed(seed))

  return function randomDriver(randomRequests$: xs<RandomRequest>) {
    const source$ = xs.create<RandomResponse<Type>>()

    randomRequests$.addListener({
      next: request => {
        const value = request.type === NUMBER ? random.real(-Number.MIN_VALUE, Number.MAX_VALUE) : request.type === GUID ? random.uuid4() : (undefined as never)
        source$.shamefullySendNext({ category: request.category, data: request.data, value })
      },
      error: () => void 0,
      complete: () => void 0
    })
    return {
      stream: source$,
      select: <TRandom extends Type, TData = any>(category: string) => source$.filter(response => response.category === category) as xs<RandomResponse<TRandom, TData>>
    }
  }
}

export type RandomSink = DriverFactorySinkType<typeof makeRandomDriver>
