import { isMissingProjectMember, isMissingTiaTimesheetCode, isMissingTiaUser, isProjectMember } from '../viewModel'
import { Button, Card, CardBody, CardHeader, cn, Col, Container, Danger, dateInputFormat, Form, Icon, Input, Label, ModalContent, Row, Select, TextArea, Warning } from '../../../../ui'
import format from 'date-fns/format'
import { canAddRate, canCopyOrdersForMembers, canSaveOrder, canSaveOrders, KEY_NEW_ORDER, toRateItemViewModel } from './modelFunctions'
import { InputOrderItem, OrderItem, OrderItemViewModel, OrdersSources, OrdersViewState, RateItemViewModel } from './viewModel'
import { adapterProblemRenderConfig, insufficientRightsRenderConfig, renderProblem, renderProblemFor, requestErrorRenderConfig, tiaConfigInvalidRenderConfig, unhandledApiExceptionRenderConfig, unhandledTiaProblemRenderConfig } from '../../renderers'
import { VNode } from 'snabbdom'
import { jsx } from 'h'
import { isLoading } from '../../../../generic'

const rateHasInvalidUntil = (item: RateItemViewModel) =>
  item.problems.badUntil
  || item.problems.missingUntilDate
  || (item.until && (item.problems.overlappingRate))

const toOrderItemViewModel = (inputOrder: OrderItem, index: number): OrderItemViewModel => (
  {
    ...inputOrder,
    index: index
  }
)

const rateItemView = (item: RateItemViewModel, orderIndex: number) =>
  <Row key={item.index} className="item-display-margin">
    <Col className="d-flex align-self-center">{
      isMissingTiaTimesheetCode(item.tiaCode)
        ? <span title={`Missing TIA code with id ${item.tiaCode.missingTiaCodeId}!`}>{item.tiaCode.missingTiaCodeName}<Icon icon="alert-triangle" color="red" /></span>
        : item.tiaCode.writable
          ? item.tiaCode.name
          : <span title="This TIA code is read only!">{item.tiaCode.name}<Icon icon="alert-triangle" color="orange" /></span>}
    {item.problems.tiaCodeNotFound ?
      <div className="custom-invalid-feedback">
          TIA code could not be found.
      </div>
      : null
    }
    </Col>
    <Col>
      <Select
        className={cn('rate-member', {'is-invalid': item.problems.projectMemberNotFound})}
        aria-describedby={`rate-member-feedback-${orderIndex}-${item.index}`}
        data-index={item.index.toString()}
        data-orderindex={orderIndex.toString()}
      >
        {isMissingProjectMember(item.projectMember)
          ? <option key="missing-project-member" selected={true} value={item.projectMember.missingProjectMemberTiaUser.tiaUserId}>
            {`${item.projectMember.missingProjectMemberTiaUser.tiaFirstName} ${item.projectMember.missingProjectMemberTiaUser.tiaLastName}`}
            <Icon icon="alert-triangle" color="orange" title="Missing project member!" />
          </option>
          : isMissingTiaUser(item.projectMember)
            ? <option key="missing-tia-user" selected={true} value={item.projectMember.missingTiaUserId}>
              {item.projectMember.missingTiaUserId}
              <Icon icon="alert-triangle" color="orange" title="Missing TIA user!" />
            </option>
            : !item.projectMember
              ? <option key="blank" value="" selected={true}>-- Teamlid --</option>
              : null}
        {item.projectMembers
          .map(m =>
            <option key={m.tiaUserId} value={m.tiaUserId} selected={item.projectMember && isProjectMember(item.projectMember) && m.tiaUserId === item.projectMember.tiaUserId}>
              {m.displayName}
            </option>)}
      </Select>
      <div id={`rate-member-feedback-${orderIndex}-${item.index}`} className="invalid-feedback">
        Project member could not be found.
      </div>
    </Col>
    <Col>
      <Input
        className={cn('rate', {'is-invalid': item.problems.badDailyRate})}
        aria-describedby={`rate-daily-rate-feedback-${orderIndex}-${item.index}`}
        value={item.dailyRate.toString().replace('.', ',')}
        data-index={item.index.toString()}
        data-orderindex={orderIndex.toString()}/>
      <div id={`rate-daily-rate-feedback-${orderIndex}-${item.index}`} className="invalid-feedback">
        A daily rate is required, cannot be negative, contains only numbers and cannot start with 0.
      </div>
    </Col>
    <Col>
      <Input
        type="date"
        required
        className={cn('rate-from', {'is-invalid': item.problems.badFrom || item.problems.overlappingRate})}
        aria-describedby={`rate-from-feedback-${orderIndex}-${item.index}`}
        value={format(item.from, dateInputFormat)}
        data-index={item.index.toString()}
        data-orderindex={orderIndex.toString()}/>
      <div id={`rate-from-feedback-${orderIndex}-${item.index}`} className="invalid-feedback">
        {item.problems.badFrom ? 'A from date is required.' : ''}
        {item.problems.overlappingRate ? 'This rate overlaps with another rate that has the same TIA-code and Member.' : ''}
      </div>
    </Col>
    <Col>
      <Input
        type="date"
        required={false}
        className={cn('rate-until',
          {'is-invalid': rateHasInvalidUntil(item)})}
        aria-describedby={`rate-until-feedback-${orderIndex}-${item.index}`}
        value={item.until ? format(item.until, dateInputFormat) : ''}
        data-index={item.index.toString()}
        data-orderindex={orderIndex.toString()}/>
      <div id={`rate-until-feedback-${orderIndex}-${item.index}`} className="invalid-feedback">
        {item.problems.badUntil ? 'The until date must be after the from date.' : ''}
        {item.problems.overlappingRate ? 'This rate overlaps with another rate that has the same TIA-code and Member.' : ''}
        {item.problems.missingUntilDate
          ? 'This until date is required because there is a rate with the same TIA-code and member that has no until date or a later from date.'
          : ''}
      </div>
    </Col>
    <Col width={1}><Button context="danger" className="delete-rate" data-index={item.index.toString()} data-orderindex={orderIndex.toString()}><Icon icon="x" /></Button></Col>
  </Row>

const ratesView = (state: OrdersViewState, order: OrderItemViewModel, newCodeSuggest: VNode, memberSuggest: VNode) =>
  <Container>
    <Row key={`header-${order.index}`}>
      <Col><label className="required">TIA-code</label></Col>
      <Col><label className="required">Member</label></Col>
      <Col><label className="required">Daily rate (incl. VAT)</label></Col>
      <Col><label className="required">From</label></Col>
      <Col><label>Until</label></Col>
      <Col width={1}></Col>
    </Row>
    {order.rates.map((item, i) => rateItemView(toRateItemViewModel(item, i, state), order.index))}
    <Row key={`new-rate-item-${order.index}`} className="new-item-margin">
      <Col>
        {newCodeSuggest}
        <small className="form-text text-muted">
          *Required for add (+)
        </small>
      </Col>
      <Col>
        {memberSuggest}
      </Col>
      <Col>
        <Input className="new-item-rate" value={order.newRateItem.dailyRate.toString().replace('.', ',')} data-index={order.index}/>
      </Col>
      <Col>
        <Input type="date" className="new-item-from" value={order.newRateItem.from ? format(order.newRateItem.from, dateInputFormat) : ''} data-index={order.index}/>
      </Col>
      <Col>
        <Input type="date" className="new-item-until" value={order.newRateItem.until ? format(order.newRateItem.until, dateInputFormat) : ''} data-index={order.index}/>
      </Col>
      <Col width={1}><Button context="success" id="add-new-rate" data-index={order.index} disabled={!canAddRate(order)}><Icon icon="plus" /></Button></Col>
    </Row>
  </Container>

const copyMemberOrdersView = (state: OrdersViewState) =>
  <Container>
    <Row>
      <Col>
        <label className="required">Source Member</label>
        <label
          className="icon-button-projects ms-auto"
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          data-bs-title="The project member who's active rates will be copied within each order.">
          <Icon icon="info" />
        </label>
      </Col>
      <Col>
        <label className="required">Destination Member</label>
        <label
          className="icon-button-projects ms-auto"
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          data-bs-title="The project member to which the copied rates will be assigned within each order.">
          <Icon icon="info" />
        </label>
      </Col>
      <Col width={1}></Col>
    </Row>
    <Row className="mt-3">
      <Col>
        <Select className="copy-orders-for-members-source">
          {
            <option value={''} selected={state.copyTiaUserSource === null || state.copyTiaUserSource === ''}>-- Select Source Member --</option>
          }
          {state.projectMembers
            .sort((a, b) => a.displayName < b.displayName ? -1 : a.displayName > b.displayName ? 1 : 0)
            .map(m =>
              <option key={m.tiaUserId} value={m.tiaUserId} selected={state.copyTiaUserSource === m.tiaUserId}>
                {m.displayName}
              </option>)}
        </Select>
      </Col>
      <Col>
        <Select className="copy-orders-for-members-destination">
          {
            <option value="null" selected={state.copyTiaUserDestination === null || state.copyTiaUserDestination === ''}>-- Select Destination Member --</option>
          }
          {state.projectMembers
            .sort((a, b) => a.displayName < b.displayName ? -1 : a.displayName > b.displayName ? 1 : 0)
            .map(m =>
              <option key={m.tiaUserId} value={m.tiaUserId} selected={state.copyTiaUserDestination === m.tiaUserId}>
                {m.displayName}
              </option>)}
        </Select>
      </Col>
      <Col width={1}><Button context="success" className="copy-orders-for-members" disabled={!canCopyOrdersForMembers(state)}>Copy</Button></Col>
    </Row>
  </Container>

const orderProblemRenderConfig = {
  ...requestErrorRenderConfig,
  ...insufficientRightsRenderConfig,
  ...unhandledApiExceptionRenderConfig,
  ...unhandledTiaProblemRenderConfig,
  ...tiaConfigInvalidRenderConfig
}

export const view = (sources: OrdersSources) => (state: OrdersViewState, membersAutoSuggests: VNode[], tiaCodeAutoSuggests: VNode[], modal: VNode) => {
  if ('case' in state.context.tiaUsers) {
    return <Container>
      <Danger>{renderProblemFor(state.context.tiaUsers)(orderProblemRenderConfig)}</Danger>
    </Container>
  }

  if (typeof state.context.tiaCodes.case === 'string') {
    return <Container>
      <Danger>{renderProblemFor(state.context.tiaCodes)(orderProblemRenderConfig)}</Danger>
    </Container>
  }

  return <Container>
    {!state.context.configuration.info.members.length
      ? <Warning>You cannot configure rates when there are no project members.<br /><sources.Link href={'/members'} className="alert-link">Add members</sources.Link></Warning>
      : null}
    {

    }
    <div className="accordion">

      {state.context.configuration.info.settings.orderUserCopyAllowed
        ? <Card className="mb-2" key="copy-member-card">
          <CardHeader className="d-flex p-3" id="copy-member-heading">
            <Icon icon="copy" className="ms-1" />
            <div className="flex-grow-1 align-self-center ms-4"
              role="button"
              data-bs-toggle="collapse"
              data-bs-target={'#copy-member-collapse'}
              aria-controls={'copy-member-collapse'}>
            Copy member
            </div>
          </CardHeader>
          <div id="copy-member-collapse" className={cn('collapse')} aria-labelledby="copy-member-heading">
            <CardBody className="card-body">
              {copyMemberOrdersView(state)}
            </CardBody>
          </div>
        </Card>
        : null}
      {state.orders.map((order, i) => {
        return <Card className="mb-2" key={`card-order-${i}`}>
          <CardHeader className="d-flex" id={`order-heading-${i}`}>
            <Button context='light' border={false} className='open-order' data-bs-toggle="modal" data-key={i} data-bs-target="#order-modal">
              <Icon icon="edit" />
            </Button>
            <div className="flex-grow-1 align-self-center ms-2"
              role="button"
              data-bs-toggle="collapse"
              data-bs-target={`#order-collapse-${i}`}
              aria-expanded={order.hasProblems}
              aria-controls={`order-collapse-${i}`}>
              {order.name} (€ {order.budget})
              {order.hasProblems ? <Icon icon="alert-triangle" color="red" className="ms-2" /> : null }
            </div>
            <Button context="danger" className="delete-order ms-auto" data-index={i}><Icon icon="x" /></Button>
          </CardHeader>

          <div id={`order-collapse-${i}`} className={cn('collapse', { 'show': order.hasProblems })} aria-labelledby={`order-heading-${i}`}>
            <CardBody className="card-body">
              {(order.problems.badNameField || order.problems.badBudgetField)
                ? <Danger>○
                  {order.problems.badNameField ? <div>Name is required.</div> : null}
                  {order.problems.badBudgetField ? <div>Budget is required, cannot be negative, contains only numbers and cannot start with 0.</div> : null}
                </Danger>
                : null}
              {ratesView(state, toOrderItemViewModel(order, i), tiaCodeAutoSuggests[i], membersAutoSuggests[i])}
            </CardBody>
          </div>
        </Card>
      })}
    </div>
    {state.orders.some(o => o.hasProblems)
      ? <Danger className="mt-3">
        Changes have not been saved. Some orders have validation orders.
      </Danger>
      : null}
    {state.unknownResponseError
      ? renderProblem<typeof state.unknownResponseError>({
        ...requestErrorRenderConfig,
        ...insufficientRightsRenderConfig,
        ...unhandledApiExceptionRenderConfig,
        ...adapterProblemRenderConfig
      })(state.unknownResponseError)
      : null}
    {state.orders.length
      ? <Button context="success" className="save-orders mb-1 me-3" disabled={(!canSaveOrders(state) || isLoading(state.putOrdersConfigurationResponse))}>Save</Button>
      : null}
    <Button context="secondary" border={false} data-bs-toggle="modal" className="open-order mb-1 green-border" data-key={KEY_NEW_ORDER} data-bs-target="#order-modal">
        Create new order
    </Button>
    {modal}
  </Container>
}

const orderModalBody = (orderItem: InputOrderItem) => <div>
  <Form>
    <Label className="required" for="order-name">Name</Label>
    <Input className="mb-2" id="order-name" value={orderItem.name} />
    <Label className="required" for="order-budget">Budget</Label>
    <Input
      className={{'is-invalid': orderItem.hasBadBudget, 'mb-2': true}}
      id="order-budget"
      value={orderItem.budget.toString().replace('.', ',')}
      aria-describedby='order-budget-problem' />
    <div id={'order-budget-problem'} className="invalid-feedback">
        A budget is required, cannot be negative, contains only numbers and cannot start with 0.
    </div>
    <Label for="order-description">Description</Label>
    <TextArea className="mb-2" id="order-description" value={orderItem.description} />
    <Label for="order-link">Link</Label>
    <Input className="mb-2" id="order-link" value={orderItem.link} />
    <div className="mb-2">
      <Label><input type="checkbox" id="order-prevent-distribution" checked={orderItem.preventDistribution} /> Prevent distribution</Label>
    </div>
  </Form>
</div>

export const orderModalContent = (orderItem: InputOrderItem): ModalContent => ({
  title: orderItem.isNew ? 'Create order' : 'Update order',
  body: orderModalBody(orderItem),
  footer: <>
    <Button context="secondary" data-bs-dismiss="modal">Cancel</Button>
    <Button className="save-selected-order" data-key={orderItem.key} context="primary" disabled={!canSaveOrder(orderItem)}>Save</Button>
  </>
})
