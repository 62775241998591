import { HTTPSource } from '@cycle/http'
import { GettableResource } from '../../dataServices/types'
import { handleLatest, toLoadable } from '../../dataServices/util'
import { ProjectListItem, RequestError, UnhandledApiExceptionProblem } from '../model'
import { handleError, resultOrError, simpleResult } from './resourceUtil'

export type ProjectsListResource = GettableResource<ProjectListItem[], RequestError | UnhandledApiExceptionProblem>

export const projectsListResource = (root: string) => (HTTP: HTTPSource): ProjectsListResource => {
  const category = 'get-projects'
  const request = {
    category: category,
    url: `${root}/projects`,
    method: 'GET'
  }

  return {
    name: request.url,
    get: request,
    value: toLoadable(handleLatest(HTTP)(category)({
      success: simpleResult<ProjectListItem[]>,
      failure: {
        500: resultOrError<UnhandledApiExceptionProblem>
      },
      error: handleError
    }))
  }
}
