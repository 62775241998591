import { HTTPSource } from '@cycle/http'
import { GettableResource, handleLatest, receiveJson, toLoadable } from '../../dataServices'
import { WithWarnings } from '../../fsharp'
import { BacklogWorkItemMappingProblem, InsufficientRights, ProblematicWorkItemDetails, RequestError, UnhandledApiExceptionProblem, UnhandledBacklogProblems, WorkItemProblem, WrongLevelProblem, insufficientRights } from '../model'
import { K } from '@/generic'
import { simpleResult, resultOrError, handleError } from './resourceUtil'

export type BacklogWorkItem = {
  id: string
  details: { title: string }
}

export type BacklogWorkItemsResponse = WithWarnings<BacklogWorkItem[], WorkItemProblem<ProblematicWorkItemDetails, BacklogWorkItemMappingProblem>>

export type BacklogWorkItemsResource = GettableResource<BacklogWorkItemsResponse, RequestError | InsufficientRights | WrongLevelProblem | UnhandledApiExceptionProblem  | UnhandledBacklogProblems>

export const backlogWorkItemsResource = (root: string) => (HTTP: HTTPSource) => (key: string): BacklogWorkItemsResource => {
  const url = `${root}/projects/${key}/backlog/workitems`

  const request = {
    category: `get-backlog-workitems-${key}`,
    method: 'GET',
    url: url
  }

  return {
    name: request.url,
    get: request,
    value: toLoadable(handleLatest(HTTP)(request.category)({
      success: simpleResult<BacklogWorkItemsResponse>,
      failure: {
        403: K(insufficientRights),
        409: simpleResult<WrongLevelProblem>,
        500: resultOrError<UnhandledApiExceptionProblem | UnhandledBacklogProblems>
      },
      error: handleError
    }))
  }
}
