import { TanstackTableSink, TanstackTableSource } from '@/drivers/tanstackTableDriver'
import { MainDOMSource } from '@cycle/dom'
import { HTTPSource, RequestInput } from '@cycle/http'
import { Reducer, StateSource } from '@cycle/state'
import storageDriver, { ResponseCollection } from '@cycle/storage'
import { TimeSource } from '@cycle/time'
import { RouterSource } from 'cyclic-router'
import { RouteDefinitionsMap } from 'cyclic-router/lib'
import * as queryString from 'query-string'
import { VNode } from 'snabbdom'
import xs from 'xstream'
import { UserSource } from '../authentication/userProvider'
import { Config } from '../config'
import { PrimDataServiceFactory } from '../dataServices'
import { BootstrapSink, ConfigSink, ConfigSource, DateSource, DriverSinkType, MsGraphSink, MsGraphSource, RandomSink, RandomSource, RedirectSink } from '../drivers'
import { GroundSink } from '../drivers/groundDriver'
import { makeMsalDriver, MsalSink } from '../drivers/msalDriver'
import { mapDict, mapObj, notNull, ReturnType2 } from '../generic'
import { ElementFactory, linkCreator, LinkFactory, LinkProps } from '../ui'
import { getPageSink } from '../util'

export * from './inputOutput'

export type AppSources = {
  DOM: MainDOMSource
  HTTP: HTTPSource
  router: RouterSource
  Time: TimeSource
  state: StateSource<any>
  date: DateSource
  storage: ResponseCollection
  config: ConfigSource
  random: RandomSource
  msGraph: MsGraphSource
  msal: ReturnType2<typeof makeMsalDriver>
  tanstackTable: TanstackTableSource
}

export type PageSources<State = any> = Omit<AppSources, 'state'> & {
  state: StateSource<State>
  user: UserSource
  query: xs<queryString.ParsedQuery>
  parentRouter: RouterSource
  dataService: PrimDataServiceFactory
  Link: ElementFactory<LinkProps>
  apiHost: string
  configSnapshot: Config
}

export type AppSinks = {
  DOM: xs<VNode>
  HTTP: xs<RequestInput>
  state: xs<Reducer<any>>
  redirect: xs<RedirectSink>
  router: xs<string>
  bootstrap: xs<BootstrapSink>
  storage: xs<DriverSinkType<typeof storageDriver>>
  log: xs<any>
  random: xs<RandomSink>
  config: xs<ConfigSink>
  msGraph: xs<MsGraphSink>
  focus: xs<string | HTMLElement | (string | HTMLElement)[]>
  ground: xs<GroundSink>
  msal: xs<MsalSink>
  tanstackTable: xs<TanstackTableSink>
}

export type PageSinks = Partial<AppSinks & {
  primState: xs<Reducer<any>>
}>

export type SinkMergeTemplateItem<T> = ((x: xs<T>) => xs<T>) | null
export type SinkMergeTemplate<Sinks> = { [key in keyof Required<Sinks>]: ((pageSink: Required<Sinks>[key]) => Required<Sinks>[key]) | null }

export const pageSinkMergeTemplate: SinkMergeTemplate<PageSinks> = {
  DOM: null,
  HTTP: null,
  state: null,
  bootstrap: null,
  redirect: null,
  storage: null,
  log: null,
  random: null,
  config: null,
  msGraph: null,
  router: null,
  msal: null,
  focus: null,
  ground: null,
  primState: null,
  tanstackTable: null
}

export const pageSinkTemplate: Required<PageSinks> = pageSinkMergeTemplate as any

export function mergePageSinks<T extends Record<string, xs<any>>>(
  page$: xs<Partial<T>>,
  template: {
    [key in keyof T]: ((pageSink: Required<T>[key]) => Required<T>[key]) | null
  }) {
  // pretty crucial: otherwise sinks get lost
  // it's ok to remember a control stream, we're not re-emitting values from value streams
  const rememberedPage$ = page$.remember()
  const merge = <TKey extends keyof T>(sinkKey: TKey) =>
    template[sinkKey]
      ? template[sinkKey]!(getPageSink(rememberedPage$ as any, sinkKey))
      : getPageSink(rememberedPage$, sinkKey)

  return mapObj(template, (_, key) => merge(key)) as T
}

export type AppSourcesWithNormalPath = AppSources & ({ normalPath?: string })

const NORMAL_PATH = Symbol()
export const withNormalPath = (normalPath: string) => <T extends (...args: any[]) => any>(component: T) => {
  (component as any)[NORMAL_PATH] = normalPath
  return component
}

type DefaultExtensions = {
  router: RouterSource
  parentRouter: RouterSource
  Link: LinkFactory
  query: xs<queryString.ParsedQuery>
  normalPath: string | undefined
  parentPath: string | undefined
}

export function withRouterBehaviour
<TPageSinks,
  TAppSources extends { router: RouterSource; normalPath?: string },
  TExtendedAppSources extends DefaultExtensions = TAppSources & DefaultExtensions>
(sources: TAppSources & Partial<DefaultExtensions>,
  routes: RouteDefinitionsMap,
  sourcesExtender: (sources: TAppSources & DefaultExtensions) => TExtendedAppSources = x => x as unknown as TExtendedAppSources,
  wrapper: (sources: TAppSources, sinks: TPageSinks) => TPageSinks = ((_: TAppSources, sinks: TPageSinks) => sinks)) {
  const match$ = sources.router.define(mapDict(routes, target => typeof target === 'string' ? withNormalPath(target)(routes[target]) : target))

  return match$
    .filter(x => x.path !== null)
    .fold(
      ({ prevPath, existingComponent }, { path, value }) => {
        if (path == null) {
          throw new Error('Unrecognised path ' + path)
        }

        if (prevPath === path) {
          return { prevPath, existingComponent }
        }

        try {
          // this is probably broken
          const componentNormalPath = value[NORMAL_PATH] as string | undefined
          const isRoot = path === '/' || path === componentNormalPath
          const normalizedPath = (isRoot ? componentNormalPath || (sources.normalPath === sources.parentPath ? '/' : sources.normalPath) : '/') || path
          const parentRouter = sources.router.path(normalizedPath)

          const pageSinks: TPageSinks = value(
            sourcesExtender(Object.assign({}, sources, {
              router: sources.router.path(path),
              parentRouter: parentRouter,
              Link: linkCreator(parentRouter),
              query: xs.of(queryString.parse(location.search)),
              parentPath: path,
              normalPath: componentNormalPath
            }))
          )

          return {
            prevPath: path,
            existingComponent: { _url: path, ...wrapper(sources, pageSinks) }
          }
        } catch (err) {
          console.log(err)
          throw err
        }
      },
      { prevPath: null as null | string, existingComponent: null as TPageSinks | null }
    )
    .map(({ existingComponent }) => existingComponent)
    .filter(notNull)
    .remember()
}
