import { MainDOMSource } from '@cycle/dom'
import { StateSource } from '@cycle/state'
import xs from 'xstream'
import { Manager, maybeParse, id } from '../../../../../generic'
import { jsx } from 'h'
import { input, Input, inputValue, click, Button, Success, Warning, Loading, Label, ToggleButtonGroup, ToggleButton } from '../../../../../ui'
import { Credentials, usernamePassword, apiKey, defaultCredentials } from './types'
import { ResponseCollection } from '@cycle/storage'
import { DEFAULT, DERIVED, dynamicCaseModel, IGNORE, INIT, pageSinkTemplate } from '../../../../../infrastructure'

type Sources = { DOM: MainDOMSource; state: StateSource<State>; storage: ResponseCollection }

export const credentialsStorageKey = 'redmine-credentials'

export enum States {
  Loading = 'Loading',
  Display = 'Display',
  Edit = 'Edit'
}

type Loading = {
  case: States.Loading
}

type Display = {
  case: States.Display
  credentials: Credentials
}

type Edit = {
  case: States.Edit
  credentials: Credentials
  previousCredentials: Credentials
}

type State = Loading | Display | Edit

export type CredentialsState = State

export const initialCredentialsState: State = { case: States.Loading }

const isDisplay = (state: State): state is Display => state.case === States.Display

function intent({ DOM, storage }: Sources) {
  return {
    loadCredentials: storage.local.getItem<string | null>(credentialsStorageKey).take(1).map(maybeParse<Credentials>(defaultCredentials)),
    selectCredentialType:
      xs.merge(
        click(DOM, '#redmine-via-client-username-password-label').mapTo(usernamePassword as typeof usernamePassword),
        click(DOM, '#redmine-via-client-api-key-label').mapTo(apiKey as typeof apiKey)),
    setUsername: input(DOM, '#redmine-via-client-username-input').map(inputValue),
    setPassword: input(DOM, '#redmine-via-client-password-input').map(inputValue),
    setApiKey: input(DOM, '#redmine-via-client-api-key-input').map(inputValue),
    edit: click(DOM, '#redmine-via-client-edit-credentials'),
    save: click(DOM, '#redmine-via-client-save-credentials'),
    cancel: click(DOM, '#redmine-via-client-cancel-credentials')
  }
}

const { set, setWhen } = Manager<Display | Edit>()
const { set: setFullState } = Manager<State>()

const whenCredentials = setWhen(s => s.credentials.credentialType === usernamePassword)
const whenApiKey = setWhen(s => s.credentials.credentialType === apiKey)

const setCredentialsPresent =
  set(s => s.credentials.credentialsPresent)((_, s) =>
    !!(s.credentials.credentialType === apiKey
      ? s.credentials.apiKey
      : s.credentials.password && s.credentials.username))

function model(intents: ReturnType<typeof intent>, sources: Sources) {
  return dynamicCaseModel(sources.state.stream)(
    pageSinkTemplate,
    intents,
    {
      [INIT]: IGNORE,
      loadCredentials: {
        [DEFAULT]: credentials =>
          setFullState(id)(_ =>
            credentials.credentialsPresent
              ? { case: States.Display, credentials: credentials }
              : { case: States.Edit, credentials: credentials, previousCredentials: defaultCredentials })
      },
      edit: {
        [States.Display]: () => state => ({
          case: States.Edit,
          credentials: state.credentials,
          previousCredentials: state.credentials
        })
      },
      cancel: {
        [States.Edit]: () => state => ({
          case: States.Display,
          credentials: state.previousCredentials
        })
      },
      save: {
        [States.Edit]: {
          state: () => state => ({
            case: States.Display,
            credentials: state.credentials
          }),
          output: () => state => ({
            storage: [{
              key: credentialsStorageKey,
              value: JSON.stringify(state.credentials)
            }]
          })
        }
      },
      selectCredentialType: {
        [States.Edit]: set(s => s.credentials.credentialType)
      },
      setUsername: {
        [States.Edit]: whenCredentials(state => state.credentials.username)
      },
      setPassword: {
        [States.Edit]: whenCredentials(state => state.credentials.password)
      },
      setApiKey: {
        [States.Edit]: whenApiKey(info => info.credentials.apiKey)
      },
      [DERIVED]: s => s.case !== States.Loading ? setCredentialsPresent(s) : s
    })
}

function render(state: State) {
  if (state.case === States.Loading) {
    return <Loading />
  }

  const credentials = state.credentials

  if (isDisplay(state)) {
    return (
      <div>
        {credentials.credentialsPresent
          ? <Success className="mt-1">Currently configured: {credentials.credentialType === usernamePassword ? 'username and password' : 'API key'}</Success>
          : <Warning>No credentials configured</Warning>}
        <Button context={credentials.credentialsPresent ? 'secondary' : 'primary'} id="redmine-via-client-edit-credentials" className="mb-3">
          Configure credentials
        </Button>
      </div>
    )
  }

  return (
    <div>
      <ToggleButtonGroup className="mb-3">
        <ToggleButton
          id="redmine-via-client-username-password-label"
          name="credential-type-option"
          value={usernamePassword}
          active={credentials.credentialType === usernamePassword}>
          Username &amp; Password
        </ToggleButton>
        <ToggleButton
          id="redmine-via-client-api-key-label"
          name="credential-type-option"
          value={apiKey}
          active={credentials.credentialType === 'apiKey'}>
          API Key
        </ToggleButton>
      </ToggleButtonGroup>
      <div className="mb-3">
        {credentials.credentialType === usernamePassword ? (
          <>
            <Label for="username-input">Username</Label>
            <Input id="redmine-via-client-username-input" value={credentials.username} className="mb-3" />
            <Label for="password-input">Password</Label>
            <Input type="password" id="redmine-via-client-password-input" value={credentials.password} autocomplete="off" />
          </>
        ) : (
          <>
            <Label for="api-key-input">API Key</Label>
            <Input type="password" id="redmine-via-client-api-key-input" value={credentials.apiKey} autocomplete="off" />
          </>
        )}
      </div>
      <Button context="secondary" id="redmine-via-client-cancel-credentials" key="save">
        Cancel
      </Button>
      <Button context="primary" id="redmine-via-client-save-credentials" className="ms-3" key="cancel" disabled={!credentials.credentialsPresent}>
        Save
      </Button>
    </div>
  )
}

export function RedmineCredentials(sources: Sources) {
  const intents = intent(sources)

  return {
    ...model(intents, sources),
    DOM: sources.state.stream.map(render)
  }
}
