import xs, { MemoryStream, Operator, Stream } from 'xstream'
import concat from 'xstream/extra/concat'
import { flatten } from './array'
import { StreamType } from './types'
import { exists, fetch, keysToObj, set, unique } from './util'

export const startWith = <U extends any>(u: U) => <T>(stream: xs<T>) => (stream as xs<T | U>).startWith(u)

export type Input<T> = { readonly [P in keyof T]: xs<T[P]> }

export function settersFor<T>() {
  return <K extends keyof T>(inputs: Input<T>) => {
    const keys = Object.keys(inputs) as K[]
    const setters = keys.map(k => inputs[k].map(set<T, K>(k)))
    return xs.merge(...setters)
  }
}

export function fetch2<T, K extends keyof T>(name: K) {
  return (o$: xs<T>) => o$.map(fetch(name)).filter(exists)
}

export const splitErrors = <T extends any>(xx: xs<T>) => {
  const ERROR = Symbol()
  type Err = { [ERROR]: any }
  type Annotated = xs<T | { [ERROR]: any }>

  const isError = (x: T | { [ERROR]: any }): x is Err => !!(x && typeof x === 'object' && ERROR in (x as {[ERROR]: any}))
  const isValue = (x: T | { [ERROR]: any }): x is T => !isError(x)

  const annotated = xx.replaceError(e => concat(xs.of({ [ERROR]: e }), xx as any) as any) as Annotated

  const errors = annotated.filter(isError).map(x => x[ERROR])
  const values = annotated.filter(isValue)

  return {
    errors: errors,
    values: xx instanceof MemoryStream ? values.remember() : values
  }
}


type GenericSink = Record<string, xs<any> | MemoryStream<any>>
type NonMemory<TSink> = { [key in keyof TSink]: xs<StreamType<TSink[key]>> }

export function mergeSinks<S1 extends GenericSink, S2 extends GenericSink, S3 extends GenericSink, S4 extends GenericSink, S5 extends GenericSink, S6 extends GenericSink>
(sink1: S1, sink2: S2, sink3: S3, sink4: S4, sink5: S5, sink6: S6): NonMemory<S1 & S2 & S3 & S4 & S5 & S6>
export function mergeSinks<S1 extends GenericSink, S2 extends GenericSink, S3 extends GenericSink, S4 extends GenericSink, S5 extends GenericSink>
(sink1: S1, sink2: S2, sink3: S3, sink4: S4, sink5: S5): NonMemory<S1 & S2 & S3 & S4 & S5>
export function mergeSinks<S1 extends GenericSink, S2 extends GenericSink, S3 extends GenericSink, S4 extends GenericSink>
(sink1: S1, sink2: S2, sink3: S3, sink4: S4): NonMemory<S1 & S2 & S3 & S4>
export function mergeSinks<S1 extends GenericSink, S2 extends GenericSink, S3 extends GenericSink>
(sink1: S1, sink2: S2, sink3: S3): NonMemory<S1 & S2 & S3>
export function mergeSinks<S1 extends GenericSink, S2 extends GenericSink>
(sink1: S1, sink2: S2): NonMemory<S1 & S2>
export function mergeSinks<S extends GenericSink>(...sinks: Partial<S>[]) {
  const keys = flatten(unique(sinks.map(Object.keys)))
  return keysToObj(keys, key => xs.merge(...sinks.map(sink => sink[key] || xs.never()))) as NonMemory<S>
}

const ignoreSyncValueOnSubscribeOperator = <T>(stream: xs<T>): Operator<T, T> => {
  let subscribing = false
  const operator = {
    type: 'ignoreSyncValueOnSusbcribe',
    ins: stream,
    out: null as unknown as xs<T>, // hey - not my type
    _start: (out: xs<T>) => {
      subscribing = true
      operator.out = out
      stream._add(operator)
      subscribing = false
    },
    _stop: () => {
      stream._remove(operator)
      operator.out = null as unknown as xs<T>
    },
    _n: (value: T) => {
      if (!subscribing && operator.out) {
        operator.out._n(value)
      }
    },
    _e: (err: any) => {
      if (operator.out) {
        operator.out._e(err)
      }
    },
    _c: () => {
      if (operator.out) {
        operator.out._c()
      }
    }
  }

  return operator
}

export const ignoreSyncValueOnSubscribe = <T>(stream: xs<T>) => new Stream(ignoreSyncValueOnSubscribeOperator(stream))

// "stateChange" operator: changes a state/memory stream to an event/trigger stream that emits state changes: support from/to predicates

const NOTHING = Symbol()
type NOTHING = typeof NOTHING
export const changeWhen =
  <T>(comparer: (current: T, next: T) => boolean) =>
    (stream: MemoryStream<T>) =>
      stream
        .fold(
          ({ current }: { current: T | NOTHING; changed: boolean }, next: T) =>
            current === NOTHING
              ? { current: next, changed: true }
              : { current: next, changed: comparer(current, next) },
          { current: NOTHING as T | NOTHING, changed: false })
        .filter(change => change.changed)
        .map(({ current }) => current as T)
