import { MainDOMSource } from '@cycle/dom'
import { StateSource } from '@cycle/state'
import xs from 'xstream'
import sampleCombine from 'xstream/extra/sampleCombine'
import { isLoaded, Loadable, Manager, mapLoaded, notNull, value } from '../../generic'
import { jsx } from 'h'
import { click, cn, Icon, input, Input, inputValue, renderLoadable, targetData } from '../../ui'

export type Suggestable<T> = {
  display: string
  value: T
}

export type AutoSuggestState<T> = {
  wildcardInput?: string
  input: string
  isInvalid?: boolean
  ariaDescribedby?: string
  errorMessage?: string
  suggestables: Loadable<Suggestable<T>[]>
}

export const toSuggestable = <T extends any>(display: (item: T) => string) => (item: T): Suggestable<T> => ({ value: item, display: display(item) })

// lower is better except negative which means no match
// effectively, prefer matches that start earlier
export const autoSuggestScore = (val: string, display: string, wildcardInput?: string) =>
  val === (wildcardInput || '*') ? 0 : val === '' ? -1 : display.toLowerCase().indexOf(val.toLowerCase())

export const getSuggestedItems = <T extends any>(autoSuggestState: AutoSuggestState<T>) =>
  mapLoaded(autoSuggestState.suggestables, suggestables =>
    suggestables.filter(suggestable => autoSuggestScore(autoSuggestState.input, suggestable.display, autoSuggestState.wildcardInput) >= 0)
  )

const view = (isMultiline: boolean) => (state: AutoSuggestState<unknown>) => (
  <div className="auto-suggest-container">
    <Input type="text" className={cn('input', { 'is-invalid': state.isInvalid })} aria-describedby={state.ariaDescribedby} value={state.input} />
    {renderLoadable(state.suggestables, suggestables => suggestables.some(suggestable => suggestable.display === state.input)
      ? <span></span>
      : <div className="auto-suggest-items-container">
        {suggestables
          .map((suggestable, i) => ({ display: suggestable.display, index: i, score: autoSuggestScore(state.input, suggestable.display, state.wildcardInput) }))
          .filter(x => x.score >= 0)
          .sort((a, b) => a.score - b.score)
          .map((suggestable) =>
            <div
              key={suggestable.index}
              className={cn('auto-suggest-item', isMultiline ? 'justify-content-start' : '')}
              dataset={{ index: suggestable.index.toString() }}>
              {isMultiline ? <Icon icon='check' className='check-icon' /> : ''}
              <div className='auto-suggest-item-display'>{suggestable.display}</div>
            </div>)}
      </div>)}
    <div id={state.ariaDescribedby} className="invalid-feedback mb-1">
      {state.errorMessage}
    </div>
  </div>
)

type Sources<T> = { DOM: MainDOMSource; state: StateSource<AutoSuggestState<T>>; clear?: xs<undefined> }
const AutoSuggestInner = (isMultiline = false) => <T extends any>(sources: Sources<T>) => {
  const { set } = Manager<AutoSuggestState<T>>()

  const chooseSuggestion = click(sources.DOM, '.auto-suggest-item').map(targetData(d => +d.index!))
  const setInput = input(sources.DOM, '.input').map(inputValue)

  const stateReducers = xs.merge(
    (sources.clear || xs.never()).mapTo(set(state => state.input)('')),
    chooseSuggestion.map(index => set(state => state.input)((val, state) => (isLoaded(state.suggestables) ? value(state.suggestables)[index].display : val))),
    setInput.map(set(state => state.input))
  )

  const choose = chooseSuggestion
    .compose(sampleCombine(sources.state.stream))
    .map(([index, state]) => (isLoaded(state.suggestables) ? value(state.suggestables)[index].value : undefined))
    .filter(notNull)

  return {
    DOM: sources.state.stream.map(view(isMultiline)),
    state: stateReducers,
    autoSuggestChoose: choose,
    autoSuggestInput: setInput
  }
}

export const AutoSuggest = AutoSuggestInner(false)

export const MultilineAutoSuggest = AutoSuggestInner(true)

export const TypedAutoSuggest = <T extends any>() => (sources: Sources<T>) => AutoSuggest<T>(sources)

export const TypedMultilineAutoSuggest = <T extends any>() => (sources: Sources<T>) => MultilineAutoSuggest<T>(sources)
