import xs, { MemoryStream, Stream } from 'xstream'

export function log(label: string, ...preArgs: any[]) {
  return <T>(arg: T, ...args: any[]) => {
    console.log(label, ...preArgs, arg, ...args)
    return arg
  }
}

const subscriptionSpyProducer = <T extends any>(label: string, ins: xs<T>) => {
  let _out = null as xs<T> | null
  return {
    _type: 'subscriptionSpy',
    _start: (out: xs<T>) => {
      console.log(label, 'STARTING')
      ins._add(out)
    },
    _stop: () => {
      console.log(label, 'STOPPING')
      ins._remove(_out!)
      _out = null
    }
  }
}

export const subscriptionSpy = (label: string) => <T extends any>(stream: xs<T>) =>
  '_has' in (stream as MemoryStream<T>) ? new MemoryStream(subscriptionSpyProducer(label, stream)) : new Stream(subscriptionSpyProducer(label, stream))

export const assertNever = (never: never) => {
  throw new Error('Unrecognised value: ' + never)
}
