import { HTTPSource } from '@cycle/http'
import { handleLatest, Request, toLoadable } from '../../dataServices'
import { GettableResource, SearchableResource } from '../../dataServices/types'
import { handleError, resultOrError, simpleResult } from './resourceUtil'
import { InsufficientRights, insufficientRights, RequestError, TiaConfigurationInvalid, UnhandledApiExceptionProblem, UnhandledTiaProblem } from '../model'
import { K } from '@/generic'

export type TiaTimesheetCode = {
  id: string
  name: string
  writable: boolean
}

export type TiaSearchCodesResponse = TiaTimesheetCode[]

export type TiaSearchCodesResource =
  SearchableResource<string, TiaSearchCodesResponse, RequestError | InsufficientRights | UnhandledApiExceptionProblem | UnhandledTiaProblem | TiaConfigurationInvalid>

const makeUrl = (root: string, projectKey: string) => `${root}/projects/${projectKey}/tia/codes/search`

export const tiaSearchCodesRequest = (root: string, projectKey: string, category: string, query: string) => Request({
  category: category,
  method: 'GET',
  url: makeUrl(root, projectKey),
  query: {
    searchterm: query
  }
})

export const readTiaSearchCodesResponse = (HTTP: HTTPSource, category: string) =>
  toLoadable(handleLatest(HTTP)(category)({
    success: simpleResult<TiaSearchCodesResponse>,
    failure: {
      403: K(insufficientRights),
      409: simpleResult<TiaConfigurationInvalid>,
      500: resultOrError<UnhandledApiExceptionProblem | UnhandledTiaProblem>
    },
    error: handleError
  }))

export const tiaSearchCodesResource = (root: string) => (HTTP: HTTPSource) => (key: string): TiaSearchCodesResource => {
  const category = `tia-search-codes-${key}`

  return {
    name: makeUrl(root, key),
    search: term => tiaSearchCodesRequest(root, key, category, term),
    value: readTiaSearchCodesResponse(HTTP, category)
  }
}

export type TiaCodesResponse = TiaTimesheetCode[]

export type TiaCodesResource = GettableResource<TiaCodesResponse, RequestError | InsufficientRights | UnhandledApiExceptionProblem | UnhandledTiaProblem | TiaConfigurationInvalid>

export const tiaCodesResource = (root: string) => (HTTP: HTTPSource) => (key: string): TiaCodesResource => {
  const category = `tia-codes-${key}`

  const request = {
    category: category,
    method: 'GET',
    url: `${root}/projects/${key}/tia/codes`
  }

  return {
    name: request.url,
    get: request,
    value: toLoadable(handleLatest(HTTP)(category)({
      success: simpleResult<TiaCodesResponse>,
      failure: {
        403: K(insufficientRights),
        409: simpleResult<TiaConfigurationInvalid>,
        500: resultOrError<UnhandledApiExceptionProblem | UnhandledTiaProblem>
      },
      error: handleError
    }))
  }
}
