import { HTTPSource, RequestOptions, Response } from '@cycle/http'
import xs from 'xstream'
import dropRepeats from 'xstream/extra/dropRepeats'
import flattenConcurrently from 'xstream/extra/flattenConcurrently'
import { and, id, K, keysOf, Loadable, loaded, loadError, loading, not, Unloaded } from '../generic'

export const REQUEST_ERROR = Symbol()
export type REQUEST_ERROR = typeof REQUEST_ERROR
export type RequestError<Request> = { [REQUEST_ERROR]: Error; request: Request }
export type MyRequestError = Error // errors generated without server response - these do not have a response associated with them
export type NormalizedRequestError<Request> = { [REQUEST_ERROR]: MyRequestError; request: NormalResponseRequest<Request> }

export type Request<T> = RequestOptions & { send: T }
// sometimes properties such as "method" are added, but since I don't know the logic, I won't try to reproduce
export type NormalResponseRequest<Request = undefined> = Request extends undefined ? RequestOptions : Request extends string ? { url: string } : Request
export type RequestResponse<Request = undefined> = Response & { request: NormalResponseRequest<Request> }

// the effort is to have an as clean as possible type - even though in practice double undefined will nearly never be used
export type MyResponse<Request = undefined, Result = undefined> =
  Request extends undefined
    ? Result extends string | undefined
      ? Response
      : Omit<Response, 'body'> & { body: Result }
    : Result extends string | undefined
      ? Omit<Response, 'request'> & { request: NormalResponseRequest<Request> }
      : Omit<Response, 'body' | 'request'> & { body: Result } & { request: NormalResponseRequest<Request> }

export type ErrorOrFailure<Request = undefined, Result = undefined> = MyResponse<Request, Result> | NormalizedRequestError<Request>

type HandlersResult<Handlers extends { [key: string]: (...args: any[]) => any }> = Handlers extends { [key: string]: (...args: any[]) => infer T } ? T : never

export const bodyAs = <Body>() => (x: MyResponse<any, any>) => x.body as Body
export const isRequestError = <Request>(x: ErrorOrFailure<Request, any>): x is NormalizedRequestError<Request> => REQUEST_ERROR in x
export const isNotRequestError = <Request, Result>(x: ErrorOrFailure<Request, Result>): x is MyResponse<Request, Result> => !isRequestError(x)

export const errorMessageOrResponseText = (x: RequestError<any> | MyResponse<any, any>) => isRequestError(x) ? x[REQUEST_ERROR].message : x.text

const isIn = (statuses: number[]) => (response: MyResponse<any, any>) => !!~statuses.indexOf(response.status)
const isInRange = (from: number, to: number) => (response: MyResponse<any, any>) => response.status >= from && response.status < to
const isSuccess = isInRange(200, 300)

const handleResponses = <Request, HandledResponse>(handlers: { [key: string]: (xs: MyResponse<Request, HandledResponse>) => HandledResponse }) =>
  <Response>(responses: xs<MyResponse<Request, Response>>) => {
    const keys = keysOf(handlers)

    return responses
      .filter(response => !!~keys.indexOf(response.status.toString()))
      .map(response => handlers[response.status](response as unknown as MyResponse<Request, HandledResponse>))
  }

export type OperationHandler<Request> = {
  success: { [key: string]: (response: MyResponse<Request, any>) => any } | ((response: MyResponse<Request, any>) => any) // unkeyed: 200-range
  failure?: { [key: string]: (response: MyResponse<Request, any>) => any } | ((response: MyResponse<Request, any>) => any) // unkeyed any non-success, unspecified -> error
  error?: (xs: ErrorOrFailure<Request, any>) => any // default = errorMessageOrResponseText -> string
}

type SuccessResult<Request, Handler extends OperationHandler<Request>> =
  Handler['success'] extends { [key: string]: (...args: any[]) => any }
    ? HandlersResult<Handler['success']>
    : Handler['success'] extends (...args: any[]) => any
      ? ReturnType<Handler['success']>
      : never

type FailureResult<Request, Handler extends OperationHandler<Request>> =
  Handler['failure'] extends { [key: string]: (...args: any[]) => any }
    ? HandlersResult<Handler['failure']>
    : Handler['failure'] extends (...args: any[]) => any
      ? ReturnType<Handler['failure']>
      : never

type ErrorResult<Request, Handler extends OperationHandler<Request>> =
  Handler['error'] extends (...args: any[]) => any
    ? ReturnType<Handler['error']>
    : string

// request type is required, but response is not - it is derived from what the handlers say it is
// this is easier for scenario's where different status codes yield different result types
export const handle = (concurrently: boolean) => (HTTP: HTTPSource) => <Request>(category: string) => <Handler extends OperationHandler<Request>>(handler: Handler) => {
  const requests = HTTP.select(category)
  const resultsMeta = requests
    .map(response$ => response$
      .replaceError((err: Error & { response?: MyResponse<Request, any> }) =>
        xs.of('response' in err
          ? err.response
          : { [REQUEST_ERROR]: err, request: response$.request as unknown as Request } as NormalizedRequestError<Request>) as any)) as
    unknown as xs<xs<MyResponse<Request, any> | NormalizedRequestError<Request>>>

  const results = (concurrently ? resultsMeta.compose(flattenConcurrently) : resultsMeta.flatten())

  const requestErrors = results.filter((x): x is NormalizedRequestError<Request> => REQUEST_ERROR in x)
  const responses = results.filter((x): x is MyResponse<Request, any> => !(REQUEST_ERROR in x))

  const successFilter =
    typeof handler.success === 'function'
      ? isSuccess
      : isIn(keysOf(handler.success).map(x => +x))

  const failureFilter =
    handler.failure
      ? typeof handler.failure === 'function'
        ? not(successFilter)
        : isIn(keysOf(handler.failure).map(x => +x))
      : K(false)

  const errorFilter = and(not(successFilter), not(failureFilter))

  const successResults =
    typeof handler.success === 'function'
      ? responses.filter(successFilter).map(handler.success)
      : handleResponses(handler.success)(responses)

  const failureResults =
    handler.failure
      ? typeof handler.failure === 'function'
        ? responses.filter(failureFilter).map(handler.failure)
        : handleResponses(handler.failure)(responses)
      : xs.never()

  const errorResults = xs.merge(requestErrors, responses.filter(errorFilter)).map(handler.error || errorMessageOrResponseText)

  const hasUncompleted =
    concurrently
      ? xs.merge(requests.mapTo(+1), results.mapTo(-1)).fold((a, b) => a + b, 0).map(c => c > 0)
      : xs.merge(requests.mapTo(true), results.mapTo(false)).compose(dropRepeats()).startWith(false)

  return {
    success$: successResults as xs<SuccessResult<Request, Handler>>,
    failure$: xs.merge(failureResults, errorResults) as xs<FailureResult<Request, Handler> | ErrorResult<Request, Handler>>,
    hasUncompleted$: hasUncompleted
  }
}

export const handleConcurrently = handle(true)
export const handleLatest = handle(false)

export const receiveLatest = (HTTP: HTTPSource, category: string) => handleLatest(HTTP)(category)({ success: id })
export const receiveJson = <T>(HTTP: HTTPSource, category: string) => handleLatest(HTTP)(category)({ success: bodyAs<T>() })

export type Operation<Success, Failure = string> = {
  success$: xs<Success>
  failure$: xs<Failure>
  hasUncompleted$: xs<boolean>
}

export function getNoChacheHeaders() {
  return {
    'Cache-control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: 0
  }
}

export function toQueryString(o: { [key: string]: string | number | null }) {
  return Object.keys(o)
    .map(key => [key, o[key]])
    .filter(([_, value]) => value)
    .map(([key, value]) => `${key}=${encodeURIComponent((value as any).toString())}`)
    .join('&')
}

// LEAK with concurrent operations - there is no notion "current"
export function toLoadable<Success, Failure, OutSuccess = Success, OutFailure = Failure>(
  operations: Operation<Success, Failure>,
  readSuccess: (x: Success) => OutSuccess = id as any,
  readFailure: (x: Failure) => OutFailure = id as any) {
  const setLoading = operations.hasUncompleted$.filter(id).mapTo(loading)
  const setResult = operations.success$.map(readSuccess).map(loaded).map(K)
  const setError = operations.failure$.map(readFailure).map(loadError).map(K)

  return xs.merge(setLoading, setResult, setError).fold((loadable, action) => action(loadable), Unloaded as Loadable<OutSuccess, OutFailure>)
}

export function Request<T = Record<any, any>>(request: RequestOptions & T) {
  return request
}
