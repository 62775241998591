import { Client, GraphError } from '@microsoft/microsoft-graph-client'
import xs from 'xstream'
import { DriverSinkType } from './driverUtil'

export type SetMsGraphAccessToken = {
  accessToken: string
}

export enum MsGraphQueryEnum {
  ProfilePhoto
}

export type PredefinedMsGraphQuery = {
  category: string
  type: MsGraphQueryEnum
}

export type CustomMsGraphQuery = {
  category: string
  query: string
}

export type MsGraphResource = string | GraphError

export type MsGraphAction = PredefinedMsGraphQuery | CustomMsGraphQuery | SetMsGraphAccessToken

const isSetMsGraphAccessToken = (action: MsGraphAction): action is SetMsGraphAccessToken => !!(action as SetMsGraphAccessToken).accessToken
const isPredefinedMsGraphQuery = (action: MsGraphAction): action is PredefinedMsGraphQuery => !!(action as PredefinedMsGraphQuery).type

export function msGraphDriver(actions: xs<MsGraphAction>) {
  let accessToken: string

  class MyAuthenticationProvider {
    async getAccessToken() {
      return Promise.resolve(accessToken)
    }
  }

  const msGraphClient = Client.initWithMiddleware({
    authProvider: new MyAuthenticationProvider()
  })

  const responses = xs.create<MsGraphResource>()

  actions.subscribe({
    next: action => {
      if (isSetMsGraphAccessToken(action)) {
        accessToken = action.accessToken
      } else if (isPredefinedMsGraphQuery(action)) {
        msGraphClient
          .api('https://graph.microsoft.com/v1.0/me/photo/$value')
          .get()
          .then(response => responses.shamefullySendNext(response))
          .catch(err => responses.shamefullySendError(err))
      } else {
        msGraphClient
          .api('https://graph.microsoft.com/v1.0/' + action.query)
          .get()
          .then(response => responses.shamefullySendNext(response))
          .catch(err => responses.shamefullySendError(err))
      }
    }
  })

  return responses
}

export type MsGraphSource = ReturnType<typeof msGraphDriver>
export type MsGraphSink = DriverSinkType<typeof msGraphDriver>
