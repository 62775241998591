import isolate from '@cycle/isolate'
import format from 'date-fns/format'
import xs from 'xstream'
import { apply, flatten, groupBy, id, isLoaded, loaded, Manager, mergeSinks, Unloaded, value, C, remove, orderByDescending, isLoadError } from '../../../generic'
import { VNode } from 'snabbdom'
import { jsx } from 'h'
import { DERIVED, dynamicModel, IGNORE, INIT, pageSinkTemplate } from '../../../infrastructure'
import { Button, capture, Checkbox, CheckboxLabel, click, Col, Container, Danger, dateInput, dateInputFormat, events, Icon, Input, inputs, renderLoadable, readChecked, Row, Warning, change, indexedDate, clickWithIndex, NavLink, NavLinkCreator } from '../../../ui'
import { fromSymmetric, magicMirror, omit, stateScope } from '../../../util'
import { formatLocalDate } from '../../../util/date'
import { Level5ProjectConfiguration, isLevel5 } from '../../model'
import { InputWorkItemMappingItem, SetWorkItemMappingConfigurationResource, workItemMappingConfiguration } from '../../resources'
import { BacklogWorkItem, backlogWorkItemsResource, BacklogWorkItemsResource } from '../../resources/backlog'
import { autoSuggestScore, Suggestable, toSuggestable, TypedAutoSuggest } from '../autoSuggest'
import { ProjectPageSources } from '../common'
import { backlogProblemsRenderConfig, backlogWorkItemMappingProblemRenderConfig, insufficientRightsRenderConfig, renderConfigurationResponse, renderProblem, renderProblemFor, requestErrorRenderConfig, setWorkItemMappingProblemRenderConfig, tiaConfigInvalidRenderConfig, unhandledApiExceptionRenderConfig, unhandledTiaProblemRenderConfig, wrongLevelProblemRenderConfig } from '../renderers'
import { backlogItemId, ConfigurationContext, getProjectMembersForTiaCode, isMissingBacklogWorkItem, isMissingTiaTimesheetCode, MappingTiaTimesheetCode, WorkItemMappingItem, WorkItemMappingViewState } from './viewModel'
import { asSimple } from '@/fsharp'

type WorkItemMappingSources = ProjectPageSources<WorkItemMappingViewState> & { mirror: { chooseWorkItem: xs<BacklogWorkItem> } }

const newItem = (
  tiaCodeSuggestables: WorkItemMappingViewState['newItem']['tiaCodeInput']['suggestables'],
  workItemSuggestables: WorkItemMappingViewState['newItem']['workItemInput']['suggestables']): WorkItemMappingViewState['newItem'] =>
  ({
    from: null,
    until: null,
    workItem: null,
    workItemInput: {
      input: '',
      suggestables: workItemSuggestables
    },
    tiaCode: null,
    tiaCodeInput: {
      input: '',
      suggestables: tiaCodeSuggestables
    }
  })

const defaultSettings: WorkItemMappingViewState['settings'] = {
  hideExpired: true,
  groupBy: ['backlogItem']
}

export const issueAfleidingenViewState = (context: ConfigurationContext): WorkItemMappingViewState => {
  if (!isLevel5(context.configuration.levelConfiguration) || typeof context.tiaCodes.case === 'string') {
    return {
      context: context,
      workItemsCounter: 0,
      mappings: [],
      workItems: Unloaded,
      newItem: newItem(Unloaded, Unloaded),
      settings: defaultSettings,
      putWorkItemMappingConfigurationResponse: Unloaded
    }
  }

  const rates = flatten(context.configuration.levelConfiguration.fields[0].orders.map(o => o.rates))
  const tiaCodes = context.tiaCodes
  const mappingTiaTimesheetCodes =
    groupBy(
      rates,
      item => item.tiaCodeId,
      (([tiaCodeId, items]): MappingTiaTimesheetCode => ({ id: tiaCodeId, name: items[0].tiaCodeName, writable: tiaCodes[tiaCodeId]?.writable ?? false })))

  const workItemMapping = context.configuration.levelConfiguration.fields[0].workItemMapping
  return ({
    context: context,
    workItemsCounter: workItemMapping.length,
    mappings: workItemMapping.map((m, i): WorkItemMappingItem => {
      const from = m.from
      const until = m.until
      const tiaCode = mappingTiaTimesheetCodes.find(tm => m.tiaCodeId === tm.id) || { missingTiaCodeId: m.tiaCodeId, missingTiaCodeName: m.tiaCodeName }
      const workItemId = m.workItemId
      return ({
        from: from,
        until: asSimple(until),
        projectMembers: getProjectMembersForTiaCode(rates, context.configuration.info.members, m.tiaCodeId),
        tiaCode: tiaCode,
        workItem: workItemId,
        key: i
      })
    }),
    workItems: Unloaded,
    newItem: newItem(loaded(mappingTiaTimesheetCodes.map((item): Suggestable<MappingTiaTimesheetCode> => ({ display: item.name, value: item }))), Unloaded),
    settings: defaultSettings,
    putWorkItemMappingConfigurationResponse: Unloaded
  })
}

const workItemDisplayText = (item: BacklogWorkItem) => `#${item.id}: ${item.details.title}`

const workItemMappingItemView = (item: WorkItemMappingItem, showWorkItem: boolean) =>
  <Row key={item.key}>
    <Col>{
      (typeof item.workItem === 'string' || !showWorkItem)
        ? '' : isMissingBacklogWorkItem(item.workItem) ? <span>Missing work item #{item.workItem.missingBacklogWorkItemId}<Icon icon="alert-triangle" color="orange" /></span>
          : <span>{workItemDisplayText(item.workItem)}</span>}</Col>
    <Col>{
      isMissingTiaTimesheetCode(item.tiaCode)
        ? <span title={`Missing TIA code with id ${item.tiaCode.missingTiaCodeId}!`}>{item.tiaCode.missingTiaCodeName}<Icon icon="alert-triangle" color="red" /></span>
        : item.tiaCode.writable
          ? item.tiaCode.name
          : <span title="This TIA code is read only!">{item.tiaCode.name}<Icon icon="alert-triangle" color="orange" /></span> }
    </Col>
    <Col>{item.projectMembers.map(m => m.displayName).join(', ')}</Col>
    <Col><Input data-index={item.key} type="date" className="from" value={item.from ? format(item.from, dateInputFormat) : ''} /></Col>
    <Col><Input data-index={item.key} type="date" className="until" value={item.until ? format(item.until, dateInputFormat) : ''} /></Col>
    <Col width={1}><Button data-index={item.key} context="danger" className="delete"><Icon icon="x" /></Button></Col>
  </Row>

const hideExpiredCheckboxId = 'issue-mapping-hide-expired' as const

const intent = (sources: WorkItemMappingSources, workItemsResource: BacklogWorkItemsResource, mappingResource: SetWorkItemMappingConfigurationResource) => ({
  ...capture(
    inputs({
      '.new-item-from': dateInput,
      '.new-item-until': dateInput
    }),
    events({
      '.from': indexedDate,
      '.until': indexedDate,
      '.delete': clickWithIndex,
      '#add-new-work-item-mapping': click,
      '#save-work-item-mapping': click,
      [`#${hideExpiredCheckboxId}` as const]: C(change, readChecked)
    })
  )(sources),
  chooseWorkItem: sources.mirror.chooseWorkItem,
  receiveBacklogWorkItems: workItemsResource.value,
  putWorkItemMappingConfigurationResponse: mappingResource.putResponse
})

const { get, set } = Manager<WorkItemMappingViewState>()

const model = (intents: ReturnType<typeof intent>, sources: WorkItemMappingSources, mappingResource: SetWorkItemMappingConfigurationResource) =>
  dynamicModel(sources.state.stream)(
    pageSinkTemplate,
    intents,
    {
      [INIT]: IGNORE,
      chooseWorkItem: set(state => state.newItem.workItem),
      '.new-item-from': newValue => set(state => state.newItem.from)(val => newValue || val),
      '.new-item-until': newValue => set(state => state.newItem.until)(val => newValue || val),
      '#add-new-work-item-mapping': _ => state => {
        const { workItem, tiaCodeInput: { input }, from, until } = state.newItem
        if (!workItem || !input || !from || !isLoaded(state.newItem.tiaCodeInput.suggestables) || !isLevel5(state.context.configuration.levelConfiguration)) {
          return state
        }

        const suggestables = value(state.newItem.tiaCodeInput.suggestables)
        const filteredSuggestables = suggestables.filter(suggestable => autoSuggestScore(state.newItem.tiaCodeInput.input, suggestable.display) >= 0)
        if (!filteredSuggestables.length) {
          return state
        }

        const rates = flatten(state.context.configuration.levelConfiguration.fields[0].orders.map(o => o.rates))

        const newItems =
          filteredSuggestables
            .map((suggestedCode, i): WorkItemMappingItem => ({
              workItem: workItem,
              tiaCode: suggestedCode.value,
              from: from,
              until: until,
              projectMembers: getProjectMembersForTiaCode(rates, state.context.configuration.info.members, suggestedCode.value.id),
              key: state.workItemsCounter + i
            }))

        return set(s => s.mappings)([...state.mappings, ...newItems])
          .and(s => s.newItem)(newItem(state.newItem.tiaCodeInput.suggestables, state.newItem.workItemInput.suggestables))
          .and(s => s.workItemsCounter)(state.workItemsCounter + newItems.length)(state)
      },

      '.from': ({index, value}) => set(state => state.mappings.find(m => m.key === index)!.from)(val => value || val),
      '.until': ({index, value}) => set(state => state.mappings.find(m => m.key === index)!.until)(value),
      '.delete': index => set(state => state.mappings)(mappings => remove(mappings.find(m => m.key === index)!)(mappings)),

      receiveBacklogWorkItems: response => state => {
        if (isLoadError(response)) {
          return set(s => s.workItems)(response)(state)
        }

        if (!isLoaded(response)) {
          return state
        }

        const workItems = value(response).result

        return set(s => s.workItems)(response)
          .and(s => s.newItem.workItemInput.suggestables)(loaded(workItems.map(toSuggestable(workItemDisplayText))))
          .and(s => s.mappings)(afleidingen =>
            afleidingen.map(afleiding => {
              if (typeof afleiding.workItem !== 'string') {
                return afleiding
              }

              const workItemId = afleiding.workItem

              return ({
                ...afleiding,
                workItem: workItems.find(t => t.id === workItemId) || { missingBacklogWorkItemId: workItemId }
              })
            }))(state)
      },

      '#save-work-item-mapping': {
        output: _ => state => {
          if (!state.mappings.every(m => m.workItem && m.tiaCode && m.from)) {
            return {}
          }

          return {
            HTTP: [mappingResource.put(state.mappings.map((m): InputWorkItemMappingItem => ({
              from: formatLocalDate(m.from),
              until: m.until ? formatLocalDate(m.until) : null,
              tiaCodeName: isMissingTiaTimesheetCode(m.tiaCode) ? m.tiaCode.missingTiaCodeName : m.tiaCode.name,
              workItemId: typeof m.workItem === 'string' ? m.workItem : isMissingBacklogWorkItem(m.workItem) ? m.workItem.missingBacklogWorkItemId : m.workItem.id
            })))]
          }
        }
      },

      '#issue-mapping-hide-expired': set(state => state.settings.hideExpired),

      putWorkItemMappingConfigurationResponse: {
        state: set(state => state.putWorkItemMappingConfigurationResponse),
        output: response => state => {
          if (!isLoaded(response)) {
            return {}
          }

          return {
            HTTP: [sources.primState.projectConfiguration(state.context.key).refresh]
          }
        }
      },
      [DERIVED]: IGNORE
    })

const view = (NavLinkForBacklogConfiguration: NavLink) => (state: WorkItemMappingViewState, tiaCodeSuggest: VNode, workItemSuggest: VNode) => {
  if (!isLevel5(state.context.configuration.levelConfiguration)) {
    return <Container>
      <Warning>You cannot configure the backlog item-based derivations if the project level is lower than level 5. Configure a backlog integration first.</Warning>
    </Container>
  }

  if (typeof state.context.tiaCodes.case === 'string') {
    return <Container>
      <Danger>{renderProblemFor(state.context.tiaCodes)({
        ...requestErrorRenderConfig,
        ...insufficientRightsRenderConfig,
        ...unhandledApiExceptionRenderConfig,
        ...unhandledTiaProblemRenderConfig,
        ...tiaConfigInvalidRenderConfig
      })}</Danger>
    </Container>
  }

  const relevantMappings = state.mappings.filter(mapping => !state.settings.hideExpired || (mapping.until === null || mapping.until >= new Date()))
  const groups = groupBy(relevantMappings, m => backlogItemId(m.workItem), id)
  const longestIdLength = Math.max(...groups.map(([key]) => key.length))
  const sortedGroups = orderByDescending(([key]) => key.padStart(longestIdLength, '0'), groups)

  return <Container>
    <Row key="controls" className="mb-2">
      <Col>
        <Checkbox id={hideExpiredCheckboxId} checked={state.settings.hideExpired} />
        <CheckboxLabel for={hideExpiredCheckboxId} className="ms-1">Hide expired derivations</CheckboxLabel>
      </Col>
    </Row>
    <Row key="header" className="mb-2">
      <Col><label className="required">Backlog item</label></Col>
      <Col><label className="required">TIA-code</label></Col>
      <Col><label>Members</label></Col>
      <Col><label className="required">From</label></Col>
      <Col><label>Until</label></Col>
      <Col width={1}></Col>
    </Row>
      {...sortedGroups.map(([_, group]) =>
        [workItemMappingItemView(group[0], true), ...group.slice(1).map(m => workItemMappingItemView(m, true))])}
      <Row key="new">
        <Col>
          {workItemSuggest}
        </Col>
        <Col>
          {tiaCodeSuggest}
        </Col>
        <Col></Col>
        <Col><Input type="date" className="new-item-from" value={state.newItem.from ? format(state.newItem.from, dateInputFormat) : ''} /></Col>
        <Col><Input type="date" className="new-item-until" value={state.newItem.until ? format(state.newItem.until, dateInputFormat) : ''} /></Col>
        <Col width={1}><Button context="success" id="add-new-work-item-mapping"><Icon icon="plus" /></Button></Col>
      </Row>
      <Row key="controls" className="mt-2">
        <Col>
          <Button context="primary" id="save-work-item-mapping">Save</Button>
          {renderLoadable(state.putWorkItemMappingConfigurationResponse, {
            loaded: () => null,
            error: problem => renderLoadable(state.workItems, workItemsResponse => <Danger className="mt-3">
              {renderConfigurationResponse(setWorkItemMappingProblemRenderConfig(workItemsResponse.result))(problem)}
            </Danger>)
          })}
        </Col>
      </Row>
      {renderLoadable(
        state.workItems,
        { loaded:
          workItemsResponse => workItemsResponse.warnings.length
            ? <Row key="problems" className="mt-3">
              <Warning>
                {workItemsResponse.warnings.map(problem => <div>{renderProblem(backlogWorkItemMappingProblemRenderConfig(problem.workItemDetails))(problem.problemDetails)}</div>)}
              </Warning>
            </Row>
            : null,
        error: problem =>
          <Danger className="mt-3">
            {renderProblemFor(problem)({
              ...insufficientRightsRenderConfig,
              ...unhandledApiExceptionRenderConfig,
              ...requestErrorRenderConfig,
              ...wrongLevelProblemRenderConfig,
              ...backlogProblemsRenderConfig(
                NavLinkForBacklogConfiguration,
                (state.context.configuration.levelConfiguration.fields[0] as Level5ProjectConfiguration).backlogConfigurations) })}
          </Danger>
        })}
  </Container>
}

export const WorkItemMappingComponent = magicMirror((sources: WorkItemMappingSources) => {
  const backlogWorkItems = backlogWorkItemsResource(sources.apiHost + '/api')(sources.HTTP)(sources.projectKey)
  const mappingResource = workItemMappingConfiguration(sources.apiHost + '/api')(sources.HTTP)(sources.projectKey)

  const tiaCodeSuggest = isolate(TypedAutoSuggest<MappingTiaTimesheetCode>(), stateScope(fromSymmetric(get(state => state.newItem.tiaCodeInput)), 'tiaCodeSuggest'))(sources)
  const workItemSuggest = isolate(TypedAutoSuggest<BacklogWorkItem>(), stateScope(fromSymmetric(get(state => state.newItem.workItemInput)), 'workItemSuggest'))(sources)

  const sinks = model(intent(sources, backlogWorkItems, mappingResource), sources, mappingResource)

  return mergeSinks(
    sinks,
    omit(tiaCodeSuggest, 'DOM', 'autoSuggestChoose'),
    omit(workItemSuggest, 'DOM', 'autoSuggestChoose'),
    {
      DOM: xs.combine(sources.state.stream, tiaCodeSuggest.DOM, workItemSuggest.DOM).map(apply(view(NavLinkCreator(sources.parentRouter.path('backlog'))))),
      HTTP: xs.of(backlogWorkItems.get),
      chooseWorkItem: workItemSuggest.autoSuggestChoose
    })
})
