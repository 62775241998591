import { jsx } from 'h'
import { Container,Row } from '../../../ui'
import { ProjectPageSources } from '../common'

export const GetPowerBITemplatePage = (sources: ProjectPageSources<Record<string, any>> ) => ({
  DOM: sources.config.config.map(config => <Container>
    <Row className="px-3">
      <a
        className="btn btn-primary border"
        target="_blank"
        href={config.PowerBITemplateUrl}>
        Download Power BI template
      </a>
    </Row>
  </Container>
  )
})
