export type MappableAzureField =
  | 'WorkItemType'
  | 'Iteration'
  | 'Milestone'
  | 'Points'
  | 'Status'
  | 'Title'
  | 'Order'

export type AzureBacklogMappingConfig = {
  customFields: string[]
  customMapping: { [K in MappableAzureField]?: string }
}

export const emptyAzureBacklogMappingConfig: AzureBacklogMappingConfig = {
  customFields: [],
  customMapping: {}
}

export type AzureBacklogConfiguration = {
  organization: string
  projectId: string
  pat: string
  mappingConfig: AzureBacklogMappingConfig
}
