import { Driver, Drivers, Main, MatchingMain } from '@cycle/run'
import { timeDriver, TimeSource } from '@cycle/time'
import { Scheduler } from '@cycle/time/lib/es6/src/scheduler'
import xs, { Stream } from 'xstream'
import { strategyKey } from './constants'

type ScopePath = PropertyKey[]
export type Scope = {
  path: ScopePath
  parentScope?: Scope
}

type RegisteredStream = {
  scope: Scope
  stream: RecordedStream<any>
}

export type RegisteredStreams = RegisteredStream[]

export type RecordedTime = number

export type RecordedEventBase<D extends Drivers> = {
  driverName: StrategyDriverNames<D>
  time: RecordedTime
  scope: Scope
}

export type SimpleRecordedEvent<D extends Drivers> = RecordedEventBase<D> & {
  event: any
}

export type InnerRecordedEvent<D extends Drivers> = RecordedEventBase<D> & {
  inner: {
    index: number
    isMemory: boolean
  }
}

export type ErrorRecordedEvent<D extends Drivers> = RecordedEventBase<D> & {
  error: any
}

export type CompleteRecordedEvent<D extends Drivers> = RecordedEventBase<D> & {
  complete: true
}

export type RecordedEvent<D extends Drivers> = SimpleRecordedEvent<D> | InnerRecordedEvent<D> | ErrorRecordedEvent<D> | CompleteRecordedEvent<D>

export function isSimpleEvent<D extends Drivers>(event: RecordedEventBase<D>): event is SimpleRecordedEvent<D> {
  return 'event' in event
}

export function isErrorEvent<D extends Drivers>(event: RecordedEventBase<D>): event is ErrorRecordedEvent<D> {
  return 'error' in event
}

export function isCompleteEvent<D extends Drivers>(event: RecordedEventBase<D>): event is CompleteRecordedEvent<D> {
  return 'complete' in event
}

export type PlaybackEvent<D extends Drivers> = RecordedEvent<D> & {
  streams: PlaybackStreams<D>
}

export type Log<D extends Drivers> = RecordedEvent<D>[]

type RecordEvent = (scope: Scope, event: any) => void
type RecordInner = (scope: Scope, index: number, isMemory: boolean) => void
type RecordError = (scope: Scope, error: any) => void
type RecordComplete = (scope: Scope) => void

export type SinklessDriver = () => any
export type SinkDriver = (sink: Stream<any>) => any

export type StrategyDriver<D extends Driver<any, any>> = D & {
  [strategyKey]: DriverStrategy<D>
}
export type SourceRecordableDriver<D extends Driver<any, any>> = D & {
  [strategyKey]: RecordSourceStrategy<D>
}
export type SinkConfigurableDriver<D extends Driver<any, any>> = D & {
  [strategyKey]: ConfigureSinkStrategy<D>
}

export type StrategyDriverNames<D extends Drivers> = { [k in keyof D]: D[k] extends StrategyDriver<D[k]> ? k : never }[keyof D]
export type StrategyDrivers<D extends Drivers> = { [k in StrategyDriverNames<D>]: StrategyDriver<D[k]> }

export type MaybeRecordableDrivers<D extends Drivers> = { [P in keyof D]: StrategyDriver<D[P]> | D[P] }

export type SourceRecordableDrivers<D extends Drivers> = { [P in keyof D]: D[P] extends SourceRecordableDriver<D[P]> ? D[P] : never }

export type RecordedSource<So> = So

export type RecordedStream<T> =
  xs<T>
  & {
    _offsetIndex: number
    _recordedSource: xs<T> | undefined
  }

export type PlaybackStreams<D extends Drivers> = {
  get: (driverName: StrategyDriverNames<D>, scope: Scope) => Array<RecordedStream<any>>
  add: (driverName: StrategyDriverNames<D>, scope: Scope, stream: RecordedStream<any>) => void
  remove: (driverName: StrategyDriverNames<D>, scope: Scope, stream: RecordedStream<any>) => void
  contains: (driverName: StrategyDriverNames<D>, scope: Scope, stream: RecordedStream<any>) => boolean
}

type RegisterStream = (scope: Scope, stream: RecordedStream<any>) => void
type UnregisterStream = (scope: Scope, stream: RecordedStream<any>) => void

export type RecordingContext = {
  paused: boolean
  replay: boolean
  driverName: string
  recordEvent: RecordEvent
  recordInner: RecordInner
  recordError: RecordError
  recordComplete: RecordComplete
  registerStream: RegisterStream
  unregisterStream: UnregisterStream
  getStream: (scope: Scope) => RecordedStream<any> | null
  pause$: xs<boolean>
  replaying$: xs<boolean>
  rootStrategy: any
}

export type Strategy = any
export type FunctionStrategy = (...args: any[]) => { strategy: any; scope?: string }

type SourceStrategy<_So> = any
type SinkStrategy<Si> = (sink: Si, replaying$: xs<boolean>) => Si

export type RecordSourceStrategy<Source> = {
  source: SourceStrategy<Source>
}
export type ConfigureSinkStrategy<Sink> = {
  sink: SinkStrategy<Sink>
}
export type DualStrategy<D extends Driver<any, any>> = RecordSourceStrategy<DriverSource<D>> & ConfigureSinkStrategy<DriverSink<D>>

export type DriverSink<D extends Driver<any, any>> = D extends (sink: xs<infer T>) => any ? xs<T> : never
export type DriverSource<D extends Driver<any, any>> = D extends (sink: Stream<any>) => infer T ? T : D extends () => infer T ? T : never

export type DriverStrategy<D extends Driver<any, any>> = RecordSourceStrategy<DriverSource<D>> | ConfigureSinkStrategy<DriverSink<D>> | DualStrategy<D>

export type InnerStreamRecordingStrategy = {
  inner: (stream: xs<any>) => Strategy
}

export type ValueStreamRecordingStrategy = {
  record: (event: any) => any
}

export type StreamRecordingStrategy = {
  replayCompose?: (stream: xs<any>) => xs<any>
  compose?: (stream: xs<any>) => xs<any>
} & (ValueStreamRecordingStrategy | InnerStreamRecordingStrategy)

export type DriversWithTime<D extends Drivers> = D & { Time: typeof timeDriver }
export type MatchingMainWithTime<D extends Drivers, M extends Main> = MatchingMain<DriversWithTime<D>, M>

export type PrivateTimeSource = TimeSource & {
  _pause: () => void
  _time: () => number
  _scheduler: Scheduler<any>
  _runVirtually: (done: (err?: any) => void, timeToRunTo: number) => void
  _resume: (time: number) => void
}

export type TimeDriver = typeof timeDriver
