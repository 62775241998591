import isolate from '@cycle/isolate'
import { VNode } from 'snabbdom'
import xs from 'xstream'
import { toIntent } from '../../../../dataServices'
import { arrayToMap, C, ErrorType, except, isLoaded, isLoading, Manager, mapError, mapLoaded, mergeSinks, Unloaded, value } from '../../../../generic'
import { jsx } from 'h'
import { DERIVED, dynamicIntent, dynamicModel, IGNORE, INIT, pageSinkTemplate, StaticIntentDefinition } from '../../../../infrastructure'
import { Button, capture, change, Checkbox, CheckboxLabel, click, Danger, events, FormCheck, Input, inputs, inputTarget, Label, renderLoadable, Warning } from '../../../../ui'
import { omit, stateScope } from '../../../../util'
import { dummyCredential, emptyTimeEntryMappingConfig, PaymoConfiguration, TimeEntryMappingConfig } from '../../../model'
import { mapServiceItemValue, serviceItemToIntent } from '../../../primDataService'
import { timeTrackerConfiguration } from '../../../resources'
import { PaymoProject, paymoProjectsResource, PAYMO_AUTHENTICATION_FAILED } from '../../../resources/paymo'
import { ProjectPageSources } from '../../common'
import { insufficientRightsRenderConfig, paymoConfigInvalidRenderConfig, renderConfigurationResponse, renderProblem, renderProblemFor, requestErrorRenderConfig, setTimeTrackerConfigurationProblemRenderConfig, timeTrackerConfigurationSettingWarningRenderConfig, unhandledApiExceptionRenderConfig, unhandledPaymoProblemRenderConfig, wrongLevelProblemRenderConfig } from '../../renderers'
import { ConfigurationContext, LastConfiguredProjectInfo, PaymoConfigurationViewState } from '../viewModel'
import { TimeEntryMappingConfigurationComponent } from './timeEntryMappingConfiguration'
import { asSimple } from '@/fsharp'

type State = PaymoConfigurationViewState
type Sources = ProjectPageSources<State>

const emptyState = (context: ConfigurationContext): State => ({
  context: context,
  timeEntryMappingConfig: emptyTimeEntryMappingConfig,
  apiKey: '',
  projectsResponse: Unloaded,
  selectedProjects: new Set<number>(),
  lastConfiguredProjectInformation: new Map<number, LastConfiguredProjectInfo<number>>(),
  missingProjects: [],
  timeTrackerConfigurationUpdateResponse: Unloaded
})

export const paymoConfigurationViewState = (context: ConfigurationContext, timeEntryMappingConfig: TimeEntryMappingConfig, paymoConfig: PaymoConfiguration | null): State => {
  return {
    ...emptyState(context),
    timeEntryMappingConfig: timeEntryMappingConfig,
    apiKey: paymoConfig?.apiKey ?? '',
    selectedProjects: new Set<number>(paymoConfig?.projects.map(p => p.projectId) ?? []),
    lastConfiguredProjectInformation:
      arrayToMap(
        paymoConfig?.projects ?? [],
        p => p.projectId,
        p => ({
          id: p.projectId,
          name: p.lastConfiguredName,
          colour: p.lastConfiguredColour,
          state: p.lastConfiguredStatus === 1 ? 'Active' : 'Inactive'
        }))
  }
}

const intent = (sources: Sources) =>
  dynamicIntent<State>()(
    pageSinkTemplate,
    {
      ...capture(
        inputs({
          '#paymo-apikey': null
        }),
        events({
          '.save': click,
          '[data-projectid]': C(change, es => es.map(C(inputTarget, el => [+(el.dataset['projectid'] as string), el.checked] as const))),
        }))(sources),
      paymoProjects: {
        discriminator: state => state.apiKey,
        value: (state, apiKey: string) =>
          (apiKey
            ? apiKey === dummyCredential
              ? serviceItemToIntent(mapServiceItemValue(
                sources.primState.paymo.projects(state.context.key),
                loadable => mapError(loadable, e => e as typeof e | typeof PAYMO_AUTHENTICATION_FAILED)))
              : toIntent(paymoProjectsResource(sources.HTTP)(state.apiKey))
            : xs.never<State['projectsResponse']>()) as StaticIntentDefinition<State['projectsResponse'], { HTTP: null }>
      },
      receivePutResponse: timeTrackerConfiguration(sources.apiHost + '/api')(sources.HTTP)(sources.projectKey).putResponse
    })

const { set } = Manager<State>()

const model = (intents: ReturnType<typeof intent>, sources: Sources) =>
  dynamicModel(sources.state.stream)(
    pageSinkTemplate,
    intents, {
      [INIT]: IGNORE,
      '#paymo-apikey': key => set(state => state.apiKey)(key)
        .and(s => s.selectedProjects)(new Set())
        .and(s => s.projectsResponse)(Unloaded),
      paymoProjects: response =>
        set(state => state)(state => {
          if (!isLoaded(response)) {
            return {
              ...state,
              projectsResponse: response
            }
          }

          const projects = value(response)

          return {
            ...state,
            missingProjects:
              except(Array.from(state.lastConfiguredProjectInformation.keys()))(projects.map(p => p.id))
                .map(missingProjectId => state.lastConfiguredProjectInformation.get(missingProjectId)!),
            projectsResponse: mapLoaded(response, projects => projects.slice().sort((a, b) => a.name.localeCompare(b.name)))
          }
        }),
      '[data-projectid]': ([projectId, selected]) => set(state => state.selectedProjects)(selectedProjects => {
        const newSet = new Set(selectedProjects)
        if (selected) {
          newSet.add(projectId)
        } else {
          newSet.delete(projectId)
        }
        return newSet
      }),
      '.save': {
        output: _ => state => ({
          HTTP: [
            timeTrackerConfiguration(sources.apiHost + '/api')(sources.HTTP)(sources.projectKey).put({
              timeEntryMappingConfig: {
                ignoreOverlap: state.timeEntryMappingConfig.ignoreOverlap,
                timeEntryPaddingAmount: asSimple(state.timeEntryMappingConfig.timeEntryPaddingAmount)
              },
              timeTrackerConfiguration: {
                case: 'PaymoConfigInput',
                fields: [{
                  apiKey: state.apiKey,
                  projectIds: Array.from(state.selectedProjects)}]}
            })
          ]
        })
      },
      receivePutResponse: {
        state: response => set(state => state.timeTrackerConfigurationUpdateResponse)(response),
        output: response => _ =>
          (isLoaded(response))
            ? {
              HTTP: [sources.primState.projectConfiguration(sources.projectKey).refresh]
            }
            : {}
      },
      [DERIVED]: IGNORE
    })

const renderClockifyResourceError = (err: ErrorType<State['projectsResponse']>) =>
  err === PAYMO_AUTHENTICATION_FAILED
    ? <Warning>The provided API key is not valid.</Warning>
    : typeof err === 'string'
      ? <Danger>{err}</Danger>
      : <Danger>{renderProblemFor(err)({
        ...requestErrorRenderConfig,
        ...insufficientRightsRenderConfig,
        ...unhandledApiExceptionRenderConfig,
        ...wrongLevelProblemRenderConfig,
        ...paymoConfigInvalidRenderConfig,
        ...unhandledPaymoProblemRenderConfig
      })}</Danger>

const renderProject = (selectedProjects: Set<number>) => (project: PaymoProject) =>
  <FormCheck>
    <Checkbox id={`paymo-project-${project.id}`} dataset={{'projectid': project.id.toString()}} type="checkbox" checked={selectedProjects.has(project.id)} />
    <CheckboxLabel for={`paymo-project-${project.id}`}>{project.name}</CheckboxLabel>
  </FormCheck>

const view = (state: State, timeEntryMappingConfigNode: VNode) => <div>
  <div className="d-inline-block mb-3">
    <Label className="required" for="paymo-apikey">Paymo API key</Label>
    <Input className="mb-3" id="paymo-apikey" type="password" value={state.apiKey} autocomplete="off" />

    {renderLoadable(state.projectsResponse,
      {
        loaded: projectsResponse =>
          <>
            <label className="required">Projects</label>
            {state.missingProjects.length
              ? <Warning>
                <label>
                  Missing projects: uncheck and save to resolve this issue
                </label>
                <div>
                  {state.missingProjects.map(project =>
                    <FormCheck>
                      <Checkbox
                        id={`paymo-project-${project.id}`}
                        dataset={{'projectid': project.id.toString()}}
                        type="checkbox"
                        checked={state.selectedProjects.has(project.id)} />
                      <CheckboxLabel for={`paymo-project-${project.id}`}>{project.name} ({state.lastConfiguredProjectInformation.get(project.id)!.state})</CheckboxLabel>
                    </FormCheck>)}
                </div>
              </Warning>
              : null}
            {projectsResponse.some(project => !project.active)
              ? <>
                <div>
                  <label
                    style={{cursor: 'pointer'}}
                    data-bs-toggle="collapse"
                    data-bs-target="#paymo-active-projects"
                    aria-expanded={true}
                    aria-controls="paymo-active-projects">
                    Active projects
                  </label>
                  <div className="collapse show" id="paymo-active-projects">
                    {projectsResponse.filter(project => project.active).map(renderProject(state.selectedProjects))}
                  </div>
                </div>
                <div>
                  <label
                    style={{cursor: 'pointer'}}
                    data-bs-toggle="collapse"
                    data-bs-target="#paymo-inactive-projects"
                    aria-expanded={false}
                    aria-controls="paymo-inactive-projects">
                    Inactive projects
                  </label>
                  <div className="collapse" id="paymo-inactive-projects">
                    {projectsResponse.filter(project => !project.active).map(renderProject(state.selectedProjects))}
                  </div>
                </div>
              </>
              : projectsResponse.map(renderProject(state.selectedProjects))}
          </>,
        error: renderClockifyResourceError
      })}
  </div>
  {timeEntryMappingConfigNode}
  {renderLoadable(state.timeTrackerConfigurationUpdateResponse, {
    loaded: warnings =>
      warnings.length
        ? <Warning className='mb-3'>
          <h4>The configuration was saved, but the following issues have been detected:</h4>
          {warnings.map(warning => <div>{renderProblem(timeTrackerConfigurationSettingWarningRenderConfig(state.context.configuration.info.members))(warning)}</div>)}</Warning>
        : null,
    loading: () => null,
    error: err => <Danger className='mb-3'>{renderConfigurationResponse(setTimeTrackerConfigurationProblemRenderConfig)(err)}</Danger>
  })}
  <Button
    context="primary"
    className="save"
    disabled={!state.selectedProjects.size || isLoading(state.timeTrackerConfigurationUpdateResponse)}>
      Save
  </Button>
</div>

export const PaymoConfigurationComponent = (sources: Sources) => {
  const sinks = model(intent(sources), sources)

  const timeEntryMappingConfigurationComponent =
    isolate(
      TimeEntryMappingConfigurationComponent,
      stateScope({
        get: (state: State) => ({...state.timeEntryMappingConfig, projectSettings: state.context.configuration.info.settings}),
        set: (state, childState) => set(s => s.timeEntryMappingConfig)(childState)(state)
      }))(sources)

  return mergeSinks(sinks, omit(timeEntryMappingConfigurationComponent, 'DOM'), {
    DOM: xs.combine(sources.state.stream, timeEntryMappingConfigurationComponent.DOM).map(([state, child]) => view(state, child))
  })
}
