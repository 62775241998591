import { C, Manager } from '@/generic'
import { jsx } from '@/h'
import { DERIVED, dynamicModel, IGNORE, INIT, pageSinkTemplate } from '@/infrastructure'
import { change, Checkbox, CheckboxLabel, events, readChecked } from '@/ui'
import { MainDOMSource } from '@cycle/dom'
import { StateSource } from '@cycle/state'

export type State = {
  hideExpired: boolean
}

export const state: State = { hideExpired: false }

export type Sources = { DOM: MainDOMSource; state: StateSource<State> }

export const intent = events({
  '.hide-checked': C(change, readChecked)
})

const { set } = Manager<State>()

export const behaviour = {
  '.hide-checked': set(state => state.hideExpired)
}

export const model = (intents: ReturnType<typeof intent>, sources: Sources) =>
  dynamicModel(sources.state.stream)(pageSinkTemplate, intents, {
    [INIT]: IGNORE,
    ...behaviour,
    [DERIVED]: IGNORE
  })

export const view = (state: State) =>
  <CheckboxLabel>
    <Checkbox className="hide-checked me-1" checked={state.hideExpired} />
    Hide expired derivations
  </CheckboxLabel>

