import { HTTPSource } from '@cycle/http'
import { handleLatest, toLoadable } from '../../dataServices'
import { GettableResource } from '../../dataServices/types'
import { ClockifyConfigurationInvalid, InsufficientRights, PaymoConfigurationInvalid, RequestError, UnhandledApiExceptionProblem, UnhandledClockifyProblem, UnhandledPaymoProblem, WrongLevelProblem, insufficientRights } from '../model'
import { simpleResult, handleError, resultOrError } from './resourceUtil'
import { K } from '@/generic'

export type TimeTrackerUser = {
  id: string
  name: string
}

export type TimeTrackerUsersResponse = TimeTrackerUser[]

export type TimeTrackerUsersResource =
  GettableResource<TimeTrackerUsersResponse, RequestError | InsufficientRights | WrongLevelProblem | ClockifyConfigurationInvalid | PaymoConfigurationInvalid | UnhandledClockifyProblem | UnhandledPaymoProblem | UnhandledApiExceptionProblem>

export const timeTrackerUsersResource = (root: string) => (HTTP: HTTPSource) => (key: string): TimeTrackerUsersResource => {
  const url = `${root}/projects/${key}/timetracker/users`

  const request = {
    category: url,
    method: 'GET',
    url: url
  }

  return {
    name: request.url,
    get: request,
    value: toLoadable(handleLatest(HTTP)(request.category)({
      success: simpleResult<TimeTrackerUsersResponse>,
      failure: {
        403: K(insufficientRights),
        409: simpleResult<WrongLevelProblem | ClockifyConfigurationInvalid | PaymoConfigurationInvalid>,
        500: resultOrError<UnhandledApiExceptionProblem | UnhandledClockifyProblem | UnhandledPaymoProblem>
      },
      error: handleError
    }))
  }
}
