import isolate from '@cycle/isolate'
import { withState } from '@cycle/state'
import { routerify } from 'cyclic-router'
import switchPath from 'switch-path'
import xs from 'xstream'
import { TestComponent } from './authentication/test'
import { isAuthError, isUser, USER, UserProvider } from './authentication/userProvider'
import { withPrimState } from './dataServices'
import { AppSources, mergePageSinks, pageSinkMergeTemplate, PageSinks, withRouterBehaviour } from './infrastructure'
import { PlayGround } from './playground/playground'
import { Index } from './prim'
import { withAuthenticated } from './prim/authentication'
import './style/main.scss'
import { redirectPath } from './util/pages'
import { getPageSink } from './util'
import { splitErrors } from './generic'
import { AuthActionType } from './drivers/msalDriver'

function main(sources: AppSources) {
  const userProvider = UserProvider(sources.msal)
  const authenticatedUser = userProvider[USER].filter(isUser)

  const page$ = sources.config.config.map(config => {
    const pageSources = {
      ...sources,
      config: {
        ...sources.config,
        config: xs.of(config).remember()
      },
      apiHost: config.prim.host,
      configSnapshot: config,
      user: userProvider[USER]
    }

    return withRouterBehaviour<PageSinks, typeof pageSources>(pageSources, {
      '/': redirectPath('/projects'),
      '/projects': isolate(withAuthenticated(withPrimState(Index, config.prim.host)), { state: 'prim', DOM: 'projects', '*': null }),
      '/auth/test': TestComponent,
      '/playground': isolate(withAuthenticated(PlayGround))
    })
  }).flatten()

  function findA([e, t]: [Event, Element | undefined]) {
    const mt = t && t.nodeName && t.nodeName.toLowerCase() === 'a' ? t : undefined
    return [e, mt] as [Event, HTMLAnchorElement | undefined]
  }

  const navigationClick$ = sources.DOM.events('click')
    .map(e => [e, e.target as Element] as [Event, Element])
    .map(findA)
    .filter(([_, t]) => !!(t && t.href))
  const linkNavigationUrl$ = navigationClick$.map(([_, a]) => (a as HTMLAnchorElement).href)

  const { values, errors } = splitErrors(getPageSink(page$, 'DOM'))

  return mergePageSinks(page$, {
    ...pageSinkMergeTemplate,
    DOM: _ => values,
    router: routerPageSink => xs.merge(
      routerPageSink,
      linkNavigationUrl$.map(url => url.replace(location.origin, '')),
      userProvider.user.filter(isAuthError).mapTo('/auth/error')),
    msal: msalSink => msalSink.startWith({ type: AuthActionType.Init }),
    msGraph: routerMsGraphSink => xs.merge(routerMsGraphSink, authenticatedUser.map(user => ({ accessToken: user.tokens[0].token }))),
    log: pageLogs => xs.merge(pageLogs, errors)
  })
}

export const App = isolate(routerify(withState(main), switchPath, { basename: '' }), 'main')
