/* eslint-disable @typescript-eslint/no-use-before-define */
import isolateFn from '@cycle/isolate'
import { Driver, Drivers, Main, MatchingDrivers, MatchingMain, setup as cycleSetup, Sinks } from '@cycle/run'
import { timeDriver } from '@cycle/time'
import { Scheduler } from '@cycle/time/lib/es6/src/scheduler'
import xs, { Listener, MemoryStream, Producer, Subscription } from 'xstream'
import { keysOf, mapObj } from '../generic'
import { strategyKey } from './constants'
import { ConfigureSinkStrategy, DriverSink, DriverSource, DriverStrategy, FunctionStrategy, InnerStreamRecordingStrategy, isCompleteEvent, isErrorEvent, isSimpleEvent, Log, MaybeRecordableDrivers, PlaybackEvent, PlaybackStreams, PrivateTimeSource, RecordedEvent, RecordedStream, RecordedTime, RecordingContext, RecordSourceStrategy, RegisteredStreams, Scope, SinkConfigurableDriver, SourceRecordableDriver, StrategyDriver, StrategyDriverNames, StrategyDrivers, StreamRecordingStrategy } from './types'

function addPath(scope: Scope, ...pathParth: PropertyKey[]) {
  return { ...scope, path: scope.path.concat(pathParth) }
}

const emptyScope: Scope = { path: [] }

function getRegisteredStream(streams: RegisteredStreams, scope: Scope) {
  return streams.filter(({ scope: streamScope }) => like(scope.path, streamScope.path)).map(({ stream }) => stream)
}

function unregisterStream(streams: RegisteredStreams, scope: Scope, stream: xs<any>) {
  const indexes = streams
    .map((x, i) => (like(x.scope.path, scope.path) && x.stream === stream ? i : -1))
    .filter(index => index >= 0)
    .map((index, i) => index - i)

  for (const correctedIndex of indexes) {
    streams.splice(correctedIndex, 1)
  }
}

export function pauseWhileReplaying<Si extends xs<any>>(sink: Si, replaying$: xs<boolean>) {
  return sink.compose(pausable(replaying$)) as Si
}

function scheduleLog<D extends Drivers>(
  scheduler: Scheduler<any>,
  log: Log<D>,
  replayStream: xs<PlaybackEvent<D>>,
  streams: PlaybackStreams<D>,
  adjustedTime: RecordedTime,
  timeToScheduleTo?: RecordedTime) {
  const filteredLog = log.filter(recordedEvent => timeToScheduleTo === undefined || recordedEvent.time <= timeToScheduleTo)
  for (const recordedEvent of filteredLog) {
    let replayTime = recordedEvent.time
    if (replayTime <= adjustedTime) {
      replayTime = adjustedTime + 1
      // console.log('adjust replay time from', recordedEvent.time, 'to', replayTime)
    }
    adjustedTime = replayTime

    scheduler.next(replayStream as any, recordedEvent.time, {
      ...recordedEvent,
      streams
    },
    // (_e, t, _s, c) => console.log('Replayed event for', recordedEvent.driverName, 'at', t, `(${c()})`)
    )
  }

  return adjustedTime
}

function walkObject<So extends Record<string, any>>(source: So, strategy: any, scope: Scope, context: RecordingContext) {
  return new Proxy(source, {
    get: (target, property) => {
      const strat = strategy[property]
      const value = (target as any)[property]
      // console.log('PROXY for', property, target)
      if (strat) {
        return walk(value, strat, addPath(scope, property), context)
      } else {
        return value
      }
    }
  })
}

function isStream(source: any): source is xs<any> {
  return typeof source.subscribe === 'function'
}

function isInnerstreamRecordingStrategy(strategy: StreamRecordingStrategy): strategy is InnerStreamRecordingStrategy {
  return !!(strategy as InnerStreamRecordingStrategy).inner
}

const indexes = {} as { [key: string]: number }
const pathToIndexKey = (parts: string[]) => parts.join('&')
const incrementIndex = (parts: string[], count: number) => {
  const key = pathToIndexKey(parts)
  if (key in indexes) {
    indexes[key] += count
  } else {
    indexes[key] = -1 + count
  }

  return indexes[key]
}

function walkStream(source: xs<any>, strategy: StreamRecordingStrategy, scope: Scope, context: RecordingContext) {
  // console.log('walking stream...', scope.path.join(', '));
  // re-using active streams with the same scope helps us in recording events only once (instead of per subscriber)
  // SIDE-EFFECT: the upstream producer won't see (un)subscriptions as long as there's at least one other subscriber
  // overall, this means less producer start/stop's get triggered - usually this isn't a problem since all streams tend to be hot
  // but at least the default HTTP driver respone streams are in fact COLD
  // another driver that suffers from this is the date driver - or any driver that emits a signal without a unique scope
  // a workaround could be for applications to not access those streams directly, but instead have a state layer provide central access
  // can this state layer be configured here or through strategy?
  const existingRecordedStream = context.getStream(scope)
  if (existingRecordedStream) {
    // we have to re-apply the composition since it's not part of the stored stream
    return strategy.compose ? existingRecordedStream.compose(strategy.compose) : existingRecordedStream
  }

  let offsetApplied = false

  const proxyListener: (listener: Listener<any>) => Listener<any> = (listener: Listener<any>) => {
    return {
      next: event => {
        let index: number
        if (offsetApplied) {
          index = incrementIndex(scope.path as string[], 1)
        } else {
          index = incrementIndex(scope.path as string[], 1 + recordedStream._offsetIndex)
          offsetApplied = true
        }

        if (isInnerstreamRecordingStrategy(strategy)) {
          // console.log('RECORDING INNER STREAM', context.driverName, scope.path, index)
          context.recordInner(scope, index, source instanceof MemoryStream)
          const walked = walkStream(event, strategy.inner(event), addPath(scope, index), context)
          listener.next(walked)
        } else {
          const serialisable = strategy.record(event)
          // console.log('EMITTING CONTENT EVENT', context.driverName, scope.path, serialisable)
          context.recordEvent(scope, serialisable)
          listener.next(serialisable)
        }
      },
      error: e => {
        context.recordError(scope, e)
        listener.error(e)
      },
      complete: () => {
        // console.log('unregistering completed stream', context.driverName, scope.path)
        context.unregisterStream(scope, recordedStream)

        context.recordComplete(scope)
        listener.complete()
      }
    }
  }

  // the proxy produce registers en unregisters the stream depending on whether there are active subscribers
  // this is so that inactive streams are not accidentally recycled (and trigger unwanted subscription effects)
  let sub: Subscription | null = null
  let starting = false
  let stoppedDuringStarting = false
  const proxyProducer: Producer<unknown> = {
    start: listener => {
      if (sub !== null) {
        // means there's a start after a completion
        throw new Error('Cannot start before stopping: ' + JSON.stringify(scope))
      } else {
        // console.log('SUBBING', context.driverName, JSON.stringify(scope))
        starting = true
        const maybeReplayComposedSource =
          (context.replay && strategy.replayCompose)
            ? source.compose(strategy.replayCompose)
            : source
        sub =
          maybeReplayComposedSource
            .compose(pausable(context.pause$))
            .subscribe(proxyListener(listener))

        if (stoppedDuringStarting) {
          starting = false
          stoppedDuringStarting = false
          // console.log('UNSUBBED DURING START', context.driverName, JSON.stringify(scope))
          sub.unsubscribe()
          sub = null
        }
      }
    },
    stop: () => {
      if (!sub) {
        // multiple stops? side-effect of proxy-listener's complete?
        if (!starting) {
          throw new Error('Cannot stop when not started: ' + JSON.stringify(scope))
        } else {
          stoppedDuringStarting = true
        }
      } else {
        // console.log('STOPPED', context.driverName, JSON.stringify(scope))
        sub.unsubscribe()
        sub = null
      }
      // console.log('unregistering stream due to stopped proxy producer', context.driverName, scope.path)
      context.unregisterStream(scope, recordedStream)
    }
  }

  const recordedStream = (source instanceof MemoryStream ? xs.createWithMemory(proxyProducer) : xs.create(proxyProducer)) as RecordedStream<any>

  context.registerStream(scope, recordedStream)

  // this is to make sure recorded inner streams start with the correct next index after replay
  // careful that this exact stream instance is the one that needs to be kept track of
  recordedStream._offsetIndex = 0

  recordedStream._recordedSource = source

  // don't register the composed stream
  return (strategy.compose ? recordedStream.compose(strategy.compose) : recordedStream)
}

function walkFunction(source: any, strategy: FunctionStrategy, scope: Scope, context: RecordingContext) {
  return function recorded(this: any, ...args: any[]) {
    const { scope: addedScope, strategy: valueStrategy } = strategy(...args)
    const value = source.apply(this, args)

    if (valueStrategy) {
      return walk(value, valueStrategy, addedScope ? addPath(scope, addedScope) : scope, context)
    } else {
      return value
    }
  }
}

function walk<So>(source: So, strategy: any, scope: Scope, context: RecordingContext): So {
  if (!source) {
    return source
  } else if (typeof source === 'function') {
    return walkFunction(source, strategy, scope, context) as any
  } else if (isStream(source)) {
    return walkStream(source, strategy, scope, context) as any
  } else if (typeof source === 'object') {
    return walkObject(source as any, strategy, scope, context)
  } else {
    throw new Error(`Source type unsupported: ${source}`)
  }
}

function like<T>(ar1: T[], ar2: T[]) {
  return ar1.length === ar2.length && ar1.every((value, index) => ar2[index] === value)
}

export function configure<TDriver extends Driver<any, any>, TStrategy extends DriverStrategy<TDriver>>(driver: TDriver, strategy: TStrategy) {
  return Object.assign(driver, {
    [strategyKey]: strategy
  })
}

type DriverWithName<D extends Driver<any, any>> = D & ((_: any, name?: string) => ReturnType<D>)

function record<Si, So, TDriver extends SourceRecordableDriver<DriverWithName<Driver<Si, So>>>>(driver: TDriver, context: RecordingContext) {
  const strategy = driver[strategyKey]

  function recordedDriver(sink: Si, driverName?: string) {
    const configuredSink = isConfigureSinkStrategy(strategy) ? strategy.sink(sink as any, context.replaying$) : sink
    const source = driver(configuredSink, driverName)

    return isRecordSourceStrategy(strategy) ? walk(source, strategy.source, emptyScope, context) : source
  }

  return recordedDriver
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function isSourceRecordable<TDriver extends Driver<any, any>>(driver: TDriver): driver is SourceRecordableDriver<TDriver> {
  return hasStrategy(driver) && isRecordSourceStrategy(driver[strategyKey])
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function isSinkConfigurable<TDriver extends Driver<any, any>>(driver: TDriver): driver is SinkConfigurableDriver<TDriver> {
  return hasStrategy(driver) && isConfigureSinkStrategy(driver[strategyKey])
}
function isRecordSourceStrategy<TDriver extends Driver<any, any>>(strategy: DriverStrategy<TDriver>): strategy is RecordSourceStrategy<DriverSource<TDriver>> {
  return !!(strategy as RecordSourceStrategy<DriverSource<TDriver>>).source
}
function isConfigureSinkStrategy<TDriver extends Driver<any, any>>(strategy: DriverStrategy<TDriver>): strategy is ConfigureSinkStrategy<DriverSink<TDriver>> {
  return !!(strategy as ConfigureSinkStrategy<DriverSink<TDriver>>).sink
}

function hasStrategy<TDriver extends Driver<any, any>>(driver: TDriver): driver is StrategyDriver<TDriver> {
  return !!(driver as any)[strategyKey]
}

function pausable(pause$: xs<boolean>) {
  let paused = false
  const pauseSubscription = pause$.subscribe({
    next(x) {
      paused = x
    }
  })

  return <T>(stream: xs<T>) => {
    stream.setDebugListener({
      complete() {
        pauseSubscription.unsubscribe()
      }
    })

    return stream.filter(() => !paused)
  }
}

export function replayDrivers
<OD extends Drivers, OM extends Main, D extends MaybeRecordableDrivers<MatchingDrivers<OD, OM>>, M extends MatchingMain<OD & { Time: typeof timeDriver }, OM>>(
  drivers: D,
  logs: RecordedEvent<D>[],
  setup: typeof cycleSetup,
  main: M,
  isolate: typeof isolateFn,
  first: boolean,
  timeToReplayTo?: RecordedTime
) {
  if (typeof isolate === 'function' && 'reset' in isolate) {
    (isolate as any).reset()
  }

  const realTime = timeDriver(null) as PrivateTimeSource
  if (!first) {
    realTime._pause()
  }

  const pause$ = xs.create<boolean>().startWith(!first)//.debug('PAUSED?')

  const recordableDrivers = (filterX(drivers, hasStrategy) as unknown) as StrategyDrivers<D>

  const registeredStreams = mapObj(recordableDrivers, _ => [] as RegisteredStreams)
  ;(window as any).registeredStreams = registeredStreams
  ;(window as any).getRegisteredStream = getRegisteredStream
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const sourceLogs = logs.slice()

  let paused = !first

  const recordedDrivers = mapObj(recordableDrivers, (driver: StrategyDriver<D[any]>, key: StrategyDriverNames<D>) => {
    const context: RecordingContext = {
      replay: !first,
      get paused() {
        return paused
      },
      driverName: key as string,
      recordEvent: (scope, event) => {
        if (!paused) {
          // console.info('recording event', key, scope, event)
          sourceLogs.push({ event, driverName: key, scope, time: +new Date() })
        }
      },
      recordInner: (scope, index, isMemory) => {
        if (!paused) {
          // console.info('recording inner', key, scope, inner)
          sourceLogs.push({ inner: { index: index, isMemory: isMemory }, driverName: key, scope, time: +new Date() })
        }
      },
      recordError: (scope, error) => {
        if (!paused) {
          sourceLogs.push({ error, driverName: key, scope, time: +new Date() })
        }
      },
      recordComplete: scope => {
        if (!paused) {
          // console.info('recording complete', key, scope)
          sourceLogs.push({ complete: true, driverName: key, scope, time: +new Date() })
        } else {
          // console.info('SKIPPED recording complete', key, scope)
        }
      },
      registerStream: (scope, stream) => {
        // console.log('registering stream', key, scope)
        registeredStreams[key].push({ scope, stream })
      },
      unregisterStream: (scope, stream) => unregisterStream(registeredStreams[key], scope, stream),
      pause$,
      replaying$: first ? xs.of(false as boolean) : pause$,
      rootStrategy: driver[strategyKey],
      getStream: scope => getRegisteredStream(registeredStreams[key], scope)[0] || null
    }
    return record(driver as any, context)
  })

  const driversWithTime = Object.assign({}, drivers, recordedDrivers, {
    Time: () => realTime
  })

  // this one I cannot get right
  // something with 'P extends keyof Sos | "Time"' instead of 'P extends keyof (Sos & Sis) | "Time"'
  const newSourcesSinksAndRun = setup(main, driversWithTime as any)

  const newDispose = newSourcesSinksAndRun.run()

  try {
    const sourcesAndSinksAndDispose = {
      sources: newSourcesSinksAndRun.sources,
      sinks: newSourcesSinksAndRun.sinks,
      dispose: function dispose(this: any) {
        // pause recording, so as to not capture completions
        // forceful completion of inner streams happens after replay
        paused = true
        pause$.shamefullySendNext(true)
        for (const k of keysOf(registeredStreams)) {
          for (const { stream } of registeredStreams[k]) {
            // if we don't complete all sources, the previous application stays alive!
            // ?! we only complete registered streams? Is this sufficient?
            stream.shamefullySendComplete()
            if ((stream as any).dispose) {
              console.log('ACTUALLY DISPOSING', k);
              (stream as any).dispose() // this was taken from replay - needed? (guess so)
            }
          }
        }
        pause$.shamefullySendComplete()
        return newDispose.call(this)
      }
    }

    if (first) {
      // allowing replay on first run might cause unintended effects when subscribing to sources with cold streams
      // why is it not always paused? hm...
      /// DOES THE SAME PROBLEM NOT OCCUR ON RERUN OF COLD STREAMS? IS PAUSE SOURCES A THING?
      return new Promise<typeof sourcesAndSinksAndDispose & { logs: typeof sourceLogs }>(resolve =>
        setTimeout(
          () =>
            resolve({
              logs: sourceLogs,
              ...sourcesAndSinksAndDispose
            }),
          0
        )
      )
    } else {
      const replayStream = xs.create<PlaybackEvent<D>>()
      const playbackedInnerStreamCompleters = [] as (() => void)[]
      const replaySubscription = replayStream.subscribe({
        next: playbackEvent => {
          const { driverName, streams, scope } = playbackEvent
          const replayStreams = streams.get(driverName, scope)
          // console.info('Replaying for', playbackEvent.driverName, 'scope', scope.path, '(', playbackEvent.time, ')' )

          if (!replayStreams.length) {
            console.warn('No replay stream found for driver', playbackEvent.driverName, 'scope', scope.path)

            return
          }

          if (isSimpleEvent(playbackEvent)) {
            replayStreams.forEach(stream => stream.shamefullySendNext(playbackEvent.event))
          } else if (isErrorEvent(playbackEvent)) {
            replayStreams.forEach(stream => stream.shamefullySendError(playbackEvent.error))
          } else if (isCompleteEvent(playbackEvent)) {
            replayStreams.forEach(stream => {
              stream.shamefullySendComplete()
              streams.remove(driverName, scope, stream)
            })
          } else {
            const innerStream = (playbackEvent.inner.isMemory ? xs.createWithMemory() : xs.create()) as RecordedStream<any>
            innerStream._offsetIndex = 0

            const innerStreamScope = addPath(scope, playbackEvent.inner.index)
            streams.add(driverName, innerStreamScope, innerStream)
            playbackedInnerStreamCompleters.push(() => {
              if (streams.contains(driverName, innerStreamScope, innerStream)) {
                innerStream.shamefullySendComplete()

                // console.log('removing inner stream during replay due to completion', playbackEvent.driverName, scope.path)
                streams.remove(driverName, innerStreamScope, innerStream)
                sourceLogs.push({ complete: true, scope, driverName: playbackEvent.driverName, time: +new Date() })
              }
            })
            replayStreams.forEach(stream => {
              stream._offsetIndex++
              stream.shamefullySendNext(innerStream)
            })
          }
        },
        error: err => console.error('Error while replaying!', err),
        complete: () => console.error('Replay completed unexpectedly')
      })

      let adjustedTime = 0

      const playbackStreams: PlaybackStreams<D> =
      {
        get: (driverName: StrategyDriverNames<D>, scope: Scope) =>
          getRegisteredStream(registeredStreams[driverName], scope),
        add: (driverName: StrategyDriverNames<D>, scope: Scope, stream: RecordedStream<any>) =>
          registeredStreams[driverName].push({ scope, stream }),
        remove: (driverName: StrategyDriverNames<D>, scope: Scope, stream: RecordedStream<any>) =>
          unregisterStream(registeredStreams[driverName], scope, stream),
        contains: (driverName: StrategyDriverNames<D>, scope: Scope, stream: RecordedStream<any>) =>
          registeredStreams[driverName].some(registration => like(scope.path, registration.scope.path) && stream === registration.stream)
      }

      adjustedTime = scheduleLog(
        realTime._scheduler,
        logs,
        replayStream,
        playbackStreams,
        adjustedTime,
        timeToReplayTo
      )

      let timeToRunTo = realTime._time()
      if (timeToRunTo <= adjustedTime) {
        timeToRunTo = adjustedTime + 1
      }

      return new Promise<typeof sourcesAndSinksAndDispose & { logs: typeof sourceLogs }>((resolve, reject) => {
        realTime._runVirtually(err => {
          if (err) {
            reject(err)
            return
          }

          realTime._resume(timeToRunTo)
          replaySubscription.unsubscribe()

          // playbacked inner streams can never resume or emit new values
          // better to complete them and mark their completion in the new logs so subsequent playbacks don't get confused
          for (const playbackedInnerStreamCompleter of playbackedInnerStreamCompleters) {
            playbackedInnerStreamCompleter()
          }

          paused = false
          pause$.shamefullySendNext(false)

          resolve({ logs: sourceLogs, ...sourcesAndSinksAndDispose })
        }, timeToRunTo)
      })
    }
  } catch (err) {
    newDispose()
    throw err
  }
}

export type RerunResult<OD extends Drivers, OM extends Main, D extends MatchingDrivers<OD, OM>, M extends MatchingMain<D, OM>> = {
  sources: MSources<M>
  sinks: Sinks<M>
  dispose: (this: any) => any
  logs: Log<D>
}

export type MSources<M extends Main> = M extends (sources: infer S) => any ? S : never

type TypeFilteredNames<T, FT> = { [k in keyof T]: T[k] extends FT ? k : never }[keyof T]
type Filtered<T, FT> = { [k in TypeFilteredNames<T, FT>]: T[k] }

function filterX<T extends Record<PropertyKey, any>, FT>(o: T, fn: (value: any, key: keyof T) => value is FT) {
  const result = { ...o }
  keysOf(result).forEach(key => {
    if (!fn(o[key], key)) {
      delete result[key]
    }
  })

  return result as Filtered<T, FT>
}

export function rerunner
<OD extends Drivers, OM extends Main, D extends MaybeRecordableDrivers<MatchingDrivers<OD, OM>>, M extends MatchingMain<D & { Time?: typeof timeDriver }, OM>>
(makeDrivers: () => D, setup: typeof cycleSetup, isolate: typeof isolateFn) {
  let logs: Log<D>
  let dispose: () => void
  let first = true
  let drivers: D

  return async (main: M, newLogs?: typeof logs, timeToReplayTo?: RecordedTime) => {
    if (drivers) {
      keysOf(drivers).forEach(key => {
        const driver = drivers[key]
        if (driver && typeof (driver as any).dispose === 'function') {
          (driver as any).dispose()
        }
      })
    }
    drivers = makeDrivers()
    if (newLogs || logs) {
      logs = (newLogs || logs).slice()
    } else {
      logs = []
    }
    if (dispose) {
      dispose()
    }

    first = first && (!(newLogs?.length))

    const stuff = await replayDrivers(drivers, logs, setup, main, isolate, first, timeToReplayTo)

    first = false
    logs = stuff.logs
    dispose = stuff.dispose

    return stuff as RerunResult<OD, OM, D, M>
  }
}

export async function rerunnerAsync
<OD extends Drivers, OM extends Main, D extends MaybeRecordableDrivers<MatchingDrivers<OD, OM>>, M extends MatchingMain<D & { Time: typeof timeDriver }, OM>>
(main: M, makeDrivers: () => D, setup: typeof cycleSetup, isolate: typeof isolateFn) {
  const mainRunner = rerunner<OD, OM, D, M>(makeDrivers, setup, isolate)

  return {
    run: mainRunner,
    ...(await mainRunner(main))
  }
}
