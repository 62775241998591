import { makeDOMDriver } from '@cycle/dom'
import defaultModules from '@cycle/dom/lib/es6/modules'
import { captureClicks, makeHistoryDriver } from '@cycle/history'
import { makeHTTPDriver } from '@cycle/http'
import storageDriver from '@cycle/storage'
import { makeCookieDriver } from 'cycle-cookie-driver'
import { eventListenersModule } from 'snabbdom'
import xs from 'xstream'
import dropRepeats from 'xstream/extra/dropRepeats'
import { staticConfig } from './config'
import { focusSinkDriver } from './drivers'
import { BootstrapAction, makeBootstrapDriver } from './drivers/bootstrapDriver'
import { configDriver } from './drivers/configDriver'
import { makeDateDriver } from './drivers/dateDriver'
import { groundDriver } from './drivers/groundDriver'
import { makeMsalDriver } from './drivers/msalDriver'
import { msGraphDriver } from './drivers/msGraphDriver'
import { makeRandomDriver } from './drivers/randomDriver'
import { makeRedirectDriver } from './drivers/redirectDriver'
import { makeTanstackTableDriver } from './drivers/tanstackTableDriver'
import { configure } from './recorder'
import { pauseWhileReplaying } from './recorder/recorder'
import { recordDOMStrategy, recordHTTPStrategy, simpleRecord } from './recorder/strategies'

const storageStrategy = (keyOrNumber: string | number) => ({
  scope: keyOrNumber,
  strategy: simpleRecord
})

export function makeDrivers(seed?: number) {
  return () => ({
    DOM: configure(
      makeDOMDriver('#content', {
        modules: [...defaultModules, eventListenersModule]
      }),
      recordDOMStrategy()
    ),
    history: configure(captureClicks(makeHistoryDriver()), {
      source: simpleRecord
    }),
    HTTP: configure(makeHTTPDriver(), recordHTTPStrategy()),
    log: (msg: xs<any>) =>
      msg.subscribe({
        next: x => {
          console.log(x)
        },
        error: x => {
          console.error(x)
        },
        complete: () => void 0
      }),
    redirect: makeRedirectDriver(),
    bootstrap: configure(makeBootstrapDriver(), {
      sink: function disableAnimationWhileReplaying(sink: xs<BootstrapAction>, replaying$: xs<boolean>) {
        // while replaying, disable modal animation so the actions don't get ignored
        return sink.compose(actions => replaying$.map(replaying => actions.map(action => (replaying ? { ...action, disableAnimation: true } : action))).flatten())
      }
    }),
    tanstackTable: makeTanstackTableDriver(),
    cookie: makeCookieDriver(),
    date: configure(makeDateDriver(), {
      source: {
        days: simpleRecord
      }
    }),
    storage: configure(storageDriver, {
      source: {
        local: {
          key: storageStrategy,
          getItem: storageStrategy
        },
        session: {
          key: storageStrategy,
          getItem: storageStrategy
        }
      }
    }),
    random: configure(makeRandomDriver(seed), {
      sink: pauseWhileReplaying
    }),
    msal: configure(
      makeMsalDriver(staticConfig.msal),
      {
        source: simpleRecord,
        sink: pauseWhileReplaying
      }
    ),
    config: configure(configDriver, {
      source: {
        config: simpleRecord
      },
      sink: pauseWhileReplaying
    }),
    msGraph: configure(msGraphDriver, {
      source: simpleRecord,
      sink: pauseWhileReplaying
    }),
    focus: configure(focusSinkDriver, {
      sink: pauseWhileReplaying
    }),
    ground: groundDriver
  })
}
