const VALUE = Symbol()

export const None = Symbol()

export interface Some<T> {
  [VALUE]: T
}

export type Option<T> = typeof None | Some<T>

export const some = <T>(value: T) => ({ [VALUE]: value })

export const isSome = <T>(option: Option<T>): option is Some<T> => typeof option === 'object' && VALUE in (option as Some<T>)
export const isNone = <T>(option: Option<T>): option is typeof None => option === None

export const someValue = <T>(s: Some<T>) => s[VALUE]
export const mapOption = <T, U, V>(option: Option<T>, some: (value: T) => U, none: () => V) => (isNone(option) ? none() : some(someValue(option)))
