import { CaseType, FOption, FTuple } from '../../fsharp'
import { caseOr } from '../../generic/case'
import { AzureBacklogConfiguration } from './azureDevOps'
import { PaymoConfiguration } from './paymo'
import { JiraBacklogConfiguration } from './jira'
import { ClockifyConfiguration } from './clockify'
import { RedmineViaClientBacklogConfiguration } from './redmine'
import { TiaConfiguration } from './tia'

export const dummyCredential = '00000000-0000-0000-0000-000000000000' as const

export type ProjectListItem = {
  id: string
  key: string
  name: string
  description: string
  isAdminForProject: boolean
  isMemberForProject: boolean
}

export type TaskMappingItem = {
  taskId: string
  from: Date
  until: FOption<Date>
  tiaCodeId: string
  tiaCodeName: string
}

export type WorkItemMappingItem = {
  workItemId: string
  from: Date
  until: FOption<Date>
  tiaCodeId: string
  tiaCodeName: string
}

export type DailyRate = CaseType<'DailyRate', [rate: number]>

export type RateItem = {
  tiaCodeId: string
  tiaUserId: string
  tiaCodeName: string // this should only be informative - actually reference is the id - but TIA api does not expose codes by id for now
  dailyRate: DailyRate
  from: Date
  until: FOption<Date>
}

export type TaskSetting = {
  taskId: string
  backlogItemRequired: boolean
  role: FOption<string>
  overheadScope: FOption<OverheadScope>
}

export type ProjectMember = {
  timeTrackerId: string
  cronosUsername: string | null
  cronosAdObjectId: string | null
  tiaUserId: string
  tiaUsername: string
  displayName: string
  role: FOption<string>
}

export type ProjectSettings = {
  timeEntryPaddingAllowed: boolean
  orderUserCopyAllowed: boolean
}

export type ProjectInfo = {
  name: string
  id: string
  key: string
  version: number
  description: string
  administrators: string[]
  members: ProjectMember[]
  settings: ProjectSettings
  reportParameters: Record<string, string>
}

export type Sprint = {
  name: string
  from: Date
  until: Date
}

export type Budget = number

export type Order = {
  id: string
  name: string
  budget: Budget
  preventDistribution: boolean
  description: string
  link: string
  rates: RateItem[]
}

export type TimeTrackerConfiguration =
  | CaseType<'ClockifyConfiguration', [ClockifyConfiguration]>
  | CaseType<'PaymoConfiguration', [PaymoConfiguration]>

export type BacklogConfiguration =
  | CaseType<'RedmineViaClientBacklogConfiguration', [RedmineViaClientBacklogConfiguration]>
  | CaseType<'AzureBacklogConfiguration', [AzureBacklogConfiguration]>
  | CaseType<'JiraBacklogConfiguration', [JiraBacklogConfiguration]>

export type TimeEntryMappingConfig = {
  ignoreOverlap: boolean
  timeEntryPaddingAmount: FOption<number>
}

export const emptyTimeEntryMappingConfig: TimeEntryMappingConfig = {
  ignoreOverlap: false,
  timeEntryPaddingAmount: null
}

export type Level1ProjectConfiguration = {
  tiaConfiguration: TiaConfiguration
  orders: Order[]
  sprints: Sprint[]
}

export type Level3ProjectConfiguration = {
  tiaConfiguration: TiaConfiguration
  timeTrackerConfiguration: TimeTrackerConfiguration
  timeEntryMappingConfig: TimeEntryMappingConfig
  orders: Order[]
  sprints: Sprint[]
  taskMapping: TaskMappingItem[]
  taskSettings: TaskSetting[]
}

export type Level5ProjectConfiguration = {
  tiaConfiguration: TiaConfiguration
  timeTrackerConfiguration: TimeTrackerConfiguration
  timeEntryMappingConfig: TimeEntryMappingConfig
  backlogConfigurations: Array<FTuple<[string, BacklogConfiguration]>>
  orders: Order[]
  sprints: Sprint[]
  taskMapping: TaskMappingItem[]
  taskSettings: TaskSetting[]
  workItemMapping: WorkItemMappingItem[]
}

export type ProjectLevelConfiguration =
  | CaseType<'Level1', [Level1ProjectConfiguration]>
  | CaseType<'Level3', [Level3ProjectConfiguration]>
  | CaseType<'Level5', [Level5ProjectConfiguration]>

type Levels = ProjectLevelConfiguration extends CaseType<infer Level, any> ? Level : never

export type ProjectConfiguration = {
  info: ProjectInfo
  levelConfiguration: ProjectLevelConfiguration
}

export type ProjectDescriptionInput = {
  name: string
  description: string
}

export type TiaCredentialsInput = {
  username: string
  password: string
  apiKey: string
}

export type ProjectCreationInfo = {
  key: string
  projectDescriptionInput: ProjectDescriptionInput
  tiaCredentials: TiaCredentialsInput
}

export type ProjectUpdateInfo = {
  projectDescriptionInput: ProjectDescriptionInput
  tiaCredentials: FOption<TiaCredentialsInput>
}

export type AzureDevOpsWorkItemFieldInfo = {
  id: string
  name: string
}

export type JiraIssueFieldInfo = {
  id: string
  name: string
}

export type JiraIssueLinkInfo = {
  id: string
  name: string
  inward: string
  outward: string
}

export type JiraIssueTypeInfo = {
  id: string
  name: string
}

export type JiraProjectInfo = {
  key: string
  name: string
}

export const isLevel = <Level extends Levels>(level: Level) =>
  (config: ProjectLevelConfiguration): config is ProjectLevelConfiguration & CaseType<Level, any> => config.case === level

export const isLevel1 = isLevel('Level1')
export const isLevel3 = isLevel('Level3')
export const isLevel5 = isLevel('Level5')

export const isLevel3OrHigher = caseOr(isLevel3, isLevel5)
export const isLevel1OrHigher = caseOr(isLevel1, isLevel3OrHigher)

export type OverheadScope = 1 | 2 | 3
export const OverheadScope: Record<string, OverheadScope> = {
  PersonalDaily: 1,
  PersonalSprint: 2,
  TeamSprint: 3
}
export const overheadScopes = [
  { name: 'Personal daily', id: OverheadScope.PersonalDaily },
  { name: 'Personal sprint', id: OverheadScope.PersonalSprint },
  { name: 'Team sprint', id: OverheadScope.TeamSprint }
]

export type ProblematicWorkItemDetails = {
  id: FOption<string>
  parentId: FOption<string>
  type: FOption<string>
  title: FOption<string>
  url: FOption<string>
}

export type ProblematicNormalizedWorkItemDetails = {
  id: string
  parentId: FOption<string>
  type: string
  title: string
  url: string
}

export type TimeEntryProblem<Detail, Problem> = {
  timeEntryDetails: Detail
  problemDetails: Problem
}

export type WorkItemProblem<Detail, Problem> = {
  workItemDetails: Detail
  problemDetails: Problem
}
