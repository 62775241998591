import { HTTPSource } from '@cycle/http'
import { GettableResource, toLoadable, Request, handleLatest } from '../../dataServices'
import { K, mapLoaded } from '../../generic'
import { AzureDevOpsConfigurationInvalid, AzureDevOpsWorkItemFieldInfo, InsufficientRights, RequestError, UnhandledApiExceptionProblem, UnhandledAzureDevOpsProblem, WrongLevelProblem, insufficientRights } from '../model'
import { handleError, resultOrError, simpleResult } from './resourceUtil'

export type ListResponse<T> = {
  count: number
  value: T[]
}

export type ProjectState = 'createPending' | 'deleted' | 'deleting' | 'new' | 'unchanged' | 'wellFormed'
export type ProjectVisibility = 'private' | 'public'

export type DirectTeamProjectReference = {
  abbreviation: string
  defaultTeamImageUrl: string
  description: string
  id: string
  lastUpdateTime: string
  name: string
  revision: number
  state: ProjectState
  url: string
  visibility: ProjectVisibility
}

export type PrimTeamProjectReference = {
  id: string
}

export type TeamProjectReference = {
  id: string
  name: string
  description: string
}

// TODO: support both server and cloud (only cloud is implemented)
export const directAzureProjectsResource = (HTTP: HTTPSource) => (pat: string) => (organisation: string): GettableResource<null | TeamProjectReference[], RequestError> => {
  const url = `https://dev.azure.com/${organisation}/_apis/projects?api-version=6.1-preview.4&getDefaultTeamImageUrl=true`

  const bearerToken = window.btoa(`Basic:${pat}`)

  const request = Request({
    category: `get-direct-azure-projects-${organisation}-${pat}`,
    method: 'GET',
    url: url,
    headers: { Authorization: `Basic ${bearerToken}` },
    redirects: 0
  })

  return {
    name: request.url,
    get: request,
    value:
      toLoadable(handleLatest(HTTP)(request.category)({
        success: simpleResult<null | ListResponse<DirectTeamProjectReference>>,
        error: handleError
      }))
      .map(loadable => mapLoaded(loadable, response => response && response.value as TeamProjectReference[]))
  }
}

export type PrimAzureProjectsResource = GettableResource<TeamProjectReference[], RequestError | InsufficientRights | WrongLevelProblem | UnhandledApiExceptionProblem | AzureDevOpsConfigurationInvalid | UnhandledAzureDevOpsProblem>

export const azureProjectsResource =
  (root: string) =>
    (HTTP: HTTPSource) =>
      (key: string) =>
        (backlogConfigurationId: string): PrimAzureProjectsResource => {
          const url = `${root}/projects/${key}/azuredevops/${backlogConfigurationId}/projects`

          const request = {
            category: `get-azuredevops-projects-${key}`,
            method: 'GET',
            url: url
          }

          return {
            name: request.url,
            get: request,
            value: toLoadable(handleLatest(HTTP)(request.category)({
              success: simpleResult<TeamProjectReference[]>,
              failure: {
                403: K(insufficientRights),
                409: simpleResult<WrongLevelProblem | AzureDevOpsConfigurationInvalid>,
                500: resultOrError<UnhandledApiExceptionProblem | UnhandledAzureDevOpsProblem>
              },
              error: handleError
            }))
          }
        }

export type PrimAzureWorkItemFieldsResource = GettableResource<AzureDevOpsWorkItemFieldInfo[], RequestError | InsufficientRights | WrongLevelProblem | UnhandledApiExceptionProblem | AzureDevOpsConfigurationInvalid | UnhandledAzureDevOpsProblem>

export const azureWorkItemFieldsResource =
  (root: string) =>
    (HTTP: HTTPSource) =>
      (key: string) =>
        (backlogConfigurationId: string): PrimAzureWorkItemFieldsResource => {
          const url = `${root}/projects/${key}/azuredevops/${backlogConfigurationId}/workitemfields`

          const request = {
            category: `get-azuredevops-workitemfields-${key}`,
            method: 'GET',
            url: url
          }

          return {
            name: request.url,
            get: request,
            value: toLoadable(handleLatest(HTTP)(request.category)({
              success: simpleResult<AzureDevOpsWorkItemFieldInfo[]>,
              failure: {
                403: K(insufficientRights),
                409: simpleResult<WrongLevelProblem | AzureDevOpsConfigurationInvalid>,
                500: resultOrError<UnhandledApiExceptionProblem | UnhandledAzureDevOpsProblem>
              },
              error: handleError
            }))
          }
        }
