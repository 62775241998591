import isolate from '@cycle/isolate'
import xs from 'xstream'
import { apply, id, memo, mergeSinks } from '../../../../generic'
import { Modal } from '../../../../ui'
import { omit, stateScope } from '../../../../util'
import { ProjectMember } from '../../../model'
import { TiaTimesheetCode } from '../../../resources/tiaTimesheetCodes'
import { AutoSuggestState, TypedMultilineAutoSuggest } from '../../autoSuggest'
import { makeCollection } from '@cycle/state'
import { get, getMembersSuggestables, set } from './modelFunctions'
import { intent } from './intent'
import { OrderItem, OrdersSources, OrdersViewState } from './viewModel'
import { model } from './model'
import { orderModalContent, view } from './view'
import { mergeWithIndex } from '../../../../infrastructure'
import debounce from 'xstream/extra/debounce'
import { BootstrapAction } from '@/drivers'

export const OrdersConfigurationComponent = (sources: OrdersSources) => {
  const getProjectMemberAutoSuggestState = memo((input: string, projectMembers: ProjectMember[]): AutoSuggestState<ProjectMember> => ({
    input: input,
    suggestables: getMembersSuggestables(projectMembers),
  }))

  const getTiaCodeAutoSuggestState = memo((input: string, suggestables: AutoSuggestState<TiaTimesheetCode>['suggestables']): AutoSuggestState<TiaTimesheetCode> => ({
    input: input,
    suggestables: suggestables,
  }))

  const MembersAutoSuggest = TypedMultilineAutoSuggest<ProjectMember>()
  const membersAutoSuggests = isolate(makeCollection({
    item: MembersAutoSuggest,
    itemScope: id,
    collectSinks: instances => ({
      CDOM: instances.pickCombine('DOM'),
      state: instances.pickMerge('state'),
    })
  }), stateScope<OrdersViewState, AutoSuggestState<ProjectMember>[]>({
    get:
      get(state =>
        state.orders
          .map((order): AutoSuggestState<ProjectMember> =>
            getProjectMemberAutoSuggestState(order.newRateItem.memberInput.input, state.projectMembers))),
    set: (state, suggestStates: AutoSuggestState<ProjectMember>[]) =>
      set(s => s.orders)(
        orders => orders.map((order, index): OrderItem => ({ ...order, newRateItem: {...order.newRateItem, memberInput: suggestStates[index] } })))(state)
  }, 'members'))(sources)

  const TiaCodeAutoSuggest = TypedMultilineAutoSuggest<TiaTimesheetCode>()
  const tiaCodeAutoSuggests = isolate(makeCollection({
    item: TiaCodeAutoSuggest,
    itemScope: id,
    collectSinks: instances => ({
      CDOM: instances.pickCombine('DOM'),
      state: instances.pickMerge('state'),
      tiaCodeInput: mergeWithIndex(instances,'autoSuggestInput')
    })
  }), stateScope<OrdersViewState, AutoSuggestState<TiaTimesheetCode>[]>({
    get:
      get(state =>
        state.orders
          .map((order): AutoSuggestState<TiaTimesheetCode> =>
            getTiaCodeAutoSuggestState(order.newRateItem.tiaCodeInput.input, order.newRateItem.tiaCodeInput.suggestables))),
    set: (state, suggestStates: AutoSuggestState<TiaTimesheetCode>[]) =>
      set(s => s.orders)(
        orders => orders.map((order, index) => ({ ...order, newRateItem: {...order.newRateItem, tiaCodeInput: suggestStates[index]}})))(state)
  }, 'tiaCodes'))(sources)

  const modal = Modal({DOM: sources.DOM, content: sources.state.stream.map(s => orderModalContent(s.selectedOrder)), id: xs.of('order-modal')})

  const sinks = model(intent(sources, tiaCodeAutoSuggests.tiaCodeInput), sources, modal.actions.map(a => a.hide))

  return mergeSinks(
    {
      ...sinks,
      state: sinks.state,
      bootstrap: sources.DOM.select('[data-bs-toggle="tooltip"]').elements().map(xs.fromArray).flatten().map((el): BootstrapAction => ({ type: 'tooltip', target: el }))
    },
    membersAutoSuggests,
    tiaCodeAutoSuggests,
    omit(modal, 'DOM'),
    {
      DOM: xs.combine(
        sources.state.stream,
        membersAutoSuggests.CDOM,
        tiaCodeAutoSuggests.CDOM,
        modal.DOM)
        .map(apply(view(sources))).compose(debounce(150)),
    })
}
