import { AccountInfo, AuthenticationResult, Configuration, InteractionRequiredAuthError, LogLevel, PublicClientApplication, RedirectRequest, SsoSilentRequest } from '@azure/msal-browser'
import xs from 'xstream'
import { flatten, id, notNull, orderBy } from '../generic'
import { DriverFactorySinkType } from './driverUtil'
import { assertNever } from '@/util'

const defaultMsalConfig: Configuration = {
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message)
            return
          case LogLevel.Info:
            console.info(message)
            return
          case LogLevel.Verbose:
            console.debug(message)
            return
          case LogLevel.Warning:
            console.warn(message)
            return
        }
      }
    }
  },
  auth: {
    clientId: ''
  }
}

export type TokenInfo = {
  expiresOn: Date | null
  scopes: string[]
  token: string
  roles: string[]
}

export type MsalAccountInfo = {
  id: string
  name: string
  username: string
  tokens: TokenInfo[]
}

type MsalConfig = {
  clientId: string
  authority: string
  redirectUri: string
  logoutRedirectUri: string
  domainHint?: string
  loginScopes?: string[]
}

export enum AuthActionType {
  Init = 'init',
  LoadAccounts = 'loadAccounts',
  Login = 'login',
  Logout = 'logout',
  AcquireToken = 'acquireToken'
}

// eslint-disable-next-line @typescript-eslint/ban-types
type WithType<T, U = {}> = { type: T } & U

export type AuthAction =
  WithType<AuthActionType.LoadAccounts>
  | WithType<AuthActionType.Init>
  | WithType<AuthActionType.Login>
  | WithType<AuthActionType.AcquireToken, { accountId: string; scopes: string[] }>
  | WithType<AuthActionType.Logout, { accountId: string }>

export enum AuthEventType {
  SigninFailed = 'signinFailed',
  AccountsLoaded = 'accountsLoaded',
  UserLoggedOut = 'userLoggedOut',
  AccessTokenExpired = 'accessTokenExpired',
  SilentRenewFailed = 'silentRenewFailed'
}

export type AuthEvent =
  WithType<AuthEventType.AccountsLoaded, { accounts: MsalAccountInfo[] }>
  | WithType<AuthEventType.UserLoggedOut>

function normalizeAction(action: AuthAction) {
  return typeof action === 'string' ? { type: action } : action
}

function toMsalAccountInfo(accounts: AccountInfo[]): MsalAccountInfo[] {
  return accounts.map(account => ({
    id: account.homeAccountId,
    name: account.name || account.username,
    username: account.username,
    tokens: []
  }))
}

function toTokenInfo(authenticationResult: AuthenticationResult): TokenInfo {
  return {
    expiresOn: authenticationResult.expiresOn,
    scopes: authenticationResult.scopes.slice(),
    token: authenticationResult.accessToken,
    roles: (authenticationResult.idTokenClaims as { roles?: string[] }).roles || []
  }
}

function scopesMatch(scopes1: string[], scopes2: string[]) {
  return orderBy(id, scopes1).join(' ') === orderBy(id, scopes2).join(' ')
}

function getLoginRequest(config: MsalConfig): RedirectRequest & SsoSilentRequest {
  return {
    scopes: config.loginScopes || [],
    domainHint: config.domainHint
  }
}

export const makeMsalClient = (config: MsalConfig) =>
  new PublicClientApplication({
    ...defaultMsalConfig,
    auth: {
      ...(defaultMsalConfig.auth || {}),
      clientId: config.clientId,
      authority: config.authority,
      redirectUri: config.redirectUri,
      postLogoutRedirectUri: config.logoutRedirectUri
    }
  })

export const makeMsalDriver = (config: MsalConfig) => {
  const authEvents = xs.createWithMemory<AuthEvent>()
  const client = makeMsalClient(config)

  const accounts = [] as MsalAccountInfo[]
  const refreshHandlers = [] as ReturnType<typeof setTimeout>[]

  function handleError(err: any) {
    authEvents.shamefullySendError(err)
  }

  function handleAuthenticationResult(result: AuthenticationResult | null) {
    const msalAccounts = client.getAllAccounts()
    accounts.splice(0, accounts.length, ...toMsalAccountInfo(msalAccounts))
    for (const timeout of refreshHandlers) {
      clearTimeout(timeout)
    }

    if (result && result.account) {
      const resultAccount = result.account
      const accountIndex = accounts.findIndex(acc => acc.id === resultAccount.homeAccountId)
      const account = accounts[accountIndex]
      const tokenInfo = toTokenInfo(result)
      const existingTokenIndex = account.tokens.findIndex(token => scopesMatch(token.scopes, result.scopes))
      const tokens = existingTokenIndex === -1 ? account.tokens.concat(tokenInfo) : account.tokens.slice().splice(existingTokenIndex, 1, tokenInfo)

      accounts.splice(accountIndex, 1, { ...account, tokens: tokens })
    }

    const accountWithoutToken = accounts.find(account => !account.tokens.length)
    if (accountWithoutToken) {
      const tokenRequest = {
        account: getMsalAccount(accountWithoutToken),
        scopes: config.loginScopes || []
      }
      client.acquireTokenSilent(tokenRequest)
        .then(handleAuthenticationResult)
        .catch(handleAuthError(tokenRequest))
    } else {
      refreshHandlers.splice(0, refreshHandlers.length, ...flatten(accounts.map(account => account.tokens.map(token => {
        if (token.expiresOn == null) {
          return null
        }

        const ms = +token.expiresOn - Date.now() - (1000 * 60 * 6)
        if (ms < 0) {
          return null
        }

        return setTimeout(refreshTokenHandler(account, token.scopes), ms)
      }).filter(notNull))))

      authEvents.shamefullySendNext({ type: AuthEventType.AccountsLoaded, accounts })
    }
  }

  function getMsalAccount(accountInfo: MsalAccountInfo) {
    return client.getAllAccounts().find(msalAccount => msalAccount.homeAccountId === accountInfo.id)!
  }

  function refreshTokenHandler(account: MsalAccountInfo, scopes: string[]) {
    const request = { account: getMsalAccount(account), scopes: scopes }
    return () => client
      .acquireTokenSilent(request)
      .then(handleAuthenticationResult)
      .catch(handleAuthError(request))
  }

  function handleAuthError(request: RedirectRequest) {
    return (err: any) => {
      if (err instanceof InteractionRequiredAuthError) {
        client.loginRedirect(request)
      } else {
        handleError(err)
      }
    }
  }

  function handleInit() {
    client.handleRedirectPromise().then(handleAuthenticationResult).catch(handleError)
  }

  const loginRequest = getLoginRequest(config)

  return (sink: xs<AuthAction>) => {
    sink.subscribe({
      next: action => {
        const normalizedAction = normalizeAction(action)
        switch (normalizedAction.type) {
          case AuthActionType.Init:
            handleInit()
            break
          case AuthActionType.LoadAccounts:
            handleAuthenticationResult(null)
            break
          case AuthActionType.Login:
            client
              .loginRedirect(loginRequest)
              .catch(handleError)
            break
          case AuthActionType.Logout:
            client
              .logout({ account: client.getAllAccounts().find(account => account.homeAccountId === normalizedAction.accountId)! })
              .catch(handleError)
            break
          case AuthActionType.AcquireToken: {
            const request = {
              account: client.getAllAccounts().find(account => account.homeAccountId === normalizedAction.accountId)!,
              scopes: normalizedAction.scopes,
            }
            client
              .acquireTokenSilent(request)
              .then(handleAuthenticationResult)
              .catch(handleAuthError(request))
            break
          }
          default:
            handleError(`Unrecognised action: ${normalizedAction}`)
            break
        }
      }
    })

    return authEvents
  }
}

export type MsalSink = DriverFactorySinkType<typeof makeMsalDriver>
