import isolate from '@cycle/isolate'
import { makeCollection } from '@cycle/state'
import xs from 'xstream'
import { asOption, asSimple, caseValue } from '../../../fsharp'
import { append, apply, ErrorType, id, isArray, isLoaded, K, loaded, Manager, mergeSinks, removeAt, setIn, StreamType, Unloaded, value } from '../../../generic'
import { VNode } from 'snabbdom'
import { jsx } from 'h'
import { DERIVED, dynamicModel, IGNORE, INIT, mergeWithIndex, pageSinkTemplate, toOutput } from '../../../infrastructure'
import { Button, capture, click, clickWithIndex, cn, Col, Container, Danger, events, Icon, indexedValue, inputs, renderLoadable, Row, Select } from '../../../ui'
import { omit, stateScope } from '../../../util'
import { ConfigurationProblem, isLevel3OrHigher, ProjectMember, SetProjectMembersConfigurationProblem } from '../../model'
import { rolesResource, RolesResponse } from '../../resources/roles'
import { TiaUser } from '../../resources/tiaUsers'
import { TimeTrackerUser, TimeTrackerUsersResource, TimeTrackerUsersResponse } from '../../resources/timeTrackerUsers'
import { AutoSuggestState, Suggestable, TypedAutoSuggest } from '../autoSuggest'
import { ProjectPageSources } from '../common'
import { clockifyConfigInvalidRenderConfig, insufficientRightsRenderConfig, paymoConfigInvalidRenderConfig, projectMembersConfigurationProblemRenderConfig, renderProblem, renderProblemFor, requestErrorRenderConfig, responseRenderConfig, tiaConfigInvalidRenderConfig, unhandledApiExceptionRenderConfig, unhandledClockifyProblemRenderConfig, unhandledPaymoProblemRenderConfig, unhandledTiaProblemRenderConfig, wrongLevelProblemRenderConfig } from '../renderers'
import { ConfigurationContext, DisplayProjectMember, MembersViewState } from './viewModel'

type MembersSources = ProjectPageSources<MembersViewState>

const tiaUsersOrEmpty = (context: ConfigurationContext) =>
  isArray(context.tiaUsers) ? context.tiaUsers : []

const timeTrackerUsersOrEmpty = (maybeTimeTrackerUsers: MembersViewState['timeTrackerUsers']) =>
  (!maybeTimeTrackerUsers || ('case' in maybeTimeTrackerUsers)) ? [] : maybeTimeTrackerUsers

const getTiaSuggestables = (tiaUsers: TiaUser[], projectMembers: readonly DisplayProjectMember[]) =>
  loaded(
    tiaUsers
      .filter(tiaUser => !projectMembers.some(member => member.tiaUserId === tiaUser.tiaUserId))
      .map((user): Suggestable<TiaUser> => ({ display: `${user.tiaFirstName} ${user.tiaLastName}`, value: user })))

const getTimeTrackerUserSuggestables = (timeTrackerUsers: TimeTrackerUser[] | null) =>
  loaded((timeTrackerUsers || [])
    .map((timeTrackerUser): Suggestable<TimeTrackerUser> => ({ display: timeTrackerUser.name, value: timeTrackerUser })))

export const membersViewState = (
  context: ConfigurationContext,
  timeTrackerUsers: TimeTrackerUsersResponse | ErrorType<StreamType<TimeTrackerUsersResource['value']>> | null,
  roles: RolesResponse | null): MembersViewState => {
  const tiaUsers = tiaUsersOrEmpty(context)
  const projectMembers = context.configuration.info.members.map(projectMemberToDisplayMember(tiaUsers)(timeTrackerUsersOrEmpty(timeTrackerUsers)))

  return ({
    context: context,
    timeTrackerUsers: timeTrackerUsers,
    roles: roles,
    tiaUserSuggest: {
      input: '',
      suggestables: getTiaSuggestables(tiaUsers, projectMembers)
    },
    newTimeTrackerUserSuggest: {
      input: '',
      suggestables: getTimeTrackerUserSuggestables(timeTrackerUsersOrEmpty(timeTrackerUsers))
    },
    projectMembers: projectMembers,
    projectMembersConfigurationUpdateResponse: Unloaded,
    newItem: {
      tiaUser: null,
      timeTrackerUser: null,
      role: null
    }
  })
}

const intent = (
  sources: MembersSources,
  chooseNewTiaUser: xs<TiaUser>,
  chooseNewTimeTrackerUser: xs<TimeTrackerUser>,
  chooseTimeTrackerUser: xs<readonly [TimeTrackerUser, number]>,
  timeTrackerInput: xs<readonly [string, number]>) =>
  ({
    ...capture(
      inputs({
        '.choose-new-role': null,
      }),
      events({
        '.choose-role': indexedValue,
        '.delete-project-member': clickWithIndex,
        '.add-project-member': click,
        '#save-project-members': click
      })
    )(sources),
    chooseTiaUser: chooseNewTiaUser,
    chooseNewTimeTrackerUser: chooseNewTimeTrackerUser,
    chooseTimeTrackerUser: chooseTimeTrackerUser,
    clearTimeTrackerUser: timeTrackerInput.filter(([value, _]) => value === '').map(([_, index]) => index),
    getRoles: rolesResource(sources.apiHost + '/api')(sources.HTTP).value
  })

const projectMemberToDisplayMember = (tiaUsers: TiaUser[]) => (timeTrackerUsers: TimeTrackerUsersResponse | null)=> (projectMember: ProjectMember): DisplayProjectMember => {
  const tiaUser = tiaUsers.find(user => user.tiaUserId === projectMember.tiaUserId)
  const timeTrackerUser = timeTrackerUsers?.find(user => user.id === projectMember.timeTrackerId)

  return {
    displayName: projectMember.displayName,
    tiaUserId: projectMember.tiaUserId,
    tiaUsername: projectMember.tiaUsername,
    timeTrackerId: projectMember.timeTrackerId,
    cronosUsername: tiaUser?.cronosUserInfo?.fields[0]?.cronosUsername ?? '<Cronos user not found>',
    cronosAdObjectId: tiaUser?.cronosUserInfo?.fields[0]?.cronosAdObjectId ?? '<Cronos AD object id>',
    ...(tiaUser ? {
      tiaFirstName: tiaUser.tiaFirstName,
      tiaLastName: tiaUser.tiaLastName,
      employerName: tiaUser.employerName
    } : {
      tiaFirstName: '<User not found in TIA>',
      tiaLastName: '',
      employerName: ''
    }),
    timeTrackerUserInput: timeTrackerUser ? timeTrackerUser.name : '',
    role: asSimple(projectMember.role)
  }
}

const tiaUserToProjectDisplayMember = (tiaUser: TiaUser): DisplayProjectMember => {
  const cronosUserInfo = tiaUser.cronosUserInfo && caseValue(tiaUser.cronosUserInfo)

  return {
    tiaUserId: tiaUser.tiaUserId,
    tiaUsername: tiaUser.tiaUsername,
    timeTrackerId: null,
    timeTrackerUserInput: '',
    cronosAdObjectId: cronosUserInfo ? cronosUserInfo.cronosAdObjectId : '',
    cronosUsername: cronosUserInfo ? cronosUserInfo.cronosAdObjectId : '',
    displayName: cronosUserInfo ? cronosUserInfo.displayName : `${tiaUser.tiaFirstName} ${tiaUser.tiaLastName}`,
    tiaFirstName: tiaUser.tiaFirstName,
    tiaLastName: tiaUser.tiaLastName,
    employerName: tiaUser.employerName,
    role: null
  }
}

const { get, set } = Manager<MembersViewState>()

const { set: setPm } = Manager<DisplayProjectMember>()

const updateRole = setPm(pm => pm.role)
const updateTimeTrackerUser = (timeTrackerUser: TimeTrackerUser) =>
  setPm(pm => pm)(pm => ({ ...pm, timeTrackerId: timeTrackerUser.id, timeTrackerUserInput: timeTrackerUser.name }))

const SAVE_PROJECT_MEMBERS = 'saveProjectMembers'
const model = (intents: ReturnType<typeof intent>, sources: MembersSources) =>
  dynamicModel(sources.state.stream)(
    { ...pageSinkTemplate, [SAVE_PROJECT_MEMBERS]: null as any as xs<readonly DisplayProjectMember[]> },
    intents,
    {
      [INIT]: IGNORE,
      getRoles: response => state => {
        if(isLoaded(response)){
          return set(state => state.roles)(value(response))(state)
        }
        return state
      },
      chooseTiaUser: set(s => s.newItem.tiaUser),
      chooseNewTimeTrackerUser: set(state => state.newItem.timeTrackerUser),
      chooseTimeTrackerUser: ([timeTrackerUser, index]) =>
        set(state => state.projectMembers[index].timeTrackerId)(timeTrackerUser.id),
      clearTimeTrackerUser: index => set(state => state.projectMembers[index].timeTrackerId)(null),
      '.choose-new-role': role => set(state => state.newItem.role)(role),
      '.choose-role': ({index, value: role}) =>
        set(state => state.projectMembers[index].role)(role  === '' ? null : role),
      '.delete-project-member': index =>
        set(s => s.projectMembers)(removeAt(index))
          .and(state => state.tiaUserSuggest.suggestables)((_, state) => getTiaSuggestables(tiaUsersOrEmpty(state.context), state.projectMembers)),
      '.add-project-member': _ => state => {
        const tiaUser = state.newItem.tiaUser
        const timeTrackerUser = state.newItem.timeTrackerUser
        const role = state.newItem.role

        if (!tiaUser) {
          return state
        }

        const updateProjectMember =
          timeTrackerUser
            ? role
              ? updateTimeTrackerUser(timeTrackerUser)
                .then(updateRole(role))
              : updateTimeTrackerUser(timeTrackerUser)
            : role
              ? updateRole(role)
              : id

        return set(s => s.projectMembers)(append(tiaUserToProjectDisplayMember(tiaUser)))
          .and(s => s.tiaUserSuggest.suggestables)((__, s) => getTiaSuggestables(tiaUsersOrEmpty(s.context), s.projectMembers))
          .and(s => s.newItem.tiaUser)(null)
          .and(s => s.tiaUserSuggest.input)('')
          .and(s => s.newTimeTrackerUserSuggest.input)('')
          .and(s => s.newItem.timeTrackerUser)(null)
          .and(s => s.newItem.role)(null)
          .and(s => s.projectMembers)(
            setIn<DisplayProjectMember>(pm => pm.tiaUserId === tiaUser.tiaUserId, updateProjectMember))(state)
      },
      '#save-project-members':
        toOutput (SAVE_PROJECT_MEMBERS)(get(s => s.projectMembers)),
      [DERIVED]: IGNORE
    }
  )

const rolesDropdown = (state: MembersViewState, index: string, selected: string | null, nameClass: string, projectMember?: DisplayProjectMember) => {
  const isInvalid = projectMember && projectMember.role && !(state.roles && state.roles.find(role => role === projectMember.role))
  return (
    <Select
      className={cn(nameClass, {'is-invalid': isInvalid})}
      dataset={{index}}>
      <option></option>
      {isInvalid ? <option selected>{projectMember?.role}</option> : ''}
      {state.roles?.map(r => <option value={r} selected={r===selected}>{r}</option>)}
    </Select>
  )
}

const view = (state: MembersViewState, tiaAutoSuggest: VNode, timeTrackerAutoSuggests: VNode[], newTimeTrackerAutoSuggest: VNode) => {
  if ('case' in state.context.tiaUsers) {
    return <Container>
      <Danger>{renderProblemFor(state.context.tiaUsers)({
        ...requestErrorRenderConfig,
        ...insufficientRightsRenderConfig,
        ...unhandledApiExceptionRenderConfig,
        ...unhandledTiaProblemRenderConfig,
        ...tiaConfigInvalidRenderConfig
      })}</Danger>
    </Container>
  }

  if (state.timeTrackerUsers && 'case' in state.timeTrackerUsers) {
    return <Container>
      <Danger>{renderProblemFor(state.timeTrackerUsers)({
        ...requestErrorRenderConfig,
        ...insufficientRightsRenderConfig,
        ...unhandledApiExceptionRenderConfig,
        ...wrongLevelProblemRenderConfig,
        ...unhandledClockifyProblemRenderConfig,
        ...unhandledPaymoProblemRenderConfig,
        ...clockifyConfigInvalidRenderConfig,
        ...paymoConfigInvalidRenderConfig
      })}</Danger>
    </Container>
  }

  const tiaUsers = state.context.tiaUsers

  return <Container>
    {renderLoadable(state.projectMembersConfigurationUpdateResponse, {
      loaded: K(null),
      error: ([problem, projectMembers]) => <Row className="p-3" key="problem">
        <Danger className="w-100">
          {renderProblem(
            responseRenderConfig<ConfigurationProblem<SetProjectMembersConfigurationProblem>>({
              ConfigurationProblem: (problems: SetProjectMembersConfigurationProblem) => problems.map(problem => <div>{renderProblem(projectMembersConfigurationProblemRenderConfig(
                projectMembers.map(m => ({ ...m, role: asOption(m.role), timeTrackerId: m.timeTrackerId || '' })),
                tiaUsers,
                timeTrackerUsersOrEmpty(state.timeTrackerUsers))
              )(problem)}</div>
              )
            }))(problem)}
        </Danger>
      </Row>
    })}
    <Row key="header" className="member-header mb-2">
      <Col className="margin-col" width={4}>
        <label className="required">TIA user info</label>
      </Col>
      {isLevel3OrHigher(state.context.configuration.levelConfiguration) ?
        <Col className="margin-col">
          <label>Time tracker user</label>
        </Col>
        : ''}
      <Col className="margin-col" width={2}>
        <label>Role</label>
      </Col>
      <Col width={1}></Col>
    </Row>
    {state.projectMembers.map((projectMember, index) => <Row className="item-display-margin">
      <Col width={4} className="margin-col">
        <div>{projectMember.tiaFirstName} {projectMember.tiaLastName} ({projectMember.tiaUsername})</div>
      </Col>
      {isLevel3OrHigher(state.context.configuration.levelConfiguration) ?
        <Col className="margin-col">
          {timeTrackerAutoSuggests[index]}
        </Col>
        : ''}
      <Col className="margin-col" width={2}>
        {rolesDropdown(state, index.toString(), projectMember.role, 'choose-role', projectMember)}
        <div id={'role_' + index} className='invalid-feedback'>
              Role {projectMember.role} was not found.
        </div>
      </Col>
      <Col width={1}>
        <Button context="danger" className="delete-project-member" dataset={{ index: index.toString() }}>
          <Icon icon="x" className="delete-member-icon" />
        </Button>
      </Col>
    </Row>)}
    <Row key="new-item-margin" className="new-item-margin">
      <Col width={4} className="margin-col">
        {tiaAutoSuggest}
      </Col>
      {isLevel3OrHigher(state.context.configuration.levelConfiguration) ?
        <Col className="margin-col">{newTimeTrackerAutoSuggest}</Col>
        : ''}
      <Col className="margin-col" width={2}>
        {rolesDropdown(state, '', state.newItem.role, 'choose-new-role')}
      </Col>
      <Col width={1}>
        <Button context="success" disabled={!(state.newItem.tiaUser && state.tiaUserSuggest.input)} className="add-project-member">
          <Icon icon="plus" />
        </Button>
      </Col>
    </Row>
    <Row>
      <Col>
        <Button context="primary" id="save-project-members">Save</Button>
      </Col>
    </Row>
  </Container>
}

export const MembersComponent = (sources: MembersSources) => {

  const tiaUserAutoSuggest = isolate(TypedAutoSuggest<TiaUser>(),'tiaUserSuggest')(sources)

  const TimeTrackerUserAutoSuggest = TypedAutoSuggest<TimeTrackerUser>()

  const newTimeTrackerAutoSuggest = isolate(TimeTrackerUserAutoSuggest, 'newTimeTrackerUserSuggest')(sources)

  const timeTrackerAutoSuggests = isolate(makeCollection({
    item: TimeTrackerUserAutoSuggest,
    itemScope: id,
    collectSinks: instances => ({
      CDOM: instances.pickCombine('DOM'),
      state: instances.pickMerge('state'),
      chooseTimeTrackerUser: mergeWithIndex(instances, 'autoSuggestChoose'),
      timeTrackerInput: mergeWithIndex(instances, 'autoSuggestInput')
    })
  }), stateScope<MembersViewState, AutoSuggestState<TimeTrackerUser>[]>({
    get:
      get(state =>
        state.projectMembers
          .map((projectMember, i): AutoSuggestState<TimeTrackerUser> => {
            const timeTrackerUsers = timeTrackerUsersOrEmpty(state.timeTrackerUsers)
            return ({
              input: projectMember.timeTrackerUserInput,
              suggestables: getTimeTrackerUserSuggestables(timeTrackerUsers),

              isInvalid:
              projectMember.timeTrackerId
                ? !(timeTrackerUsers.find(ttu => ttu.id === projectMember.timeTrackerId))
                : false,

              ariaDescribedby: `time-tracker-user-${i}`,
              errorMessage: `Time tracker user with id ${projectMember.timeTrackerId} for TIA user ${projectMember.displayName} was not found.`
            })
          })),
    set: (state, suggestStates: AutoSuggestState<TimeTrackerUser>[]) =>
      set(s => s.projectMembers)(
        projectMembers => projectMembers.map((projectMember, index) => ({ ...projectMember, timeTrackerUserInput: suggestStates[index].input})))(state)
  }))(sources)

  const sinks =
    model(
      intent(
        sources,
        tiaUserAutoSuggest.autoSuggestChoose,
        newTimeTrackerAutoSuggest.autoSuggestChoose,
        timeTrackerAutoSuggests.chooseTimeTrackerUser,
        timeTrackerAutoSuggests.timeTrackerInput),
      sources)

  return mergeSinks(
    sinks,
    timeTrackerAutoSuggests,
    omit(tiaUserAutoSuggest, 'DOM', 'autoSuggestChoose'),
    omit(newTimeTrackerAutoSuggest, 'DOM', 'autoSuggestChoose'),
    {
      DOM: xs.combine(sources.state.stream, tiaUserAutoSuggest.DOM, timeTrackerAutoSuggests.CDOM, newTimeTrackerAutoSuggest.DOM).map(apply(view)),
      HTTP: xs.of(rolesResource(sources.apiHost + '/api')(sources.HTTP).get)
    })
}
