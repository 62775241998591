import xs from 'xstream'
import sampleCombine from 'xstream/extra/sampleCombine'
import { isUser } from '../authentication/userProvider'
import { AuthActionType } from '../drivers/msalDriver'
import { startWith } from '../generic'
import { jsx } from 'h'
import { PageSources } from '../infrastructure'
import { click } from '../ui'
import { Request } from '../dataServices'

export const TestComponent = (sources: PageSources) => {
  const authNode = sources.user
    .compose(sampleCombine(sources.msGraph.startWith('')))
    .map(([info, picture]) => <div>
      {info ? JSON.stringify(info) : 'NOT LOGGED IN '}
      <button key="load" id="load-button">LOAD</button>
      <button key="login" id="login-button">LOGIN</button>
      <button key="logout" id="logout-button">LOGOUT</button>
      <button key="call4" id="call-button4">CALL TEST</button>
      <button key="call5" id="call-button5">CALL TEST2</button>
      <button key="picture" id="picture">LOAD PIC</button>
      {picture}
    </div>)

  const configNode = xs.of(<h1>{sources.configSnapshot.environment}</h1>)

  return {
    DOM: xs.combine(configNode.compose(startWith(null)), authNode.compose(startWith(null))).map(([config, auth]) => <div>{config}{auth}</div>),
    msal: xs.merge(
      click(sources.DOM, '#load-button').mapTo(AuthActionType.LoadAccounts),
      click(sources.DOM, '#login-button').mapTo(AuthActionType.Login),
      click(sources.DOM, '#logout-button').mapTo(AuthActionType.Logout)),
    HTTP: xs.merge(
      click(sources.DOM, '#call-button4')
        .mapTo(Request({
          url: 'http://localhost:7071/api/debug/log/239db6e6-5cbb-429d-8c2f-3b12396c9908',
          method: 'get'})),
      click(sources.DOM, '#call-button5')
        .mapTo(Request({
          url: 'http://localhost:7071/api/debug/log',
          method: 'post',
          send: {'the': 'payload'}})))
      .compose(sampleCombine(sources.user.filter(isUser).compose(startWith(null))))
      .map(([req, user]) => ({...req, category: 'test-request', headers: user ? { Authorization: 'Bearer ' + user.tokens[0].token } : {} })),
    msGraph: click(sources.DOM, '#picture').mapTo(({ query: 'users?$filter=(userPrincipalName eq \'gorisru@cronos.be\') or (userPrincipalName eq \'lacagkr@cronos.be\')' })),
    log: sources.HTTP
      .select()
      .map(resp => resp.map(x => ({ THERESPONSE: x, THEREQUEST: resp.request })))
      .map(resp => resp.replaceError((e: any) => xs.of({ THEERROR: e }) as any) as any)
      .flatten()
      .map((x: any) => ({ THETHING: x }))
  }
}
