import { HTTPSource } from '@cycle/http'
import { GettableResource, receiveJson, toLoadable, Request, handleLatest, isRequestError, REQUEST_ERROR, PuttableResource } from '../../dataServices'
import { K } from '../../generic'
import { Credentials, usernamePassword } from '../components/configuration/backlog/redmineViaClientConfiguration/types'

export type IdentifiableName = {
  id: number
  name: string
}
export type Reference = {
  id: number
}

export type ParentReference = Reference

export type CustomField = {
  id: number
  name: string
  value: string | number | boolean
}

export type RedmineProject = {
  id: string
  name: string
  identifier: string
  description: string
  status: number
  is_public: boolean
  custom_fields: [{id: number; name: string; value: string}]
  created_on: string
  updated_on: string
}

export type RedmineIssue = {
  id: number
  project: IdentifiableName
  subject: string
  description: string
  status: IdentifiableName | null
  tracker: IdentifiableName
  parent: ParentReference | null
  custom_fields: CustomField[]
}

export type RedmineProjectsResponse = {
  projects: RedmineProject[]
  total_count: number
  offset: number
  limit: number
}

export type RedmineIssuesResponse = {
  issues: RedmineIssue[]
  total_count: number
  offset: number
  limit: number
}

export const MAX_LIMIT = 100 // api max limit

const offsetInner = (totalCount: number, limit: number, offsets: number[]): number[] =>
  (offsets[offsets.length - 1] >= totalCount - limit ? offsets : offsetInner(totalCount, limit, [...offsets, offsets[offsets.length - 1] + limit]))
export const offsets = (totalCount: number, limit: number) => offsetInner(totalCount, limit, [0])

const toRequestOptions = (credentials: Credentials) =>
  credentials.credentialType === usernamePassword
    ? { user: credentials.username, password: credentials.password }
    : { headers: { Authorization: 'Basic ' + btoa(credentials.apiKey) } }

export const issuesCategory = (projectId: number) => `issues[${projectId}]`


export const redmineProjectsViaClientProjectsResource = (HTTP: HTTPSource) => (redmineApiRoot: string) => (credentials: Credentials): GettableResource<RedmineProjectsResponse> => {
  const url = `${redmineApiRoot}/projects.json`

  const request = Request({
    category: `get-redmine-via-client-projects-${redmineApiRoot}`,
    method: 'GET',
    url: url,
    ...toRequestOptions(credentials)
  })

  return {
    name: request.url,
    get: request,
    value: toLoadable(receiveJson<RedmineProjectsResponse>(HTTP, request.category))
  }
}

export const redmineIssuesViaClientResource =
  (HTTP: HTTPSource) =>
    (redmineApiRoot: string) =>
      (credentials: Credentials) =>
        (projectId: number, offset: number, limit: number, expected: number): GettableResource<RedmineIssuesResponse> => {
          const url = `${redmineApiRoot}/issues.json`

          const request = Request({
            category: `issues[${projectId}-${offset}]`,
            method: 'GET',
            url: url,
            query: {
              project_id: projectId,
              limit,
              status_id: '*',
              offset
            },
            expected: expected,
            ...toRequestOptions(credentials)
          })

          return {
            name: `${request.url}?project_id=${projectId}&offset=${offset}`,
            get: request,
            value: toLoadable(receiveJson<RedmineProjectsResponse>(HTTP, request.category))
          }
        }

export const redmineProjectIssuesCountResource =
  (HTTP: HTTPSource) => (redmineApiRoot: string) => (credentials: Credentials) => (projectId: number): GettableResource<RedmineIssuesResponse> => {
    const request = Request({
      category: `count[${projectId}]`,
      url: `${redmineApiRoot}/issues.json`,
      query: {
        project_id: projectId,
        limit: 1, // only interested in the total_count result
        status_id: '*'
      },
      ...toRequestOptions(credentials)
    })

    return {
      name: `${request.url}?project_id=${projectId}`,
      get: request,
      value: toLoadable(receiveJson<RedmineIssuesResponse>(HTTP, request.category))
    }
  }

export type UploadRedmineIssuesViaClientBody = {
  projectIds: number[]
  issues: RedmineIssue[]
}

export const uploadRedmineIssuesViaClientResource =
  (HTTP: HTTPSource) => (root: string) => (key: string): PuttableResource<UploadRedmineIssuesViaClientBody> => {
    const category = 'UpdateProjectRedmineData'

    return {
      put: body => ({
        category: category,
        url: `${root}/api/projects/${key}/redmine-via-client/issues`,
        method: 'PUT',
        send: body
      }),
      putResponse: toLoadable(handleLatest(HTTP)(category)({
        success: K(undefined),
        error: err => isRequestError(err) ? err[REQUEST_ERROR].message : err.text
      }))
    }
  }
