import { serviceItemToIntent } from '@/dataServices'
import { isLoaded, notNull } from '@/generic'
import { dynamicIntent, pageSinkTemplate, IGNORE } from '@/infrastructure'
import { capture, click, events, inputs } from '@/ui'
import format from 'date-fns/format'
import { getUserTimesheetResource, selectedMemberStorageKey, TimesheetSources, TimesheetViewModel } from './types'

export const intent = (sources: TimesheetSources) => {
  const domInput = capture(
    events({
      '.previous-month': click,
      '.next-month': click,
      '.sync': click,
      '.refresh': click,
      '.nuke-cache': click
    }),
    inputs({
      '.project-member': null
    })
  )(sources)

  return dynamicIntent<TimesheetViewModel>()(
    pageSinkTemplate, {
      ...domInput,
      date: sources.primState.date,
      loadProjectConfiguration: serviceItemToIntent(sources.primState.projectConfiguration(sources.projectKey)),
      loadReport: {
        discriminator: state =>
          (!state.selectedProjectMember || !isLoaded(state.configuration))
            ? IGNORE
            : `${sources.apiHost}/api/projects/${sources.projectKey}/${state.selectedProjectMember}?month=${format(state.selectedDate, 'yyyy-MM')}`,
        value: state => serviceItemToIntent(getUserTimesheetResource(sources)(state))
      },
      syncResponse: {
        discriminator: state =>
          (!state.selectedProjectMember || !isLoaded(state.configuration))
            ? IGNORE
            : `${sources.apiHost}/api/projects/${sources.projectKey}/${state.selectedProjectMember}/sync?month=${format(state.selectedDate, 'yyyy-MM')}`,
        value: state => getUserTimesheetResource(sources)(state).syncResponse
      },
      loadProjectMember: sources.storage.local.getItem<string | null>(selectedMemberStorageKey(sources.projectKey)).take(1).filter(notNull),
      // toggleDay: click(sources.DOM, '.day-row')
      //   .map(elementTarget)
      //   .map(findParentNode('tr'))
      //   .map(fromData(data => +data.date!)),
      updateTable: {
        discriminator: state => state.timesheetReportTableKey,
        value: state => sources.tanstackTable.table(state.timesheetReportTableKey)
      }
    })
}
