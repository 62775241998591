import { isUser } from '@/authentication'
import { PrimDataService, authorize } from '@/dataServices'
import { jsx } from '@/h'
import { AppSinks, PageSinks, PageSources, mergePageSinks, pageSinkMergeTemplate, withRouterBehaviour } from '@/infrastructure'
import { rolesResource } from '@/prim/resources'
import { NavLinkCreator, renderLoadable } from '@/ui'
import { redirectPath, spy } from '@/util'
import isolate from '@cycle/isolate'
import { Reducer } from '@cycle/state'
import xs from 'xstream'


export type PlayGroundPrimPageSources<State = any> = PageSources<State> & {
  primState: PrimDataService
}

export type PlayGroundPageSinks = Partial<AppSinks & {
  primState: xs<Reducer<any>>
}>


// const withPrimState = (component: Component<PrimPageSources, PageSinks>, apiHost: string) => {
//   return (sources: PageSources & { state: StateSource<{ component: any; data: any }> }) => {
//     const root = apiHost + '/api'
//     const innerState = sources.state.select('state').stream.remember() as MemoryStream<ServiceState>
//     const services = primDataService(root)(sources.HTTP)(innerState)

//     const componentSinks = isolate(component, { state: 'component', '*': null })({ ...sources, primState: services })

//     const shouldBeAuthorized = (request: RequestInput) => {
//       return (typeof request === 'string' ? request : request.url).startsWith(root)
//     }

//     return {
//       ...componentSinks,
//       state: xs
//         .merge(
//           xs.of(K<PrimState>({ state: {}, component: undefined })),
//           services.primState,
//           componentSinks.state || xs.never(),
//           componentSinks.primState || xs.never(),
//           sources.date.days.map(date => (state: any) => ({ ...state, state: { ...state, date: date } }))),
//       // automatically authenticate calls to the api
//       HTTP: xs.merge(services.HTTP, componentSinks.HTTP || xs.never())
//         .compose(sampleCombine(sources.user))
//         .map(([request, user]) => shouldBeAuthorized(request) ? authorize(request, user) : request),
//       // primstate needs to persist, disconnected from whether pages subscribe to state
//       ground: xs.merge(innerState, componentSinks.ground || xs.never()).filter(K(false)),
//     }
//   }
// }



const Page1 = (sources: PageSources<unknown>): PageSinks => {
  const roles = rolesResource(sources.apiHost + '/api')(sources.HTTP)
  // const rolesServiceItem = to

  const NavLink = NavLinkCreator(sources.parentRouter)

  return {
    DOM: xs.combine(sources.user, roles.value)
      .map(([user, roles]) =>
        <div>
          <div>Hello, {user === 'unloaded' ? '...' : user === 'anonymous' ? 'world' : user === 'authError' ? '???' : user.name}! You're on page 1!</div>
          <div>These roles exist: {renderLoadable(roles, { loaded: roles => roles.join(', ') })}</div>
          <NavLink href='/page2'>To page 2!</NavLink>
        </div>),
    HTTP: sources.user.filter(isUser).take(1).compose(spy('USER')).map(user => authorize(roles.get, user) as any)
  }
}

const Page2 = (sources: PageSources<unknown>): PageSinks => {
  const roles = rolesResource(sources.apiHost + '/api')(sources.HTTP)

  const NavLink = NavLinkCreator(sources.parentRouter)

  return {
    DOM: xs.combine(sources.user, roles.value)
      .map(([user, roles]) =>
        <div>
          <div>Hello, {user === 'unloaded' ? '...' : user === 'anonymous' ? 'world' : user === 'authError' ? '???' : user.name}! You're on page 2!</div>
          <div>These roles exist: {renderLoadable(roles, { loaded: roles => roles.join(', ') })}</div>
          <NavLink href='/page1'>To page 1!</NavLink>
        </div>)
  }
}



export const PlayGround = (sources: PageSources<unknown>): PageSinks => {
  const page$ = withRouterBehaviour<PageSinks, PageSources<unknown>>(sources, {
    '/': redirectPath('/page1'),
    '/page1': isolate(Page1, 'page1'),
    '/page2': isolate(Page2, 'page2')
  })

  return mergePageSinks(page$, pageSinkMergeTemplate)

}
