import { HTTPSource } from '@cycle/http'
import { GettableResource, handleLatest, toLoadable } from '../../dataServices'
import { ClockifyConfigurationInvalid, InsufficientRights, PaymoConfigurationInvalid, RequestError, UnhandledApiExceptionProblem, UnhandledClockifyProblem, UnhandledPaymoProblem, WrongLevelProblem, insufficientRights } from '../model'
import { handleError, resultOrError, simpleResult } from './resourceUtil'
import { K } from '@/generic'
import { FOption } from '@/fsharp'

export type TimeTrackerTask = {
  id: string
  name: string
  group: FOption<string>
}

export type TimeTrackerTasksResponse = TimeTrackerTask[]

export type TimeTrackerTasksResource =
  GettableResource<TimeTrackerTasksResponse, RequestError | InsufficientRights | WrongLevelProblem | ClockifyConfigurationInvalid | PaymoConfigurationInvalid | UnhandledClockifyProblem | UnhandledPaymoProblem | UnhandledApiExceptionProblem>

export const timeTrackerTasksResource = (root: string) => (HTTP: HTTPSource) => (key: string): TimeTrackerTasksResource => {
  const url = `${root}/projects/${key}/timetracker/tasks`

  const request = {
    category: url,
    method: 'GET',
    url: url
  }

  return {
    name: request.url,
    get: request,
    value: toLoadable(handleLatest(HTTP)(request.category)({
      success: simpleResult<TimeTrackerTasksResponse>,
      failure: {
        403: K(insufficientRights),
        409: simpleResult<WrongLevelProblem | ClockifyConfigurationInvalid | PaymoConfigurationInvalid>,
        500: resultOrError<UnhandledApiExceptionProblem | UnhandledClockifyProblem | UnhandledPaymoProblem>
      },
      error: handleError
    }))
  }
}
