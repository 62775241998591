/* eslint-disable @typescript-eslint/no-use-before-define */
import { isArray } from './array'
import { C, compose } from './util'

const tracing = false
const PATH = Symbol('PATH')
const OBJ = Symbol('OBJ')
const VALUE = Symbol('VALUE')

const ctrace = (...args: any[]) => {
  if (tracing) {
    console.log(...args)
  }
}

const CALL = Symbol('CALL')
type Call = {
  [CALL]: {
    thisArg: any
    argumentsList: any[]
  }
}

type Key = string | number | symbol | Call
type PathType = Array<Key>
type Path = Array<Key | PathType>
export type Lens<TObject, TProp> = (x: TObject) => TProp

const makeCopyForWrite = (obj: any, key: Key, path: Path) => {
  const type = typeof obj
  if (type === 'object') {
    if (obj === null) {
      throw new Error(`Cannot write property "${key.toString()}" on a null value (${path.join('.')})`)
    } else {
      return (typeof key === 'string' || typeof key === 'number') && !isNaN(+key) && isArray(obj)
        ? obj.slice()
        : { ...obj }
    }
  } else {
    throw new Error(`Cannot write property "${key.toString()}" on a value of type "${type}" (${path.join('.')})`)
  }
}

const getThisVal = (forWrite: boolean, thisArg: any) => (!forWrite && thisArg && thisArg[PATH] && thisArg[VALUE]) || thisArg

const getCallValue = (forWrite: boolean, f: (...args: any[]) => any, call: Call[typeof CALL]) => f.apply(getThisVal(forWrite, call.thisArg), call.argumentsList)

const simpleRead = (forWrite: boolean, root: any, obj: any, path: Path): any => {
  if (!path.length) {
    return obj
  } // case where nothing was traversed: root object was returned from read expression => return obj itself
  const key = path[0]
  ctrace('<', key, !isArray(key), typeof key === 'object')
  if (!isArray(key) && typeof key === 'object') {
    ctrace('reading call...', forWrite, '#', obj, '|', key[CALL].thisArg, '|', key[CALL].argumentsList)
  }

  const val = isArray(key) ? obj[simpleRead(forWrite, root, root, key)] : typeof key === 'object' ? getCallValue(forWrite, obj, key[CALL]) : obj[key]
  if (path.length > 1) {
    return simpleRead(forWrite, root, val, path.slice(1))
  }
  return val
}

export const simpleWrite = <T>(root: any, path: Path, fullPath: Path, obj: T, val: any): T => {
  if (!path.length) {
    return val // case where nothing was traversed: root object was returned from read expression => overwrite with new value
  }
  const key = path[0]
  const calculatedKey: keyof T = isArray(key) ? simpleRead(false, root, root, key) : key

  if (typeof key === 'object') {
    throw new Error(`Cannot write to path "${fullPath}" because it contains a call`)
  }

  if (path.length === 1) {
    if (obj[calculatedKey] === val) {
      return obj // if the new value is the old value, don't make a copy of the current object
    }
    const copy = makeCopyForWrite(obj, calculatedKey, fullPath)
    copy[calculatedKey] = val
    return copy
  } else {
    const oldValue = obj[calculatedKey]
    const newValue = simpleWrite(root, path.slice(1), fullPath, oldValue, val)
    if (oldValue === newValue) {
      return obj // if the new value is the old value, don't make a copy of the current object
    }
    const copy = makeCopyForWrite(obj, calculatedKey, fullPath)
    copy[calculatedKey] = newValue
    return copy
  }
}

type Tracer<T> = {
  obj: T
  [PATH]: Path
  [VALUE]: any
}

let lastEvaluatedPrimitivePath: any
let lastEvaluatedPrimitiveValue: any

const wrap = (tracer: Tracer<any>, path: Path) => {
  const lastKey = path[path.length - 1]
  lastEvaluatedPrimitivePath = undefined
  lastEvaluatedPrimitiveValue = undefined

  ctrace('<<<', path)
  const value = isArray(lastKey)
    ? simpleRead(true, tracer.obj, tracer.obj, path)
    : lastKey && typeof lastKey === 'object' && lastKey[CALL] ? getCallValue(true, tracer[VALUE], lastKey[CALL]) : tracer[VALUE][lastKey as any]
  ctrace('>>>', path, value)

  // in order for the proxied item to be identifiable as a function, the target must also be a function
  if (value && typeof value === 'object' && PATH in value) {
    ctrace('XW')
    return value
  } else if (typeof value === 'function') {
    const dummyTraceF = () => {
      throw new Error('dummy')
    };
    (dummyTraceF as any).obj = tracer.obj;
    (dummyTraceF as any)[PATH] = path;
    (dummyTraceF as any)[VALUE] = value
    return new Proxy((dummyTraceF as any) as Tracer<any>, tracerHandler)
  } else if (value && typeof value === 'object') {
    return new Proxy({ obj: tracer.obj, [PATH]: path, [VALUE]: value }, tracerHandler)
  } else {
    ctrace('PW')
    if (lastEvaluatedPrimitivePath && lastEvaluatedPrimitiveValue === value) {
      return value
    }
    lastEvaluatedPrimitivePath = path
    lastEvaluatedPrimitiveValue = value
    return value
  }
}

const tracerValue = (tracer: Tracer<any>) => tracer[VALUE]

const tracerHandler: ProxyHandler<Tracer<any>> = {
  get: (target, prop, _receiver) => {
    ctrace('>', prop)
    if (prop === PATH) {
      return target[PATH]
    }
    if (prop === OBJ) {
      return target.obj
    }
    if (prop === Symbol.toPrimitive) {
      return () => simpleRead(true, target.obj, target.obj, target[PATH])
    }
    if (typeof prop === 'object' && PATH in prop) {
      throw new Error('Cannot use traced values as index')
    }
    const newPath = [...target[PATH], prop]
    ctrace('>>', prop)
    return wrap(target, newPath)
  },
  apply: (target, thisArg, argumentsList) => {
    ctrace('trying to apply...', target, '||', thisArg, '||', argumentsList)
    return wrap(target, [...target[PATH], { [CALL]: { thisArg, argumentsList } }])
  },
  ownKeys: target => {
    ctrace('ownkeys', target)
    return Reflect.ownKeys(tracerValue(target))
  },
  has: (target, propertyKey) => {
    ctrace('has', target, propertyKey)
    if (propertyKey === PATH || propertyKey === OBJ || propertyKey === VALUE) {
      return true
    }
    return Reflect.has(tracerValue(target), propertyKey)
  },
  getPrototypeOf: target => {
    ctrace('getPrototypeOf', target)
    return Reflect.getPrototypeOf(tracerValue(target))
  },
  getOwnPropertyDescriptor: (target, propertyKey) => {
    ctrace('getOwnPropertyDescriptor', target, propertyKey)
    return Reflect.getOwnPropertyDescriptor(tracerValue(target), propertyKey)
  },
  setPrototypeOf: () => {
    throw new Error('Cannot write to a tracer')
  },
  preventExtensions: () => {
    throw new Error('Cannot write to a tracer')
  },
  defineProperty: () => {
    throw new Error('Cannot write to a tracer')
  },
  set: () => {
    throw new Error('Cannot write to a tracer')
  },
  deleteProperty: () => {
    throw new Error('Cannot write to a tracer')
  },
  construct: () => {
    throw new Error('Cannot construct from a traced function')
  },
  isExtensible: target => {
    return Reflect.isExtensible(tracerValue(target))
  }
}

export const trace = <T, U>(lens: Lens<T, U>, x: T) => {
  const traced = (lens(new Proxy({ obj: x, [PATH]: [], [VALUE]: x }, tracerHandler) as any) as any) as Tracer<T>
  if ((typeof traced !== 'object' || !traced) && typeof traced !== 'function') {
    if (traced !== lastEvaluatedPrimitiveValue && !(isNaN(traced) && isNaN(lastEvaluatedPrimitiveValue))) {
      throw new Error('Something outside of the traced object was returned')
    }
    return lastEvaluatedPrimitivePath as Path
  }
  return traced[PATH]
}

export const read = <T, P>(lens: Lens<T, P>) => (x: T) => {
  return lens(x)
}

export type NewValue<T, P> = P | ((x: P, r: T) => P)

export type Updater<T> = {
  (x: T): T
  and: <P>(lens: Lens<T, P>) => (upd: P | ((x: P, r: T) => P)) => Updater<T>
  then: (updater: Updater<T> | ((x: T) => T)) => Updater<T>
  for: <TP>(lens: Lens<TP, T>) => Updater<TP>
}

export const then = <T>(nextUpdater: Updater<T> | ((x: T) => T)) => (updater: Updater<T>) => makeUpdater(compose(updater, nextUpdater))

export const makeUpdater = <T>(upd: (x: T) => T): Updater<T> => {
  const updater = (x: T) => upd(x)

  updater.and = <P>(lens: Lens<T, P>) => (innerUpd: P | ((x: P, r: T) => P)) => makeUpdater(compose(updater, write(lens)(innerUpd)))

  updater.then = (nextUpdater: Updater<T> | ((x: T) => T)) => then(nextUpdater)(updater)

  updater.for = <TP>(lens: Lens<TP, T>) => makeUpdater(write(lens)(updater))

  return updater
}

export type Writer<T, P> = (upd: P | ((x: P, r: T) => P)) => Updater<T>

export const write = <T, P>(lens: Lens<T, P>) => (update: P | ((x: P, r: T) => P)): Updater<T> => {
  const innerUpdater = (x: T) => {
    // if upd is func, optimize trace to also track value
    const path = trace(lens, x)
    const writeVal = typeof update === 'function' ? (update as (x: P, r: T) => P)(read(lens)(x), x) : update
    ctrace('----------', path)
    return simpleWrite(x, path, path, x, writeVal)
  }

  return makeUpdater(innerUpdater)
}

export const writeFor = <T, T1>(outerLens: Lens<T, T1>) => <T2 extends T1>(guard: (x: T1) => x is T2) =>
  <P extends any>(innerLens: Lens<T2, P>) => (upd: P | ((x: P, r: T2) => P)) => {
    const innerUpdater = (outerValue: T) => {
      const fullLens = C(outerLens as any, innerLens)
      const innerValue = outerLens(outerValue)
      if (guard(innerValue)) {
      // if upd is func, optimize trace to also track value
        const path = trace(fullLens, outerValue)
        const writeVal = typeof upd === 'function' ? (upd as (x: P, r: T2) => P)(read(innerLens)(innerValue), innerValue) : upd
        return simpleWrite(outerValue, path, path, outerValue, writeVal)
      } else {
        return outerValue
      }
    }

    return makeUpdater(innerUpdater)
  }

export const writeWhen = <T>(predicate: (x: T) => boolean) => <P>(lens: Lens<T, P>) => (upd: NewValue<T, P>): Updater<T> => {
  const innerUpdater = (x: T) => {
    if (!predicate(x)) {
      return x
    }
    // if upd is func, optimize trace to also track value
    const path = trace(lens, x)
    const writeVal = typeof upd === 'function' ? (upd as (x: P, r: T) => P)(read(lens)(x), x) : upd
    return simpleWrite(x, path, path, x, writeVal)
  }

  return makeUpdater(innerUpdater)
}

export const readNow = <T, U>(obj: T, lens: Lens<T, U>) => read(lens)(obj)
export const writeNow = <T, P>(obj: T, val: NewValue<T, P>, lens: Lens<T, P>) => write(lens)(val)(obj)

export const writeTo = <T, P>(lens: Lens<T, P>) => (updater: Updater<P>) => updater.for(lens)

export type WriteTo<T, U> = (update: U | ((x: U, r: T) => U)) => Updater<T>
export const Writer = <T>() => <U>(f: Lens<T, U>) => write(f)
export type Write<T> = <U>(f: Lens<T, U>) => Updater<T>
export const WriterFor = <T>() => <U>(f: Lens<T, U>) => writeFor(f)
export const WriterWhen = <T>() => (predicate: (x: T) => boolean) => writeWhen(predicate)
export const WriterCase = <T>() => <U extends T>(guard: (x: T) => x is U) => (writeWhen<U>(guard) as any) as <P>(lens: Lens<U, P>) => (upd: NewValue<U, P>) => Updater<T>
export const WriterTo = <T>() => <P>(lens: Lens<T, P>) => writeTo(lens)
export const Reader = <T>() => <U>(f: Lens<T, U>) => read(f)
export const Lenser = <T>() => <U>(f: Lens<T, U>) => f
