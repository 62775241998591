import xs from 'xstream'
import { append, C, combineLoadables, flatten, isLoaded, K, keysOf, Manager, mapError, mapLoaded, mapOption, maybeValue, removeAt, Unloaded, value } from '../../../../generic'
import { jsx } from 'h'
import { dynamicIntent, IGNORE, INIT, DERIVED, pageSinkTemplate, SINKS, dynamicCaseModel, DEFAULT } from '../../../../infrastructure'
import { Button, capture, click, Col, Container, Danger, events, Input, inputs, Label, Props, renderLoadable, Row, Select, targetData, ToggleButton, ToggleButtonGroup } from '../../../../ui'
import { dummyCredential, isLevel5, JiraAutoLinkIds, JiraBacklogConfiguration, JiraCustomFieldId,JiraHierarchyDefinition, JiraIssueLinkDirection, JiraIssueLinkId, JiraParentLink, JiraTypeId } from '../../../model'
import { backlogConfiguration, jiraIssueFieldsFromJiraResource, jiraIssueFieldsResource, jiraIssueLinksFromJiraResource, jiraIssueLinksResource, jiraIssueTypesFromJiraResource, jiraIssueTypesResource, jiraProjectsFromJiraResource, jiraProjectsResource } from '../../../resources'
import { ProjectPageSources } from '../../common'
import { renderConfigurationResponse, setBacklogConfigurationProblemRenderConfig } from '../../renderers'
import { ConfigurationContext, JiraAutoIdsInput, jiraCloudInstanceType, JiraConfigurationViewState, jiraCustomHierarchyType, jiraDefaultHierarchyType, JiraDescendantInput, JiraHierarchyInput, jiraInstanceDiscriminator, JiraParentLinkInput, jiraServerInstanceType } from '../viewModel'
import { asOption, asSimple, caseValue, fSome, mapFOption, match, Tuple } from '@/fsharp'
import { discriminator, matchBy } from '@/generic/case'
import { Dataset } from 'snabbdom'
import { GettableResource } from '@/dataServices'
import { handlePutBacklogConfigurationResponse } from './model'

type State = JiraConfigurationViewState

const emptyState = (context: ConfigurationContext, backlogConfigurationId: string):
Omit<State, typeof jiraInstanceDiscriminator | 'hierarchyDefinition' | 'mappingDefinition'> => ({
  context: context,
  backlogConfigurationId: backlogConfigurationId,
  host: '',
  project: '',
  primKnowsSecret: false,
  projects: Unloaded,
  issueFields: Unloaded,
  issueLinks: Unloaded,
  issueTypes: Unloaded,
  saveResponse: Unloaded
})

const AutoIdsInput = (autoIds: JiraAutoLinkIds): JiraAutoIdsInput => ({
  parentLinkId: autoIds.parentLinkId && caseValue(caseValue(autoIds.parentLinkId)),
  epicLinkId: autoIds.epicLinkId && caseValue(caseValue(autoIds.epicLinkId)),
  hierarchyLinkId: autoIds.hierarchyLinkId && caseValue(caseValue(autoIds.hierarchyLinkId))
})

const ParentLinkInput = (parentLink: JiraParentLink): JiraParentLinkInput =>
  match(parentLink, {
    Auto: (auto): JiraParentLinkInput => ({
      type: 'auto',
      autoLinkIds: AutoIdsInput(auto)
    }),
    ParentIssue: (): JiraParentLinkInput => ({ type: 'parentIssue' }),
    ParentLink: (customFieldId): JiraParentLinkInput => ({
      type: 'parentLink',
      customFieldId: caseValue(customFieldId)
    }),
    EpicLink: (customFieldId): JiraParentLinkInput => ({
      type: 'epicLink',
      customFieldId: caseValue(customFieldId)
    }),
    IssueLink: (issueLinkId, linkDirection): JiraParentLinkInput => ({
      type: 'issueLink',
      linkId: caseValue(issueLinkId),
      linkDirection: linkDirection.case
    })
  })

const parentLinkTypeDescriptions: { [K in JiraParentLinkInput['type']]: string } = {
  auto: 'Auto',
  parentIssue: 'Parent issue',
  parentLink: 'Parent link',
  epicLink: 'Epic link',
  issueLink: 'Issue link'
}

const parentLinkTypes = keysOf(parentLinkTypeDescriptions)

const HierarchyInput = (hierarchyDefinition: JiraHierarchyDefinition): JiraHierarchyInput =>
  match(hierarchyDefinition, {
    Default: (autoIds): JiraHierarchyInput => ({
      type: jiraDefaultHierarchyType,
      ...AutoIdsInput(autoIds)
    }),
    Custom: (customHierarchy): JiraHierarchyInput => ({
      type: jiraCustomHierarchyType,
      topLevelTypeId: caseValue(customHierarchy.topLevelTypeId),
      definedDescendants: customHierarchy.definedDescendants.map((d): JiraDescendantInput => ({
        issueTypeId: caseValue(d.item1),
        parentLinks: d.item2.map(ParentLinkInput)
      })),
      rest: customHierarchy.rest.map(ParentLinkInput)
    })
  })

const defaultAutoIds: JiraAutoIdsInput = {
  parentLinkId: null,
  epicLinkId: null,
  hierarchyLinkId: null
}

export const jiraConfigurationViewState = (context: ConfigurationContext, backlogConfigurationId: string, jiraConfig: JiraBacklogConfiguration | null): State => {
  if (!isLevel5(context.configuration.levelConfiguration) || jiraConfig == null) {
    return {
      ...emptyState(context, backlogConfigurationId),
      [jiraInstanceDiscriminator]: jiraCloudInstanceType,
      cloudUsername: '',
      cloudToken: '',
      hierarchyDefinition: {
        type: jiraDefaultHierarchyType,
        ...defaultAutoIds
      },
      mappingDefinition: {
        storyPoints: null,
        iteration: null
      }
    }
  }

  return {
    ...emptyState(context, backlogConfigurationId),
    host: jiraConfig.host,
    project: jiraConfig.project,
    primKnowsSecret: jiraConfig.authenticationInfo.fields[0].token === dummyCredential,
    ...match(jiraConfig.authenticationInfo, {
      JiraCloudApiTokenAuthentication: info => ({
        [jiraInstanceDiscriminator]: jiraCloudInstanceType,
        cloudUsername: info.username,
        cloudToken: info.token
      }),
      JiraServerPat: info => ({
        [jiraInstanceDiscriminator]: jiraServerInstanceType,
        serverToken: info.token
      })
    }),
    hierarchyDefinition: HierarchyInput(jiraConfig.hierarchyDefinition),
    mappingDefinition: {
      storyPoints: asSimple(mapFOption(caseValue)(jiraConfig.mappingDefinition.storyPoints)),
      iteration: asSimple(mapFOption(caseValue)(jiraConfig.mappingDefinition.iteration))
    }
  }
}

type Sources = ProjectPageSources<State>

const jiraAuthenticationForProjectDiscriminator =
  (state: State) =>
    (state.host
      && state.project
      && matchBy(jiraInstanceDiscriminator)(state, { [jiraCloudInstanceType]: s => s.cloudUsername && s.cloudToken, [jiraServerInstanceType]: s => s.serverToken }))
      ? `${state.host}
|${state.project}
|${state[jiraInstanceDiscriminator]}
|${matchBy(jiraInstanceDiscriminator)(state, { [jiraCloudInstanceType]: s => `${s.cloudUsername}|${s.cloudToken}`, [jiraServerInstanceType]: s => s.serverToken })}`
      : IGNORE

const jiraAuthenticationDiscriminator =
  (state: State) =>
    (state.host && matchBy(jiraInstanceDiscriminator)(state, { [jiraCloudInstanceType]: s => s.cloudUsername && s.cloudToken, [jiraServerInstanceType]: s => s.serverToken }))
      ? `${state.host}
|${state[jiraInstanceDiscriminator]}
|${matchBy(jiraInstanceDiscriminator)(state, { [jiraCloudInstanceType]: s => `${s.cloudUsername}|${s.cloudToken}`, [jiraServerInstanceType]: s => s.serverToken })}`
      : IGNORE

const inputWith = <T extends Dataset, InputType extends string = string>(mapValue?: (value: string) => InputType) =>
  (v: string, e: Event) => ({value: mapValue ? mapValue(v) : v as InputType, data: targetData(d => d as T)(e)})
type DescendantIndexData = { descendantIndex: string }
type ParentLinkIndexData = { parentLinkIndex: string }

const resourceFromPrimOrJira =
  <TValue, TErrorsPrim, TErrorsJira>(
    primResource: (backlogConfigurationId: string) => GettableResource<TValue, TErrorsPrim>,
    jiraResource: (jiraHost: string, project: string, secret: string, username?: string) => GettableResource<TValue, TErrorsJira>) =>
    (state: State) =>
      (state.primKnowsSecret
        ? primResource(state.backlogConfigurationId)
        : matchBy(jiraInstanceDiscriminator)(state, {
          cloud: state => jiraResource(state.host, state.project, state.cloudToken, state.cloudUsername),
          server: state => jiraResource(state.host, state.project, state.serverToken)
        })) as GettableResource<TValue, TErrorsPrim | TErrorsJira>

const intent = (sources: Sources) => {
  const projectsFromPrim = jiraProjectsResource(sources.apiHost + '/api')(sources.HTTP)(sources.projectKey)
  const issueFieldsFromPrim = jiraIssueFieldsResource(sources.apiHost + '/api')(sources.HTTP)(sources.projectKey)
  const issueLinksFromPrim = jiraIssueLinksResource(sources.apiHost + '/api')(sources.HTTP)(sources.projectKey)
  const issueTypesFromPrim = jiraIssueTypesResource(sources.apiHost + '/api')(sources.HTTP)(sources.projectKey)
  const projectsFromJira = jiraProjectsFromJiraResource(sources.apiHost + '/api')(sources.HTTP)
  const issueFieldsFromJira = jiraIssueFieldsFromJiraResource(sources.apiHost + '/api')(sources.HTTP)
  const issueLinksFromJira = jiraIssueLinksFromJiraResource(sources.apiHost + '/api')(sources.HTTP)
  const issueTypesFromJira = jiraIssueTypesFromJiraResource(sources.apiHost + '/api')(sources.HTTP)

  const projects = resourceFromPrimOrJira(projectsFromPrim, (jiraHost: string, _project: string, secret: string, username?: string) => projectsFromJira(jiraHost, secret, username))
  const issueFields = resourceFromPrimOrJira(issueFieldsFromPrim, issueFieldsFromJira)
  const issueLinks = resourceFromPrimOrJira(issueLinksFromPrim, issueLinksFromJira)
  const issueTypes = resourceFromPrimOrJira(issueTypesFromPrim, issueTypesFromJira)

  return dynamicIntent<State>()(
    pageSinkTemplate,
    {
      ...capture(inputs({
        '#jira-host': null,
        '#jira-project': null,
        '#jira-cloud-username': null,
        '#jira-cloud-token': null,
        '#jira-server-token': null,
        '#default-parent-link': null,
        '#default-epic-link': null,
        '#default-hierarchy-link': null,
        '#custom-toplevel-type': null,
        '.custom-descendant-type': inputWith<DescendantIndexData>(),
        '.custom-descendant-parent-link-type': inputWith<DescendantIndexData & ParentLinkIndexData, JiraParentLinkInput['type']>(),
        '.descendant-auto-parent-link': inputWith<DescendantIndexData & ParentLinkIndexData>(),
        '.descendant-auto-epic-link': inputWith<DescendantIndexData & ParentLinkIndexData>(),
        '.descendant-auto-hierarchy-link': inputWith<DescendantIndexData & ParentLinkIndexData>(),
        '.descendant-parent-link': inputWith<DescendantIndexData & ParentLinkIndexData>(),
        '.descendant-epic-link': inputWith<DescendantIndexData & ParentLinkIndexData>(),
        '.descendant-issue-link': inputWith<DescendantIndexData & ParentLinkIndexData>(),
        '.descendant-issue-direction': inputWith<DescendantIndexData & ParentLinkIndexData, JiraIssueLinkDirection['case']>(),
        '.custom-rest-parent-link-type': inputWith<ParentLinkIndexData, JiraParentLinkInput['type']>(),
        '.rest-auto-parent-link': inputWith<ParentLinkIndexData>(),
        '.rest-auto-epic-link': inputWith<ParentLinkIndexData>(),
        '.rest-auto-hierarchy-link': inputWith<ParentLinkIndexData>(),
        '.rest-parent-link': inputWith<ParentLinkIndexData>(),
        '.rest-epic-link': inputWith<ParentLinkIndexData>(),
        '.rest-issue-link': inputWith<ParentLinkIndexData>(),
        '.rest-issue-direction': inputWith<ParentLinkIndexData, JiraIssueLinkDirection['case']>(),
        '#story-points-field': null,
        '#iteration-field': null,
      }), events({
        '.save': click,
        '.add-custom-descendant': click,
        '.remove-custom-descendant': C(click, es => es.map(targetData(data => data as DescendantIndexData))),
        '.add-custom-descendant-parent-link': C(click, es => es.map(targetData(data => data as DescendantIndexData))),
        '.remove-custom-descendant-parent-link': C(click, es => es.map(targetData(data => data as DescendantIndexData & ParentLinkIndexData))),
        '.add-custom-rest-parent-link': click,
        '.remove-custom-rest-parent-link': C(click, es => es.map(targetData(data => data as ParentLinkIndexData)))
      }))(sources),
      selectCredentialType:
        xs.merge(
          click(sources.DOM, '#jira-type-cloud-label').mapTo(jiraCloudInstanceType),
          click(sources.DOM, '#jira-type-server-label').mapTo(jiraServerInstanceType)),
      selectHierarchyType:
        xs.merge(
          click(sources.DOM, '#jira-hierarchy-default-label').mapTo(jiraDefaultHierarchyType),
          click(sources.DOM, '#jira-hierarchy-custom-label').mapTo(jiraCustomHierarchyType)),
      projectsResponse: {
        discriminator: jiraAuthenticationDiscriminator,
        value: state => ({
          intent: projects(state).value,
          [SINKS]: { HTTP: xs.of(projects(state).get) }
        })
      },
      fieldsResponse: {
        discriminator: jiraAuthenticationForProjectDiscriminator,
        value: state => ({
          intent: issueFields(state).value,
          [SINKS]: { HTTP: xs.of(issueFields(state).get) }
        })
      },
      linksResponse: {
        discriminator: jiraAuthenticationForProjectDiscriminator,
        value: state => ({
          intent: issueLinks(state).value,
          [SINKS]: { HTTP: xs.of(issueLinks(state).get) }
        })
      },
      typesResponse: {
        discriminator: jiraAuthenticationForProjectDiscriminator,
        value: state => ({
          intent: issueTypes(state).value,
          [SINKS]: { HTTP: xs.of(issueTypes(state).get) }
        })
      },
      receivePutResponse: {
        discriminator: state => state.backlogConfigurationId,
        value: state => backlogConfiguration(sources.apiHost + '/api')(sources.HTTP)(sources.projectKey)(state.backlogConfigurationId).putResponse
      }
    })
}

const sw = <T extends PropertyKey, U>(value: T, map: { [K in T]: (V: K) => U }) =>
  map[value](value)

const { set: setCloud } = Manager<State & { [jiraInstanceDiscriminator]: typeof jiraCloudInstanceType }>()
const { set: setServer } = Manager<State & { [jiraInstanceDiscriminator]: typeof jiraServerInstanceType }>()
const { set, setFor } = Manager<State>()
const { setCase: setParentLink } = Manager<JiraParentLinkInput>()


const ofType = discriminator('type')
const setHierarchy = setFor(f => f.hierarchyDefinition)
const setDefaultHierarchy = setHierarchy(ofType('default'))
const setCustomHierarchy = setHierarchy(ofType('custom'))

const setDescendantParentLink = (descendantIndex: number, parentLinkIndex: number) =>
  setCustomHierarchy(f => f.definedDescendants[descendantIndex].parentLinks[parentLinkIndex])

const setAutoParentLink = setParentLink(ofType('auto'))
const setIssueLinkParentLink = setParentLink(ofType('issueLink'))

const toJiraParentLink = (pl: JiraParentLinkInput) => matchBy('type')(pl, {
  auto: ({autoLinkIds}) => JiraParentLink.Auto({
    parentLinkId: autoLinkIds.parentLinkId ? fSome(JiraCustomFieldId.JiraCustomFieldId(autoLinkIds.parentLinkId)) : null,
    epicLinkId: autoLinkIds.epicLinkId ? fSome(JiraCustomFieldId.JiraCustomFieldId(autoLinkIds.epicLinkId)) : null,
    hierarchyLinkId: autoLinkIds.hierarchyLinkId ? fSome(JiraIssueLinkId.JiraIssueLinkId(autoLinkIds.hierarchyLinkId)) : null
  }),
  parentIssue: () => JiraParentLink.ParentIssue(),
  parentLink: pl => JiraParentLink.ParentLink(JiraCustomFieldId.JiraCustomFieldId(pl.customFieldId)),
  epicLink: pl => JiraParentLink.EpicLink(JiraCustomFieldId.JiraCustomFieldId(pl.customFieldId)),
  issueLink: il =>
    JiraParentLink.IssueLink(
      JiraIssueLinkId.JiraIssueLinkId(il.linkId), JiraIssueLinkDirection[il.linkDirection]())
})

const DEFAULT_PARENT_LINK_FIELD_NAME = 'Parent Link'
const DEFAULT_EPIC_LINK_FIELD_NAME = 'Epic Link'
const DEFAULT_HIERARCHY_LINK_NAME = 'Hierarchy'

const getDefaultParentLinkCustomFieldId =
  (state: State) => isLoaded(state.issueFields) ? value(state.issueFields).find(f => f.name === DEFAULT_PARENT_LINK_FIELD_NAME)?.id || '' : ''
const getDefaultEpicLinkCustomFieldId =
  (state: State) => isLoaded(state.issueFields) ? value(state.issueFields).find(f => f.name === DEFAULT_EPIC_LINK_FIELD_NAME)?.id || '' : ''
const getDefaultHierarchyIssueLinkId =
  (state: State) => isLoaded(state.issueLinks) ? value(state.issueLinks).find(f => f.name === DEFAULT_HIERARCHY_LINK_NAME)?.id || '' : ''

const setParentLinkType = (state: State, inputType: JiraParentLinkInput['type']) =>
  sw(inputType, {
    auto: (type): JiraParentLinkInput => ({
      type,
      autoLinkIds: {
        parentLinkId: getDefaultParentLinkCustomFieldId(state),
        epicLinkId: getDefaultEpicLinkCustomFieldId(state),
        hierarchyLinkId: getDefaultHierarchyIssueLinkId(state)
      }
    }),
    parentIssue: type => ({ type }),
    parentLink: type => ({
      type,
      customFieldId: getDefaultParentLinkCustomFieldId(state)
    }),
    epicLink: type => ({
      type,
      customFieldId: getDefaultEpicLinkCustomFieldId(state)
    }),
    issueLink: type => ({
      type,
      linkId: '',
      linkDirection: 'Outward' as const
    })
  })

const getDefaultAutoIds =
  (state: State): JiraAutoIdsInput => ({
    parentLinkId: getDefaultParentLinkCustomFieldId(state),
    epicLinkId: getDefaultEpicLinkCustomFieldId(state),
    hierarchyLinkId: getDefaultHierarchyIssueLinkId(state)
  })

const setDefaultHierarchyOnLoaded =
  (state: State): State =>
    mapOption(
      maybeValue(combineLoadables(state.issueFields, state.issueLinks)),
      () =>
        ({
          ...state,
          hierarchyDefinition: matchBy('type')(state.hierarchyDefinition, {
            custom: x => x,
            default: defaultDefinition => ({
              ...defaultDefinition,
              ...getDefaultAutoIds(state)
            })
          })
        }),
      K(state))

const forDefault = <TIntent extends any>(handler: (intent: TIntent) => (state: State) => State) => ( { [DEFAULT]: handler } )

const toMaybeJiraCustomFieldId = (id: string | null) => mapFOption(JiraCustomFieldId.JiraCustomFieldId)(asOption(id || null))

const model = (intents: ReturnType<typeof intent>, sources: Sources) =>
  dynamicCaseModel(sources.state.stream, jiraInstanceDiscriminator)(
    pageSinkTemplate,
    intents, {
      [INIT]: IGNORE,
      '#jira-host': forDefault(set(state => state.host)),
      '#jira-cloud-username': { [jiraCloudInstanceType]: v => setCloud(state => state.cloudUsername)(v).and(s => s.primKnowsSecret)(false) },
      '#jira-cloud-token': { [jiraCloudInstanceType]: v => setCloud(state => state.cloudToken)(v).and(s => s.primKnowsSecret)(false) },
      '#jira-server-token': { [jiraServerInstanceType]: v => setServer(state => state.serverToken)(v).and(s => s.primKnowsSecret)(false) },
      '#jira-project': forDefault(set(state => state.project)),
      selectCredentialType: forDefault(set(state => state.instanceType)),
      selectHierarchyType: {
        [DEFAULT]: hierarchyType =>
          set(state => state.hierarchyDefinition)((definition, state) =>
            definition.type === hierarchyType
              ? definition
              : hierarchyType === jiraDefaultHierarchyType
                ? {
                  type: jiraDefaultHierarchyType,
                  ...getDefaultAutoIds(state)
                }: {
                  type: jiraCustomHierarchyType,
                  topLevelTypeId: '',
                  definedDescendants: [],
                  rest: []
                })
      },
      '.save': {
        [DEFAULT]: {
          output: _ => state => ({
            HTTP: [
              backlogConfiguration(sources.apiHost + '/api')(sources.HTTP)(sources.projectKey)(state.backlogConfigurationId).put({
                case: 'JiraBacklogConfiguration',
                fields: [{
                  host: state.host,
                  project: state.project,
                  authenticationInfo:
                      state.instanceType === jiraCloudInstanceType
                        ? {
                          case: 'JiraCloudApiTokenAuthentication',
                          fields: [{
                            username: state.cloudUsername,
                            token: state.cloudToken
                          }]
                        }
                        : {
                          case: 'JiraServerPat',
                          fields: [{
                            token: state.serverToken
                          }]
                        },
                  hierarchyDefinition:
                    matchBy('type')(state.hierarchyDefinition, {
                      default: d => JiraHierarchyDefinition.Default({
                        parentLinkId: toMaybeJiraCustomFieldId(d.parentLinkId),
                        epicLinkId: toMaybeJiraCustomFieldId(d.epicLinkId),
                        hierarchyLinkId: mapFOption(JiraIssueLinkId.JiraIssueLinkId)(asOption(d.hierarchyLinkId))
                      }),
                      custom: c => JiraHierarchyDefinition.Custom({
                        topLevelTypeId: JiraTypeId.JiraTypeId(c.topLevelTypeId),
                        definedDescendants: c.definedDescendants.map(dd =>
                          Tuple(
                            JiraTypeId.JiraTypeId(dd.issueTypeId),
                            dd.parentLinks.map(toJiraParentLink))),
                        rest: c.rest.map(toJiraParentLink)
                      })
                    }),
                  mappingDefinition: {
                    storyPoints: toMaybeJiraCustomFieldId(state.mappingDefinition.storyPoints),
                    iteration: toMaybeJiraCustomFieldId(state.mappingDefinition.iteration)
                  }
                }]})
            ]
          })
        }
      },
      '#default-parent-link': forDefault(setDefaultHierarchy(f => f.parentLinkId)),
      '#default-epic-link': forDefault(setDefaultHierarchy(f => f.epicLinkId)),
      '#default-hierarchy-link': forDefault(setDefaultHierarchy(f => f.hierarchyLinkId)),
      '#custom-toplevel-type': forDefault(setCustomHierarchy(f => f.topLevelTypeId)),
      '.add-custom-descendant': forDefault(() => setCustomHierarchy(f => f.definedDescendants)(append({ issueTypeId: '', parentLinks: [{
        type: 'parentIssue'
      } as JiraParentLinkInput] } as JiraDescendantInput))),
      '.add-custom-descendant-parent-link': forDefault(({descendantIndex}) =>
        setCustomHierarchy(f => f.definedDescendants[+descendantIndex].parentLinks)(append({
          type: 'parentIssue'
        } as JiraParentLinkInput))),
      '.custom-descendant-type': forDefault(
        input => setCustomHierarchy(f => f.definedDescendants[+input.data.descendantIndex].issueTypeId)(input.value)),
      '.custom-descendant-parent-link-type': forDefault(
        input => state => setDescendantParentLink(+input.data.descendantIndex, +input.data.parentLinkIndex)(setParentLinkType(state, input.value))(state)),
      '.descendant-auto-parent-link': forDefault(
        input => setDescendantParentLink(+input.data.descendantIndex, +input.data.parentLinkIndex)(setAutoParentLink(pl => pl.autoLinkIds.parentLinkId)(input.value))),
      '.descendant-auto-epic-link': forDefault(
        input => setDescendantParentLink(+input.data.descendantIndex, +input.data.parentLinkIndex)(setAutoParentLink(pl => pl.autoLinkIds.epicLinkId)(input.value))),
      '.descendant-auto-hierarchy-link': forDefault(
        input => setDescendantParentLink(+input.data.descendantIndex, +input.data.parentLinkIndex)(setAutoParentLink(pl => pl.autoLinkIds.hierarchyLinkId)(input.value))),
      '.descendant-parent-link': forDefault(
        input => setDescendantParentLink(+input.data.descendantIndex, +input.data.parentLinkIndex)(setParentLink(ofType('parentLink'))(pl => pl.customFieldId)(input.value))),
      '.descendant-epic-link': forDefault(
        input => setDescendantParentLink(+input.data.descendantIndex, +input.data.parentLinkIndex)(setParentLink(ofType('epicLink'))(pl => pl.customFieldId)(input.value))),
      '.descendant-issue-link': forDefault(
        input => setDescendantParentLink(+input.data.descendantIndex, +input.data.parentLinkIndex)(setIssueLinkParentLink(pl => pl.linkId)(input.value))),
      '.descendant-issue-direction': forDefault(
        input => setDescendantParentLink(+input.data.descendantIndex, +input.data.parentLinkIndex)(setIssueLinkParentLink(pl => pl.linkDirection)(input.value))),
      '.remove-custom-descendant-parent-link': forDefault(
        input => setCustomHierarchy(f => f.definedDescendants[+input.descendantIndex].parentLinks)(pls => pls.length > 1 ? removeAt(+input.parentLinkIndex)(pls) : pls)),
      '.remove-custom-descendant': forDefault(
        input => setCustomHierarchy(f => f.definedDescendants)(removeAt(+input.descendantIndex))),
      '.add-custom-rest-parent-link': forDefault(() =>
        setCustomHierarchy(f => f.rest)(append({
          type: 'parentIssue'
        } as JiraParentLinkInput))),
      '.custom-rest-parent-link-type': forDefault(
        input => state => setCustomHierarchy(f => f.rest[+input.data.parentLinkIndex])(setParentLinkType(state, input.value))(state)),
      '.rest-auto-parent-link': forDefault(
        input => setCustomHierarchy(f => f.rest[+input.data.parentLinkIndex])(setAutoParentLink(pl => pl.autoLinkIds.parentLinkId)(input.value))),
      '.rest-auto-epic-link': forDefault(
        input => setCustomHierarchy(f => f.rest[+input.data.parentLinkIndex])(setAutoParentLink(pl => pl.autoLinkIds.epicLinkId)(input.value))),
      '.rest-auto-hierarchy-link': forDefault(
        input => setCustomHierarchy(f => f.rest[+input.data.parentLinkIndex])(setAutoParentLink(pl => pl.autoLinkIds.hierarchyLinkId)(input.value))),
      '.rest-parent-link': forDefault(
        input => setCustomHierarchy(f => f.rest[+input.data.parentLinkIndex])(setParentLink(ofType('parentLink'))(pl => pl.customFieldId)(input.value))),
      '.rest-epic-link': forDefault(
        input => setCustomHierarchy(f => f.rest[+input.data.parentLinkIndex])(setParentLink(ofType('epicLink'))(pl => pl.customFieldId)(input.value))),
      '.rest-issue-link': forDefault(
        input => setCustomHierarchy(f => f.rest[+input.data.parentLinkIndex])(setIssueLinkParentLink(pl => pl.linkId)(input.value))),
      '.rest-issue-direction': forDefault(
        input => setCustomHierarchy(f => f.rest[+input.data.parentLinkIndex])(setIssueLinkParentLink(pl => pl.linkDirection)(input.value))),
      '.remove-custom-rest-parent-link': forDefault(
        input => setCustomHierarchy(f => f.rest)(removeAt(+input.parentLinkIndex))),
      '#story-points-field': forDefault(set(state => state.mappingDefinition.storyPoints)),
      '#iteration-field': forDefault(set(state => state.mappingDefinition.iteration)),
      projectsResponse: forDefault(loadable => set(state => state.projects)(mapLoaded(loadable, projects => projects.map(p => ({ id: p.key, name: `${p.name} (${p.key})` }))))),
      fieldsResponse: forDefault(loadable => set(state => state.issueFields)(loadable).then(setDefaultHierarchyOnLoaded)),
      linksResponse: forDefault(loadable => set(state => state.issueLinks)(loadable).then(setDefaultHierarchyOnLoaded)),
      typesResponse: forDefault(loadable => set(state => state.issueTypes)(loadable).then(setDefaultHierarchyOnLoaded)),
      receivePutResponse: {
        [DEFAULT]: {
          state: set(state => state.saveResponse),
          output: handlePutBacklogConfigurationResponse(sources)
        }
      },
      [DERIVED]: IGNORE
    })

const FieldSelect = (props: Props<{ fields: { id: string; name: string }[]; selectedValue: string | null | undefined }>) =>
  <Select {...props}>
    { <option value={props.emptyValue ?? ''}>---</option> }
    {props.fields.map(f =>
      <option selected={props.selectedValue === f.id} value={f.id} key={f.id}>{f.name}</option>)}
  </Select>

type DescendantRow = readonly [descendant: JiraDescendantInput, descendantIndex: number, parentLink: JiraParentLinkInput, parentLinkIndex: number]
const descendantsToTable = (descendants: readonly JiraDescendantInput[]) =>
  flatten(descendants.map((d, di) =>
    d.parentLinks.map((pl, pli) => [d, di, pl, pli] as DescendantRow)))

const view = (state: State) =>
  <div>
    <div className="mb-3">
      <Label className="required" for="jira-host">Host</Label>
      <Input id="jira-host" value={state.host} aria-describedby="jira-host-help" />
      <small id="jira-host-help" className="form-text text-muted">
        The host of your organization's Jira instance. For example <samp>https://qframe.atlassian.net</samp>
      </small>
    </div>
    <ToggleButtonGroup className="mb-3">
      <ToggleButton
        key="cloud"
        id="jira-type-cloud-label"
        name="instance-type-option"
        value={jiraCloudInstanceType}
        active={state[jiraInstanceDiscriminator] === jiraCloudInstanceType}>
        Cloud
      </ToggleButton>
      <ToggleButton
        key="server"
        id="jira-type-server-label"
        name="instance-type-option"
        value={jiraServerInstanceType}
        active={state[jiraInstanceDiscriminator] === jiraServerInstanceType}>
        Data Center/server edition
      </ToggleButton>
    </ToggleButtonGroup>

    { state.instanceType === jiraCloudInstanceType
      ? [
        <div className="mb-3">
          <Label className="required" for="jira-cloud-username">User account</Label>
          <Input id="jira-cloud-username" value={state.cloudUsername} aria-describedby="jira-cloud-username-help" />
          <small id="jira-cloud-username-help" className="form-text text-muted">
            Your user account email address. For example <samp>john.doe@example.com</samp>
          </small>
        </div>,
        <div className="mb-3">
          <Label className="required" for="jira-cloud-token">Api token</Label>
          <Input type="password" id="jira-cloud-token" aria-describedby="jira-cloud-token-help" value={state.cloudToken} autocomplete="off" />
          <small id="jira-cloud-token-help" className="form-text text-muted">
            <a style={{textDecoration: 'underline'}} target="_blank" href="https://support.atlassian.com/atlassian-account/docs/manage-api-tokens-for-your-atlassian-account/">
              Learn how to create an api token for Jira cloud.
            </a>
          </small>
        </div>
      ]
      : <div className="mb-3">
        <Label className="required" for="jira-server-token">Personal access token</Label>
        <Input type="password" id="jira-server-token" aria-describedby="jira-server-organistokenation-help" value={state.serverToken} autocomplete="off" />
        <small id="jira-server-token-help" className="form-text text-muted">
          <a style={{textDecoration: 'underline'}} target="_blank" href="https://confluence.atlassian.com/enterprise/using-personal-access-tokens-1026032365.html">
          Learn how to create a personal access token for Jira Data Center and server edition.
          </a>
        </small>
      </div> }
    {renderLoadable(state.projects, projects =>
      <div className="mb-3">
        <Label className="required" for="jira-project">Project key</Label>

        <FieldSelect
          aria-describedby="jira-project-help"
          id="jira-project"
          fields={projects}
          selectedValue={state.project} />

        <small id="jira-project-help" className="form-text text-muted">
          The key of your project.
        </small>
      </div>)}

    {renderLoadable(combineLoadables(combineLoadables(state.issueTypes, state.issueFields), mapError(state.issueLinks, e => [e])), ([[types, fields], links]) =>
      <div className="mb-3">
        <h4>Hierarchy definition</h4>
        <ToggleButtonGroup className="mb-3">
          <ToggleButton
            key="default"
            id="jira-hierarchy-default-label"
            name="hierarchy-type-option"
            value={jiraDefaultHierarchyType}
            active={state.hierarchyDefinition.type === jiraDefaultHierarchyType}>
        Default
          </ToggleButton>
          <ToggleButton
            key="custom"
            id="jira-hierarchy-custom-label"
            name="hierarchy-type-option"
            value={jiraCustomHierarchyType}
            active={state.hierarchyDefinition.type === jiraCustomHierarchyType}>
        Custom
          </ToggleButton>
        </ToggleButtonGroup>
        <h5>Field mapping</h5>
        <Row>
          <Col width={1}><Label for="story-points-field">Story points</Label></Col>
          <Col Width={2}>
            <FieldSelect
              id="story-points-field"
              fields={fields}
              selectedValue={state.mappingDefinition.storyPoints} />
          </Col>
        </Row>
        <Row>
          <Col width={1}><Label for="iteration-field">Iteration</Label></Col>
          <Col Width={2}>
            <FieldSelect
              id="iteration-field"
              fields={fields}
              selectedValue={state.mappingDefinition.iteration} />
          </Col>
        </Row>
        {
          matchBy('type')(state.hierarchyDefinition, {
            default: hierarchy =>
              <div>
                <h5>Default hierarchy</h5>

                <div>
                  <label for="default-parent-link">Parent link:</label>
                  <FieldSelect
                    id="default-parent-link"
                    fields={fields}
                    selectedValue={hierarchy.parentLinkId} />
                  <label for="default-epic-link">Epic link:</label>
                  <FieldSelect
                    id="default-epic-link"
                    fields={fields}
                    selectedValue={hierarchy.epicLinkId} />
                  <label for="default-hierarchy-link">Hierarchy link:</label>
                  <div>
                    <FieldSelect
                      id="default-hierarchy-link"
                      fields={links}
                      selectedValue={hierarchy.hierarchyLinkId} />
                  </div>
                </div>
              </div>,
            custom: hierarchy =>
              <>
                <h5>Top level issue type</h5>
                <FieldSelect
                  id="custom-toplevel-type"
                  fields={types}
                  selectedValue={hierarchy.topLevelTypeId} />
                <h5 className="mt-2">Descendants</h5>
                <Row key="header">
                  <Col width={1}><strong>Type</strong></Col>
                  <Col width={1}><strong>Link type</strong></Col>
                  <Col width={3}><strong>Link parameters</strong></Col>
                  <Col width={2}></Col>
                </Row>
                {descendantsToTable(hierarchy.definedDescendants).map(([dd, descendantIndex, pl, parentLinkIndex], rowIndex) =>
                  <Row key={rowIndex} className={parentLinkIndex ? 'mt-2' : 'mt-4'}>
                    <Col width={1}>
                      { !parentLinkIndex
                        ? <FieldSelect className="custom-descendant-type" dataset={{descendantIndex: descendantIndex.toString()}} fields={types} selectedValue={dd.issueTypeId} />
                        : null}
                    </Col>
                    <Col width={1}>
                      <Select className="custom-descendant-parent-link-type" dataset={{descendantIndex: descendantIndex.toString(), parentLinkIndex: parentLinkIndex.toString()}}>
                        {parentLinkTypes.map(t =>
                          <option value={t} selected={pl.type === t} key={t}>{parentLinkTypeDescriptions[t]}</option>)}
                      </Select>
                    </Col>
                    <Col width={3}>
                      {matchBy('type')(pl, {
                        auto: ({ autoLinkIds }) =>
                          <>
                            <label for={`descendant-auto-parent-link-${descendantIndex}-${parentLinkIndex}`}>Parent link:</label>
                            <FieldSelect
                              id={`descendant-auto-parent-link-${descendantIndex}-${parentLinkIndex}`}
                              className="descendant-auto-parent-link"
                              dataset={{ descendantIndex: descendantIndex.toString(), parentLinkIndex: parentLinkIndex.toString() }}
                              fields={fields}
                              selectedValue={autoLinkIds.parentLinkId} />
                            <label for={`descendant-auto-epic-link-${descendantIndex}-${parentLinkIndex}`}>Epic link:</label>
                            <FieldSelect
                              id={`descendant-auto-epic-link-${descendantIndex}-${parentLinkIndex}`}
                              className="descendant-auto-epic-link"
                              dataset={{ descendantIndex: descendantIndex.toString(), parentLinkIndex: parentLinkIndex.toString() }}
                              fields={fields}
                              selectedValue={autoLinkIds.epicLinkId} />
                            <label for={`descendant-auto-hierarchy-link-${descendantIndex}-${parentLinkIndex}`}>Hierarchy link:</label>
                            <FieldSelect
                              id={`descendant-auto-hierarchy-link-${descendantIndex}-${parentLinkIndex}`}
                              className="descendant-auto-hierarchy-link"
                              dataset={{ descendantIndex: descendantIndex.toString(), parentLinkIndex: parentLinkIndex.toString() }}
                              fields={links}
                              selectedValue={autoLinkIds.hierarchyLinkId} />
                          </>,
                        parentIssue: () => null,
                        parentLink: ({ customFieldId }) =>
                          <FieldSelect
                            id={`descendant-parent-link-${descendantIndex}-${parentLinkIndex}`}
                            className="descendant-parent-link"
                            dataset={{ descendantIndex: descendantIndex.toString(), parentLinkIndex: parentLinkIndex.toString() }}
                            fields={fields}
                            selectedValue={customFieldId} />,
                        epicLink: ({ customFieldId }) =>
                          <FieldSelect
                            id={`descendant-epic-link-${descendantIndex}-${parentLinkIndex}`}
                            className="descendant-epic-link"
                            dataset={{ descendantIndex: descendantIndex.toString(), parentLinkIndex: parentLinkIndex.toString() }}
                            fields={fields}
                            selectedValue={customFieldId} />,
                        issueLink: ({ linkId, linkDirection }) =>
                          <>
                            <FieldSelect
                              id={`descendant-issue-link-${descendantIndex}-${parentLinkIndex}`}
                              className="descendant-issue-link"
                              dataset={{ descendantIndex: descendantIndex.toString(), parentLinkIndex: parentLinkIndex.toString() }}
                              fields={links}
                              selectedValue={linkId} />
                            <code>ChildIssue</code>
                            <Select
                              className="descendant-issue-direction"
                              dataset={{ descendantIndex: descendantIndex.toString(), parentLinkIndex: parentLinkIndex.toString() }}>
                              <option value="Outward" selected={linkDirection === 'Outward'}>{links.find(l => l.id === linkId)?.outward ?? 'Outward'}</option>
                              <option value="Inward" selected={linkDirection === 'Inward'}>{links.find(l => l.id === linkId)?.inward ?? 'Inward'}</option>
                            </Select>
                            <code>ParentIssue</code>
                          </>
                      })}
                    </Col>
                    <Col width={2}>
                      { !parentLinkIndex
                        ?
                        <>
                          <Button context='primary' className='add-custom-descendant-parent-link' dataset={{descendantIndex: descendantIndex.toString()}}>Add link</Button>
                          <Button context='danger' className='ms-2 remove-custom-descendant' dataset={{descendantIndex: descendantIndex.toString()}}>Remove child</Button>
                        </>
                        : <Button
                          context='danger'
                          className='remove-custom-descendant-parent-link'
                          dataset={{ descendantIndex: descendantIndex.toString(), parentLinkIndex: parentLinkIndex.toString() }}>
                              Remove link
                        </Button>}
                    </Col>

                  </Row>)}

                <Button context='primary' className='mt-2 add-custom-descendant'>Add child</Button>

                <h5 className="mt-2">Rest</h5>
                <Container>
                  <Row>
                    <Col width={1}><strong>Link type</strong></Col>
                    <Col width={3}><strong>Link parameters</strong></Col>
                    <Col width={1}></Col>
                  </Row>
                  {hierarchy.rest.map((pl, parentLinkIndex) =>
                    <Row key={parentLinkIndex} className={parentLinkIndex ? 'mt-2' : 'mt-4'}>
                      <Col width={1}>
                        <Select className="custom-rest-parent-link-type" dataset={{parentLinkIndex: parentLinkIndex.toString()}}>
                          {parentLinkTypes.map(t =>
                            <option value={t} selected={pl.type === t} key={t}>{parentLinkTypeDescriptions[t]}</option>)}
                        </Select>
                      </Col>
                      <Col width={3}>
                        {matchBy('type')(pl, {
                          auto: ({ autoLinkIds }) =>
                            <div>
                              <label for={`rest-auto-parent-link-${parentLinkIndex}`}>Parent link:</label>
                              <FieldSelect
                                id={`rest-auto-parent-link-${parentLinkIndex}`}
                                className="rest-auto-parent-link"
                                dataset={{ parentLinkIndex: parentLinkIndex.toString() }}
                                fields={fields}
                                selectedValue={autoLinkIds.parentLinkId} />
                              <label for={`rest-auto-epic-link-${parentLinkIndex}`}>Epic link:</label>
                              <FieldSelect
                                id={`rest-auto-epic-link-${parentLinkIndex}`}
                                className="rest-auto-epic-link"
                                dataset={{ parentLinkIndex: parentLinkIndex.toString() }}
                                fields={fields}
                                selectedValue={autoLinkIds.epicLinkId} />
                              <label for={`rest-auto-hierarchy-link-${parentLinkIndex}`}>Hierarchy link:</label>
                              <FieldSelect
                                id={`rest-auto-hierarchy-link--${parentLinkIndex}`}
                                className="rest-auto-hierarchy-link"
                                dataset={{ parentLinkIndex: parentLinkIndex.toString() }}
                                fields={links}
                                selectedValue={autoLinkIds.hierarchyLinkId} />
                            </div>,
                          parentIssue: () => null,
                          parentLink: ({ customFieldId }) =>
                            <FieldSelect
                              id={`rest-parent-link-${parentLinkIndex}`}
                              className="rest-parent-link"
                              dataset={{ parentLinkIndex: parentLinkIndex.toString() }}
                              fields={fields}
                              selectedValue={customFieldId} />,
                          epicLink: ({ customFieldId }) =>
                            <FieldSelect
                              id={`rest-epic-link-${parentLinkIndex}`}
                              className="rest-epic-link"
                              dataset={{ parentLinkIndex: parentLinkIndex.toString() }}
                              fields={fields}
                              selectedValue={customFieldId} />,
                          issueLink: ({ linkId, linkDirection }) => <div>
                            <FieldSelect
                              id={`rest-issue-link-${parentLinkIndex}`}
                              className="rest-issue-link"
                              dataset={{ parentLinkIndex: parentLinkIndex.toString() }}
                              fields={links}
                              selectedValue={linkId} />
                            <code>ChildIssue</code>
                            <Select
                              className="rest-issue-direction"
                              dataset={{ parentLinkIndex: parentLinkIndex.toString() }}>
                              <option value="Outward" selected={linkDirection === 'Outward'}>{links.find(l => l.id === linkId)?.outward ?? 'Outward'}</option>
                              <option value="Inward" selected={linkDirection === 'Inward'}>{links.find(l => l.id === linkId)?.inward ?? 'Inward'}</option>
                            </Select>
                            <code>ParentIssue</code>
                          </div>
                        })}
                      </Col>
                      <Col width={2}>
                        <Button context='danger' className='remove-custom-rest-parent-link' dataset={{ parentLinkIndex: parentLinkIndex.toString() }}>Remove rest link</Button>
                      </Col>
                    </Row>
                  )}

                </Container>
                <Button context='primary' className='mt-2 add-custom-rest-parent-link'>Add rest link</Button>
              </>
          })
        }
      </div>
    )}
    <Button context="primary" className="save">Save</Button>
    {renderLoadable(state.saveResponse, {
      loaded: () => null,
      error: problem => <Danger className="mt-3">{renderConfigurationResponse(setBacklogConfigurationProblemRenderConfig)(problem)}</Danger>
    })}
  </div>

export const JiraConfiguration = (sources: Sources) => ({
  ...model(intent(sources), sources),
  DOM: sources.state.stream.map(view)
})
