import { Attrs, h, VNode, VNodeData } from 'snabbdom'
import { deepFlatten } from './generic'

export type JSXComponent = (props: VNodeData, children: any[]) => VNode

const buildAttrs = (props: VNodeData) =>
  ({ ...props,
    value: undefined,
    checked: undefined,
    selected: undefined,
    className: undefined,
    attrs: undefined,
    class: undefined,
    style: undefined,
    dataset: undefined,
    on: undefined,
    attachData: undefined,
    hook: undefined,
    key: undefined,
    ns: undefined,
    fn: undefined,
    args: undefined,
    is: undefined,
    ...props.attrs } as unknown as Attrs)

const buildProps = (props: VNodeData) =>
  ({ value: props.value,
    checked: props.checked,
    selected: props.selected,
    ...props.props })

export const vnodeProps = (props: VNodeData | null): VNodeData => {
  return {
    ...props,
    props:
      props
        ? buildProps(props)
        : undefined,
    attrs:
      props
        ? buildAttrs(props)
        : undefined,
    class:
        props?.class
        ?? (props?.className
          ? Object.fromEntries((props.className as string).split(' ').map(x => [x, true]))
          : undefined),
    key: props?.key ?? props?.id ?? undefined
  }
}

export const jsx = (sel: string | JSXComponent, props: VNodeData, ...children: any[]) =>
  typeof sel === 'string' ? h(sel, vnodeProps(props), deepFlatten(children) as any) : sel(props || {}, children)

jsx.Fragment = (_: any, ...children: any[]) => children
