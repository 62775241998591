import { ErrorOrFailure, MyResponse, errorMessageOrResponseText } from '@/dataServices'
import { isArray, mapObj } from '@/generic'
import { RequestError } from '../model'

const localDateRegex = /^\d{4}-\d{2}-\d{2}$/
const zonedDateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:(?:\+\d{2}:\d{2}|Z)|\.000Z)?$/

/** Deep conversion of an object. Parses date strings into dates. */
export const simpleTranslate = <T>(obj: T): T =>
  obj
    ? isArray(obj)
      ? obj.map((x: any) => simpleTranslate(x))
      : typeof obj === 'object'
        ? obj instanceof Date
          ? obj
          : mapObj(obj as any, simpleTranslate)
        : typeof obj === 'string'
          ? localDateRegex.test(obj)
            ? new Date(obj)
            : zonedDateTimeRegex.test(obj)
              ? new Date(obj)
              : obj
          : obj
    : obj as any


export const resultOrError = <T>(response: MyResponse<any, any>) =>
  typeof response.body === 'object' ? simpleTranslate(response.body as T) : RequestError.RequestError(response.text)

export const simpleResult = <T>(response: MyResponse<any, any>) =>
  simpleTranslate<T>(response.body as T)

export const handleError = (response: ErrorOrFailure<any, any>) =>
  RequestError.RequestError(errorMessageOrResponseText(response))
