import { jsx } from 'h'
import { dynamicModel, pageSinkTemplate, IGNORE, INIT, DERIVED } from '../../../infrastructure'
import { isLoaded, isLoadError, loadErrorMessage, Manager, mergeSinks, removeAt, Unloaded } from '../../../generic'
import format from 'date-fns/format'
import { Button, capture, click, clickWithIndex, cn, Col, Container, dateInput, dateInputFormat, events, Icon, indexedDate, indexedValue, Input, inputs, Row } from '../../../ui'
import { isLevel1OrHigher, Sprint, SprintProblem } from '../../model'
import { ProjectPageSources } from '../common'
import { ConfigurationContext, InputSprint, SprintsViewState } from './viewModel'
import { enumerate, renderProblem, responseRenderConfig } from '../renderers'
import { formatLocalDate } from '../../../util/date'
import { caseValue } from '@/fsharp'

const emptyNewItem: SprintsViewState['newItem'] = {
  name: '',
  from: null,
  until: null
}

const emptyViewState = (context: ConfigurationContext): SprintsViewState => ({
  sprints: [],
  newItem: emptyNewItem,
  putSprintConfigurationResponse: Unloaded,
  context: context,
  unknownResponseError: null
})

export const sprintsViewState = (context: ConfigurationContext): SprintsViewState => {
  if (!isLevel1OrHigher(context.configuration.levelConfiguration)) {
    return emptyViewState(context)
  }

  return {
    ...emptyViewState(context),
    sprints: context.configuration.levelConfiguration.fields[0].sprints.map(makeInputSprint)
  }
}

const makeInputSprint = (sprintLike: Sprint): InputSprint => ({
  name: sprintLike.name,
  from: sprintLike.from,
  until: sprintLike.until,
  problems: {  badFrom: false,
    badName: false,
    badUntil: false,
    duplicateSprintName: false,
    overlappingSprintNames: []
  }})

const stripErrors = makeInputSprint

type SprintSources = ProjectPageSources<SprintsViewState>

const intent = (sources: SprintSources) => ({
  ...capture(
    inputs({
      '.new-sprint-name': null,
      '.new-sprint-from': dateInput,
      '.new-sprint-until': dateInput
    }),
    events({
      '.add-new-sprint': click,
      '.save-sprints': click,
      '.delete-sprint': clickWithIndex,
      '.sprint-name': indexedValue,
      '.sprint-from': indexedDate,
      '.sprint-until': indexedDate
    })
  )(sources),
  putSprintResponse: sources.primState.sprintsConfiguration(sources.projectKey).putResponse
})

const { set } = Manager<SprintsViewState>()

const model = (intents: ReturnType<typeof intent>, sources: SprintSources) =>
  dynamicModel(sources.state.stream)(
    pageSinkTemplate,
    intents,
    {
      [INIT]: IGNORE,
      '.add-new-sprint': _ => state => {
        if (!state.newItem.from || !state.newItem.until || !state.newItem.name) {
          return state
        }

        const newSprint: InputSprint = makeInputSprint({
          name: state.newItem.name,
          from: state.newItem.from,
          until: state.newItem.until,
        })

        return {
          ...state,
          newItem: emptyNewItem,
          sprints: [...state.sprints, newSprint]
        }
      },
      '.delete-sprint': index => set(state => state.sprints)(removeAt(index)),
      '.new-sprint-name': set(state => state.newItem.name),
      '.new-sprint-from': set(state => state.newItem.from),
      '.new-sprint-until': set(state => state.newItem.until),
      '.sprint-name': ({ index, value }) => set(state => state.sprints[index])(sprint =>
        ({
          ...sprint,
          name: value,
          problems: {
            ...sprint.problems,
            badName: false,
            duplicateSprintName: false
          }
        })),
      '.sprint-from': ({ index, value }) => set(state => state.sprints[index])(sprint =>
        ({
          ...sprint,
          from: value || sprint.from,
          problems: {
            ...sprint.problems,
            badFrom: false,
            overlappingSprintNames: []
          }
        })),
      '.sprint-until': ({ index, value }) => set(state => state.sprints[index])(sprint =>
        ({
          ...sprint,
          until: value || sprint.until,
          problems: {
            ...sprint.problems,
            badUntil: false,
            overlappingSprintNames: []
          }
        })),
      '.save-sprints': {
        output: _ => state => ({
          HTTP: [sources.primState.sprintsConfiguration(sources.projectKey).put(state.sprints.map(viewSprint => ({
            ...viewSprint,
            from: formatLocalDate(viewSprint.from),
            until: formatLocalDate(viewSprint.until)
          })))]
        })
      },
      putSprintResponse: {
        state: response =>
          set(state => state.putSprintConfigurationResponse)(response)
            .and(state => state)(state => {
              if (!isLoadError(state.putSprintConfigurationResponse)) {
                return {
                  ...state,
                  unknownResponseError: null,
                  sprints: state.sprints.map(stripErrors)
                }
              }

              const loadProblem = loadErrorMessage(state.putSprintConfigurationResponse)

              if (loadProblem.case !== 'ConfigurationProblem') {
                return {
                  ...state,
                  unknownResponseError: loadProblem
                }
              } else {
                const sprintProblems = caseValue(loadProblem)
                return {
                  ...state,
                  sprints:
                    state.sprints
                      .map((sprint, index) => {
                        const problems = sprintProblems.filter(({ fields: [i] }) => i === index)
                        const badFields = problems.find((p): p is SprintProblem & { case: 'BadSprintField'} => p.case === 'BadSprintField')
                        const badName = !!(badFields && badFields.fields[1].includes('name'))
                        const badFrom = !!(badFields && badFields.fields[1].includes('from date'))
                        const badUntil = !!(badFields && badFields.fields[1].includes('until date'))
                        const duplicateSprintName = !!(problems.find((p): p is SprintProblem & { case: 'DuplicateSprintName'} => p.case === 'DuplicateSprintName'))
                        const overlappingSprints = problems.find((p): p is SprintProblem & { case: 'SprintOverlap'} => p.case === 'SprintOverlap')?.fields[1]
                        const overlappingSprintNames = state.sprints.filter((s, i) => overlappingSprints?.includes(i)).map(s => s.name)

                        return {
                          ...sprint,
                          problems: {
                            badName: badName,
                            badFrom: badFrom,
                            badUntil: badUntil,
                            duplicateSprintName: duplicateSprintName,
                            overlappingSprintNames: overlappingSprintNames
                          }
                        }
                      })
                }
              }
            }),
        output: response => _state => ({
          HTTP: isLoaded(response) ? [sources.primState.projectConfiguration(sources.projectKey).refresh] : []
        })
      },
      [DERIVED]: IGNORE
    })

const sprintOverlapMessage = (sprints: string[]) =>
  `This sprint overlaps with sprint${sprints.length === 1 ? '' : 's'} ${enumerate(sprints)}`

function view(state: SprintsViewState) {
  return <div>
    <Container>
      <Row className="mb-2">
        <Col><label className='required'>Name</label></Col>
        <Col><label className='required'>From</label></Col>
        <Col><label className='required'>Until</label></Col>
        <Col></Col>
      </Row>
      {state.sprints.map((sprint, i) => {
        return <Row className="mb-2">
          <Col>
            <Input
              value={sprint.name}
              className={cn('sprint-name', {'is-invalid': sprint.problems.badName || sprint.problems.duplicateSprintName})}
              aria-describedby={`sprint-name-feedback-${i}`}
              data-index={i.toString()} />
            <div id={`sprint-name-feedback-${i}`} className="invalid-feedback mb-1">
              {sprint.problems.badName ? 'A sprint name is required.' : 'Sprint names must be unique.'}
            </div>
          </Col>
          <Col>
            <Input
              type="date"
              value={format(sprint.from, dateInputFormat)}
              className={cn('sprint-from', {'is-invalid': sprint.problems.badFrom || sprint.problems.overlappingSprintNames.length })}
              aria-describedby={`sprint-from-feedback-${i}`}
              data-index={i.toString()} />
            <div id={`sprint-from-feedback-${i}`} className="invalid-feedback mb-1">
              { sprint.problems.overlappingSprintNames.length
                ? sprintOverlapMessage(sprint.problems.overlappingSprintNames)
                : 'A from date is required.' }
            </div>
          </Col>
          <Col>
            <Input
              type="date"
              value={format(sprint.until, dateInputFormat)}
              className={cn('sprint-until', {'is-invalid': sprint.problems.badUntil || sprint.problems.overlappingSprintNames.length })}
              aria-describedby={`sprint-until-feedback-${i}`}
              data-index={i.toString()} />
            <div id={`sprint-until-feedback-${i}`} className="invalid-feedback mb-1">
              { sprint.problems.overlappingSprintNames.length
                ? sprintOverlapMessage(sprint.problems.overlappingSprintNames)
                : 'A until date is required and must be after the from date.' }
            </div>
          </Col>
          <Col><Button context="danger" className="delete-sprint" data-index={i.toString()}><Icon icon="x" /></Button></Col>
        </Row>
      }
      )}
      <Row>
        <Col><Input value={state.newItem.name ? state.newItem.name : ''} className="new-sprint-name" /></Col>
        <Col><Input type="date" value={state.newItem.from ? format(state.newItem.from, dateInputFormat) : ''} className="new-sprint-from" /></Col>
        <Col><Input type="date" value={state.newItem.until ? format(state.newItem.until, dateInputFormat) : ''} className="new-sprint-until" /></Col>
        <Col><Button context="success" className="add-new-sprint"><Icon icon="plus" /></Button></Col>
      </Row>
      <Row>
        <Col>
          <Button context="primary" className="save-sprints mt-2 mb-3">Save</Button>
          { state.unknownResponseError
            ? renderProblem(responseRenderConfig({}))(state.unknownResponseError)
            : null }
        </Col>
      </Row>
    </Container>
  </div>
}

export const SprintsConfigurationComponent = (sources: SprintSources) => {
  const sinks = model(intent(sources), sources)

  return mergeSinks(sinks, {
    DOM: sources.state.stream.map(view)
  })
}
