import isolate from '@cycle/isolate'
import xs from 'xstream'
import { receiveLatest, toLoadable } from '../../../../../dataServices'
import { apply, C, deselect, flatten, id, isLoaded, isLoadError, isLoading, isSelectable, isSelected, isToggleable, isUnloaded, isUntoggleable, Loadable, loaded, loading, Manager, mapLoaded, maybeParse, mergeSinks, select, selectable, StreamType, toggleable, Unloaded, untoggleable, value } from '../../../../../generic'
import { jsx } from 'h'
import { DERIVED, dynamicIntent, dynamicModel, IGNORE, INIT, NOTHING, pageSinkTemplate, SINKS } from '../../../../../infrastructure'
import { Button, capture, click, cn, Danger, events, Info, Input, input, inputs, inputTarget, Renderable, renderLoadable, Success } from '../../../../../ui'
import { RedmineViaClientBacklogConfiguration } from '../../../../model'
import { backlogConfiguration } from '../../../../resources'
import { MAX_LIMIT, offsets, RedmineIssuesResponse, redmineIssuesViaClientResource, redmineProjectIssuesCountResource, uploadRedmineIssuesViaClientResource } from '../../../../resources/redmineViaClient'
import { ProjectPageSources } from '../../../common'
import { RedmineViaClientConfigurationViewState, ConfigurationContext, RedmineConfigurationProject, ProjectProgress, emptyRedminConfigurationProjectProgress, ProjectCountInfo} from '../../viewModel'
import { CredentialsState, initialCredentialsState, RedmineCredentials, States } from './credentials'
import { Credentials } from './types'
import { handlePutBacklogConfigurationResponse } from '../model'

type State = RedmineViaClientConfigurationViewState

const projects: RedmineConfigurationProject[] = [
  {
    name: 'NHS',
    id: 76,
    actualProjectIdsReturnedByRedmine: [76]
  },
  {
    name: 'NHS OLD',
    id: 34,
    actualProjectIdsReturnedByRedmine: [34]
  },
  {
    name: 'HPW OLD',
    id: 8,
    actualProjectIdsReturnedByRedmine: [8, 13]
  },
  {
    name: 'Nuget en ServiceBus (VONET)',
    id: 31,
    actualProjectIdsReturnedByRedmine: [31]
  },
  {
    name: 'Inkomensbevraging',
    id: 56,
    actualProjectIdsReturnedByRedmine: [56, 57]
  }
]

export const redmineViaClientConfigurationViewState = (
  context: ConfigurationContext,
  backlogConfigurationId: string,
  redmineViaClientConfig: RedmineViaClientBacklogConfiguration | null): State => {

  return {
    context: context,
    backlogConfigurationId: backlogConfigurationId,
    primKnowsConfig: redmineViaClientConfig != null,
    redmineApiUrl: redmineViaClientConfig?.apiUrl ?? '',
    saveResponse: Unloaded,
    sync: {
      totalCount: Unloaded,
      countQueriesPending: 0,
      projectCountResults: [],
      projectIssues: [],
      percentageIssuesLoaded: 0,
      credentials: initialCredentialsState,
      projectProgress: [],
      showProgressDetails: true,
      syncing: false,
      canSync: false,
      allIssuesLoaded: false,
      uploadResponse: Unloaded,
      availableProjects: projects.map(project => selectable(project, false, true))
    }
  }
}

const sumAllIssues = (s: State) => s.sync.projectIssues.reduce((t, i) => t + i.issues.issues.length, 0)

export const issuesCategory = (projectId: number) => `issues[${projectId}]`
export const countCategory = (projectId: number) => `count[${projectId}]`

type Sources = ProjectPageSources<State>

const selectedProjectsStorageKey = 'selected-projects'

const intent = ({ DOM, HTTP, storage, apiHost, projectKey }: Sources) => {
  const storedSelectedProjects = storage.local.getItem<string | null>(selectedProjectsStorageKey).take(1).map(maybeParse<number[]>([]))

  return dynamicIntent<State>()(
    pageSinkTemplate,
    {
      ...capture(
        inputs({
          '#redmine-api-url': null,
        }),
        events({
          '#sync-button': click,
          '#toggle-details': click,
          '.save-configuration': click,
          '.available-project': C(input, x => x.map(inputTarget).map(t => [+t.value, t.checked] as [number, boolean]))
        }))({DOM}),
      totalResponse: {
        discriminator: s => s.sync.syncing && s.sync.credentials.case === States.Display ? s.sync.credentials.credentials : null,
        value: (s, d: Credentials | null) => {
          if (!d) {
            return xs.never<Loadable<readonly [RedmineConfigurationProject, RedmineIssuesResponse]>[]>()
          }

          return xs.combine(
            ...selectedProjects(s)
              .map(p => redmineProjectIssuesCountResource(HTTP)(s.redmineApiUrl)(d)(p.id)
                .value
                .map(loadable => mapLoaded(loadable, response => [p, response] as const))))
        }
      },
      issuesResponse: {
        discriminator: s =>
          s.sync.syncing
          && isLoaded(s.sync.totalCount)
          && s.sync.credentials.case === States.Display
            ? s.sync.credentials.credentials
            : null,
        value: (s, d: Credentials | null) => {
          if (!d) {
            return xs.never<Loadable<RedmineIssuesResponse & { project: RedmineConfigurationProject }>[]>()
          }

          const resources = flatten(
            s.sync.projectCountResults.map(({ project, totalCount }) =>
              offsets(totalCount, MAX_LIMIT)
                .map(offset => ({...redmineIssuesViaClientResource(HTTP)(s.redmineApiUrl)(d)(project.id, offset, MAX_LIMIT, totalCount), project: project }) )))

          return {
            intent: xs.combine(...resources.map(r => r.value.map(l => mapLoaded(l, v => ({...v, project: r.project }))))),
            [SINKS]: {
              HTTP: xs.of(...resources.map(r => r.get))
            }
          }
        }
      },
      uploadResponse: uploadRedmineIssuesViaClientResource(HTTP)(apiHost)(projectKey).putResponse,
      loadSelectedProjects: storedSelectedProjects,
      processUploadResponse: toLoadable(receiveLatest(HTTP, 'upload-issues'), id),
      receivePutResponse: {
        discriminator: state => state.backlogConfigurationId,
        value: state => backlogConfiguration(apiHost + '/api')(HTTP)(projectKey)(state.backlogConfigurationId).putResponse
      }
    })
}

const { set, get } = Manager<State>()

const selectedProjects = get(s => s.sync.availableProjects.filter(isSelected).map(sp => sp.value))

const setCanSync = set(s => s.sync.canSync)((_, s) =>
  !s.sync.syncing
  && !!s.redmineApiUrl
  && s.sync.credentials.case === States.Display
  && s.sync.credentials.credentials.credentialsPresent
  && s.sync.availableProjects.filter(isSelected).length > 0)

const setProjectSelectionToggleable = set(s => s.sync.availableProjects)((pss, { sync: { syncing } }) =>
  pss.map(ps => (!syncing ? (isToggleable(ps) ? ps : toggleable(ps)) : isToggleable(ps) ? untoggleable(ps) : ps)))

const updateAvailableProjects = ([projectId, selected]: StreamType<ReturnType<ReturnType<typeof intent>>['intent']['.available-project']>) =>
  set(s => s.sync.availableProjects.find(sp => sp.value.id === projectId))(sp =>
    (!sp || selected === isSelected(sp) || isUntoggleable(sp) ? sp : !isSelected(sp) ? select(sp) : deselect(sp)))

const model = (intents: ReturnType<typeof intent>, sources: Sources) =>
  dynamicModel(sources.state.stream)(
    pageSinkTemplate,
    intents, {
      [INIT]: IGNORE,
      '#redmine-api-url': set(s => s.redmineApiUrl),
      totalResponse: totalResponses => {
        const maybeError = totalResponses.find(isLoadError)
        if (maybeError) {
          return set(s => s.sync.totalCount)(maybeError)
            .and(s => s.sync.syncing)(false)
        }
        const countResults =
          totalResponses
            .filter(isLoaded)
            .map(value)
            .map(([project, response]): ProjectCountInfo =>
              ({ project, totalCount: response.total_count }))

        return set(s => s.sync.projectCountResults)(countResults)
          .and(s => s.sync.projectProgress)((progresses, state) =>
            progresses.map((progress): typeof progress => {
              const maybeCountResult = state.sync.projectCountResults.find(r => r.project.id === progress.project.id)
              if (!maybeCountResult) {
                return progress
              }
              return {...progress, totalCount: maybeCountResult.totalCount}
            }))
          .and(s => s.sync.totalCount)(t =>
            countResults.length === totalResponses.length ? loaded(countResults.reduce((t, r) => t + r.totalCount, 0)) : loading(t))
      },
      issuesResponse: {
        state: issueResponses => {
          const loadedIssues = issueResponses.filter(isLoaded).map(value)

          const projectProgress =
            (progress: ProjectProgress[]) =>
              progress.map(p => {
                const received = loadedIssues.filter(i => i.project.id === p.project.id).reduce((t, i) => t + i.issues.length, 0)
                return ({
                  ...p,
                  received: received,
                  percentageReceived: 100 * received / p.totalCount
                })
              })

          return set(s => s.sync.projectIssues)(loadedIssues.map(r => ({issues: r, project: r.project })))
            .and(s => s.sync.projectProgress)(projectProgress)
            .and(s => s.sync.allIssuesLoaded)(loadedIssues.length === issueResponses.length)
        },
        output: responses => state => {
          if (!responses.every(isLoaded)) {
            return {}
          }

          return ({
            HTTP: [uploadRedmineIssuesViaClientResource(sources.HTTP)(sources.apiHost)(sources.projectKey).put({
              projectIds: selectedProjects(state).map(p => p.id),
              issues: flatten(responses.filter(isLoaded).map(value).map(r => r.issues))
            })]
          })
        }
      },
      uploadResponse: uploadResponse =>
        set(s => s.sync.uploadResponse)(uploadResponse)
          .and(s => s.sync.syncing)(!isLoaded(uploadResponse) && !isUnloaded(uploadResponse) && !isLoadError(uploadResponse)),
      loadSelectedProjects: selectedProjectIds =>
        set(s => s.sync.availableProjects)(sps => sps.map(sp => (selectedProjectIds.includes(sp.value.id) && isSelectable(sp) ? select(sp) : sp))),
      '#toggle-details': () => set(s => s.sync.showProgressDetails)(showProgressDetails => !showProgressDetails),
      '#sync-button': {
        state: () =>
          set(s => s.sync.syncing)(true)
            .and(s => s.sync.uploadResponse)(Unloaded)
            .and(s => s.sync.totalCount)(Unloaded)
            .and(s => s.sync.projectIssues)([])
            .and(s => s.sync.projectCountResults)([])
            .and(s => s.sync.percentageIssuesLoaded)(0)
            .and(s => s.sync.projectProgress)((_, s) => selectedProjects(s).map(emptyRedminConfigurationProjectProgress)),
        // .and(s => s.sync.countQueriesPending)((_, s) => selectedProjects(s).length),
        // good case where the bootstrap animation still breaks
        // the shown element is removed (because totalCount unloaded) but the show-state can stay in 'true'
        // when the element gets re-rendered (because totalCount loaded) it will enter rendered hidden
        // .and(s => s.showProgressDetails)(false)}
        output: _ => state => {
          const credentials = state.sync.credentials.case !== States.Loading ? state.sync.credentials.credentials : null
          if (!credentials) {
            return NOTHING
          }

          return ({
            HTTP: selectedProjects(state).map(({ id }) => redmineProjectIssuesCountResource(sources.HTTP)(state.redmineApiUrl)(credentials)(id).get)
          })
        }
      },
      processUploadResponse: response =>
        set(s => s.sync.uploadResponse)(response)
          .and(s => s.sync.syncing)((_, s) => !isLoaded(s.sync.uploadResponse) && !isUnloaded(s.sync.uploadResponse) && !isLoadError(s.sync.uploadResponse)),
      '.available-project': {
        state: updateAvailableProjects,
        output: input => s => {
          const updated = updateAvailableProjects(input)(s)
          const serializedProjects = JSON.stringify(updated.sync.availableProjects.filter(isSelected).map(sp => sp.value.id))

          return ({
            storage: [{
              key: selectedProjectsStorageKey,
              value: serializedProjects
            }]
          })
        }
      },
      '.save-configuration': {
        output: () => state => ({
          HTTP: [
            backlogConfiguration(sources.apiHost + '/api')(sources.HTTP)(sources.projectKey)(state.backlogConfigurationId).put({
              case: 'RedmineViaClientBacklogConfiguration',
              fields: [{
                apiUrl: state.redmineApiUrl
              }]
            })
          ]
        })
      },
      receivePutResponse: {
        state: response => set(s => s.saveResponse)(response).and(s => s.primKnowsConfig)(b => isUnloaded(response) ? b : isLoaded(response)),
        output: handlePutBacklogConfigurationResponse(sources)
      },
      [DERIVED]: set(s => s.sync.percentageIssuesLoaded)((_, s) => (!isLoaded(s.sync.totalCount) ? 0 : (100 * sumAllIssues(s)) / value(s.sync.totalCount)))
        .and(s => s.sync.allIssuesLoaded)((_, s) => isLoaded(s.sync.totalCount) && s.sync.projectProgress.every(p => p.received === p.totalCount))
        .then(setCanSync)
        .then(setProjectSelectionToggleable)
    })

const view = (state: State, credentials: Renderable) =>
  <div>
    { !state.sync.syncing
      ?
      <>
        <div>
          <label for="redmine-api-url">Redmine API root</label>
          <Input id="redmine-api-url" value={state.redmineApiUrl} aria-describedby="redmine-api-url-help" />
          <small id="redmine-api-url-help" className="form-text text-muted">Redmine's api root. e.g. https://puskas.vmsw.vonet.be</small>

        </div>
        <Button context='primary' className='save-configuration mt-2 mb-2' disabled={isLoading(state.saveResponse) || !state.redmineApiUrl}>Save API root</Button>
        {state.primKnowsConfig ? credentials : null}

      </>
      : null
    }

    {state.primKnowsConfig && state.sync.credentials.case === States.Display && state.sync.credentials.credentials.credentialsPresent
      ? <div>
        <div>
          {state.sync.syncing ? (
            ''
          ) : (
            <div>
              <h3>Select projects to sync</h3>
              {state.sync.availableProjects.map(sp => (
                <div className="form-check">
                  <input
                    className="form-check-input available-project"
                    type="checkbox"
                    value={sp.value.id}
                    id={`defaultCheck-${sp.value.id}`}
                    checked={isSelected(sp)}
                    disabled={isUntoggleable(sp)} />
                  <label className="form-check-label" for={`defaultCheck-${sp.value.id}`}>
                    {sp.value.name}
                  </label>
                </div>
              ))}
            </div>
          )}
        </div>
        {renderLoadable(state.sync.totalCount, {
          unloaded: () => <span />,
          loading: () => (
            <div>
              <h3>Progress</h3>
              <Info>Connecting...</Info>
            </div>
          ),
          error: () => (
            <div>
              <h3>Progress</h3>
              <Danger>Failed to connect. Make sure your VPN is active.</Danger>
            </div>
          ),
          loaded: totalCount => (
            <div>
              <h3>Progress</h3>
              <div className="mb-3">
              Total ({sumAllIssues(state)}/{totalCount})
                <div className="progress">
                  <div
                    className={cn('progress-bar', {
                      'bg-success': isLoading(state.sync.uploadResponse) || isLoaded(state.sync.uploadResponse),
                      'bg-danger': isLoadError(state.sync.uploadResponse),
                      'progress-bar-animated': state.sync.percentageIssuesLoaded !== 100 || isLoading(state.sync.uploadResponse),
                      'progress-bar-striped': state.sync.percentageIssuesLoaded !== 100 || isLoading(state.sync.uploadResponse)
                    })}
                    role="progressbar"
                    aria-valuenow={state.sync.percentageIssuesLoaded}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: state.sync.percentageIssuesLoaded + '%' }}
                  />
                </div>
                <div id="issue-progress-details" className={cn('collapse', { show: state.sync.showProgressDetails })}>
                  {!state.sync.showProgressDetails
                    ? null
                    : state.sync.projectProgress.map(progress => (
                      <div>
                        {progress.project.name} ({progress.received}/{progress.totalCount})
                        <div className="progress">
                          <div
                            className={cn('progress-bar bg-info', {
                              'progress-bar-animated': progress.percentageReceived !== 100,
                              'progress-bar-striped': progress.percentageReceived !== 100
                            })}
                            role="progressbar"
                            aria-valuenow={progress.percentageReceived}
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: progress.percentageReceived + '%' }}
                          />
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              {renderLoadable(state.sync.uploadResponse, {
                unloaded: () => <Info>Downloading...</Info>,
                loading: () => <Info>Uploading...</Info>,
                loaded: (_, stale) => stale ? <Info>Uploading...</Info> : <Success>Issues uploaded</Success>,
                error: e => <Danger>Failed to upload issues{e ? `: ${e}` : ''}</Danger>
              })}
            </div>
          )
        })}
        <div>
          <Button context="primary" id="sync-button" disabled={!state.sync.canSync} className="me-3">
          Sync
          </Button>
        </div>
      </div>
      : null }
  </div>

export const RedmineViaClientConfiguration = (sources: Sources) => {
  const credentials = isolate(
    RedmineCredentials,
    {
      '*': 'credentials',
      state: {
        get: (s: State) => s.sync.credentials,
        set: (s: State, c: CredentialsState) => set(s => s.sync.credentials)(c)(s)
      }
    })(sources)

  return {
    ...mergeSinks(credentials, model(intent(sources), sources)),
    DOM: xs.combine(sources.state.stream, credentials.DOM).map(apply(view))
  }
}
