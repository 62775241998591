import { ErrorType, groupBy, Loadable, orderBy, StreamType, Unloaded } from '@/generic'
import { ReportEntry, TimesheetResponse, TreeWorkItem, UnprocessableEntry } from '@/prim/model'
import { round2 } from '@/util'
import { Table } from '@tanstack/table-core'
import format from 'date-fns/format'
import { ProjectPageSources } from '../common'
import { LoadableFromGettable } from '@/dataServices'
import { ProjectConfigurationResource, TimeSheetResource } from '@/prim/resources'

export type TimesheetSources = ProjectPageSources<TimesheetViewModel>

export const emptyTimesheetViewModel = () => ({
  selectedProjectMember: '',
  isSyncing: false,
  canRefresh: false,
  canSync: false,
  selectedDate: new Date(0),
  report: Unloaded as Loadable<Report, ErrorType<LoadableFromGettable<TimeSheetResource>>>,
  configuration: Unloaded as LoadableFromGettable<ProjectConfigurationResource>,
  syncResponse: Unloaded as Loadable<UnprocessableEntry[], ErrorType<StreamType<TimeSheetResource['syncResponse']>>>,
  isNuking: false,
  timesheetReportTableKey: {},
  table: Unloaded as Loadable<Table<DayReportRecord>>
})

export type TimesheetViewModel = ReturnType<typeof emptyTimesheetViewModel>


export type DayReportRecord = {
  workDate: Date
  hours: number
  taskName: string
  workItem: TreeWorkItem | null
  tiaCodeName: string
  description: string
}

function DayReport([date, entries]: [number, readonly ReportEntry[]]) {
  return {
    date: new Date(date),
    hours: round2(entries.reduce((sum, entry) => sum + entry.hours, 0)),
    entries: orderBy(e => e.tiaCodeId.split('').reduce((s, c) => s + c.charCodeAt(0), 0), entries)
  }
}

function UserReport(entries: ReportEntry[], username: string) {
  return {
    username,
    entries,
    dayReports: orderBy(
      dr => +new Date(dr.date),
      groupBy(entries, e => +e.workDate, DayReport)
    )
  }
}

export function Report(timesheetResponse: TimesheetResponse) {
  return {
    dayReports: orderBy(
      dr => +new Date(dr.date),
      groupBy(timesheetResponse.timeEntries, e => +e.workDate, DayReport)
    ),
    ownProblems: timesheetResponse.ownProblems,
    otherMembersWithProblems: timesheetResponse.otherMembersWithProblems,
    otherTimeEntryProblems: timesheetResponse.otherTimeEntryProblems,
    workItemProblems: timesheetResponse.workItemProblems
  }
}

export type UserReport = ReturnType<typeof UserReport>
export type DayReport = ReturnType<typeof DayReport>
export type Report = ReturnType<typeof Report>

export const selectedMemberStorageKey = (projectKey: string) => `selected-member-${projectKey}`

export const getUserTimesheetResource = (sources: TimesheetSources) => (state: TimesheetViewModel) =>
  sources.primState.timesheetReport(sources.projectKey, state.selectedProjectMember, format(state.selectedDate, 'yyyy-MM'))
