import { ErrorType, isArray, isLoading, unique } from '@/generic'
import { ProjectMember } from '@/prim'
import { Button, Col, Container, Danger, Info, renderLoadable, Row, Select, Warning } from '@/ui'
import format from 'date-fns/format'
import nl from 'date-fns/locale/nl'
import { jsx } from 'h'
import { insufficientRightsRenderConfig, memberNotFoundRenderConfig, paymoConfigInvalidRenderConfig, projectNotFoundRenderConfig, renderProblem, renderProblemFor, requestErrorRenderConfig, tiaSyncProblemRenderConfig, timesheetProblemRenderConfig, unhandledApiExceptionRenderConfig, unhandledAzureDevOpsProblemRenderConfig, unhandledClockifyProblemRenderConfig, unhandledJiraProblemRenderConfig, unhandledPaymoProblemRenderConfig, unprocessableEntryRenderConfig, wrongLevelProblemRenderConfig } from '../renderers'
import { formatMonth } from '../shared'
import { TimesheetViewModel, Report, DayReportRecord } from './types'
import { renderCell, renderHeader } from '@/ui/tanstack'
import { Table } from '@tanstack/table-core'

export function renderDate(date: Date) {
  return format(date, 'P', { locale: nl })
}

function renderDateControls(model: TimesheetViewModel) {
  return (
    <div className="month-selector form-group">
      <button type="button" className="btn btn-primary previous-month">
        &lsaquo;
      </button>
      <span className="selected-month">{formatMonth(model.selectedDate)}</span>
      <button type="button" className="btn btn-primary next-month">
        &rsaquo;
      </button>
    </div>
  )
}

function renderProjectMemberSelect(selectedProjectMember: string, canRefresh: boolean, members: ProjectMember[]) {
  return <>
    <div className="ms-2">
      <Select className="project-member">
        <option value="" selected={selectedProjectMember === ''}>
          -- Select user --
        </option>
        {members.filter(m => m.timeTrackerId).sort((a, b) => a.displayName.localeCompare(b.displayName)).map(member =>
          <option value={member.tiaUserId} selected={selectedProjectMember === member.tiaUserId}>
            {member.displayName}
          </option>)}
      </Select>
    </div>
    <Button context='secondary' disabled={!canRefresh} className="refresh ms-2">
      &#8635;
    </Button>
  </>
}

const renderReport = (report: Report, table: Table<DayReportRecord>) =>
  <div>
    {report.ownProblems.length
      ? <Warning key="own-timesheet-problems">
        <h5>Some of your time entries have problems. Please resolve these to make sure all your work gets billed.</h5>
        {report.ownProblems.map(problem => <div>{renderProblem(timesheetProblemRenderConfig)(problem)}</div>)}
      </Warning>
      : null}
    {report.otherMembersWithProblems.length
      ? <Warning key="other-members-with-timesheet-problems">
        <h5>These other project members have problems with their time entries. This may affect your own time entries.</h5>
        {unique(report.otherMembersWithProblems.map(member => member.displayName)).map(memberDisplayName => <div>{memberDisplayName}</div>)}
      </Warning>
      : null }
    {report.otherTimeEntryProblems.length
      ? <Warning key="other-time-entry-timesheet-problems">
        <h5>These time entry problems cannot be linked to a team member. They may be yours, or impact your own time entries when resolved.</h5>
        {report.otherTimeEntryProblems.map(problem => <div>{renderProblem(timesheetProblemRenderConfig)(problem)}</div>)}
      </Warning>
      : null}
    {report.workItemProblems.length
      ? <Info key="workitem-timesheet-problems">
        <details>
          <summary>
            <h5 style={{display: 'inline'}}>
              There are issues that may be related to your or your project member's problems, but are not necessarily a problem in and of themselves.
            </h5>
          </summary>
          {report.workItemProblems.map(problem => <div>{renderProblem(timesheetProblemRenderConfig)(problem)}</div>)}
        </details>
      </Info>
      : null}
    <div key="report">
      {report.dayReports.length
        ? renderTable(table)
        : report.ownProblems.length
          ? <span>No valid time entries found</span>
          : <span>No time entries found</span>}
    </div>
  </div>

const renderTable = (table: Table<DayReportRecord>) => {
  const [dateHeader, durationHeader, taskHeader, workItemHeader, tiaCodeHeader, commentHeader] = table.getLeafHeaders()
  return <Container className="user-report">
    <Row key="header" className="user-report-header">
      <Col width={1}><strong>{renderHeader(dateHeader)}</strong></Col>
      <Col width={1}><strong>{renderHeader(durationHeader)}</strong></Col>
      <Col width={2}><strong>{renderHeader(taskHeader)}</strong></Col>
      <Col width={2}><strong>{renderHeader(workItemHeader)}</strong></Col>
      <Col width={2}><strong>{renderHeader(tiaCodeHeader)}</strong></Col>
      <Col><strong>{renderHeader(commentHeader)}</strong></Col>
    </Row>
    {table.getRowModel().rows.map((row, i) => {
      if (row.getIsGrouped()) {
        const [groupedDateCell, groupedDurationCell] = row.getVisibleCells()

        return <Row key={i} className="timesheet-row-grouped" role="button" on={{click: row.getToggleExpandedHandler()}}>
          <Col width={1}><strong>{renderCell(groupedDateCell)}</strong></Col>
          <Col width={1}><strong>{renderCell(groupedDurationCell)}</strong></Col>
        </Row>
      } else {
        const cells = row.getVisibleCells()
        return <Row key={i} className="timesheet-row-ungrouped" on={{click: row.getToggleExpandedHandler()}}>
          <Col width={1}>{renderCell(cells[0])}</Col>
          <Col width={1}>{renderCell(cells[1])}</Col>
          <Col width={2}>{renderCell(cells[2])}</Col>
          <Col width={2}>{renderCell(cells[3])}</Col>
          <Col width={2}>{renderCell(cells[4])}</Col>
          <Col>{renderCell(cells[5])}</Col>
        </Row>
      }

    })}
  </Container>
}

const userTimesheetProblemRenderConfig = {
  ...wrongLevelProblemRenderConfig,
  ...unhandledClockifyProblemRenderConfig,
  ...unhandledPaymoProblemRenderConfig,
  ...paymoConfigInvalidRenderConfig,
  ...unhandledAzureDevOpsProblemRenderConfig,
  ...unhandledJiraProblemRenderConfig
}

const timesheetResourceProblems = {
  ...requestErrorRenderConfig,
  ...unhandledApiExceptionRenderConfig,
  ...insufficientRightsRenderConfig,
  ...projectNotFoundRenderConfig,
  ...memberNotFoundRenderConfig
}

const renderTimesheetError = (problems: ErrorType<TimesheetViewModel['report']>) =>
  <Danger>
    { isArray(problems)
      ? problems.map(problem =>
        <div>
          {renderProblemFor(problem)(userTimesheetProblemRenderConfig)}
        </div>)
      : renderProblemFor(problems)(timesheetResourceProblems) }
  </Danger>

const renderTimesheetSyncError = (problems: ErrorType<TimesheetViewModel['syncResponse']>) =>
  <Danger>
    { isArray(problems)
      ? problems.map(problem =>
        <div>
          {renderProblemFor(problem)(userTimesheetProblemRenderConfig)}
        </div>)
      : renderProblemFor(problems)({
        ...timesheetResourceProblems,
        ...tiaSyncProblemRenderConfig}) }
  </Danger>

export const renderTimesheet = (model: TimesheetViewModel) =>
  renderLoadable(model.configuration, ({ info: { members } }) => (
    <Row className="mt-3">
      <Col>
        <form className="form-inline mb-3">
          <div className="sync-container">
            {renderDateControls(model)}
            {renderProjectMemberSelect(model.selectedProjectMember, model.canRefresh, members)}
            <button type="button" className="sync btn btn-primary" disabled={isLoading(model.report) || model.isSyncing || !model.canSync}>
            Sync to Cronos timesheet
            </button>
          </div>
        </form>
        {renderLoadable(
          model.syncResponse,
          {
            loaded: (unprocessableEntries, loading) =>
              unprocessableEntries?.length
                ? <Danger className={loading ? 'stale': ''}>{unprocessableEntries.map(entry => <div>{renderProblem(unprocessableEntryRenderConfig)(entry)}</div>)}</Danger>
                : null,
            error: renderTimesheetSyncError
          })}
        {renderLoadable(model.table, (table, loadingTable) =>
          renderLoadable(
            model.report,
            {
              loaded: (report, loadingReport) =>
                <div className={(loadingTable || loadingReport) ? 'stale' : ''}>
                  {report != null ? renderReport(report, table) : <Warning>No user report found</Warning>}
                </div>,
              error: renderTimesheetError
            }
          ))}
        <div className="row justify-content-end">
          <div className="col-1">
            <button className="btn btn-light nuke-cache" disabled={model.isNuking}>
            Nuke cache
            </button>
          </div>
        </div>
      </Col>
    </Row>
  ))
