import isolate, { Component } from '@cycle/isolate'
import { StorageRequest } from '@cycle/storage'
import { RouterSource } from 'cyclic-router'
import { VNode } from 'snabbdom'
import xs from 'xstream'
import { isApplicationAdmin } from '../../authentication/model'
import { isUser } from '../../authentication/userProvider'
import { BootstrapAction } from '../../drivers'
import { caseValue } from '../../fsharp'
import { C, combineLoadables, ErrorType, id, isLoaded, isLoadError, Loadable, loadError, loadErrorMessage, mapLoaded, mergeSinks, value } from '../../generic'
import { jsx } from 'h'
import { mergePageSinks, pageSinkMergeTemplate, PageSinks, withRouterBehaviour } from '../../infrastructure'
import { Container, createHref, Danger, Icon, Nav, NavBar, NavBarNav, NavBrand, NavLinkCreator, renderLoadable, Row, targetData } from '../../ui'
import { cn } from '../../ui/util'
import { redirectPath } from '../../util'
import { Level1ProjectConfiguration, Level5ProjectConfiguration, ProjectConfiguration, ProjectListItem } from '../model'
import { ProjectConfigurationIndex } from './configuration'
import { CreateProject } from './createProject'
import { Timesheet } from './timesheetReport'
import { PrimPageSources } from './types'
import { LoadableFromGettable } from '@/dataServices'
import { ProjectsListResource } from '../resources'
import { renderProblemFor, requestErrorRenderConfig, unhandledApiExceptionRenderConfig } from './renderers'

const renderLevel1Configuration = (_config: Level1ProjectConfiguration) => <div>Level 1</div>
const renderLevel5Configuration = (_config: Level5ProjectConfiguration) => <div>Level 5</div>

const unknown = id

function renderLevelConfiguration(configuration: ProjectConfiguration) {
  switch (configuration.levelConfiguration.case) {
    case 'Level1': return renderLevel1Configuration(caseValue(configuration.levelConfiguration))
    case 'Level5': return renderLevel5Configuration(caseValue(configuration.levelConfiguration))
    default: return <Danger>Unknown project configuration level "{unknown(configuration).levelConfiguration.case}"</Danger>
  }
}

const isApplicationAdmin$ = (sources: PrimPageSources) => sources.user.map(u => (isUser(u) && isApplicationAdmin(u)))

export const ErrorPageNoProjectsInList = (sources: PrimPageSources) => {
  return {
    DOM: isApplicationAdmin$(sources).map(isAppAdmin =>
      <Container>
        <Row className="justify-content-md-center">
          <div className="error-no-project-in-list">
            <Danger>
              {isAppAdmin
                ? <p>There aren't any projects. <sources.Link href={createHref(sources.router, '/create')} className="alert-link">Create a project</sources.Link></p>
                : <p>You don't have acces to any projects. Ask an admin to grant you access to a project.</p>
              }
            </Danger>
          </div>
        </Row>
      </Container>
    )
  }
}

const projectsList =
  (
    sources: PrimPageSources,
    loadableProjectsList: LoadableFromGettable<ProjectsListResource>,
    selectedProject: Loadable<ProjectListItem | undefined, ErrorType<typeof loadableProjectsList>>,
    isAppAdmin: boolean
  ) => (<div className="ms-auto btn-group dropdown-menu-projects">
    <button className="dropdown-button dropdown-button-projects nav-item" type="button" data-bs-toggle="dropdown" aria-expanded="false">
      {renderLoadable(
        selectedProject, {
          loaded: selectedProject =>
            selectedProject  ? `Project: ${selectedProject.name}` : ' -- Choose a project -- ',
          error: problem => <Danger>
            {renderProblemFor(problem)({
              ...unhandledApiExceptionRenderConfig,
              ...requestErrorRenderConfig})}
          </Danger>})}
      <Icon className="dropdown-icon" icon="chevron-down"/>
    </button>
    <div className="dropdown-menu dropdown-menu-end">
      {renderLoadable(
        combineLoadables(loadableProjectsList, selectedProject),
        { loaded:
          ([projectList, selectedProject]) =>
            projectList.map(project =>
              <>
                <div key={project.key} className="d-flex">
                  <sources.Link
                    href={createHref(sources.router, `/${project.key}/timesheet`)}
                    className={cn('project', project.key === selectedProject?.key ? 'font-weight-bold' : '')}
                    data-project={project.key}>
                    {project.name}
                  </sources.Link>
                  <div
                    key={project.key + project.description}
                    className="icon-button-projects ms-auto"
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    data-bs-title={project.description}>
                    <Icon icon="info" />
                  </div>
                </div>
                <div className="dropdown-divider"></div>
              </>
            ),
        error: problems =>
          <Danger>
            {problems.map(problem =>
              <div>
                {renderProblemFor(problem)({
                  ...unhandledApiExceptionRenderConfig,
                  ...requestErrorRenderConfig})}
              </div>
            )}
          </Danger>})}
      {isAppAdmin
        ?
        <div className="d-flex">
          <sources.Link className="btn btn-primary border mx-auto" role='button' href={createHref(sources.router, '/create')}>Create new</sources.Link>
        </div>
        : ''
      }
    </div>
  </div>)

export const renderConfiguration = (configuration: ProjectConfiguration) =>
  <div><h1>{configuration.info.name}</h1><p>{configuration.info.description}</p>{renderLevelConfiguration(configuration)}</div>

const projectsForUser = (sources: PrimPageSources) => sources.primState.projects.value

const modifySinks = <So, Si>(component: Component<So, Si>, f: (sinks: Si) => Si) => C(component, f)

const brandBar =
  (sources: PrimPageSources) =>
    (projectKey: string | null) =>
      (loadableProjectsList: LoadableFromGettable<ProjectsListResource>, isAppAdmin: boolean) => {
        const NavLink = NavLinkCreator(sources.router)
        const selectedProject = mapLoaded(loadableProjectsList, projects => projects.find(project => project.key === projectKey))

        return (
          <NavBar className="prim-nav-bar">
            <NavBrand className="col-2 prim-nav-bar-brand" href={createHref(sources.router, '/')}>PRIM</NavBrand>
            <NavBarNav className="">
              <Nav>
                {renderLoadable(
                  selectedProject, {
                    loaded: project =>
                      project && project.isAdminForProject
                        ? <>
                          <NavLink href={`/${projectKey}/timesheet`}>Timesheet</NavLink>
                          <NavLink href={`/${projectKey}/configuration`}>Configuration</NavLink>
                        </>
                        : '',
                    error: problem =>
                      <Danger>
                        {renderProblemFor(problem)({
                          ...unhandledApiExceptionRenderConfig,
                          ...requestErrorRenderConfig})}
                      </Danger>
                  }
                )}
              </Nav>
            </NavBarNav>
            {projectsList(sources, loadableProjectsList, selectedProject, isAppAdmin)}
          </NavBar>
        )
      }

const withNavigation =
  (sources: PrimPageSources) =>
    (projectKey: string | null) =>
      <So, Si extends { DOM: xs<VNode> }>(component: Component<So, Si>) =>
        modifySinks(component, (sinks: Si) => ({
          ...sinks,
          DOM: xs.combine(sinks.DOM, projectsForUser(sources), isApplicationAdmin$(sources)).map(([vnode, projects, isAdmin]) =>
            <div className="page-content-general">
              <Row>
                {brandBar(sources)(projectKey)(projects, isAdmin)}
              </Row>
              {vnode}
            </div>
          )
        }))

const createLastSelectedProjectRedirect = (router: RouterSource, maybeLocalStorageProjectKey: string | null, projectList: ProjectListItem[]): string => {
  const maybeFirstProjectInListKey: string | undefined = projectList[0]?.key
  const isAuthorised = projectList.some(p => p.key === maybeLocalStorageProjectKey)

  const maybeProjectKey = isAuthorised ? maybeLocalStorageProjectKey : maybeFirstProjectInListKey

  if (maybeProjectKey) {
    return createHref(router, `/${maybeProjectKey}`)
  } else {
    return createHref(router, '/no-project-found')
  }
}

const redirectRoot = (sources: PrimPageSources, router: RouterSource) => {

  const maybeProjectKey = sources.storage.local.getItem<string | null>('projectKey')

  const projectList = projectsForUser(sources).filter(isLoaded).map(value)
  const projectListProblem = projectsForUser(sources).filter(isLoadError).map(loadErrorMessage)

  return {
    DOM: projectListProblem.map(problem =>
      <Danger className="mt-3">
        {renderProblemFor(problem)({...requestErrorRenderConfig, ...unhandledApiExceptionRenderConfig})}
      </Danger>),
    router: xs.combine(maybeProjectKey, projectList).map(([localStorageKey, projectListItems]) => createLastSelectedProjectRedirect(router, localStorageKey, projectListItems))
  }
}

export const Index = (sources: PrimPageSources) => {
  const selectedProjectKey$ = sources.DOM
    .select('.project')
    .events('click')
    .map(e => targetData(data => data.project!)(e))
    .map(projectKey => ({ key: 'projectKey', value: projectKey } as StorageRequest))

  const page$ = withRouterBehaviour<PageSinks, PrimPageSources>(sources, {
    '/': ({ router }: { router: RouterSource }) => redirectRoot(sources, router),
    '/:key': () => redirectPath('/timesheet'),
    '/no-project-found': () => withNavigation(sources)(null)(isolate(ErrorPageNoProjectsInList, 'errorPage'))(sources),
    '/:key/timesheet': (key: string) => (componentSources: PrimPageSources) =>
      withNavigation(sources)(key)(isolate(Timesheet, 'timesheet'))(({ ...componentSources, projectKey: key })),
    '/:key/configuration': (key: string) => (componentSources: PrimPageSources) =>
      withNavigation(sources)(key)(isolate(ProjectConfigurationIndex, 'configuration'))(({ ...componentSources, projectKey: key })),
    '/create': () => withNavigation(sources)(null)(isolate(CreateProject, 'createProject'))(sources),
  })

  return mergeSinks(
    mergePageSinks(page$, pageSinkMergeTemplate),
    {
      storage: selectedProjectKey$,
      bootstrap: sources.DOM.select('[data-bs-toggle="tooltip"]').elements().map(xs.fromArray).flatten().map((el): BootstrapAction => ({ type: 'tooltip', target: el }))
    })
}
