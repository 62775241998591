import { HTTPSource } from '@cycle/http'
import { ErrorOrFailure, handleLatest, isRequestError, Request, toLoadable } from '../../dataServices'
import { GettableResource } from '../../dataServices/types'
import { K } from '../../generic'
import { insufficientRights, InsufficientRights, PaymoConfigurationInvalid, RequestError, UnhandledApiExceptionProblem, UnhandledPaymoProblem, WrongLevelProblem } from '../model'
import { simpleResult, resultOrError, handleError } from './resourceUtil'

export type PaymoProject = {
  id: number
  name: string
  color: string
  active: boolean
}

export type PaymoProjectsResponse = PaymoProject[]

export type PaymoProjectsResource<TError = string> = GettableResource<PaymoProjectsResponse, TError>

export type PaymoProjectsFromProjectResource = PaymoProjectsResource<RequestError | InsufficientRights | WrongLevelProblem | UnhandledApiExceptionProblem | PaymoConfigurationInvalid | UnhandledPaymoProblem>

export const projectPaymoProjectsResource = (root: string) => (HTTP: HTTPSource) => (key: string): PaymoProjectsFromProjectResource => {
  const url = `${root}/projects/${key}/paymo/projects`

  const request = {
    category: `get-project-paymo-projects-${key}`,
    method: 'GET',
    url: url
  }

  return {
    name: request.url,
    get: request,
    value: toLoadable(handleLatest(HTTP)(request.category)({
      success: simpleResult<PaymoProjectsResponse>,
      failure: {
        403: K(insufficientRights),
        409: simpleResult<WrongLevelProblem | PaymoConfigurationInvalid>,
        500: resultOrError<UnhandledApiExceptionProblem | UnhandledPaymoProblem>
      },
      error: handleError
    }))
  }
}

export type PaymoError = {
  message: string
  code: number
}

export const PAYMO_AUTHENTICATION_FAILED = Symbol()

const paymoFailureAndErrorHandlers = {
  failure: {
    401: K(PAYMO_AUTHENTICATION_FAILED)
  },
  error: (err: ErrorOrFailure<unknown>) => {
    if (isRequestError(err)) {
      return 'Request failed. Check your connection.'
    }

    return 'An unknown error occured while connecting to Paymo.'
  }
}

export type PaymoProjectsFromPaymoResource = PaymoProjectsResource<string | typeof PAYMO_AUTHENTICATION_FAILED>

export const paymoProjectsResource = (HTTP: HTTPSource) => (key: string): PaymoProjectsFromPaymoResource => {
  const url = 'https://app.paymoapp.com/api/projects'

  const credentials = window.btoa(`${key}:prim_frontend`)

  const request = Request({
    category: `get-paymo-projects-${key}`,
    method: 'GET',
    headers: {
      'accept': 'application/json',
      'Authorization': `Basic ${credentials}`
    },
    url: url
  })

  return {
    name: request.url,
    get: request,
    value: toLoadable(handleLatest(HTTP)(request.category)({
      success: response => {
        const paymoProjectsResponse = response.body as { projects: PaymoProjectsResponse }
        paymoProjectsResponse.projects.sort((a, b) => +b.active - +a.active)
        return paymoProjectsResponse.projects
      },
      ...paymoFailureAndErrorHandlers
    }))
  }
}
