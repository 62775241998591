export const commonConfig = {
  host: location.origin,
  msal: {
    authority: 'https://login.microsoftonline.com/49c3d703-3579-47bf-a888-7c913fbdced9',
    clientId: '5e7feada-703b-40a9-9481-9e44403b2d15',
    redirectUri: location.origin + '/auth/login',
    logoutRedirectUri: location.origin + '/auth/logout',
    domainHint: 'cronos.be',
    loginScopes: ['https://cronos/clockify-cronos/dummy', 'offline_access']
  }
}
