import { MainDOMSource } from '@cycle/dom'
import xs from 'xstream'
import { Action } from '../drivers/bootstrapDriver'
import { jsx } from 'h'
import { Button } from './bootstrap'
import { Renderable } from './util'

export type ModalContent = {
  title: Renderable
  body: Renderable
  footer?: Renderable
}

export function renderModal(id: string, content: ModalContent) {
  return (
    <div className="modal fade" id={id} tabIndex="-1" role="dialog" aria-labelledby={`${id}Title`} aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id={`${id}Title`}>
              {content.title}
            </h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" data-bs-target={`#${id}`} aria-label="Close">
            </button>
          </div>
          <div className="modal-body">{content.body}</div>
          { content.footer
            ? <div className="modal-footer">{content.footer}</div>
            : null
          }
        </div>
      </div>
    </div>
  )
}

export const credentialModalButtons = (id: string, canSave: boolean) => [
  <Button context="secondary" className="cancel-credentials" data-bs-dismiss="  modal" data-bs-target={`#${id}`}>
    Cancel
  </Button>,
  <Button context="primary" className="save-credentials" disabled={!canSave}>
    Save
  </Button>
]

export function Modal({ content, id }: { DOM: MainDOMSource; content: xs<ModalContent>; id: xs<string> }) {
  const actions = (instanceId: string) => ({
    show: Action({ target: `#${instanceId}`, type: 'modal', options: { type: 'show' } }),
    hide: Action({ target: `#${instanceId}`, type: 'modal', options: { type: 'hide' } })
  })

  return {
    DOM: xs.combine(id, content).map(args => renderModal(...args)),
    modalId: id,
    actions: id.map(actions)
  }
}
