import isolate from '@cycle/isolate'
import { VNode } from 'snabbdom'
import xs from 'xstream'
import dropRepeats from 'xstream/extra/dropRepeats'
import { caseValue } from '@/fsharp'
import { isLoaded, Manager, mergeSinks, Unloaded } from '@/generic'
import { jsx } from 'h'
import { DEFAULT, DERIVED, dynamicCaseModel, IGNORE, INIT, mergePageSinks, pageSinkMergeTemplate, PageSinks, pageSinkTemplate } from '@/infrastructure'
import { Button, click, Danger, events, renderLoadable } from '@/ui'
import { assertNever } from '@/util'
import { emptyTimeEntryMappingConfig, isLevel1, isLevel3, isLevel3OrHigher } from '@/prim/model'
import { timeTrackerConfiguration } from '@/prim/resources'
import { ProjectPageSources } from '../../common'
import { ConfigurationContext, TimeTrackerConfigurationViewState } from '../viewModel'
import { ClockifyConfigurationComponent, clockifyConfigurationViewState } from './clockifyConfiguration'
import { PaymoConfigurationComponent, paymoConfigurationViewState } from './paymoConfiguration'

type State = TimeTrackerConfigurationViewState
type Sources = ProjectPageSources<State>

export const timeTrackerConfigurationViewState = (context: ConfigurationContext): State => {
  if (isLevel1(context.configuration.levelConfiguration)) {
    return { type: 'chooseTimeTrackerType', context: context, adminMode: false }
  }

  const { timeEntryMappingConfig, timeTrackerConfiguration } =
    isLevel3OrHigher(context.configuration.levelConfiguration) ? caseValue(context.configuration.levelConfiguration) : (() => {
      throw new Error('Project should be Level 3 or higher')
    })()

  switch (timeTrackerConfiguration.case) {
    case 'ClockifyConfiguration':
      return {
        type: 'clockify',
        state: clockifyConfigurationViewState(context, timeEntryMappingConfig, caseValue(timeTrackerConfiguration)),
        adminMode: false,
        deleteResponse: Unloaded
      }
    case 'PaymoConfiguration':
      return {
        type: 'paymo',
        state: paymoConfigurationViewState(context, timeEntryMappingConfig, caseValue(timeTrackerConfiguration)),
        adminMode: false,
        deleteResponse: Unloaded
      }
    default: return assertNever(timeTrackerConfiguration)
  }
}

const timeTrackerConfigurationResource = (sources: Sources) =>
  timeTrackerConfiguration(sources.apiHost + '/api')(sources.HTTP)(sources.projectKey)

const intent = (sources: Sources) =>
  ({
    adminMode: sources.router.history$.map(h => !!~h.search.toLowerCase().indexOf('admin')),
    ...events({
      '.clockify': click,
      '.paymo': click,
      '#replace-time-tracker-configuration': click,
      '#delete-time-tracker-configuration': click
    })(sources),
    deleteResponse: timeTrackerConfigurationResource(sources).deleteResponse
  })

const { set } = Manager<State>()

const model = (intents: ReturnType<typeof intent>, sources: Sources) =>
  dynamicCaseModel(sources.state.stream, 'type')(
    pageSinkTemplate,
    intents, {
      [INIT]: IGNORE,
      adminMode: {
        [DEFAULT]: set(s => s.adminMode)
      },
      '.clockify': {
        chooseTimeTrackerType: _ => s => ({
          type: 'clockify',
          adminMode: s.adminMode,
          deleteResponse: Unloaded,
          state: clockifyConfigurationViewState(
            s.context,
            emptyTimeEntryMappingConfig,
            null)}),
      },
      '.paymo': {
        chooseTimeTrackerType: _ => s => ({
          type: 'paymo',
          adminMode: s.adminMode,
          deleteResponse: Unloaded,
          state: paymoConfigurationViewState(
            s.context,
            emptyTimeEntryMappingConfig,
            null)})
      },
      '#replace-time-tracker-configuration': {
        clockify: _ => s => ({ type: 'chooseTimeTrackerType', context: s.state.context, adminMode: s.adminMode }),
        paymo: _ => s => ({ type: 'chooseTimeTrackerType', context: s.state.context, adminMode: s.adminMode })
      },
      '#delete-time-tracker-configuration': {
        'clockify': {
          output: _ => _ => ({ HTTP: [ timeTrackerConfigurationResource(sources).deleteRequest ] })
        },
        'paymo': {
          output: _ => _ => ({ HTTP: [ timeTrackerConfigurationResource(sources).deleteRequest ] })
        }
      },
      deleteResponse: {
        'clockify': {
          state: response => s => ({ ...s, deleteResponse: response }),
          output: response => _ => isLoaded(response) ? { HTTP: [sources.primState.projectConfiguration(sources.projectKey).refresh ] } : { }
        },
        'paymo': {
          state: response => s => ({ ...s, deleteResponse: response }),
          output: response => _ => isLoaded(response) ? { HTTP: [sources.primState.projectConfiguration(sources.projectKey).refresh ] } : { }
        }
      },
      [DERIVED]: IGNORE
    })

const view = (configurationComponent: VNode | ['chooseTimeTrackerType', boolean] ) => <div>
  { Array.isArray(configurationComponent)
    ? <>
      <Button context='primary' className="clockify">Clockify</Button>
      <Button context='primary' className="ms-1 paymo">Paymo</Button>
    </>
    : { configurationComponent }
  }
</div>

const getConfigurationComponent = (sources: Sources) =>
  sources.state.stream
    .map(state => [state.type, state.adminMode] as const)
    .compose(dropRepeats((typeA, typeB) => typeA.every((x, i) => x === typeB[i])))
    .map(([type, adminMode]): ['chooseTimeTrackerType' | PageSinks, boolean] => {
      switch (type) {
        case 'chooseTimeTrackerType': return [type, adminMode]
        case 'clockify': return [isolate(ClockifyConfigurationComponent, 'state')(sources) as PageSinks, adminMode]
        case 'paymo': return [isolate(PaymoConfigurationComponent, 'state')(sources) as PageSinks, adminMode]
        default: return assertNever(type)
      }
    })

export const TimeTrackerConfigurationComponent = (sources: Sources) => {
  const component = getConfigurationComponent(sources)
  const page$ = component
    .map(([c, adminMode]) => {
      if (typeof c === 'string') {
        return {
          DOM: xs.of(view([c, adminMode]))
        }
      }

      const helperState$ =
        sources.state.stream
          .map(s => 'deleteResponse' in s ? ([s.deleteResponse, isLevel3(s.state.context.configuration.levelConfiguration)] as const) : [null, null] as const)
          .compose(dropRepeats())

      return {
        ...c,
        DOM: xs.combine(helperState$, c.DOM!).map(([[errorResponse, isLevel3], vnode]) => <>
          {vnode}
          {adminMode
            ? <>
              <div className="mt-3">
                <Button id="replace-time-tracker-configuration" context="warning" disabled={isLevel3 === null}>Replace time tracker configuration</Button>
                <Button id="delete-time-tracker-configuration" context="danger" disabled={isLevel3 !== true} className="ms-2">Remove time tracker configuration</Button>
              </div>
              {errorResponse ? renderLoadable(errorResponse, {
                loaded: _ => '',
                error: err => <Danger className="mt-3">
                  Failed to delete time tracker configuration: {err}
                </Danger>
              }) : null}
            </>
            : null}
        </>)
      } as PageSinks
    })

  return mergeSinks(mergePageSinks(page$, pageSinkMergeTemplate), {
    ...model(intent(sources), sources)
  })
}
