import { HTTPSource } from '@cycle/http'
import { bodyAs, ErrorOrFailure, handleLatest, isRequestError, MyResponse, Request, toLoadable } from '../../dataServices'
import { GettableResource } from '../../dataServices/types'
import { InsufficientRights, WrongLevelProblem, UnhandledApiExceptionProblem, UnhandledClockifyProblem, RequestError, ClockifyConfigurationInvalid, insufficientRights } from '../model'
import { handleError, resultOrError, simpleResult } from './resourceUtil'
import { K } from '@/generic'

export type ClockifyWorkspace = {
  id: string
  name: string
}

export type ClockifyProject = {
  id: string
  name: string
  color: string
  archived: boolean
}

export type ClockifyWorkspaceResponse = ClockifyWorkspace[]
export type ClockifyProjectsResponse = ClockifyProject[]

export type ClockifyWorkspacesResource<TError> = GettableResource<ClockifyWorkspaceResponse, TError>
export type ClockifyProjectsResource<TError> = GettableResource<ClockifyProjectsResponse, TError>

export type ClockifyWorkspacesFromProjectResource = ClockifyWorkspacesResource<RequestError | InsufficientRights | WrongLevelProblem | UnhandledApiExceptionProblem | ClockifyConfigurationInvalid | UnhandledClockifyProblem>

export const projectClockifyWorkspacesResource = (root: string) => (HTTP: HTTPSource) => (key: string): ClockifyWorkspacesFromProjectResource => {
  const url = `${root}/projects/${key}/clockify/workspaces`

  const request = {
    category: `get-project-clockify-workspaces-${key}`,
    method: 'GET',
    url: url
  }

  return {
    name: request.url,
    get: request,
    value: toLoadable(handleLatest(HTTP)(request.category)({
      success: simpleResult<ClockifyWorkspaceResponse>,
      failure: {
        403: K(insufficientRights),
        409: simpleResult<WrongLevelProblem | ClockifyConfigurationInvalid>,
        500: resultOrError<UnhandledApiExceptionProblem | UnhandledClockifyProblem>
      },
      error: handleError
    }))
}
}

export type ClockifyProjectsFromProjectResource = ClockifyProjectsResource<RequestError | InsufficientRights | WrongLevelProblem | UnhandledApiExceptionProblem | ClockifyConfigurationInvalid | UnhandledClockifyProblem>

export const projectClockifyProjectsResource = (root: string) => (HTTP: HTTPSource) => (key: string, workspaceId: string): ClockifyProjectsFromProjectResource => {
  const url = `${root}/projects/${key}/clockify/workspaces/${workspaceId}/projects`

  const request = {
    category: `get-project-clockify-projects-${key}-${workspaceId}`,
    method: 'GET',
    url: url
  }

  return {
    name: request.url,
    get: request,
    value: toLoadable(handleLatest(HTTP)(request.category)({
      success: simpleResult<ClockifyProjectsResponse>,
      failure: {
        403: K(insufficientRights),
        409: simpleResult<WrongLevelProblem | ClockifyConfigurationInvalid>,
        500: resultOrError<UnhandledApiExceptionProblem | UnhandledClockifyProblem>
      },
      error: handleError
    }))
  }
}

export type ClockifyError = {
  message: string
  code: number
}

export const CLOCKIFY_AUTHENTICATION_FAILED = Symbol()

const clockifyFailureAndErrorHandlers = {
  failure: {
    401: (response: MyResponse<unknown>) => {
      const error = bodyAs<ClockifyError>()(response)
      return error.code === 1000 ? CLOCKIFY_AUTHENTICATION_FAILED : error.message
    }
  },
  error: (err: ErrorOrFailure<unknown>) => {
    if (isRequestError(err)) {
      return 'Request failed. Check your connection.'
    }

    const maybeError = bodyAs<ClockifyError>()(err)

    if (maybeError.code) {
      return maybeError.message
    }

    return 'An unknown error occured while connecting to Clockify.'
  }
}

export type ClockifyWorkspacesFromClockifyResource = ClockifyWorkspacesResource<string | typeof CLOCKIFY_AUTHENTICATION_FAILED>

export const clockifyWorkspacesResource = (HTTP: HTTPSource) => (key: string): ClockifyWorkspacesFromClockifyResource => {
  const url = 'https://api.clockify.me/api/v1/workspaces'

  const request = Request({
    category: `get-clockify-workspaces-${key}`,
    method: 'GET',
    headers: {
      'X-Api-Key': key
    },
    url: url
  })

  return {
    name: request.url,
    get: request,
    value: toLoadable(handleLatest(HTTP)(request.category)({
      success: bodyAs<ClockifyWorkspaceResponse>(),
      ...clockifyFailureAndErrorHandlers
    }))
  }
}

export type ClockifyProjectsFromClockifyResource = ClockifyProjectsResource<string | typeof CLOCKIFY_AUTHENTICATION_FAILED>

export const clockifyProjectsResource = (HTTP: HTTPSource) => (key: string) => (workspaceId: string): ClockifyProjectsFromClockifyResource => {
  const url = `https://api.clockify.me/api/v1/workspaces/${workspaceId}/projects?page-size=1000`

  const request = Request({
    category: `get-clockify-projects-${key}`,
    method: 'GET',
    headers: {
      'X-Api-Key': key
    },
    url: url
  })

  return {
    name: request.url,
    get: request,
    value: toLoadable(handleLatest(HTTP)(request.category)({
      success: bodyAs<ClockifyProjectsResponse>(),
      ...clockifyFailureAndErrorHandlers
    }))
  }
}
