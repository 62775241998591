import xs from 'xstream'
import { caseValue, match } from '../../../../fsharp'
import { jsx } from 'h'
import { Manager, ReturnType4, isLoaded, mergeSinks } from '../../../../generic'
import { IGNORE, INIT, pageSinkTemplate, dynamicCaseModel, DERIVED, DEFAULT, dynamicIntent } from '../../../../infrastructure'
import { click, clickWithIndex, events, NavLinkCreator, NavLink, Warning, Button, Row, Col } from '../../../../ui'
import { isLevel3, isLevel3OrHigher } from '../../../model'
import { ProjectPageSources } from '../../common'
import { BacklogConfigurationsViewState, ConfigurationContext } from '../viewModel'
import { backlogConfiguration } from '../../../resources'
import { LoadableFromDeletable } from '@/dataServices'

type State = BacklogConfigurationsViewState

type Sources = ProjectPageSources<State>

export const backlogConfigurationsViewState = (context: ConfigurationContext): State => {
  if (!isLevel3OrHigher(context.configuration.levelConfiguration)) {
    return { type: 'level3Required', adminMode: false }
  }

  return {
    type: 'atLeastLevel3',
    context: context,
    backlogConfigurations:
      isLevel3(context.configuration.levelConfiguration)
        ? []
        : caseValue(context.configuration.levelConfiguration).backlogConfigurations,
    adminMode: false
  }
}

const intent = (sources: Sources) =>
  dynamicIntent<State>()(pageSinkTemplate, {
    adminMode: sources.router.history$.map(h => !!~h.search.toLowerCase().indexOf('admin')),
    ...events({
      '.create-azure-backlog-configuration': click,
      '.create-jira-backlog-configuration': click,
      '.create-redmine-via-client-backlog-configuration': click,
      '.delete-backlog-config': clickWithIndex
    })(sources),
    deleteResponse: {
      discriminator: state => state.type === 'atLeastLevel3' ? state.backlogConfigurations.map(({ item1 }) => item1).join(':') : IGNORE,
      value: (state) =>
        state.type === 'atLeastLevel3'
          ? xs.merge(...state.backlogConfigurations.map(({ item1 }) => backlogConfigurationResource(sources)(item1).deleteResponse))
          : xs.never<LoadableFromDeletable<ReturnType4<typeof backlogConfiguration>>>()
    }
  })

const backlogConfigurationResource = (sources: Sources) =>
  backlogConfiguration(sources.apiHost + '/api')(sources.HTTP)(sources.projectKey)

const { set } = Manager<State>()

const model = (intents: ReturnType<typeof intent>, sources: Sources) =>
  dynamicCaseModel(sources.state.stream, 'type')(
    pageSinkTemplate,
    intents, {
      [INIT]: IGNORE,
      adminMode: {
        [DEFAULT]: set(s => s.adminMode)
      },
      '.create-azure-backlog-configuration': {
        [DEFAULT]: {
          output: _ => _ => ({
            router: [sources.parentRouter.createHref('/backlog/new/azure')]
          })
        }
      },
      '.create-jira-backlog-configuration': {
        [DEFAULT]: {
          output: _ => _ => ({
            router: [sources.parentRouter.createHref('/backlog/new/jira')]
          })
        }
      },
      '.create-redmine-via-client-backlog-configuration': {
        [DEFAULT]: {
          output: _ => _ => ({
            router: [sources.parentRouter.createHref('/backlog/new/redmineViaClient')]
          })
        }
      },
      '.delete-backlog-config': {
        atLeastLevel3: {
          output: index => state => ({
            HTTP: [backlogConfigurationResource(sources)(state.backlogConfigurations[index].item1).deleteRequest]
          })
        }
      },
      deleteResponse: {
        [DEFAULT]: {
          output: response => _ => ({
            HTTP: isLoaded(response) ? [sources.primState.projectConfiguration(sources.projectKey).refresh] : []
          })
        }
      },
      [DERIVED]: IGNORE
    })

const view = (NavLink: NavLink) => (state: State) =>
  state.type === 'level3Required'
    ? <Warning>You cannot configure the backlog if the project level is lower than level 3. Configure a time registration integration first.</Warning>
    : <div>
      {state.backlogConfigurations.map((bl, i) =>
        <Row>
          <Col width={3}>
            <NavLink href={`/backlog/${bl.item1}`}>
              {match(bl.item2, {
                RedmineViaClientBacklogConfiguration: blc => blc.apiUrl,
                AzureBacklogConfiguration: blc => blc.organization,
                JiraBacklogConfiguration: blc => blc.host
              })}
            </NavLink>
          </Col>
          <Col width={3}>
            <NavLink href={`/backlog/${bl.item1}`}>
              {match(bl.item2, {
                RedmineViaClientBacklogConfiguration: _ => '',
                AzureBacklogConfiguration: blc => `(${blc.projectId})`,
                JiraBacklogConfiguration: blc => `(${blc.project})`
              })}
            </NavLink>
          </Col>
          <Col width={1}>
            <NavLink href={`/backlog/${bl.item1}`}>
              {match(bl.item2, {
                RedmineViaClientBacklogConfiguration: _ => 'Redmine',
                AzureBacklogConfiguration: _ => 'Azure DevOps',
                JiraBacklogConfiguration: _ => 'Jira'
              })}
            </NavLink>
          </Col>
          <Col width={1}>
            <Button context="danger" data-index={i} className="delete-backlog-config">X</Button>
          </Col>
        </Row>)}
      <div className="mt-2">
        <Button context="primary" className="create-azure-backlog-configuration">Add Azure DevOps</Button>
        <Button context="primary" className="ms-2 create-jira-backlog-configuration">Add Jira</Button>
        { state.adminMode ? <Button context="primary" className="ms-2 create-redmine-via-client-backlog-configuration">Add Redmine</Button> : null }
      </div>
    </div>

export const BacklogConfigurationsComponent = (sources: Sources) =>
  mergeSinks(model(intent(sources), sources), {
    DOM: sources.state.stream.map(view(NavLinkCreator(sources.parentRouter)))
  })
