import { calculated } from '@/generic'
import { intent } from './intent'
import { model, setCanSyncOrRefresh } from './model'
import { TimesheetSources } from './types'
import { renderTimesheet } from './view'

export const Timesheet = (sources: TimesheetSources) => {
  const intents = intent(sources)
  const modelSinks = model(intents, sources)

  return {
    ...modelSinks,
    DOM: sources.state.stream.map(renderTimesheet),
    state: modelSinks.state.compose(calculated(setCanSyncOrRefresh)),
  }
}
