import { PageSinks, mergePageSinks, pageSinkMergeTemplate } from '@/infrastructure'
import { ProjectPageSources } from '../../common'
import isolate from '@cycle/isolate'
import { AzureConfiguration, azureConfigurationViewState } from './azureConfiguration'
import { JiraConfiguration, jiraConfigurationViewState } from './jiraConfiguration'
import { RedmineViaClientConfiguration, redmineViaClientConfigurationViewState } from './redmineViaClientConfiguration'
import { BacklogConfigurationViewState, ConfigurationContext } from '../viewModel'
import { Loading, Warning } from '@/ui'
import { jsx } from '@/h'
import xs from 'xstream'
import { mergeSinks, notNull } from '@/generic'
import { isLevel5 } from '@/prim/model'
import { caseValue, match } from '@/fsharp'
import { matchBy } from '@/generic/case'
import { GUID, RandomRequest } from '@/drivers'
import dropRepeats from 'xstream/extra/dropRepeats'

type State = BacklogConfigurationViewState

export type BacklogConfigurationSources = ProjectPageSources<State> & { backlogConfigurationId: string }

type Sources = BacklogConfigurationSources

export const backlogConfigurationViewState = (context: ConfigurationContext): State => ({
  type: 'initial',
  context: context
})

const getConfigurationComponent = (sources: Sources) =>
  sources.state.stream
    .map(({ type }) => type)
    .compose(dropRepeats())
    .map((type): PageSinks | null => {
      switch (type) {
        case 'initial': return { DOM: xs.of(initialView()) }
        case 'notFound': return { DOM: xs.of(notFoundView()) }
        case 'azure': return isolate(AzureConfiguration, 'state')(sources) as PageSinks
        case 'jira': return isolate(JiraConfiguration, 'state')(sources) as PageSinks
        case 'redmineViaClient': return isolate(RedmineViaClientConfiguration, 'state')(sources) as PageSinks
        default: return null
      }
    })
    .filter(notNull)

const initialView = () =>
  <Loading />

const notFoundView = () =>
  <Warning>Backlog configuration not found.</Warning>

export type NewBacklogConfigurationSources = ProjectPageSources<State> & { type: 'azure' | 'jira' | 'redmineViaClient' }

const getContext  = (state: State) =>
  matchBy('type')(state, {
    initial: s => s.context,
    notFound: s => s.context,
    azure: s => s.state.context,
    jira: s => s.state.context,
    'new-azure': s => s.newState.context,
    'new-jira': s => s.newState.context,
    'new-redmineViaClient': s => s.newState.context,
    redmineViaClient: s => s.state.context,
  })

const selectNewStateAndComponent = (sources: NewBacklogConfigurationSources, backlogConfigurationId: string) =>
  matchBy('type')(sources, {
    azure: _ => ({
      component: isolate(AzureConfiguration, 'newState'),
      initialState: (context: ConfigurationContext) => azureConfigurationViewState(context, backlogConfigurationId, null)
    }),
    jira: _ => ({
      component: isolate(JiraConfiguration, 'newState'),
      initialState: (context: ConfigurationContext) => jiraConfigurationViewState(context, backlogConfigurationId, null)
    }),
    redmineViaClient: _ => ({
      component: isolate(RedmineViaClientConfiguration, 'newState'),
      initialState: (context: ConfigurationContext) => redmineViaClientConfigurationViewState(context, backlogConfigurationId, null)
    }),
  })

export const NewBacklogConfigurationComponent = (sources: NewBacklogConfigurationSources) => {
  const newId =
    sources
      .random
      .select<GUID>('newBacklogConfigurationId')
      .take(1)
      .map(random => random.value)

  const initialState$ =
    newId
      .map(newId => {
        const { initialState } = selectNewStateAndComponent(sources, newId)
        return xs.of((s: State): State =>
          ({
            type:
              s.type === 'azure'
                ? 'new-azure'
                : s.type === 'jira'
                  ? 'new-jira'
                  : 'new-redmineViaClient',
            newState: initialState(getContext(s))
          } as State))
      })
      .flatten()

  const page$ =
    sources.state.stream
      .filter((s): s is typeof s & { type: 'new-azure' | 'new-jira' | 'new-redminViaClient' } =>
        s.type === 'new-azure' || s.type === 'new-jira' || s.type === 'new-redmineViaClient')
      .take(1)
      .map(state =>  {
        const { component } = selectNewStateAndComponent(sources, state.newState.backlogConfigurationId)
        return component(sources)
      })

  return mergeSinks(
    mergePageSinks(page$ as xs<PageSinks>, pageSinkMergeTemplate), {
      state: initialState$,
      random: xs.of<RandomRequest>({
        category: 'newBacklogConfigurationId',
        type: 'guid'
      })
    })
}

export const ExistingBacklogConfigurationComponent = (sources: Sources) => {
  const component$ = getConfigurationComponent(sources)

  return mergeSinks(mergePageSinks(component$, pageSinkMergeTemplate), {
    state:
      xs.of((s: State): State => {
        const context = getContext(s)

        const maybeBacklogConfiguration =
        isLevel5(context.configuration.levelConfiguration)
          ? caseValue(context.configuration.levelConfiguration).backlogConfigurations.find(b => b.item1 === sources.backlogConfigurationId)
          : undefined

        if (!maybeBacklogConfiguration) {
          return { type: 'notFound', context: context }
        }

        return match(maybeBacklogConfiguration.item2, {
          AzureBacklogConfiguration: (b): State => ({
            type: 'azure',
            state: azureConfigurationViewState(context, maybeBacklogConfiguration.item1, b)
          }),
          JiraBacklogConfiguration: (b): State => ({
            type: 'jira',
            state: jiraConfigurationViewState(context, maybeBacklogConfiguration.item1, b)
          }),
          RedmineViaClientBacklogConfiguration: (b): State => ({
            type: 'redmineViaClient',
            state: redmineViaClientConfigurationViewState(context, maybeBacklogConfiguration.item1, b)
          })
        })
      })
  })
}
