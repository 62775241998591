import { InternalListener, InternalProducer, Stream } from 'xstream'

class Once<T> implements InternalProducer<T> {
  public readonly type = 'once'
  public readonly a: Array<T>
  public emitted = false

  constructor(a: Array<T>) {
    this.a = a
  }

  _start(out: InternalListener<T>): void {
    if (this.emitted) {
      return
    }
    this.emitted = true
    const a = this.a
    for (let i = 0, n = a.length; i < n; i++) {
      out._n(a[i])
    }
    out._c()
  }

  _stop(): void {
    // noop
  }
}

export const once = <T>(...values: T[]) => new Stream(new Once(values))
