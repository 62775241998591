import { Container, Row, Col, renderLoadable, Input, dateInputFormat, Icon, Danger, Button, Warning } from '@/ui'
import { renderCell } from '@/ui/tanstack'
import format from 'date-fns/format'
import { VNode } from 'snabbdom'
import { renderConfigurationResponse, renderProblemFor, setTaskMappingConfigurationProblemRenderConfig, insufficientRightsRenderConfig, requestErrorRenderConfig, tiaConfigInvalidRenderConfig, unhandledApiExceptionRenderConfig, unhandledTiaProblemRenderConfig } from '../../renderers'
import { TaskMappingViewState } from '../viewModel'
import { jsx } from '@/h'
import { isLevel3OrHigher } from '@/prim/model'

export const view = (
  state: TaskMappingViewState,
  tiaCodeSuggest: VNode,
  timeTrackerTaskSuggest: VNode
) => {
  if (!isLevel3OrHigher(state.context.configuration.levelConfiguration)) {
    return <Container>
      <Warning>You cannot configure the task mappings if the project level is lower than level 3. Configure a time registration integration first.</Warning>
    </Container>
  }

  if (typeof state.context.tiaCodes.case === 'string') {
    return <Container>
      <Danger>{renderProblemFor(state.context.tiaCodes)({
        ...requestErrorRenderConfig,
        ...insufficientRightsRenderConfig,
        ...unhandledApiExceptionRenderConfig,
        ...unhandledTiaProblemRenderConfig,
        ...tiaConfigInvalidRenderConfig
      })}</Danger>
    </Container>
  }

  return (
    <Container className="mapping-table">
      <Row key="header">
        <Col><label className="required">Task</label></Col>
        <Col><label className="required">TIA-code</label></Col>
        <Col><label>Members</label></Col>
        <Col><label className="required">From</label></Col>
        <Col><label>Until</label></Col>
        <Col width={1}></Col>
      </Row>
      {renderLoadable(state.table, table => table.getRowModel().rows.map(row => {
        return <Row>
            {...row.getVisibleCells().map((cell, i, cells) =>
              <Col {...(i === cells.length - 1 ? { width: 1 } : {})}>
                {renderCell(cell)}
              </Col>)}
        </Row>
      }))}
      <Row key="new">
        <Col>
          { timeTrackerTaskSuggest }
        </Col>
        <Col>
          {tiaCodeSuggest}
        </Col>
        <Col></Col>
        <Col><Input type="date" className="new-item-from" value={state.newItem.from ? format(state.newItem.from, dateInputFormat) : ''} /></Col>
        <Col><Input type="date" className="new-item-until" value={state.newItem.until ? format(state.newItem.until, dateInputFormat) : ''} /></Col>
        <Col width={1}><Button context="success" id="add-new-task-mapping"><Icon icon="plus" /></Button></Col>
      </Row>
      <Row key="controls" className="mt-2">
        <Col>
          <Button context="primary" id="save-task-mapping">Save</Button>
          {renderLoadable(state.putTaskMappingConfigurationResponse, {
            loaded: () => null,
            error: problem => renderLoadable(state.tasks, tasks =>
              <Danger className="mt-3">
                {renderConfigurationResponse(setTaskMappingConfigurationProblemRenderConfig(tasks))(problem)}
              </Danger>)
          })}
        </Col>
      </Row>
    </Container>
  )
}
