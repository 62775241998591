import { CaseType, caseFactory, CaseTypeFor, FOption, FTuple } from '@/fsharp'

export type JiraCloudApiTokenAuthentication = {
  username: string
  token: string
}

export type JiraServerPat = {
  token: string
}

export type JiraAuthenticationInfo =
  | CaseType<'JiraCloudApiTokenAuthentication', [JiraCloudApiTokenAuthentication]>
  | CaseType<'JiraServerPat', [JiraServerPat]>

export const JiraCustomFieldId =
  caseFactory<CaseType<'JiraCustomFieldId', [string]>>()
export type JiraCustomFieldId = CaseTypeFor<typeof JiraCustomFieldId>

export const JiraIssueLinkId = caseFactory<CaseType<'JiraIssueLinkId', [string]>>()
export type JiraIssueLinkId = CaseTypeFor<typeof JiraIssueLinkId>

export const JiraIssueLinkDirection =
  caseFactory<
  | CaseType<'Inward'>
  | CaseType<'Outward'>>()
export type JiraIssueLinkDirection = CaseTypeFor<typeof JiraIssueLinkDirection>

export type JiraAutoLinkIds =
  {
    hierarchyLinkId: FOption<JiraIssueLinkId>
    epicLinkId: FOption<JiraCustomFieldId>
    parentLinkId: FOption<JiraCustomFieldId>
  }

export const JiraParentLink =
  caseFactory<
  | CaseType<'Auto', [autoLinkIds: JiraAutoLinkIds]>
  | CaseType<'ParentIssue'>
  | CaseType<'EpicLink', [epicLinkFieldId: JiraCustomFieldId]>
  | CaseType<'ParentLink', [parentLinkFieldId: JiraCustomFieldId]>
  | CaseType<'IssueLink', [issueLinkId: JiraIssueLinkId, linkDirection: JiraIssueLinkDirection]>>()

export type JiraParentLink = CaseTypeFor<typeof JiraParentLink>

export const JiraTypeId = caseFactory<CaseType<'JiraTypeId', [string]>>()
export type JiraTypeId = CaseTypeFor<typeof JiraTypeId>

export type JiraCustomHierarchyDefinition = {
  topLevelTypeId: JiraTypeId
  definedDescendants: FTuple<[JiraTypeId, JiraParentLink[]]>[]
  rest: JiraParentLink[]
}

export const JiraHierarchyDefinition =
  caseFactory<
  | CaseType<'Default', [JiraAutoLinkIds]>
  | CaseType<'Custom', [JiraCustomHierarchyDefinition]>>()
export type JiraHierarchyDefinition = CaseTypeFor<typeof JiraHierarchyDefinition>

export type JiraMappingDefinition = {
  storyPoints: FOption<JiraCustomFieldId>
  iteration: FOption<JiraCustomFieldId>
}

export type JiraBacklogConfiguration = {
  host: string
  project: string
  authenticationInfo: JiraAuthenticationInfo
  hierarchyDefinition: JiraHierarchyDefinition
  mappingDefinition: JiraMappingDefinition
}
