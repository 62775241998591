import { Subtract } from '@/generic/arithmetic'

// standard types
export type CaseTypeWithFields<TCase extends string, TFields extends any[]> = {
  case: TCase
  fields: TFields
}

export type CaseTypeWithoutFields<TCase extends string> = {
  case: TCase
}

export type CaseType<TCase extends string, TFields extends any[] | undefined = undefined> = {
  case: TCase
} & (TFields extends any[]
  ? {
    fields: TFields
  }
  // eslint-disable-next-line @typescript-eslint/ban-types
  : {})

export const caseValues = <TCaseType extends CaseType<any, any>>(caseType: TCaseType):
TCaseType extends CaseTypeWithFields<any, infer Fields> ? Fields : [] =>
  (caseType as CaseTypeWithFields<any, any>).fields || []

export const caseValue = <TValue>(caseType: CaseType<string, [TValue]>) => caseType.fields[0]

export const isCaseValue = <T>(value: T | CaseType<string, any[] | undefined>): value is CaseType<string, any[] | undefined> =>
  !!(value && typeof value === 'object' && (value as CaseType<string, any[] | undefined>).case)

// case "None" is serialized as null apparently
export type FOption<T> = null | CaseType<'Some', [T]>
export const fSome = <T>(x: T): CaseType<'Some', [T]> => ({ case: 'Some', fields: [x] })
export const asSimple = <T>(option: FOption<T>) => option && caseValue(option)
export const asOption = <T>(value: T | null): FOption<T> => value === null ? null : fSome(value)
export const mapFOption = <T, U>(map: (v: T) => U) => (option: FOption<T>) => option === null ? option : fSome(map(caseValue(option)))

type Counts<Arr extends any[]> =
  Arr extends [infer _, ...infer Rest] & { length: infer L}
    ? [L, ...Counts<Rest>]
    : []

type CountsAsType<Indexes extends number[]> =
  Indexes extends (infer T)[]
    ? T extends number
      ? T
      : number
    : number


export type FTuple<TValues extends any[]> =
  { [L in CountsAsType<Counts<TValues>> as `item${L}`]: TValues[Subtract<L, 1>] }

export const Tuple = <TArgs extends any[]>(...args: TArgs): FTuple<TArgs> => {
  const result: Record<string, any> = {}

  for (let i = 0; i < args.length; i++) {
    result[`item${i + 1}`] = args[i]
  }

  return result as any
}

export type Cases<T> = T extends CaseType<infer TCases, any> ? TCases : never
export type CaseValue<T, TCase extends string> =
  T extends CaseType<TCase, any>
    ? T extends { fields: infer TFields }
      ? TFields
      : never
    : never

export function match<TCases extends CaseType<string, any>, TMap extends {
  [key in Cases<TCases>]:
  (CaseValue<TCases, key> extends never
    ? (() => any)
    : ((...args: CaseValue<TCases, key>) => any))  }>(
  cases: TCases,
  handlers: TMap): TMap extends { [_: string]: (...args: any[]) => infer TOut } ? TOut : never {
  return (handlers as any)[cases.case].apply(undefined, (cases as any).fields)
}

export type CaseFactory<T extends CaseType<string, any>> =
  { [key in Cases<T>]:
    (CaseValue<T, key> extends never
      ? (() => T)
      : ((...args: CaseValue<T, key>) => T)) }

const getCaseFactory = (caseName: string) =>
  (...args: any []): CaseType<string, any> => args.length ? { case: caseName, fields: args } : { case: caseName }

const caseFactoryHandler: ProxyHandler<Record<string, any>> = {
  get: (target, key,) => {
    if (typeof key !== 'string') {
      return undefined
    }

    if (!(key in target)) {
      target[key] = getCaseFactory(key)
    }

    return target[key]
  },
  apply: () => {
    throw new Error('Cannot call a union factory. Use the member methods to construct the corresponding case.')
  },
  ownKeys: () => [],
  has: () => false,
  getPrototypeOf: () => null,
  getOwnPropertyDescriptor: (target, propertyKey) => {
    let value: any

    if (typeof propertyKey !== 'string') {
      value = undefined
    } else {

      if (!(propertyKey in target)) {
        target[propertyKey] = getCaseFactory(propertyKey)
      }

      value = target[propertyKey]
    }

    return ({
      value: value
    })
  },
  setPrototypeOf: () => {
    throw new Error('Cannot modify a union factory.')
  },
  preventExtensions: () => {
    throw new Error('Cannot modify a union factory.')
  },
  defineProperty: () => {
    throw new Error('Cannot modify a union factory.')
  },
  set: () => {
    throw new Error('Cannot modify a union factory.')
  },
  deleteProperty: () => {
    throw new Error('Cannot modify a union factory.')
  },
  construct: () => {
    throw new Error('Cannot construct from a union factory. Use the member methods to construct the corresponding case.')
  },
  isExtensible: () => {
    return false
  }
}

export const caseFactory = <TCaseTypes extends CaseType<string, any>>() => {
  const proxy = new Proxy<Record<string, any>>({}, caseFactoryHandler)

  return proxy as CaseFactory<TCaseTypes>
}

export type CaseTypeFor<TCaseFactory extends CaseFactory<any>> = TCaseFactory extends CaseFactory<infer TCases> ? TCases : never
