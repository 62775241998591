import isolate from '@cycle/isolate'
import { setup } from '@cycle/run'
import 'bootstrap'
import { App } from './app'
import { logSaver, playLog } from './logs'
import { makeDrivers } from './makeDrivers'
import { rerunnerAsync } from './recorder/recorder'

const content = document.querySelector('#content')
if (!content) {
  throw new Error('Missing render container!')
}

const seed = Math.random() * 10e8

const initialRun = rerunnerAsync(App as any, makeDrivers(seed) as any, setup, isolate)

initialRun.then(({ logs: log, run: rerun, dispose }) => {
  let rerunResult: ReturnType<typeof rerun>
  const logs = [log]
  let currentApp = App
  let currentDispose = dispose

  ;(window as any).logs = logs

  window.addEventListener('beforeunload', () => {
    dispose()
    logs.length = 0
  })

  ;(window as any).saveLog = logSaver(logs)
  ;(window as any).playLog = (logId: string) => playLog(log, logId, rerun, currentDispose, currentApp)

  if (module.hot) {
    // disabling this with "hot-only" mode will ignore the weird "index always changed" bug
    // module.hot.accept('./index.ts'); // entry module must accept itself
    console.log('HOT!')
    const restart = () => {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      currentApp = require('./app').App

      const play = (playLog?: any) => {
        if (rerunResult) {
          rerunResult.then(rerunLogs => {
            currentDispose = rerunLogs.dispose
            logs.push(JSON.parse(JSON.stringify(rerunLogs.logs)))
          })
        }
        rerunResult = rerun(currentApp, playLog || null)
      }

      play()
    }

    module.hot.accept('./app', restart)
  }
})
