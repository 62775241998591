import { MainDOMSource } from '@cycle/dom'
import classnamesImport from 'classnames'
import parse from 'date-fns/parse'
import { VNode, VNodeData } from 'snabbdom'
import xs from 'xstream'
import { C, compose, deepFlatten, id } from '../generic'
import { dateInputFormat } from './date'

type RenderableInner = VNode | null | string | Array<VNode | null | string>

export type Renderable = RenderableInner | Array<RenderableInner>

export const flattenRenderable = (renderables: Renderable[]) => deepFlatten(renderables as Renderable[][]) as Renderable

export type Props<T = unknown> = VNodeData & { className?: string | Record<string, boolean> } & T

export const classnames = classnamesImport
export const cn = classnames

export const click = (DOM: MainDOMSource, selector: string) => DOM.select(selector).events('click')
export const input = (DOM: MainDOMSource, selector: string) => DOM.select(selector).events('input')
export const change = (DOM: MainDOMSource, selector: string) => DOM.select(selector).events('change')
export const focus = (DOM: MainDOMSource, selector: string) => DOM.select(selector).events('focus')
export const blur = (DOM: MainDOMSource, selector: string) => DOM.select(selector).events('blur')

export const fromData = <T extends DOMStringMap, U>(f: (x: T) => U) => (el: HTMLElement) => f(el.dataset as T)
export const target = <T extends EventTarget = EventTarget>(f: (e: Event) => T = e => (e as any).ownerTarget as T) => (e: Event) => f(e)
export const inputTarget = target<HTMLInputElement>()
export const elementTarget = target<HTMLElement>()

export const inputValue = compose(inputTarget, el => el.value)
export const targetData = <T extends DOMStringMap, U>(f: (x: T) => U) => compose(elementTarget, fromData(f))

export const deleteKeyCode = 46
export const escapeKeyCode = 27
export const backspaceKeyCode = 8
export const enterKeyCode = 13

export const leftKeyCode = 37
export const upKeyCode = 38
export const rightKeyCode = 39
export const downKeyCode = 40

export const shiftKeyCode = 16
export const ctrlKeyCode = 17
export const altKeyCode = 18

type KeyCodeSpecification = {
  code: string
}
type KeyValueSpecification = {
  key: string
}
type KeyModifierSpecification = {
  altKey?: boolean
  ctrlKey?: boolean
  shiftKey?: boolean
}
export type KeySpecification = (KeyCodeSpecification | KeyValueSpecification) & KeyModifierSpecification
export type KeyMatcher = KeySpecification | ((e: KeyboardEvent) => boolean)

function modifiersMatch(modifiers: KeyModifierSpecification, e: KeyboardEvent) {
  return (modifiers.ctrlKey == null || modifiers.ctrlKey === e.ctrlKey)
    && (modifiers.altKey == null || modifiers.altKey === e.altKey)
    && (modifiers.shiftKey == null || modifiers.shiftKey === e.shiftKey)
}

const keyMatches = (matcher: KeyMatcher) => (e: KeyboardEvent) =>
  (typeof matcher === 'function' ? matcher(e) : modifiersMatch(matcher, e) ? ('code' in matcher ? e.code === matcher.code : e.key === matcher.key) : false)

export const keydown = <T = KeyboardEvent>(DOM: MainDOMSource, selector: string, keyMatcher: KeyMatcher, f: (e: KeyboardEvent) => T = e => (e as any) as T) =>
  DOM.select(selector)
    .events('keydown', {
      preventDefault: keyMatches(keyMatcher)
    })
    .filter(keyMatches(keyMatcher))
    .map(f)

export const c = (className: string) => '.' + className

export function events<T extends { [selector: string]: (DOM: MainDOMSource, selector: string) => xs<any> }>(config: T) {
  return ({ DOM }: { DOM: MainDOMSource }) =>
    Object.keys(config)
      .map(key => ({
        key,
        value: config[key](DOM, key)
      }))
      .reduce((acc, val) => ({ ...acc, [val.key]: val.value }), {} as { [P in keyof T]: ReturnType<T[P]> })
}

export function inputs<T extends { [selector: string]: ((value: string, e: Event) => any) | null }>(config: T) {
  return ({ DOM }: { DOM: MainDOMSource }) =>
    Object.keys(config)
      .map(key => ({
        key,
        value: input(DOM, key).map(e => config[key] ? config[key]!(inputValue(e), e) : inputValue(e))
      }))
      .reduce((acc, val) => ({ ...acc, [val.key]: val.value }), {} as { [P in keyof T]: xs<T[P] extends ((...args: any[]) => infer RT) ? RT : string> })
}

export const capture = <T, U>(fa: (source: { DOM: MainDOMSource }) => T, fb: (source: { DOM: MainDOMSource }) => U) =>
  (source: { DOM: MainDOMSource }) => ({ ...fa(source), ...fb(source) })

export const findParentNode = (nodeName: keyof (HTMLElementTagNameMap & SVGElementTagNameMap)) => (el: HTMLElement): HTMLElement =>
  (el.nodeName.toUpperCase() === nodeName.toUpperCase() ? el : findParentNode(nodeName)(el.parentElement!))

export function blockEvent(ev: Event) {
  ev.stopPropagation()
  ev.preventDefault()
  return false
}

const getIndexFromTargetData = (e: Event) => targetData(data => +data.index!)(e)

export const withIndex = <T>(value: (e: Event) => T) =>
  C(input, es => es.map(e => ({
    value: value(e),
    index: getIndexFromTargetData(e)
  })))

export const clickWithIndexedValue = withIndex(inputValue)

export const clickWithIndex = C(click, es => es.map(getIndexFromTargetData))

export const dateInput = (value: string) => {
  const maybeParsed = parse(value, dateInputFormat, 0)
  return maybeParsed && +maybeParsed ? maybeParsed : null
}

export const numberInput = (value: string) => {
  if (value === '') {
    return null
  }

  const maybeNumber = +value

  return isNaN(maybeNumber) ? null : maybeNumber
}

export const indexedInputValue = <T extends any>(map: (value: string) => T) => withIndex(C(inputValue, map))

export const inputValueWithKey = <T extends any>(map: (value: string) => T) => C(
  input,
  events => events.map(e => ({
    index: targetData(data => data.key!)(e),
    value: map(inputValue(e))
  })))

export const indexedValue = indexedInputValue(id)

export const valueWithKey = inputValueWithKey(id)

export const indexedDate = indexedInputValue(dateInput)

export const readChecked = (es: xs<Event>) => es.map(C(inputTarget, el => el.checked))
