import { RouterSource } from 'cyclic-router'
import { format } from 'date-fns'
import { VNode, VNodeData } from 'snabbdom'
import { jsx, JSXComponent } from 'h'
import { dateInputFormat } from './date'
import { createHref, isSubPath, LinkProps } from './links'
import { blockEvent, classnames, cn, Props, Renderable } from './util'
import { omit } from '@/util'

export type ContextClass = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark'
export type ColorScheme = ContextClass
export type ColWidth = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
export type DeviceSize = 'sm' | 'md' | 'lg' | 'xl'

type ContextProp = {
  context: ContextClass
}

const formControl = 'form-control'

const elWithClass =
  (el: (keyof JSX.IntrinsicElements) | JSXComponent) =>
    (fixedClassNames: string) =>
      (props: VNodeData, ...children: any[]) =>
        jsx(el, {...props, className: cn(props.className, fixedClassNames)}, ...children)

const divWithClass = elWithClass('div')

export type ElementFactory<TProps = Record<string, any>> = (props: Props<TProps>, content: Renderable) => VNode | null

export const Alert = (props: Props<ContextProp>, content: Renderable) => (
  <div className={classnames(props.className, 'alert alert-' + props.context)} role="alert">
    {content}
  </div>
)

export const Warning = (props: Props, content: Renderable) => Alert({ ...props, context: 'warning' }, content)

export const Danger = (props: Props, content: Renderable) => Alert({ ...props, context: 'danger' }, content)

export const Success = (props: Props, content: Renderable) => Alert({ ...props, context: 'success' }, content)

export const Info = (props: Props, content: Renderable) => Alert({ ...props, context: 'info' }, content)

export const Card = divWithClass('card')
export const CardHeader = divWithClass('card-header')
export const CardBody = divWithClass('card-header')

export function Button(props: Props<{ context: ContextClass; border?: boolean; large?: boolean; type?: 'button' | 'submit'; disabled?: boolean }>, content: Renderable) {
  const { border: hasBorder = true } = props
  return (
    <button
      type={props.type || 'button'}
      {...props}
      className={classnames(props.className, {'border': hasBorder}, 'btn btn-' + props.context, {
        'btn-large': !!props.large
      })}
    >
      {content}
    </button>
  )
}

export const Form = (props: Props, content: Renderable) => <form {...props} on={{ ...(props.on as any), submit: blockEvent }}>{content}</form>

export const FormCheck = divWithClass('form-check')

export const Label = elWithClass('label')('form-label')

export const InputGroup = divWithClass('input-group')
export const InputGroupPrepend = divWithClass('input-group-prepend')
export const InputGroupAppend = divWithClass('input-group-append')

export type TextInputProps = {
  type?: 'text' | 'password' | 'date' | 'number' | 'button'
  value: string
  size?: number
}

export function Input(props: Props<TextInputProps>) {
  return <input {...props} type={props.type || 'text'} className={classnames(props.className, formControl)} />
}

export function ReadonlyInput(props: Props) {
  return <input {...props} readOnly className={classnames(props.className, 'form-control-plaintext')} />
}

export const TextArea = elWithClass('textarea')(formControl)

export function DateInput(props: Props<{ value: Date | null }>) {
  return <input {...props} type="date" className={cn(props.className, formControl)} value={props.value ? format(props.value, dateInputFormat) : ''} />
}

export const Select = elWithClass('select')('form-select')

export function ListGroupItem(props: Props<{ isActive?: boolean }>, content: Renderable) {
  return (
    <li {...props} className={classnames(props.className, 'list-group-item', { active: props.isActive })}>
      {content}
    </li>
  )
}

export function ListGroup(props: Props, items: ReturnType<typeof ListGroupItem>[]) {
  return (
    <ul {...props} className={cn(props.className, 'list-group')}>
      {items}
    </ul>
  )
}

export function Checkbox(props: Props<{value?: string; checked: boolean}>) {
  return <input {...props} type="checkbox" className={cn(props.className, 'form-check-input')} />
}

// Unclear why form-check-label is necessary, but it's still in the documents
export function CheckboxLabel(props: Props<{for?: string}>, content: Renderable) {
  return <label {...props} className={cn(props.className, 'form-check-label')}>
    {content}
  </label>
}

export function Col(props: Props<{ width?: ColWidth | 'auto' } | { width: ColWidth | 'auto'; breakpoint: DeviceSize }>, content: Renderable) {
  const { width, breakpoint } = props
  return (
    <div {...props} className={cn(props.className, props.width ? props.breakpoint ? `col-${breakpoint}-${width}` : `col-${width}` : 'col')}>
      {content}
    </div>
  )
}

export function Row(props: Props, content: ReturnType<typeof Col>[]) {
  return (
    <div {...props} className={classnames(props.className, 'row')}>
      {content}
    </div>
  )
}

export function Container(props: Props, contents: ReturnType<typeof Row>[]) {
  return (
    <div {...props} className={classnames(props.className, 'container-fluid')}>
      {contents}
    </div>
  )
}

export function Center(props: Props, contents: Renderable) {
  return (
    <div {...props} className={classnames(props.className, 'row justify-content-center full-view-height')}>
      <div className="col-auto center-col">{contents}</div>
    </div>
  )
}

export const Loading = divWithClass('loading')

export const NavItem = (props: Props, contents: Renderable) => <li {...props} className={cn(props.className, 'nav-Item nav-item')}>{contents}</li>
export const NavSubitem = (props: Props, contents: Renderable) => <li {...props} className={cn(props.className, 'nav-subitem')}>{contents}</li>

export const NavBar = (props: Props & { size?: DeviceSize; color?: ColorScheme }, contents: Renderable) =>
  <nav {...props} className={cn(props.className, 'navbar', `navbar-expand-${props.size || 'xl'}`)}>
    <div className="container-fluid">
      {contents}
    </div>
  </nav>

export const NavBrand = (props: Props & LinkProps, contents: Renderable) =>
  <a {...props} className={cn(props.className, 'navbar-brand')} href={props.href}>{contents}</a>

export const NavBarNav = (props: Props, contents: ReturnType<typeof NavItem>[]) =>
  <ul {...props} className={cn(props.className, 'navbar-nav')}>{contents}</ul>

export const Nav = (props: Props, contents: ReturnType<typeof NavItem>[]) =>
  <ul {...props} className={cn(props.className, 'nav')}>{contents}</ul>

export const navLinkClass = 'nav-link'

export const NavLinkCreator = (router: RouterSource) => (props: Props<LinkProps>, content: Renderable) => {
  return (
    <a className={cn(props.className, navLinkClass, { active: isSubPath(router, props.href) })} href={createHref(router, props.href)}>
      {content}
    </a>
  )
}

export type NavLink = ReturnType<typeof NavLinkCreator>

export const ToggleButtonGroup = (props: Props, content: Renderable) =>
  <div className={cn(props.className, 'btn-group btn-group-toggle')} role="group">
    {content}
  </div>

export const ToggleButton = (props: Props<{ active: boolean; name: string; value: string }>, content: Renderable) =>
  <label
    {...omit(props, 'active', 'name', 'value')}
    className={cn(props.className, 'btn btn-primary', { active: props.active })}>
    <input
      type="radio"
      className="btn-check"
      name={props.name}
      value={props.value}
      autocomplete="off"
      checked={props.active} />
    {content}
  </label>
