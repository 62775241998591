import { StreamType, isLoaded, value } from '@/generic'
import { BacklogConfigurationResource } from '@/prim/resources'
import { ProjectPageSources } from '../../common'

const empty = {}

export const handlePutBacklogConfigurationResponse = <T>(sources: ProjectPageSources<T>) => (response: StreamType<BacklogConfigurationResource['putResponse']>) => (_state: T) => {
  if (!isLoaded(response)) {
    return empty
  }

  const maybeCreatedBacklogConfigurationId = value(response,)

  return maybeCreatedBacklogConfigurationId
    ? {
      redirect: [sources.parentRouter.createHref(`/backlog/${maybeCreatedBacklogConfigurationId}`)]
    }
    : {
      HTTP: [sources.primState.projectConfiguration(sources.projectKey).refresh]
    }
}
