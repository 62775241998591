import addDays from 'date-fns/addDays'
import startOfDay from 'date-fns/startOfDay'
import xs from 'xstream'

export type DateDriver = ReturnType<typeof makeDateDriver>
export type DateSource = ReturnType<DateDriver>

export function makeDateDriver(startDate: Date = new Date()) {
  const startDay = startOfDay(startDate)
  const days$ = xs.createWithMemory<Date>()

  function setNextDay(day: Date) {
    days$.shamefullySendNext(day)
    const nextDay = addDays(startDay, 1)
    return setTimeout(() => {
      timeoutId = setNextDay(nextDay)
    }, +nextDay - +day)
  }

  let timeoutId = setNextDay(startDay)

  const driver = (_: any) => ({
    days: days$
  })

  driver.dispose = () => clearTimeout(timeoutId)

  return driver
}
