import { isLoadError, isLoading, isUnloaded, isWithStale, Loadable, loadErrorMessage, staleValue, value } from '../generic'
import { jsx } from 'h'
import { Loading } from './bootstrap'
import { Renderable } from './util'

const defaultUnloadedMessage = () => null
const defaultLoadingMessage = () => <Loading />
const defaultLoadingErrorMessage = (error: unknown) => (
  <div className="alert alert-danger" role="alert">
    Error while loading:
    <p>{error}</p>
  </div>
)

export type RenderLoadableConfig<T, TError> = {
  loaded: (x: T, isLoading: boolean) => Renderable
  loading?: () => Renderable
  unloaded?: () => Renderable
  error?: (error: TError) => Renderable
}

export function renderLoadable<T, TError>(loadable: Loadable<T, TError>, config: RenderLoadableConfig<T, TError>): Renderable
export function renderLoadable<T, TError>(
  loadable: Loadable<T, TError>,
  renderLoaded: (x: T, isRefreshing: boolean) => Renderable,
  renderLoading?: () => Renderable,
  renderUnloaded?: () => Renderable,
  renderError?: (error: TError) => Renderable
): Renderable
export function renderLoadable<T, TError>(
  loadable: Loadable<T, TError>,
  configOrRenderLoaded: RenderLoadableConfig<T, TError> | ((x: T, isRefreshing: boolean) => Renderable),
  renderLoadingParam?: () => Renderable,
  renderUnloadedParam?: () => Renderable,
  renderErrorParam?: (error: TError) => Renderable
): Renderable {
  if (!loadable) {
    throw new Error('Not a loadable value: ' + loadable)
  }

  const renderers =
    typeof configOrRenderLoaded === 'function'
      ? {
        loaded: configOrRenderLoaded,
        loading: renderLoadingParam,
        unloaded: renderUnloadedParam,
        error: renderErrorParam
      }
      : configOrRenderLoaded

  if (isUnloaded(loadable)) {
    return (renderers.unloaded || defaultUnloadedMessage)()
  }
  if (isLoadError(loadable)) {
    return (renderers.error || defaultLoadingErrorMessage)(loadErrorMessage(loadable))
  }
  if (isLoading(loadable)) {
    return isWithStale(loadable) ? renderers.loaded(staleValue(loadable), true) : (renderers.loading || defaultLoadingMessage)()
  }
  return renderers.loaded(value(loadable), false)
}
