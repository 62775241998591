import { PromisedType } from '../generic'
import { config } from './config.static'
import { commonConfig } from './config.static.common'

export const staticConfig = {
  ...commonConfig,
  ...config
}

type DynamicConfig = typeof import('./config.json')

const fetchConfig = () =>
  fetch('/config.json')
    .then(response =>
      response
        .text()
        .then(content => (
          {
            ...staticConfig,
            ...JSON.parse(content) as DynamicConfig
          })))

let fetchedConfig: ReturnType<typeof fetchConfig>

export const loadConfig = () => fetchedConfig = fetchedConfig || fetchConfig()
export const reloadConfig = () => fetchedConfig = fetchConfig()

export type Config = PromisedType<typeof fetchedConfig>
