import xs from 'xstream'
import { AuthEvent, AuthEventType } from '../drivers/msalDriver'
import { User } from './types'

export const USER = 'user'

export const Anonymous = 'anonymous'
export const Unknown = 'unloaded'
export const AuthError = 'authError'
export type UserState = typeof Unknown | typeof Anonymous | User | typeof AuthError

export const isUser = (state: UserState): state is User => typeof state === 'object'
export const isUnknown = (state: UserState): state is User => state === Unknown
export const isAnonymous = (state: UserState): state is User => state === Anonymous
export const isAuthError = (state: UserState): state is User => state === AuthError

export const UserProvider = (auth: xs<AuthEvent>) => ({
  [USER]: auth.fold((_state, event) => {
    if (event.type === AuthEventType.AccountsLoaded) {
      return event.accounts.length === 0 ? Anonymous : event.accounts[0]
    } else {
      return AuthError
    }
  }, Unknown as UserState)
})

export type UserProvider = ReturnType<typeof UserProvider>
export type UserSource = UserProvider[typeof USER]
