import { HTTPSource, RequestInput, RequestOptions } from '@cycle/http'
import isolate, { Component } from '@cycle/isolate'
import { Reducer, StateSource } from '@cycle/state'
import xs, { MemoryStream } from 'xstream'
import dropRepeats from 'xstream/extra/dropRepeats'
import sampleCombine from 'xstream/extra/sampleCombine'
import { isUser, UserState } from '../authentication/userProvider'
import { Resource } from '../dataServices/types'
import { C, exists, id, isLoading, K, notNull, ReturnType2, StreamType, Unloaded, withoutKeys } from '../generic'
import { IntentDefinitionWithSinks, PageSinks, PageSources, SINKS } from '../infrastructure'
import { PrimPageSources } from './components/types/cycleTypes'
import { newProjectResource, updateProjectConfiguration, projectMembersConfiguration, projectsListResource, reportParametersConfiguration, sprintsConfiguration, ordersConfiguration, timeTrackerConfiguration, projectConfigurationResource } from './resources'
import { projectClockifyProjectsResource, projectClockifyWorkspacesResource } from './resources/clockify'
import { projectPaymoProjectsResource } from './resources/paymo'
import { rolesResource } from './resources/roles'
import { tiaCodesResource, tiaSearchCodesResource } from './resources/tiaTimesheetCodes'
import { tiaUsersResource } from './resources/tiaUsers'
import { userTimesheetResource } from './resources/timesheetReport'
import { timeTrackerUsersResource } from './resources/timeTrackerUsers'

export const authorize = (request: RequestInput, user: UserState) => {
  if (!isUser(user)) {
    return request
  }

  const authorizationHeaderValue = `Bearer ${user.tokens[0].token}`

  if (typeof request === 'string') {
    return {
      url: request,
      headers: { Authorization: authorizationHeaderValue }
    }
  }

  return {
    ...request,
    headers: {
      ...request.headers,
      Authorization: authorizationHeaderValue
    }
  }
}

export const authenticateWith = (user$: xs<UserState>) => (requests: xs<RequestOptions>) =>
  requests
    .compose(sampleCombine(user$))
    .map(([request, user]) => authorize(request, user))

const simpleMemoize = <TArgs extends any[], TResult>(f: (...args: TArgs) => TResult, keyF: (...args: TArgs) => string) => {
  const state: Record<string, TResult> = Object.create(null)
  return (...args: TArgs) => {
    const key = keyF(...args)
    if (key in state) {
      return state[key]
    } else {
      state[key] = f(...args)
      return state[key]
    }
  }
}

export const serviceItemToIntent =
  <TServiceItem extends ServiceItem<any, any>>(serviceItem: TServiceItem)
  : IntentDefinitionWithSinks<StreamType<TServiceItem['value']>, { HTTP: null; primState: null }> =>
    ({
      intent: serviceItem.value,
      [SINKS]: serviceItem.sinks
    })


type ServiceState = Record<string, any>
type PrimState = { state: ServiceState; component: any }

const readResourceState = <T, TError>(resource: Resource<T, TError>, state: MemoryStream<ServiceState>) =>
  state
    .map(s => s[resource.name] as StreamType<Resource<T, TError>['value']> || Unloaded)
    .compose(dropRepeats())
    .remember()

const writeResourceValue = <T, TError>(resource: Resource<T, TError>) => resource.value
  .map(v => {
    return (s: ServiceState) => {
      // when resubscribing to a cold response stream, an unloaded value is emitted
      // this is expected behaviour
      // but since it's not useful to save an unloaded value when we have a stale value, it is ignored
      if (v === Unloaded && s[resource.name]) {
        return s
      }
      return ({ ...s, [resource.name]: v })
    }
  })
  .filter(notNull)
  .map(stateReducer => (s: PrimState) => ({ ...s, state: stateReducer(s.state) }))


export type ServiceItem<RT extends Resource<any, any, any>, T = RT extends Resource<any, any, any> ? StreamType<RT['value']> : never> = Omit<RT, keyof Resource<any, any, any>> & {
  value: MemoryStream<T>
  clear: (s: PrimState) => PrimState
  refresh: RT['get']
  sinks: {
    primState: xs<Reducer<any>>
    HTTP: xs<RT['get']>
  }
}

export const mapServiceItemValue2 =
  <TR extends Resource<any, any, any>, TServiceItem extends ServiceItem<TR>, T>(map: (v: StreamType<TServiceItem['value']>) => T) =>
    (item: TServiceItem) =>
      ({
        ...item,
        value: item.value.map(map)
      })

export const mapServiceItemValue =
  <TServiceItem extends ServiceItem<any, any>, T>(
    item: TServiceItem, map: (v: StreamType<TServiceItem['value']>) => T)
  : TServiceItem extends ServiceItem<infer TR, any> ? ServiceItem<TR, T> : never =>
    ({
      ...item,
      value: item.value.map(map)
    }) as any

const toServiceItemInState = (state: MemoryStream<ServiceState>) =>
  <RT extends Resource<any, any, any>>(resource: RT): ServiceItem<RT> => {
    const value = readResourceState(resource, state)

    return ({
      ...(withoutKeys('get', 'value', 'name')(resource)),
      value: value as ServiceItem<RT>['value'],
      clear: (s: PrimState) => ({ ...s, state: { ...s.state, [resource.name]: Unloaded } }),
      refresh: resource.get,
      sinks: {
        primState: writeResourceValue(resource),
        // this merge of/never has specific semantics!
        // different from both xs.of and once()...
        // xs.of fires too often, once not enough
        // the combine is to prevent the emitting of resources that are already loaded
        // the loading check is for edge cases where a a listener stopped listening while loading - the last remembered state will be loading, but the response will never arrive
        // granted, this looks like a potential race condition issue, but things seem stable so far
        HTTP: xs.merge(
          xs.of(resource.get).compose(sampleCombine(value.startWith(Unloaded))).filter(([_, loadable]) => loadable === Unloaded || isLoading(loadable)).map(([get, _]) => get),
          xs.never<ServiceItem<RT>['refresh']>())
      }

    })
  }

export type PrimDataServiceFactory = ReturnType2<typeof primDataService>
export type PrimDataService = ReturnType<PrimDataServiceFactory>

const primDataService = (root: string) => (HTTP: HTTPSource) => (state: MemoryStream<ServiceState>) => {
  const toServiceItem = toServiceItemInState(state)

  const projects = projectsListResource(root)(HTTP)
  const projectConfiguration = projectConfigurationResource(root)(HTTP)
  const timesheetReport = userTimesheetResource(root)(HTTP)
  const projectsServiceItem = toServiceItem(projects)
  const x = newProjectResource(root)(HTTP)
  const newProjectServiceItem = {
    create: x.create,
    response: x.createResponse
  }

  return {
    projects: projectsServiceItem,
    projectConfiguration: simpleMemoize(C(projectConfiguration, toServiceItem), id),
    timesheetReport: simpleMemoize(C(timesheetReport, toServiceItem), (projectKey, tiaUserId, month) => `${projectKey}-${tiaUserId}-${month}`),
    tiaUsers: simpleMemoize(C(tiaUsersResource(root)(HTTP), toServiceItem), id),
    tiaSearchTimesheetCodes: simpleMemoize(tiaSearchCodesResource(root)(HTTP), id),
    tiaTimesheetCodes: simpleMemoize(C(tiaCodesResource(root)(HTTP), toServiceItem), id),
    timeTrackerUsers: simpleMemoize(C(timeTrackerUsersResource(root)(HTTP), toServiceItem), id),
    timeTrackerConfiguration: simpleMemoize(C(timeTrackerConfiguration(root)(HTTP), id), id),
    projectMembersConfiguration: simpleMemoize(projectMembersConfiguration(root)(HTTP), id),
    ordersConfiguration: simpleMemoize(ordersConfiguration(root)(HTTP), id),
    sprintsConfiguration: simpleMemoize(sprintsConfiguration(root)(HTTP), id),
    reportParametersConfiguration: simpleMemoize(reportParametersConfiguration(root)(HTTP), id),
    roles: rolesResource(root)(HTTP),
    clockify: {
      workspaces: simpleMemoize(C(projectClockifyWorkspacesResource(root)(HTTP), toServiceItem), id),
      projects: simpleMemoize(C(projectClockifyProjectsResource(root)(HTTP), toServiceItem), (projectKey, workspaceId) => `${projectKey}-${workspaceId}`)
    },
    paymo: {
      projects: simpleMemoize(C(projectPaymoProjectsResource(root)(HTTP), toServiceItem), id)
    },
    newProject: newProjectServiceItem,
    projectDescriptionConfiguration: updateProjectConfiguration(root)(HTTP),
    date: state.map(s => s.date as Date | undefined).filter(exists).compose(dropRepeats()).remember(),
    primState: xs.merge(projectsServiceItem.sinks.primState),
    HTTP: xs.merge(projectsServiceItem.sinks.HTTP)
  }
}

export const withPrimState = (component: Component<PrimPageSources, PageSinks>, apiHost: string) => {
  return (sources: PageSources & { state: StateSource<{ component: any; data: any }> }) => {
    const root = apiHost + '/api'
    const innerState = sources.state.select('state').stream.remember() as MemoryStream<ServiceState>
    const services = primDataService(root)(sources.HTTP)(innerState)

    const componentSinks = isolate(component, { state: 'component', '*': null })({ ...sources, primState: services })

    const shouldBeAuthorized = (request: RequestInput) => {
      return (typeof request === 'string' ? request : request.url).startsWith(root)
    }

    return {
      ...componentSinks,
      state: xs
        .merge(
          xs.of(K<PrimState>({ state: {}, component: undefined })),
          services.primState,
          componentSinks.state || xs.never(),
          componentSinks.primState || xs.never(),
          sources.date.days.map(date => (state: any) => ({ ...state, state: { ...state, date: date } }))),
      // automatically authenticate calls to the api
      HTTP: xs.merge(services.HTTP, componentSinks.HTTP || xs.never())
        .compose(sampleCombine(sources.user))
        .map(([request, user]) => shouldBeAuthorized(request) ? authorize(request, user) : request),
      // primstate needs to persist, disconnected from whether pages subscribe to state
      ground: xs.merge(innerState, componentSinks.ground || xs.never()).filter(K(false)),
    }
  }
}

export type PrimStateService = ReturnType<ReturnType<ReturnType<typeof primDataService>>>
