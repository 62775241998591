import { format } from 'date-fns'
import nl from 'date-fns/locale/nl'
import { CaseType, FOption, asSimple, caseValue, caseValues } from '../../fsharp'
import { unique } from '../../generic'
import { jsx } from 'h'
import { dateDisplayFormat, NavLink, Renderable } from '../../ui'
import { BacklogWorkItemMappingProblem, IndexedValidityDurationProblem, ProblemRenderConfig, ProjectMember, ProjectMemberConfigurationProblem, ProjectProblem, SprintProblem, TaskMappingProblem, RateProblem, TimeTrackerConfigurationProblem, DurationValidityProblem, WorkItemMappingProblem, WorkItemResolveProblemDetail, OrderProblem, UnprocessableEntry, UnprocessableTimesheetLineOperation, UnprocessableTimesheetLineReason, ReportDataProblem, ProblematicWorkItemDetails, SimpleAzureWorkItemParseProblem, TimeTrackerConfigurationSettingProblem, TimeTrackerConfigurationSettingWarning, SetBacklogConfigurationProblem, WrongLevelProblem, RequestError, TaskSettingsProblem, InsufficientRights, ConfigurationProblem, AdapterProblem, TimeTrackerAdapterProblem, SimpleHttpError, SetTimeTrackerConfigurationProblem, SetTaskMappingProblem, SetWorkItemMappingProblem, UnhandledApiExceptionProblem, UnhandledTiaProblem, TiaConfigurationInvalid, UnhandledClockifyProblem, UnhandledPaymoProblem, PaymoConfigurationInvalid, AzureDevOpsConfigurationInvalid, UnhandledAzureDevOpsProblem, ClockifyConfigurationInvalid, ProjectNotFoundProblem, MemberNotFoundProblem, UnhandledJiraProblem, UnhandledBacklogProblems, Level5ProjectConfiguration, TiaSyncProblem } from '../model'
import { BacklogWorkItem } from '../resources/backlog'
import { TiaUser } from '../resources/tiaUsers'
import { TimeTrackerTask } from '../resources/timeTrackerTasks'
import { TimeTrackerUser } from '../resources/timeTrackerUsers'
import { taskDisplayName } from './shared'

const shortDate = (date: Date) => format(date, 'P', { locale: nl })

export const renderProblem = <MyProblem extends CaseType<string, any[] | undefined>>(config: ProblemRenderConfig<MyProblem>) => (problem: MyProblem): Renderable => {
  const renderOrConfig = (config as any)[problem.case] as Record<string, any> | ((...args: any[]) => Renderable)
  if (!renderOrConfig) {
    return `Unknown problem: ${JSON.stringify(problem)}`
  }

  if (typeof renderOrConfig === 'function') {
    try {
      return renderOrConfig(...caseValues(problem))
    } catch (err) {
      return `Error rendering problem ${JSON.stringify(problem)}: ${err}`
    }
  } else {
    return renderProblem(renderOrConfig)((problem as CaseType<any, any[]>).fields[0])
  }
}

export const renderProblemFor = <MyProblem extends CaseType<string, any[] | undefined>>(problem: MyProblem) => (config: ProblemRenderConfig<MyProblem>): Renderable =>
  renderProblem(config)(problem)

export const enumerate = (strings: string[]) => strings.length > 1 ? `${strings.slice(0, -1).join(', ')} and ${strings[strings.length - 1]}` : strings.join()

const renderSimpleHttpError = (simpleHttpError: SimpleHttpError) =>
  `${simpleHttpError[1]}(${simpleHttpError[0]})${simpleHttpError[2] ? `: ${simpleHttpError[2]}` : ''}`

const timeTrackerAdapterProblemRenderConfig: ProblemRenderConfig<TimeTrackerAdapterProblem> = {
  ClockifyAdapterProblem: errors =>
    <p>
      Clockify request{errors.length > 1 ? '(s)' : ''} failed:
      {errors.map(err => <p>{renderSimpleHttpError(err)}</p>)}
    </p>,
  PaymoAdapterProblem: {
    Unknown: err => `Paymo request failed: ${renderSimpleHttpError(err)}`
  }
}

export const adapterProblemRenderConfig: ProblemRenderConfig<AdapterProblem> = {
  TimeTrackerAdapterProblem: timeTrackerAdapterProblemRenderConfig,
  WorkItemAdapterProblem: {
    UnknownConnectionProblem: err => `Unknown adapter problem: ${err}`
  },
  TiaAdapterProblem: err => `TIA request failed: ${renderSimpleHttpError(err)}`,
}

export const insufficientRightsRenderConfig: ProblemRenderConfig<InsufficientRights> = {
  InsufficientRights: () => 'You are not authorised to perform this operation.'
}

const renderContactAdministratorWithMaybeOperationId = (maybeOperationId: FOption<string>) =>
  `Contact your administrator${maybeOperationId ? ` with this id: ${caseValue(maybeOperationId)}` : ''}.`

export const unhandledApiExceptionRenderConfig: ProblemRenderConfig<UnhandledApiExceptionProblem> = {
  UnhandledExceptionProblem: operationId => `The server encountered an unexpected problem. ${renderContactAdministratorWithMaybeOperationId(operationId)}`
}

export const unhandledTiaProblemRenderConfig: ProblemRenderConfig<UnhandledTiaProblem> = {
  UnhandledTiaProblem: operationId => `The server encountered an unexpected problem when communicating with TIA. ${renderContactAdministratorWithMaybeOperationId(operationId)}`
}

export const unhandledClockifyProblemRenderConfig: ProblemRenderConfig<UnhandledClockifyProblem> = {
  UnhandledClockifyProblem: operationId =>
    `The server encountered an unexpected problem when communicating with Clockify. ${renderContactAdministratorWithMaybeOperationId(operationId)}`
}

export const unhandledPaymoProblemRenderConfig: ProblemRenderConfig<UnhandledPaymoProblem> = {
  UnhandledPaymoProblem: operationId => `The server encountered an unexpected problem when communicating with Paymo. ${renderContactAdministratorWithMaybeOperationId(operationId)}`
}

export const unhandledAzureDevOpsProblemRenderConfig: ProblemRenderConfig<UnhandledAzureDevOpsProblem> = {
  UnhandledAzureDevOpsProblem: operationId =>
    `The server encountered an unexpected problem when communicating with Azure DevOps. ${renderContactAdministratorWithMaybeOperationId(operationId)}`
}

export const unhandledJiraProblemRenderConfig: ProblemRenderConfig<UnhandledJiraProblem> = {
  UnhandledJiraProblem: operationId =>
    `The server encountered an unexpected problem when communicating with JIRA. ${renderContactAdministratorWithMaybeOperationId(operationId)}`
}

export const tiaConfigInvalidRenderConfig: ProblemRenderConfig<TiaConfigurationInvalid> = {
  TiaConfigurationInvalid: () => 'There is a problem with the TIA configuration. Fix the configuration, or contact your project administrator.'
}

export const paymoConfigInvalidRenderConfig: ProblemRenderConfig<PaymoConfigurationInvalid> = {
  PaymoConfigurationInvalid: () => 'There is a problem with the Paymo configuration. Fix the configuration, or contact your project administrator.'
}

export const clockifyConfigInvalidRenderConfig: ProblemRenderConfig<ClockifyConfigurationInvalid> = {
  ClockifyConfigurationInvalid: () => 'There is a problem with the Clockify configuration. Fix the configuration, or contact your project administrator.'
}

export const azureDevOpsConfigInvalidRenderConfig: ProblemRenderConfig<AzureDevOpsConfigurationInvalid> = {
  AzureDevOpsConfigurationInvalid: id => `There is a problem with an Azure DevOps configuration. Fix configuration ${id}, or contact your project administrator.`
}

export const responseRenderConfig = <TProblem extends CaseType<string, any[] | undefined>>(problemRenderer: ProblemRenderConfig<TProblem>):
ProblemRenderConfig<RequestError | UnhandledApiExceptionProblem | InsufficientRights | AdapterProblem | TProblem> => ({
  ...problemRenderer,
  ...adapterProblemRenderConfig,
  ...unhandledApiExceptionRenderConfig,
  ...insufficientRightsRenderConfig,
  ...requestErrorRenderConfig,
  ...projectNotFoundRenderConfig
})

export const configurationProblemRenderConfig = <TProblem extends CaseType<string, any[] | undefined>>(problemRenderer: ProblemRenderConfig<TProblem>):
ProblemRenderConfig<ConfigurationProblem<TProblem>> => ({
  ConfigurationProblem: problemRenderer as any // TODO: try to remove any
})

export const configurationResponseRenderConfig = <TProblem extends CaseType<string, any[] | undefined>>(problemRenderer: ProblemRenderConfig<TProblem>) =>
  responseRenderConfig(configurationProblemRenderConfig(problemRenderer))

export const renderConfigurationResponse = <TProblem extends CaseType<string, any[] | undefined>>(problemRenderer: ProblemRenderConfig<TProblem>) =>
  renderProblem(configurationResponseRenderConfig(problemRenderer))

export const requestErrorRenderConfig: ProblemRenderConfig<RequestError> = {
  RequestError: () => 'An unexpected error occured. Reload the page and try again.'
}

export const timeTrackerConfigurationSettingProblemRenderConfig: ProblemRenderConfig<TimeTrackerConfigurationSettingProblem> = {
  TimeEntryPaddingNotAllowed: () => 'Time entry padding is not allowed for this project.',
  TimeEntryPaddingCannotBeNegative: () => 'Time entry padding cannot be negative.'
}

export const timeTrackerConfigurationSettingWarningRenderConfig = (projectMembers: readonly ProjectMember[]): ProblemRenderConfig<TimeTrackerConfigurationSettingWarning> => ({
  UserUnmappedFromTimeTracker: userId => `Project member "${projectMembers.find(m => m.tiaUserId === userId)!.displayName}" is no longer linked to a time tracker user.`
})

export const timeTrackerConfigurationProblemRenderConfig: ProblemRenderConfig<TimeTrackerConfigurationProblem> = {
  ClockifyConfigurationProblem: {
    ClockifyAuthorisationProblem: () => 'Could not authenticate with Clockify. Check your Clockify credentials.',
    ClockifyProjectsNotFound: projectIds => `Some Clockify projects could not found! (${projectIds})`,
    ClockifyWorkspaceNotFound: workspaceId => `Workspace not found! ${workspaceId}`
  },
  PaymoConfigurationProblem: {
    PaymoAuthorisationProblem: () => 'Could not authenticate with Paymo. Check your Paymo credentials.',
    PaymoProjectsNotFound: projectIds => `Some Paymo projects could not found! (${projectIds})`,
  }
}

export const setTimeTrackerConfigurationProblemRenderConfig: ProblemRenderConfig<SetTimeTrackerConfigurationProblem> = {
  TimeTrackerConfigurationProblem: timeTrackerConfigurationProblemRenderConfig,
  TimeTrackerConfigurationSettingProblem: timeTrackerConfigurationSettingProblemRenderConfig
}

export const wrongLevelProblemRenderConfig: ProblemRenderConfig<WrongLevelProblem> = {
  WrongLevel: message => `Cannot perform this action because the project does not meet the required project level requirement: ${message}`,
}

export const setBacklogConfigurationProblemRenderConfig: ProblemRenderConfig<SetBacklogConfigurationProblem> = {
  AzureConfigurationProblem: {
    AzureAuthorisationFailure: () => 'Could not authenticate with Azure DevOps. Check your Azure DevOps credentials',
    AzureProjectNotFound: projectId => `Azure DevOps project not found! ${projectId}`
  },
  JiraConfigurationProblem: {
    JiraAuthorisationFailure: () => 'Could not authenticate with Jira. Check your Jira credentials',
    JiraProjectNotFound: projectId => `Jira project not found! ${projectId}`
  },
  WrongLevelProblem: wrongLevelProblemRenderConfig
}

export const projectProblemRenderConfig: ProblemRenderConfig<ProjectProblem> = {
  MissingProjectFields: missingFields => `${enumerate(missingFields)} ${missingFields.length > 1 ? 'are' : 'is'} required`,
  TiaConfigInvalid: () => 'The given TIA credentials are invalid.',
  BadCharactersInKey: _ => 'Project key can contain only alphanumeric, "-", or "_" characters and cannot start or stop with "-".',
  DuplicateKey: key => `The key "${key}" already exists. The project key should be unique`
}

export const projectNotFoundRenderConfig: ProblemRenderConfig<ProjectNotFoundProblem> = {
  ProjectNotFound: projectKey => `Project "${projectKey}" does not exist`
}

export const memberNotFoundRenderConfig: ProblemRenderConfig<MemberNotFoundProblem> = {
  MemberNotFound: tiaUserId => `Member with TIA user id ${tiaUserId} not found`
}

export const projectMembersConfigurationProblemRenderConfig =
  (projectMembers: readonly ProjectMember[], tiaUsers: TiaUser[], timeTrackerUsers: TimeTrackerUser[]): ProblemRenderConfig<ProjectMemberConfigurationProblem> => ({
    // below errors are not expected to occur, since UI is supposed to handle this: user doesn't have sufficient control to fix this
    MissingProjectMemberFields: missingFields =>
      `Missing field${missingFields.length > 1 ? 's' : ''} ${enumerate(missingFields)} ${missingFields.length > 1 ? 'are' : 'is'} required`,
    TimeTrackerUserNotFound: id => `Time tracker user for member with TIA id ${id} was not found.`,
    TiaUserNotFound: id => `TIA user with id ${id} was not found.`,
    DuplicateTiaId: id => {
      const tiaUser = tiaUsers.find(u => u.tiaUserId === id)
      return `${enumerate(projectMembers.filter(m => m.tiaUserId === id).map(m => m.displayName))} point to the same TIA user ${tiaUser ? tiaUser.tiaUsername : id}`
    },
    DuplicateTimeTrackerUserId: id => {
      const timeTrackerUser = timeTrackerUsers.find(u => u.id === id)
      const memberNames = enumerate(projectMembers.filter(m => m.timeTrackerId === id).map(m => m.displayName))
      return `The Tia users ${memberNames} point to the same time tracker user ${timeTrackerUser ? timeTrackerUser.name : id}`
    },
    DuplicateCronosAdObjectId: id => `${enumerate(projectMembers.filter(m => m.cronosAdObjectId === id).map(m => m.displayName))} point to the same Cronos Ad Object ID ${id}`,
    RoleNotFound: (id, role) => `Role ${role} for member with TIA id ${id} was not found`,
    TiaAuthorisationFailure: () => 'Failed to authenticate with TIA. Check your TIA credentials.',
    TimeTrackerConfigurationProblem: timeTrackerConfigurationProblemRenderConfig
  })

export const validityDurationProblemRenderConfig: ProblemRenderConfig<DurationValidityProblem> = {
  MissingUntilDate: date => `from date ${format(date, dateDisplayFormat)} has a missing until date`,
  OverlappingDateRange: date => `from date ${format(date, dateDisplayFormat)} overlaps with another entry`
}

export const indexedValidityDurationProblemRenderConfig: ProblemRenderConfig<IndexedValidityDurationProblem> = {
  MissingUntilDate: _ => 'There is a line that has a missing until date',
  OverlappingDateRange: _ => 'There are overlapping lines'
}

export const rateConfigurationProblemRenderConfig = (projectMembers: readonly ProjectMember[], tiaUsers: TiaUser[]): ProblemRenderConfig<RateProblem> => ({
  ProjectMemberNotFound: (_, tiaUserId) => {
    const maybeProjectMember = projectMembers.find(m => m.tiaUserId === tiaUserId)
    const tiaUser = tiaUsers.find(user => user.tiaUserId === tiaUserId)!
    return maybeProjectMember
      ? `Project member ${maybeProjectMember.displayName} could not be found during saving. Please save your project members before saving the TIA mapping.`
      : `TIA user ${tiaUser.tiaFirstName} ${tiaUser.tiaLastName} is not a project member. Please update and save your project members.`
  },
  BadRateField: (_, fields) => `A mapping item could not be accepted because it has bad values for ${enumerate(fields)}`,
  TiaCodeNotFound: tiaCodeName => `TIA code ${tiaCodeName} could not be found`,
  RateDurationValidityProblem: (tiaUserId, tiaCodeName, problem) => {
    const memberName = projectMembers.find(m => m.tiaUserId === tiaUserId)!.displayName
    return `TIA code ${tiaCodeName} for ${memberName} with ${renderProblem(indexedValidityDurationProblemRenderConfig)(problem)}`
  }
})

export const orderProblemRenderConfig = (_projectMembers: readonly ProjectMember[]): ProblemRenderConfig<OrderProblem> => ({
  BadOrderField: (_, fields) => `An item could not be accepted because it has bad values for ${enumerate(fields)}`
})

export const sprintProblemRenderConfig: ProblemRenderConfig<SprintProblem> = {
  BadSprintField: (_index, fields) => `A sprint could not be accepted because it has bad values for ${enumerate(fields)}`,
  DuplicateSprintName: (_index, sprintName) => `Multiple sprints have the name "${sprintName}". All sprints must have a unique name.`,
  SprintOverlap: (index, overlappingSprintIndexes) => `Sprint ${index} overlaps with ${enumerate(overlappingSprintIndexes.map(x => x.toString()))}`
}

export const taskMappingConfigurationProblemRenderConfig = (tasks: TimeTrackerTask[]): ProblemRenderConfig<TaskMappingProblem> => ({
  BadTaskMappingField: fields => `A mapping item could not be accepted because it has bad values for ${enumerate(fields)}`,
  TaskNotFound: taskId => `A time tracker task with id ${taskId} could not be found`,
  RateNotFound: tiaCodeName => `A TIA mapping for code ${tiaCodeName} could not be found`,
  DurationValidityProblem: (taskId, tiaCodeName, problem) => {
    const maybeTask = tasks.find(t => t.id === taskId)
    const taskDisplay = maybeTask ? taskDisplayName(maybeTask) : taskId
    return `Task ${taskDisplay} for code ${tiaCodeName} with ${renderProblem(validityDurationProblemRenderConfig)(problem)}`
  }
})

export const setTaskMappingConfigurationProblemRenderConfig = (tasks: TimeTrackerTask[]): ProblemRenderConfig<SetTaskMappingProblem> => ({
  TaskMappingProblems: problems => problems.map(problem => <div>{renderProblem(taskMappingConfigurationProblemRenderConfig(tasks))(problem)}</div>),
  TimeTrackerConfigurationProblem: timeTrackerConfigurationProblemRenderConfig,
  WrongLevelProblem: wrongLevelProblemRenderConfig
})

export const setWorkItemMappingProblemRenderConfig = (workItems: BacklogWorkItem[]):  ProblemRenderConfig<SetWorkItemMappingProblem> => ({
  WorkItemMappingProblems: problems => problems.map(problem => <div>{renderProblem(workItemMappingConfigurationProblemRenderConfig(workItems))(problem)}</div>),
  WrongLevelProblem: wrongLevelProblemRenderConfig
})

export const taskSettingsConfigurationProblemRenderConfig: ProblemRenderConfig<TaskSettingsProblem> = ({
  BadTaskSettingField: fields => `A setting item could not be accepted because it has bad values for ${enumerate(fields)}`,
  TaskNotFound: taskId => `A time tracker task with id ${taskId} could not be found`,
  RoleNotFound: (taskId, role) => `Role "${role}" was not found for ${taskId}`
})

export const workItemMappingConfigurationProblemRenderConfig = (workItems: BacklogWorkItem[]): ProblemRenderConfig<WorkItemMappingProblem> => ({
  BadWorkItemMappingField: fields => `A mapping item could not be accepted because it has bad values for ${enumerate(fields)}`,
  WorkItemNotFound: workItemId => `A backlog work item with id ${workItemId} could not be found`,
  RateForWorkItemNotFound: tiaCodeName => `A TIA mapping for code ${tiaCodeName} could not be found`,
  WorkItemValidityDurationProblem: (workItemId, tiaCodeName, problem) => {
    const workItemDisplay = workItems.find(t => t.id === workItemId)?.details.title || workItemId
    return `Work item ${workItemDisplay} for code ${tiaCodeName} with ${renderProblem(validityDurationProblemRenderConfig)(problem)}`
  }
})

const simpleAzureWorkItemMappingProblemConfig = (workItem: ProblematicWorkItemDetails): ProblemRenderConfig<SimpleAzureWorkItemParseProblem> => ({
  MissingId: url => <span>Somehow a work item does not have an id: <a href={url} target="_blank" rel="noreferrer">{url}</a></span>,
  MissingField: fieldName => `Item #${asSimple(workItem.id) ?? '<unknown>'} is missing required field ${fieldName}`,
  MalformedField: fieldName => `Item #${asSimple(workItem.id) ?? '<unknown>'} has a malformed field ${fieldName}`
})

export const backlogWorkItemMappingProblemRenderConfig = (workItem: ProblematicWorkItemDetails): ProblemRenderConfig<BacklogWorkItemMappingProblem> => ({
  AzureWorkItemMappingProblem: {
    ...simpleAzureWorkItemMappingProblemConfig(workItem),
    MultipleAzureWorkItemParseProblems: problems => problems.map(renderProblem(simpleAzureWorkItemMappingProblemConfig(workItem))).join('\n')
  },
  JiraWorkItemMappingProblem: {
    MissingField: fieldName => `Item #${asSimple(workItem.id) ?? '<unknown>'} is missing required field ${fieldName}`,
    MalformedField: (targetFieldName, sourceFieldName, value) =>
      `Item #${asSimple(workItem.id) ?? '<unknown>'} has a malformed value for ${targetFieldName}. Source field ${sourceFieldName} has value "${value}"`
  }
})

export const timesheetProblemRenderConfig: ProblemRenderConfig<ReportDataProblem> = {
  WorkItemMappingProblems: problem => renderProblem(backlogWorkItemMappingProblemRenderConfig(problem.workItemDetails))(problem.problemDetails),
  WorkItemResolveProblem: ({ workItemDetails: { url, id, type }, problemDetails }) => renderProblem<WorkItemResolveProblemDetail>({
    InvalidParent: parentId => <span>Work item <a href={url} target="_blank" rel="noreferrer">#{id}</a> has an invalid parent #{parentId}.</span>,
    ParentNotFound: parentId => <span>Work item <a href={url} target="_blank" rel="noreferrer">#{id}</a> has a missing parent #{parentId}.</span>,
    ParentNotConfigured: () => <span>Work item <a href={url} target="_blank" rel="noreferrer">#{id}</a> is missing a parent.</span>,
    TopLevelWorkItemCannotHaveParent: () =>
      <span>Work item <a href={url} target="_blank" rel="noreferrer">#{id}</a> is a top level item and cannot have a parent.</span>,
    UnexpectedParentType: (parentId, parentUrl, parentType) =>
      <span>
        Work item <a href={url} target="_blank" rel="noreferrer">#{id}</a>
        &#32;of type {type} unexpectedly has parent <a href={parentUrl} target="_blank" rel="noreferrer">#{parentId}</a>
        &#32;of type {parentType}</span>
  })(problemDetails),
  TimeEntryMappingProblem: ({ timeEntryDetails, problemDetails }) => renderProblem<typeof problemDetails>({
    ClockifyTimeEntryNormalisationProblem: {
      MissingTask: () => `A time entry${ timeEntryDetails.date === null ? '' : ` on ${shortDate(asSimple(timeEntryDetails.date)!)}`} is missing a task.`
    },
    PaymoTimeEntryNormalisationProblem: {
      DurationRequired: () => `A time entry on ${shortDate(asSimple(timeEntryDetails.date)!)} has no duration.`
    }
  })(problemDetails),
  TimeEntryResolveProblem: problem =>
    renderProblem<typeof problem['problemDetails']>({
      ProjectMemberNotFound: () =>
        `A time entry on ${shortDate(asSimple(problem.timeEntryDetails.date)!)} refers to an unknown team member (${problem.timeEntryDetails.timeTrackerUserId})`,
      TaskNotFound: () => `A time entry on ${shortDate(asSimple(problem.timeEntryDetails.date)!)} has an unknown task with id ${asSimple(problem.timeEntryDetails.taskId)}`,
      BacklogItemRequired: task => `A time entry on ${shortDate(asSimple(problem.timeEntryDetails.date)!)} with task ${taskDisplayName(task)} requires a work item`
    })(problem.problemDetails),
  TimeEntryReportProblem: problem => renderProblem<typeof problem['problemDetails']>({
    NoWorkItemMappingApplicable: (workItemId, workItemUrl) =>
      <span>
        No applicable work item mapping could be found for a time entry on {shortDate(problem.timeEntryDetails.workDate)}
        &#32;for work item <a href={workItemUrl}>#{workItemId}</a>.</span>,
    MultipleWorkItemMappingsApplicable: (workItemId, workItemUrl, order1Name, rate1TiaCodeName, order2Name, rate2TiaCodeName) =>
      <span>
        Ambiguous work item mappings were found for a time entry on {shortDate(problem.timeEntryDetails.workDate)}
        &#32;for work item <a href={workItemUrl}>#{workItemId}</a>.
        TIA code "{rate1TiaCodeName}" in order "{order1Name}"
        and TIA code "{rate2TiaCodeName}" in order "{order2Name}" are both applicable.</span>,
    NoTaskMappingApplicable: () =>
      `No applicable task mapping could be found for a time entry on ${shortDate(problem.timeEntryDetails.workDate)} for task "${taskDisplayName(problem.timeEntryDetails.task)}".`,
    MultipleTaskMappingsApplicable: (order1Name, rate1TiaCodeName, order2Name, rate2TiaCodeName) =>
      `Ambiguous task mappings were found for a time entry on ${shortDate(problem.timeEntryDetails.workDate)} for task "${taskDisplayName(problem.timeEntryDetails.task)}".`
      + ` TIA code "${rate1TiaCodeName}" in order "${order1Name}" and TIA code "${rate2TiaCodeName}" in order "${order2Name}" are both applicable.`,
    OverlappingTimeEntry: entries =>
      `There are overlapping time entries on ${unique(entries.map(({ item1: _entryId, item2: date }) => +date)).map(d => format(d, 'P', { locale: nl })).join(', ')}`,
    WorkItemNotFound: (workItemId) =>
      `Could not find work item #${workItemId} for a time entry on ${shortDate(problem.timeEntryDetails.workDate)}.`,
    UndistributableTimeEntry: {
      InvalidScope: () =>
        `Could not distribute a time entry on ${shortDate(problem.timeEntryDetails.workDate)} because of an invalid scope. Check whether the work was done inside a sprint.`,
      NoSpecificWork: () => `Could not distribute a time entry on ${shortDate(problem.timeEntryDetails.workDate)} because there was no non-overhead work present in its scope.`
    }
  })(problem.problemDetails)
}

export const tiaSyncProblemRenderConfig: ProblemRenderConfig<TiaSyncProblem> = {
  TiaSyncProblem: maybeOperationId => `A problem occurred while trying to synchronise the time entries to TIA. ${renderContactAdministratorWithMaybeOperationId(maybeOperationId)}`
}

const operationRenderConfig: ProblemRenderConfig<UnprocessableTimesheetLineOperation> = {
  Create: entry => `The entry on ${shortDate(entry.workDate)} for ${entry.tiaCodeName} cannot be created`,
  Update: entry => `The entry on ${shortDate(entry.workDate)} for ${entry.tiaCodeName} cannot be updated`,
  Delete: (entry, codeName) => `The entry on ${shortDate(entry.date)} for ${codeName} cannot be deleted`
}

const reasonRenderConfig: ProblemRenderConfig<UnprocessableTimesheetLineReason> = {
  MonthCompleted: () => 'the month has been completed.',
  TimesheetCodeInactive: () => 'the code is inactive.',
  TimesheetCodeReadOnly: () => 'the code is read-only.',
  TimesheetCodeNotFound: () => 'the code cannot be found.'
}

export const unprocessableEntryRenderConfig: ProblemRenderConfig<UnprocessableEntry> = {
  NewEntryWithMissingComment: entry => `The entry on ${shortDate(entry.workDate)} for ${entry.tiaCodeName} is missing a comment`,
  UpdatedEntryWithMissingComment: entry => `The entry on ${shortDate(entry.workDate)} for ${entry.tiaCodeName} is missing a comment`,
  UndeleteableEntry: (entry, codeName) => `The entry on ${shortDate(entry.date)} for ${codeName} cannot be deleted`,
  UnprocessableTimesheetLine: (operation, reason) => `${renderProblem(operationRenderConfig)(operation)} because ${renderProblem(reasonRenderConfig)(reason)}`
}

export const backlogProblemsRenderConfig = (NavLink: NavLink, backlogConfigurations: Level5ProjectConfiguration['backlogConfigurations']):
ProblemRenderConfig<UnhandledBacklogProblems> => ({
  UnhandledBacklogProblems: (maybeOperationId, backlogConfigurationIds) =>
    <div>
      Failed to retrieve work items from following backlogs:
      <ul>
        {backlogConfigurationIds.map(backlogConfigurationId =>
          <li>
            <NavLink href={backlogConfigurationId}>{backlogConfigurations.find(v => v.item1 === backlogConfigurationId)?.item2.case}</NavLink>
          </li>
        )}
      </ul>
      <div>{renderContactAdministratorWithMaybeOperationId(maybeOperationId)}</div>
    </div>
})
