import { Component } from '@cycle/isolate'
import xs from 'xstream'
import delay from 'xstream/extra/delay'
import { keysOf, keysToObj } from '../generic'

type ArrayType<T> = T extends (infer U)[] ? U : never

export const mirror =
  <Si extends { [key: string]: xs<any> },
    SinkKey extends keyof Si,
    SinkKeys extends SinkKey[],
    So extends { mirror: { [K in ArrayType<SinkKeys>]: Si[K] } }>
  (component: Component<So, Si>, ...keys: SinkKeys) => {
    const mimics = keysToObj(keys, key => xs.create<Si[typeof key]>()) as { [K in SinkKey]: Si[K] }

    return (sources: Omit<So, 'mirror'>) => {
      return component({ ...sources as any, mirror: mimics })
    }
  }

/**
 * Exposes sinks as imitated streams
 * Mirrored streams are never MemoryStreams
 *  */
export const magicMirror =
  <Si extends { [key: string]: xs<any> },
    SinkKey extends keyof Si,
    So extends { mirror: Partial<{ [K in SinkKey]: Si[K] }> }>
  (component: Component<So, Si>) => {
    const imitators = {} as Partial<{ [K in SinkKey]: Si[K] }>
    let initialized = false
    return (sources: Omit<So, 'mirror'>) => {
      const mirroredSinks = new Proxy(imitators, {
        get: (target: any, key: SinkKey) => {
          if (key === 'isolateSource') {
            return undefined // Sources are probed by cycle.js for isolation, so we have to make exceptions
          }

          if (key in target) {
            return target[key]
          }

          const imitator = xs.create() as Si[typeof key]
          if (initialized) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
            // imitator.imitate(sinks[key]/*.filter(_ => true)*//*.compose(delay(0))*/)
            imitator.imitate(sinks[key].filter(_ => true)/*.compose(delay(0))*/)
          }

          target[key] = imitator
          return imitator
        }
      } as any)

      const sinks = component({ ...sources as any, mirror: mirroredSinks })

      for (const key of keysOf(imitators)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        // imitators[key]!.imitate(sinks[key]/*.filter(_ => true)*//*.compose(delay(0))*/) // TODO evaluate need for delay
        imitators[key]!.imitate(sinks[key].filter(_ => true)/*.compose(delay(0))*/) // TODO evaluate need for delay
      }

      initialized = true

      return sinks
    }
  }
