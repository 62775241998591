import { staticConfig } from './config'
import { AuthEvent, AuthEventType, MsalAccountInfo } from './drivers/msalDriver'
import { PromisedType } from './generic'
import { simpleTranslate } from './prim/resources/resourceUtil'
import { rerunnerAsync, RerunResult } from './recorder/recorder'
import { Log } from './recorder/types'

const isRecordedMsalEvent = (e: any): e is {event?: AuthEvent} =>
  e.driverName === 'msal'

const isAccountsLoadedEvent = (e: any): e is { event: (AuthEvent & { type: AuthEventType.AccountsLoaded }) } =>
  isRecordedMsalEvent(e) && (e as {event?: AuthEvent}).event?.type === AuthEventType.AccountsLoaded

const replaceAccessTokensInAccount = (newToken: string) => (account: MsalAccountInfo): MsalAccountInfo => ({
  ...account,
  tokens: account.tokens.map(t => ({
    ...t,
    token: newToken
  }))
})

const replaceAccessTokensInLog = (newToken: string) => (log: Log<any>): Log<any> =>
  log.map((e: any) => isAccountsLoadedEvent(e) ? { ...e, event: { ...e.event, accounts: e.event.accounts.map(replaceAccessTokensInAccount(newToken)) }} : e)

const stripAccessTokens = replaceAccessTokensInLog('<DUMMY TOKEN>')

const getLastToken = (log: Log<any>) => {
  const lastMsalevents = log.filter(isRecordedMsalEvent) as Array<{event?: AuthEvent}>
  if (!lastMsalevents) {
    console.warn('No Msal log found.')
    return undefined
  }

  const accountsLoadedEvents = lastMsalevents.filter((e): e is { event: (AuthEvent & { type: AuthEventType.AccountsLoaded }) } =>
    e.event?.type === AuthEventType.AccountsLoaded)
  if (!accountsLoadedEvents.length) {
    console.warn('Accounts were not loaded.')
    return undefined
  }

  const account = accountsLoadedEvents[0].event.accounts[0]
  if (!account) {
    console.warn('No accounts were loaded.')
    return undefined
  }

  const token = account.tokens[0]
  if (!token) {
    console.warn('No tokens were loaded.')
    return undefined
  }

  return token.token
}

export const logSaver = (logs: any[]) => async () => {
  const lastLog = logs[logs.length -1 ]
  const token = getLastToken(lastLog)
  if (!token) {
    console.warn('No tokens were loaded. Could not authenticate log save request.')
    return
  }

  try {
    const result = await fetch(staticConfig.prim.host + '/api/debug/log', {
      method: 'POST',
      body: JSON.stringify(stripAccessTokens(lastLog)),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })

    if (result.status !== 201) {
      console.error('Failed to safe log: ', await result.text())
      return
    }

    console.info('Saved log as ' + await result.text())
  } catch (e) {
    console.error(e)
  }
}

export const playLog = async (
  firstLog: RerunResult<any, any, any, any>['logs'],
  logId: string,
  rerun: PromisedType<ReturnType<typeof rerunnerAsync>>['run'],
  dispose: RerunResult<any, any, any, any>['dispose'],
  app: any) => {
  const token = getLastToken(firstLog)
  if (!token) {
    console.warn('No tokens were loaded. Could not authenticate log fetch request.')
    return
  }

  try {
    dispose()

    const logFetchResult = await fetch(`${staticConfig.prim.host}/api/debug/log/${logId}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    })

    if (logFetchResult.status !== 200) {
      console.error('Failed to fetch log: ', await logFetchResult.text())
      return
    }

    const logs: typeof firstLog = await logFetchResult.json()

    const translatedLogs = simpleTranslate(logs)
    const rerunResult = await rerun(app, replaceAccessTokensInLog(token)(translatedLogs) as any)

    console.info('Log played successfully', rerunResult)
  } catch (e) {
    console.error(e)
  }
}
