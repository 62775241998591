import { HTTPSource, RequestOptions } from '@cycle/http'
import { CreateableResource, DeletableResource, PuttableResource, Resource } from '../../dataServices/types'
import { errorMessageOrResponseText, handleLatest, toLoadable } from '../../dataServices/util'
import { CaseType, LocalDateString } from '../../fsharp'
import { K } from '../../generic'
import { AdapterProblem, BacklogConfiguration, ConfigurationProblem, InsufficientRights, ProjectConfiguration, ProjectCreationInfo, ProjectNotFoundProblem, ProjectProblem, ProjectUpdateInfo, RequestError, SetBacklogConfigurationProblem, SetOrdersProblem, SetProjectMembersConfigurationProblem, SetSprintsProblem, SetTaskMappingProblem, SetTaskSettingsProblem, SetTimeTrackerConfigurationProblem, SetWorkItemMappingProblem, TimeTrackerConfigurationSettingWarning, UnhandledApiExceptionProblem, WrongLevelProblem, insufficientRights } from '../model'
import { handleError, resultOrError, simpleResult } from './resourceUtil'

const getProjectConfigurationCall = (root: string) => (HTTP: HTTPSource) => (key: string) => {
  const category = `get-project-configuration-${key}`
  return {
    request: {
      category: category,
      url: `${root}/projects/${key}/configuration`,
      method: 'GET'
    },
    response: handleLatest(HTTP)(category)({
      success: simpleResult<ProjectConfiguration>,
      failure: {
        403: K(insufficientRights),
        404: simpleResult<ProjectNotFoundProblem>
      },
      error: handleError
    })
  }
}

const createProjectCall = (root: string) => (HTTP: HTTPSource) => {
  const category = 'create-new-project'

  return {
    request: (info: ProjectCreationInfo) => ({
      category: category,
      url: `${root}/projects`,
      method: 'POST',
      send: info
    }),
    response: handleLatest(HTTP)(category)({
      success: simpleResult<ProjectConfiguration>,
      failure: {
        400: simpleResult<ConfigurationProblem<ProjectProblem>>,
        403: K(insufficientRights),
        500: resultOrError<UnhandledApiExceptionProblem | AdapterProblem>
      },
      error: handleError
    })
  }
}

export type ProjectConfigurationResource =
  Resource<ProjectConfiguration, RequestError | InsufficientRights | ProjectNotFoundProblem>

export const projectConfigurationResource = (root: string) => (HTTP: HTTPSource) => (key: string): ProjectConfigurationResource => {
  const get = getProjectConfigurationCall(root)(HTTP)(key)

  return {
    name: get.request.url,
    value: toLoadable(get.response),
    get: get.request
  }
}

export const newProjectResource = (root: string) => (HTTP: HTTPSource):
CreateableResource<ProjectCreationInfo, ProjectConfiguration, RequestError | UnhandledApiExceptionProblem | InsufficientRights | AdapterProblem | ConfigurationProblem<ProjectProblem>> => {
  const create = createProjectCall(root)(HTTP)
  return {
    create: create.request,
    createResponse: toLoadable(create.response)
  }
}

export type UpdateProjectResource = PuttableResource<ProjectUpdateInfo, undefined, RequestError| InsufficientRights  | AdapterProblem | ConfigurationProblem<ProjectProblem>>

export const updateProjectConfiguration = (root: string) => (HTTP: HTTPSource) => (key: string): UpdateProjectResource => {
  const category = `set-project-${key}`

  return {
    put: (info: ProjectUpdateInfo) => ({
      category: category,
      url: `${root}/projects/${key}`,
      method: 'PUT',
      send: info
    }),
    putResponse: toLoadable(handleLatest(HTTP)(category)({
      success: K(undefined),
      failure: {
        400: simpleResult<ConfigurationProblem<ProjectProblem>>,
        403: K(insufficientRights),
        500: resultOrError<AdapterProblem>
      },
      error: handleError
    }))
  }
}

export type TimeEntryMappingConfigInput = {
  ignoreOverlap: boolean
  timeEntryPaddingAmount: number | null
}

export type ClockifyConfigInput =
    { apiKey: string
      workspaceId: string
      projectIds: string[] }

export type PaymoConfigInput =
    { apiKey: string
      projectIds: number[] }

export type TimeTrackerConfigInput =
    | CaseType<'ClockifyConfigInput', [ClockifyConfigInput]>
    | CaseType<'PaymoConfigInput', [PaymoConfigInput]>


export type PutTimeTrackerConfigurationResponse = TimeTrackerConfigurationSettingWarning[]
export type TimeTrackerConfigurationInput =
  { timeTrackerConfiguration: TimeTrackerConfigInput
    timeEntryMappingConfig: TimeEntryMappingConfigInput }

export type TimeTrackerConfigurationResource =
  PuttableResource<
  TimeTrackerConfigurationInput,
  PutTimeTrackerConfigurationResponse,
  RequestError| InsufficientRights | AdapterProblem | ConfigurationProblem<SetTimeTrackerConfigurationProblem>>
  & DeletableResource<RequestOptions, undefined, RequestError| InsufficientRights | ConfigurationProblem<WrongLevelProblem>>

export const timeTrackerConfiguration = (root: string) => (HTTP: HTTPSource) => (key: string): TimeTrackerConfigurationResource => {
  const putCategory = `set-time-tracker-configuration-${key}`
  const deleteCategory = `delete-time-tracker-configuration-${key}`
  const resourceUrl = `${root}/projects/${key}/configuration/timetracker`

  return {
    put: configuration => ({
      category: putCategory,
      method: 'PUT',
      url: resourceUrl,
      send: configuration
    }),
    putResponse: toLoadable(handleLatest(HTTP)(putCategory)({
      success: simpleResult<PutTimeTrackerConfigurationResponse>,
      failure: {
        400: simpleResult<ConfigurationProblem<SetTimeTrackerConfigurationProblem>>,
        403: K(insufficientRights),
        500: resultOrError<AdapterProblem>
      },
      error: handleError
    })),

    deleteRequest: {
      category: deleteCategory,
      method: 'DELETE',
      url: resourceUrl
    },
    deleteResponse: toLoadable(handleLatest(HTTP)(putCategory)({
      success: K(undefined),
      failure: {
        400: simpleResult<ConfigurationProblem<WrongLevelProblem>>,
        403: K(insufficientRights)
      },
      error: handleError
    }))
  }
}

export type InputProjectMember = {
  timeTrackerId: string | null
  tiaUserId: string
  displayName: string
  role: string | null
}
export type PutProjectMembersConfigurationResource =
  PuttableResource<InputProjectMember[], undefined, RequestError | InsufficientRights | AdapterProblem | ConfigurationProblem<SetProjectMembersConfigurationProblem>>

export const projectMembersConfiguration = (root: string) => (HTTP: HTTPSource) => (key: string): PutProjectMembersConfigurationResource => {
  const category = `set-project-members-configuration-${key}`

  return {
    put: configuration => ({
      category: category,
      method: 'PUT',
      url: `${root}/projects/${key}/configuration/projectmembers`,
      send: configuration
    }),
    putResponse: toLoadable(handleLatest(HTTP)(category)({
      success: K(undefined),
      failure: {
        400: simpleResult<ConfigurationProblem<SetProjectMembersConfigurationProblem>>,
        403: K(insufficientRights),
        500: resultOrError<AdapterProblem>
      },
      error: handleError
    }))
  }
}

export type InputRate = {
  tiaCodeId: string
  tiaUserId: string
  dailyRate: number
  from: LocalDateString | null
  until: LocalDateString | null
}

export type InputOrder = {
  id: string
  name: string
  budget: string
  preventDistribution: boolean
  description: string
  link: string
  rates: InputRate[]
}

export type SetOrderConfigurationResource = PuttableResource<InputOrder[], undefined, RequestError | InsufficientRights | AdapterProblem | ConfigurationProblem<SetOrdersProblem>>

export const ordersConfiguration = (root: string) => (HTTP: HTTPSource) => (key: string): SetOrderConfigurationResource => {
  const category = `set-orders-configuration-${key}`

  return {
    put: orders => ({
      category: category,
      method: 'PUT',
      url: `${root}/projects/${key}/configuration/orders`,
      send: orders
    }),
    putResponse: toLoadable(handleLatest(HTTP)(category)({
      success: K(undefined),
      failure: {
        400: simpleResult<ConfigurationProblem<SetOrdersProblem>>,
        404: K(insufficientRights),
        500: resultOrError<AdapterProblem>
      },
      error: err => [{ case: 'RequestError', fields: [errorMessageOrResponseText(err)] as [string] }]
    }))
  }
}

export type InputTaskMappingItem = {
  tiaCodeName: string
  taskId: string
  from: LocalDateString | null
  until: LocalDateString | null
}

export type SetTaskMappingConfigurationResource =
  PuttableResource<InputTaskMappingItem[], undefined, RequestError | InsufficientRights | AdapterProblem | ConfigurationProblem<SetTaskMappingProblem>>

export const taskMappingConfiguration = (root: string) => (HTTP: HTTPSource) => (key: string): SetTaskMappingConfigurationResource => {
  const category = `set-task-mapping-configuration-${key}`

  return {
    put: configuration => ({
      category: category,
      method: 'PUT',
      url: `${root}/projects/${key}/configuration/taskmapping`,
      send: configuration
    }),
    putResponse: toLoadable(handleLatest(HTTP)(category)({
      success: K(undefined),
      failure: {
        400: simpleResult<ConfigurationProblem<SetTaskMappingProblem>>,
        403: K(insufficientRights),
        500: resultOrError<AdapterProblem>
      },
      error: handleError
    }))
  }
}

export type TaskSettingInput = {
  taskId: string
  backlogItemRequired: boolean
  role: string | null
  overheadScope: number | null
}

export type SetTaskSettingsConfigurationResource =
  PuttableResource<TaskSettingInput[], undefined, RequestError | InsufficientRights | AdapterProblem | ConfigurationProblem<SetTaskSettingsProblem>>

export const taskSettingsConfiguration = (root: string) => (HTTP: HTTPSource) => (key: string): SetTaskSettingsConfigurationResource => {
  const category = `set-task-settings-configuration-${key}`

  return {
    put: configuration => ({
      category: category,
      method: 'PUT',
      url: `${root}/projects/${key}/configuration/tasksettings`,
      send: configuration
    }),
    putResponse: toLoadable(handleLatest(HTTP)(category)({
      success: K(undefined),
      failure: {
        400: simpleResult<ConfigurationProblem<SetTaskSettingsProblem>>,
        403: K(insufficientRights),
        500: resultOrError<AdapterProblem>
      },
      error: handleError
    }))
  }
}

export type InputWorkItemMappingItem = {
  tiaCodeName: string
  workItemId: string
  from: LocalDateString | null
  until: LocalDateString | null
}

export type SetWorkItemMappingConfigurationResource =
  PuttableResource<InputWorkItemMappingItem[], undefined, RequestError | InsufficientRights | AdapterProblem | ConfigurationProblem<SetWorkItemMappingProblem>>

export const workItemMappingConfiguration = (root: string) => (HTTP: HTTPSource) => (key: string): SetWorkItemMappingConfigurationResource => {
  const category = `set-work-item-mapping-configuration-${key}`

  return {
    put: configuration => ({
      category: category,
      method: 'PUT',
      url: `${root}/projects/${key}/configuration/workitemmapping`,
      send: configuration
    }),
    putResponse: toLoadable(handleLatest(HTTP)(category)({
      success: K(undefined),
      failure: {
        400: simpleResult<ConfigurationProblem<SetWorkItemMappingProblem>>,
        403: K(insufficientRights),
        500: resultOrError<AdapterProblem>
      },
      error: handleError
    }))
  }
}

export type InputSprint = {
  name: string
  from: LocalDateString | null
  until: LocalDateString | null
}

export type SprintsResource = PuttableResource<InputSprint[], undefined, RequestError | InsufficientRights | ConfigurationProblem<SetSprintsProblem>>

export const sprintsConfiguration = (root: string) => (HTTP: HTTPSource) => (key: string): SprintsResource => {
  const putCategory = `set-sprints-${key}`
  const url = `${root}/projects/${key}/configuration/sprints`

  return {
    put: sprints => ({
      category: putCategory,
      method: 'PUT',
      url: url,
      send: sprints
    }),
    putResponse: toLoadable(handleLatest(HTTP)(putCategory)({
      success: K(undefined),
      failure: {
        400: simpleResult<ConfigurationProblem<SetSprintsProblem>>,
        403: K(insufficientRights)
      },
      error: handleError
    }))
  }
}

type ReportParametersInput = Record<string, string>

export type ReportParametersResource = PuttableResource<ReportParametersInput, undefined, RequestError | InsufficientRights>

export const reportParametersConfiguration = (root: string) => (HTTP: HTTPSource) => (key: string): ReportParametersResource => {
  const putCategory = `set-report-parameters-${key}`
  const url = `${root}/projects/${key}/configuration/reportparameters`

  return {
    put: reportParameters => ({
      category: putCategory,
      method: 'PUT',
      url: url,
      send: reportParameters
    }),
    putResponse: toLoadable(handleLatest(HTTP)(putCategory)({
      success: K(undefined),
      failure: {
        403: K(insufficientRights)
      },
      error: handleError
    }))
  }
}

type InputBacklogConfiguration = BacklogConfiguration

export type BacklogConfigurationResource =
  PuttableResource<InputBacklogConfiguration, string | null, RequestError | InsufficientRights | AdapterProblem | ConfigurationProblem<SetBacklogConfigurationProblem>>
  & DeletableResource<RequestOptions, undefined, RequestError | InsufficientRights | ConfigurationProblem<WrongLevelProblem>>

export const backlogConfiguration = (root: string) => (HTTP: HTTPSource) => (key: string) => (backlogConfigurationId: string): BacklogConfigurationResource => {
  const putCategory = `set-backlog-configuration-${key}-${backlogConfigurationId}`
  const deleteCategory = `delete-backlog-configuration-${key}-${backlogConfigurationId}`
  const resourceUrl = `${root}/projects/${key}/configuration/backlog/${backlogConfigurationId}`

  return {
    put: configuration => ({
      category: putCategory,
      method: 'PUT',
      url: resourceUrl,
      send: configuration
    }),
    putResponse: toLoadable(handleLatest(HTTP)(putCategory)({
      success: {
        201: x => x.text,
        204: K(null)
      },
      failure: {
        400: simpleResult<ConfigurationProblem<SetBacklogConfigurationProblem>>,
        403: K(insufficientRights),
        500: resultOrError<AdapterProblem>
      },
      error: handleError
    })),
    deleteRequest: {
      category: deleteCategory,
      method: 'DELETE',
      url: resourceUrl
    },
    deleteResponse: toLoadable(handleLatest(HTTP)(deleteCategory)({
      success: K(undefined),
      failure: {
        400: simpleResult<ConfigurationProblem<WrongLevelProblem>>,
        403: K(insufficientRights)
      },
      error: handleError
    }))
  }
}
