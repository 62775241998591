import { jsx } from 'h'
import { ProjectPageSources } from '../common'
import { Button, capture, click, Col, Container, Danger, events, Input, inputs, renderLoadable, Row, valueWithKey } from '../../../ui'
import { ConfigurationContext, ReportParametersViewState } from './viewModel'
import { DERIVED, dynamicModel, IGNORE, INIT, pageSinkTemplate } from '../../../infrastructure'
import { isLoaded, isLoading, Manager, mergeSinks, Unloaded } from '../../../generic'
import { insufficientRightsRenderConfig, renderProblem, requestErrorRenderConfig } from '../renderers'

type ReportParametersSources = ProjectPageSources<ReportParametersViewState>

export const reportParametersViewState = (context: ConfigurationContext): ReportParametersViewState => {
  return {
    context: context,
    reportParameters: context.configuration.info.reportParameters,
    putReportParametersResponse: Unloaded
  }
}

const intent = (sources: ReportParametersSources) => ({
  ...capture(
    inputs({
    }),
    events({
      '.report-parameter': valueWithKey,
      '.save-reports': click,
    })
  )(sources),
  putReportParametersResponse: sources.primState.reportParametersConfiguration(sources.projectKey).putResponse
})

const { set } = Manager<ReportParametersViewState>()

const model = (intents: ReturnType<typeof intent>, sources: ReportParametersSources) =>
  dynamicModel(sources.state.stream)(
    pageSinkTemplate,
    intents,
    {
      [INIT]: IGNORE,
      '.report-parameter': ({ index, value }) => set(state => state.reportParameters[index])(value),
      '.save-reports': {
        output: _ => state => ({
          HTTP: [sources.primState.reportParametersConfiguration(sources.projectKey).put(state.reportParameters)]
        })
      },
      putReportParametersResponse: {
        state: set(state => state.putReportParametersResponse),
        output: response => state => {
          if (!isLoaded(response)) {
            return {}
          }

          return {
            HTTP: [sources.primState.projectConfiguration(state.context.key).refresh]
          }
        }
      },
      [DERIVED]: IGNORE
    })

const view = (state: ReportParametersViewState) => {
  return <Container>
    {renderLoadable(state.putReportParametersResponse, {
      loaded: () => null,
      loading: () => null,
      error: problem =>
        <Danger>
          {renderProblem({
            ...insufficientRightsRenderConfig,
            ...requestErrorRenderConfig
          })(problem)}
        </Danger>
    })}
    <Row key="report-header" className="mb-2">
      <Col>
        <label>Parameter</label>
      </Col>
      <Col>
        <label>Value</label>
      </Col>
    </Row>
    {Object.keys(state.reportParameters).map(key => {
      return <Row className="mb-3 align-items-center">
        <Col>{key}</Col>
        <Col><Input data-key={key} className="report-parameter" value={state.reportParameters[key]}/></Col>
      </Row>
    })}
    <Row key="save-reports">
      <Col>
        <Button context="primary" className="save-reports" disabled={isLoading(state.putReportParametersResponse)}>Save</Button>
      </Col>
    </Row>
  </Container>
}

export const ReportParametersConfigurationComponent = (sources: ReportParametersSources) => {
  const sinks = model(intent(sources), sources)

  return mergeSinks(sinks, {
    DOM: sources.state.stream.map(view)
  })
}
