import xs from 'xstream'
import { Lens, Lenser, Reader, Updater, Writer, WriterCase, WriterFor, WriterTo, WriterWhen } from './lens'
import { C } from './util'

type Patcher<TState, TPatch extends TState> = (patcher: Partial<TPatch> | ((s: TPatch) => TState)) => (state: TState) => TState
export function Patcher<T>(): Patcher<T, T>
export function Patcher<T, T2 extends T = T>(f?: (x: T) => x is T2) {
  return (patcher: Partial<T2> | ((s: T2) => T)) => (state: T) => (!f || f(state) ? (typeof patcher === 'function' ? patcher(state as T2) : { ...state, ...patcher }) : state)
}

export function Patcher2<T, T2 extends T = T>(f?: (x: T) => x is T2) {
  return (patcher: Partial<T2> | ((s: T2) => T)) => (state: T) => (!f || f(state) ? (typeof patcher === 'function' ? patcher(state as T2) : { ...state, ...patcher }) : state)
}

export const Manager = <T>() => {
  const get = Reader<T>()
  return ({
    set: Writer<T>(),
    setWhen: WriterWhen<T>(),
    setFor: WriterFor<T>(),
    setCase: WriterCase<T>(),
    setTo: WriterTo<T>(),
    lens: Lenser<T>(),
    get: get,
    patch: Patcher<T>(),
    props: Props<T>(get)
  })
}

const Props = <T>(get: (<U>(f: Lens<T, U>) => (x: T) => U)) => new Proxy({}, {
  get: (_target, prop: any, _receiver) => get((o: any) => o[prop])
}) as { [key in keyof T]: (o: T) => T[key] }

export const patch = <T>(patcher: Partial<T> | ((x: T) => T)) => (t: T) => Patcher<T>()(patcher)(t)

// produce a reducer that when reducing over the "undefined" value returns the given default state, otherwise returns the old state
export const defaultReducer = <T>(defaultState: T) => (state: T | undefined) => (typeof state === 'undefined' ? defaultState : state)
// produce a reducer that when reducing over the "undefined" value returns the given default state, otherwise a copy of the old state
export const oldOrDefault = <T extends Record<any, any>>(def: T) => (old?: T) => old ? { ...old } : def

export function setters<TState>() {
  return <T extends { [key: string]: xs<any> }>(intents: T) =>
    <TConfig extends Partial<{ [P in keyof T]: (value: T[P] extends xs<infer U> ? U : never) => Updater<TState> }>>(config: TConfig) =>
      xs.merge(...Object.keys(config).map(key => intents[key].map(config[key]!)))
}

export function calculated<T>(derivedStateReducer: (state: T) => T) {
  return (stream: xs<(state: T) => T>) => stream.map(reducer => C(reducer, derivedStateReducer))
}

export function handlers<TState>() {
  return <T extends { [key: string]: xs<any> }>(intents: T) => <TConfig extends Partial<{ [P in keyof T]: (value: T[P]) => xs<Updater<TState>> }>>(config: TConfig) =>
    xs.merge(...Object.keys(config).map(key => intents[key].compose(config[key] as any) as xs<Updater<TState>>))
}

export const withDerived = <T extends any>(update2: (x: T) => T) => (update1: (x: T) => T) => C(update1, update2)
export const asDerived = <T extends any>(update2: (x: T) => T) => <P extends any>(update1: Writer<T, P>): Writer<T, P> => (...args) => update1(...args).then(update2)

