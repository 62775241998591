import { makeDOMDriver } from '@cycle/dom'
import { makeHTTPDriver, RequestOptions, Response } from '@cycle/http'
import xs from 'xstream'
import { makeRandomDriver } from '../drivers/randomDriver'
import { id } from '../generic'
import { pauseWhileReplaying } from './recorder'
import { DualStrategy, StreamRecordingStrategy } from './types'

type RecordFn = (event: any) => any

export const simpleRecord: StreamRecordingStrategy = { record: id }
export const innerRecord: StreamRecordingStrategy = {
  inner: () => simpleRecord
}
export function filterRecord(predicate: Predicate<any>, record: RecordFn = id): StreamRecordingStrategy {
  return {
    compose: (stream: xs<any>) => stream.filter(predicate),
    record
  }
}

export const replayLastSinkValue = <Si extends xs<any>>(sink: Si, replaying$: xs<boolean>) =>
  xs
    .combine(sink, replaying$)
    .filter(([_, replaying]) => !replaying)
    .map(([event]) => event) as Si

type SimpleElement = {
  value: HTMLInputElement['value']
  dataset: Record<string, string | undefined>
  id: HTMLInputElement['id']
  className: HTMLInputElement['className']
  nodeName: HTMLInputElement['nodeName']
  tagName: HTMLInputElement['tagName']
  nodeType: HTMLInputElement['nodeType']
  parentElement: SimpleElement | null
}

function simpleDataset(dataset: DOMStringMap) {
  if (!dataset) {
    return dataset
  }

  const fakeDataset: Record<string, string | undefined> = { }

  for (const key in dataset) {
    fakeDataset[key] = dataset[key]
  }

  return fakeDataset
}

function simpleElement(element: HTMLElement): SimpleElement {
  const fakeElement = {
    value: (element as HTMLInputElement).value,
    checked: (element as HTMLInputElement).checked,
    dataset: simpleDataset(element.dataset),
    id: element.id,
    className: element.className,
    nodeName: element.nodeName,
    tagName: element.tagName,
    nodeType: element.nodeType,
    parentElement: element.parentElement && simpleElement(element.parentElement)
  }

  Object.defineProperty(fakeElement, 'original', {
    value: element
  })

  return fakeElement
}

// This obviously is a tricky one
// Because real DOM events have live references,
// they're not good for recording (e.g. target value can change over time)
// So we make a copy - but how much and how deep?
// This depends on how the client application uses them

export function simpleRecordDomEvent(event: Event) {
  return {
    type: event.type,
    ownerTarget: simpleElement(event.target as HTMLElement),
    code: (event as KeyboardEvent).code,
    key: (event as KeyboardEvent).key,
    altKey: (event as KeyboardEvent).altKey,
    ctrlKey: (event as KeyboardEvent).ctrlKey,
    shiftKey: (event as KeyboardEvent).shiftKey
  }
}

export function recordDOMStrategy(): DualStrategy<ReturnType<typeof makeDOMDriver>>
export function recordDOMStrategy<T>(eventRecorder?: (event: Event) => T): DualStrategy<ReturnType<typeof makeDOMDriver>> {
  const sourceStrategy = {
    events: (type: string) => ({
      scope: type,
      strategy: { record: eventRecorder || simpleRecordDomEvent }
    }),
    select: (selector: string) => ({
      scope: selector,
      strategy: sourceStrategy
    }),
    isolateSource: (_domSource: any, scope: string) => ({
      scope,
      strategy: sourceStrategy
    })
  }

  return {
    source: sourceStrategy,
    sink: (sink, _replaying$) => sink
  }
}

type Predicate<T> = (x: T) => boolean
function and<T>(pred1: Predicate<T>, pred2: Predicate<T>) {
  return (x: T) => pred1(x) && pred2(x)
}

export function recordHTTPStrategy(): DualStrategy<ReturnType<typeof makeHTTPDriver>> {
  function filteredSourceStrategy(predicate: (request: RequestOptions) => boolean) {
    return {
      filter: (newPredicate: (request: RequestOptions) => boolean, scope?: string) => ({
        scope: scope ?? 'filtered',
        strategy: filteredSourceStrategy(and(predicate, newPredicate))
      }),
      select: (category: string | undefined) => ({
        scope: category,
        strategy: {
          inner: () => ({
            record: id, // safe to record HTTP event as is?
            compose: (stream: xs<Response>) => {
              const filtered = stream.filter(response => predicate(response.request));
              (filtered as any).request = (stream as any)._recordedSource.request // mimic ResponseStream
              return filtered
            }
          })
        }
      })
    }
  }

  return {
    source: filteredSourceStrategy(() => true),
    sink: pauseWhileReplaying
  }
}

export function recordRandomStrategy(): DualStrategy<ReturnType<typeof makeRandomDriver>> {
  return {
    source: {
      select: (category: string) => ({
        scope: category,
        record: id
      })
    },
    sink: pauseWhileReplaying
  }
}
