import { TaskMappingViewState } from '../viewModel'
import { dynamicIntent, pageSinkTemplate } from '@/infrastructure'
import * as hideExpired from '../configurationTable/hideExpired'
import { capture, inputs, dateInput, events, click, clickWithIndex, indexedDate } from '@/ui'
import { SetTaskMappingConfigurationResource } from '../../../resources'
import { TimeTrackerTasksResource } from '../../../resources/timeTrackerTasks'
import { TaskMappingSources } from './types'

export const intent = (sources: TaskMappingSources, tasksResource: TimeTrackerTasksResource, mappingResource: SetTaskMappingConfigurationResource) => {
  const domInput = {
    ...capture(
      inputs({
        // '.new-item-task': null,
        '.new-item-from': dateInput,
        '.new-item-until': dateInput
      }),
      events({
        '#add-new-task-mapping': click,
        '#save-task-mapping': click,
        '.delete': clickWithIndex,
        '.from': indexedDate,
        '.until': indexedDate
      })
    )(sources),
    chooseTimeTrackerTask: sources.mirror.chooseTimeTrackerTask,
    receiveTimeTrackerTasks: tasksResource.value,
    putTaskMappingConfigurationResponse: mappingResource.putResponse,
    ...hideExpired.intent(sources)
  }
  return dynamicIntent<TaskMappingViewState>()(
    pageSinkTemplate,
    {...domInput,
      updateTable: {
        discriminator: state => state.taskMappingsTableKey,
        value: state => sources.tanstackTable.table(state.taskMappingsTableKey)
      }
    })
}
