import { isSome, Option, someValue } from './option'
import { Dict } from './types'

export const never = void 0 as never

export const id = <T>(x: T) => x

export const K = <T>(x: T) => () => x

export const compose = <T extends any[], U, V>(f: (...x: T) => U, g: (y: U) => V) => (...x: T) => g(f(...x))
export const C = compose

export const flip = <A, B, C>(f: (a: A) => (b: B) => C) => (b: B) => (a: A) => f(a)(b)

type Pred<T> = (x: T) => boolean

export const not = <T>(pred: Pred<T>) => (x: T) => !pred(x)
export const and = <T>(...preds: Pred<T>[]) => (x: T) => preds.reduce((cur, pred) => cur && pred(x), true)
export const or = <T>(...preds: Pred<T>[]) => (x: T) => preds.reduce((cur, pred) => cur || pred(x), false)

export const log = (label: string) => <T>(x: T) => {
  console.log(label, x)
  return x
}

export function exists<T>(x: T | null | undefined): x is T {
  return x != undefined
}

export function fetch<T, K extends keyof T>(name: K) {
  return (o: T) => o[name]
}

export function notNull<T>(x: T | null | undefined): x is T {
  return x != null
}

// seriously weird with all this reflection support and spread operator/assign stuff there seems to be no basic "copyable" keys function
// eslint-disable-next-line @typescript-eslint/ban-types
export function keysOf<T extends Record<PropertyKey, any>>(x: T) {
  return Reflect.ownKeys(x).filter(k => Reflect.getOwnPropertyDescriptor(x, k)?.enumerable) as (keyof T)[]
}

export const last = <T>(xs: ReadonlyArray<T>): T | undefined => xs[xs.length - 1]
export const first = <T>(xs: ReadonlyArray<T>): T | undefined => xs[0]

export const fst = <T, U>(t: [T, U]) => t[0]
export const snd = <T, U>(t: [T, U]) => t[1]

export const identityEquals = <T>(x: T, y: T) => x === y
export const like = <T extends any[]>(xs: T, ys: T) => xs.length === ys.length && xs.every((x, i) => x === ys[i])

export function unique<T>(xs: T[]) {
  const uniqueSet = new Set<T>()

  for (const x of xs) {
    uniqueSet.add(x)
  }

  return Array.from(uniqueSet)
}

export const tuple = <T extends any[]>(...args: T): T => args

export type DeepReadonly<T> = T extends (infer U)[]
  ? readonly U[]
  // eslint-disable-next-line @typescript-eslint/ban-types
  : T extends Date | number | string | boolean | Function
    ? T
    // eslint-disable-next-line @typescript-eslint/ban-types
    : T extends {}
      ? { readonly [P in keyof T]: DeepReadonly<T[P]> }
      : T

export const choose = <X, Y>(xs: X[], fn: (x: X) => Option<Y>) => xs.map(fn).filter(isSome).map(someValue)

export function set<T, K extends keyof T>(key: K) {
  return (value: T[K]) => (o: T) => (({ ...o, [key]: value }) as T)
}

export function setter<T>() {
  return <K extends keyof T>(key: K) => set<T, K>(key)
}

export function maybeParse<T>(): (json: string | null) => T | null
export function maybeParse<T>(def: T): (json: string | null) => T
export function maybeParse<T>(def?: T) {
  return (json: string | null) => (json === null ? (typeof def === 'undefined' ? json : def) : (JSON.parse(json) as T))
}

export const mapDict = <T, T2>(obj: Dict<T>, fn: (x: T, key: string) => T2) => {
  const result = {} as Dict<T2>
  for (const key of Object.keys(obj)) {
    result[key] = fn(obj[key], key)
  }
  return result
}

export const mapObj = <V, V2, O extends Record<PropertyKey, V>>(obj: O, f: (v: V, k: keyof O) => V2) => {
  const result = {} as Record<keyof O, V2>
  for (const key of keysOf(obj)) {
    result[key] = f(obj[key], key)
  }
  return result
}

export const keysToObj = <T extends PropertyKey>(keys: T[], f: (key: T) => any) => keys.reduce((o, key) => ({ ...o, [key]: f(key) }), {})

export const arrayToObj = <TSource, TKey extends string | symbol, TValue>(arr: TSource[], fKey: (v: TSource) => TKey, fVal: (v: TSource) => TValue) => {
  const result = {} as Record<TKey, TValue>

  for (const val of arr) {
    result[fKey(val)] = fVal(val)
  }

  return result
}

export const arrayToMap = <TSource, TKey, TValue>(arr: TSource[], fKey: (v: TSource) => TKey, fVal: (v: TSource) => TValue) => {
  const result = new Map<TKey, TValue>()

  for (const val of arr) {
    result.set(fKey(val), fVal(val))
  }

  return result
}

export const withoutKeys = <K extends PropertyKey>(...keys: K[]) => <T extends { [k in K]: any }>(o: T): Omit<T, K> => {
  const result = { ...o }
  for (const key of keys) {
    delete result[key]
  }
  return result
}

//** For small to medium strings */
export const hash = (str: string) => {
  let valueHash = 0
  for (let i = 0; i < str.length; i++) {
    valueHash = ((valueHash << 5) - valueHash) + str.charCodeAt(i)
    valueHash |= 0
  }
  return valueHash
}

export const apply = <T extends any[], O>(f: (...args: T) => O) => (args: T) => f(...args)

export const memo = <T extends any[], O>(f: (...args: T) => O) => {
  const cache = [] as Array<[T, O]>
  return (...args: T) => {
    const maybeCacheEntry = cache.find(([cachedArgs]) => like(args, cachedArgs))

    if (maybeCacheEntry) {
      return maybeCacheEntry[1]
    }

    const value = f(...args)

    cache.push([args, value])

    return value
  }
}
