import { jsx, vnodeProps } from 'h'
import featherSvg from '../style/feather-icons/feather-sprite.svg'
import { cn, Props } from './util'

export type IconProps = {
  icon: string
  color?: string
}

export function Icon(props: Props<IconProps>) {
  return jsx(
    'svg',
    {
      ...vnodeProps(props),
      attrs: {
        class: cn(props.className, 'feather-icon')
      },
      ...(props.color ? { style: { stroke: props.color } } : null)
    },
    jsx('use', { attrs: { 'xlink:href': `${featherSvg}#${props.icon}` } })
  )
}
export const DeleteIcon = (props: Props<Omit<IconProps, 'icon'>>) => Icon({ ...props, icon: 'x', className: cn(props.className, 'delete') })
