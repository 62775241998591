import xs from 'xstream'
import { dynamicIntent, pageSinkTemplate } from '../../../../infrastructure'
import { capture, change, click, clickWithIndex, dateInput, events, indexedDate, indexedInputValue, input, inputs, inputValue, readChecked, targetData } from '../../../../ui'
import { C } from '../../../../generic'
import { readTiaSearchCodesResponse } from '../../../resources/tiaTimesheetCodes'
import { OrdersSources, OrdersViewState } from './viewModel'

const indexedDateWithOrderIndex = C(
  input,
  events => events.map(e => ({
    index: targetData(data => +data.index!)(e),
    orderIndex: targetData(data => +data.orderindex!)(e),
    value: dateInput(inputValue(e))
  })))

export const intent = (sources: OrdersSources, tiaCodeInput: xs<readonly [string, number]>) =>
  dynamicIntent<OrdersViewState>()(pageSinkTemplate,{
    date: sources.date.days,
    ...capture(
      inputs({
        '#order-name': null,
        '#order-budget': val => val.replace(',', '.'),
        '#order-description': null,
        '#order-link': null,
        '.copy-orders-for-members-source': null,
        '.copy-orders-for-members-destination': null
      }),
      events({
        '#add-new-rate': clickWithIndex,
        '.new-item-rate': indexedInputValue(val => val.replace(',', '.')),
        '.new-item-from': indexedDate,
        '.new-item-until': indexedDate,
        '.delete-rate': C(
          click,
          es => es.map(e => ({
            index: targetData(data => +data.index!)(e),
            orderIndex: targetData(data => +data.orderindex!)(e)
          }))),
        '.rate-from': indexedDateWithOrderIndex,
        '.rate-until': indexedDateWithOrderIndex,
        '.rate': C(
          input,
          events => events.map(e => ({
            index: targetData(data => +data.index!)(e),
            orderIndex: targetData(data => +data.orderindex!)(e),
            value: inputValue(e).replace(',', '.')
          }))),
        '.rate-member': C(
          input,
          events => events.map(e => ({
            index: targetData(data => +data.index!)(e),
            orderIndex: targetData(data => +data.orderindex!)(e),
            value: inputValue(e)
          }))),
        '.save-orders': click,
        '#order-prevent-distribution': C(change, readChecked),
        '.save-selected-order': C(click, es => es.map(targetData(data => data.key!))),
        '.delete-order': clickWithIndex,
        '.open-order': C(click, es => es.map(targetData(data => data.key!))),
        '.copy-orders-for-members': click,
      })
    )(sources),
    newInputCodeSearchResult: {
      discriminator: state => state.orders.length,
      value: state => xs.merge(...state.orders.map((_, i) =>
        readTiaSearchCodesResponse(sources.HTTP, `newTiaCodeSearch-${i}`)
          .map(loadableResponse => ({ response: loadableResponse, index: i } as const))))
    },
    newInputCode: tiaCodeInput,
    putOrdersConfigurationResponse: sources.primState.ordersConfiguration(sources.projectKey).putResponse
  })
