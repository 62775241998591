export const usernamePassword = 'usernamePassword'
export const apiKey = 'apiKey'

export type Credentials = {
  credentialType: typeof usernamePassword | typeof apiKey
  username: string
  password: string
  apiKey: string
  credentialsPresent: boolean
}

export const defaultCredentials: Credentials = {
  credentialType: usernamePassword,
  username: '',
  password: '',
  apiKey: '',
  credentialsPresent: false
}
