import { HTTPSource } from '@cycle/http'
import { GettableResource, handleLatest, receiveJson, Request, toLoadable } from '../../dataServices'
import { insufficientRights, InsufficientRights, JiraConfigurationInvalid, JiraIssueFieldInfo, JiraIssueLinkInfo, JiraIssueTypeInfo, JiraProjectInfo, RequestError, UnhandledApiExceptionProblem, UnhandledJiraProblem, WrongLevelProblem } from '../model'
import { K } from '@/generic'
import { simpleResult, resultOrError, handleError } from './resourceUtil'

export type JiraIssueFieldsResource = GettableResource<JiraIssueFieldInfo[], RequestError | InsufficientRights | WrongLevelProblem | UnhandledApiExceptionProblem | JiraConfigurationInvalid | UnhandledJiraProblem>

export const jiraIssueFieldsResource = (root: string) => (HTTP: HTTPSource) =>
  (key: string) => (backlogConfigurationId: string): JiraIssueFieldsResource => {
    const url = `${root}/projects/${key}/jira/${backlogConfigurationId}/issuefields`

    const request = {
      category: `get-jira-issuefields-${key}`,
      method: 'GET',
      url: url
    }

    return {
      name: request.url,
      get: request,
      value: toLoadable(handleLatest(HTTP)(request.category)({
        success: simpleResult<JiraIssueFieldInfo[]>,
        failure: {
          403: K(insufficientRights),
          409: simpleResult<WrongLevelProblem | JiraConfigurationInvalid>,
          500: resultOrError<UnhandledApiExceptionProblem | UnhandledJiraProblem>
        },
        error: handleError
      }))
    }
  }

export type JiraIssueLinksResource = GettableResource<JiraIssueLinkInfo[], RequestError | InsufficientRights | WrongLevelProblem | UnhandledApiExceptionProblem | JiraConfigurationInvalid | UnhandledJiraProblem>

export const jiraIssueLinksResource = (root: string) => (HTTP: HTTPSource) =>
  (key: string) => (backlogConfigurationId: string): JiraIssueLinksResource => {
    const url = `${root}/projects/${key}/jira/${backlogConfigurationId}/issuelinks`

    const request = {
      category: `get-jira-issuelinks-${key}`,
      method: 'GET',
      url: url
    }

    return {
      name: request.url,
      get: request,
      value: toLoadable(handleLatest(HTTP)(request.category)({
        success: simpleResult<JiraIssueLinkInfo[]>,
        failure: {
          403: K(insufficientRights),
          409: simpleResult<WrongLevelProblem | JiraConfigurationInvalid>,
          500: resultOrError<UnhandledApiExceptionProblem | UnhandledJiraProblem>
        },
        error: handleError
      }))
    }
  }

export type JiraIssueTypesResource = GettableResource<JiraIssueTypeInfo[], RequestError | InsufficientRights | WrongLevelProblem | UnhandledApiExceptionProblem | JiraConfigurationInvalid | UnhandledJiraProblem>

export const jiraIssueTypesResource = (root: string) => (HTTP: HTTPSource) =>
  (key: string) => (backlogConfigurationId: string): JiraIssueTypesResource => {
    const url = `${root}/projects/${key}/jira/${backlogConfigurationId}/issuetypes`

    const request = {
      category: `get-jira-issuetypes-${key}`,
      method: 'GET',
      url: url
    }

    return {
      name: request.url,
      get: request,
      value: toLoadable(handleLatest(HTTP)(request.category)({
        success: simpleResult<JiraIssueTypeInfo[]>,
        failure: {
          403: K(insufficientRights),
          409: simpleResult<WrongLevelProblem | JiraConfigurationInvalid>,
          500: resultOrError<UnhandledApiExceptionProblem | UnhandledJiraProblem>
        },
        error: handleError
      }))
    }
  }

export type JiraProjectsResource = GettableResource<JiraProjectInfo[], RequestError | InsufficientRights | WrongLevelProblem | UnhandledApiExceptionProblem | JiraConfigurationInvalid | UnhandledJiraProblem>

export const jiraProjectsResource = (root: string) => (HTTP: HTTPSource) =>
  (key: string) => (backlogConfigurationId: string): JiraProjectsResource => {
    const url = `${root}/projects/${key}/jira/${backlogConfigurationId}/projects`

    const request = {
      category: `get-jira-projects-${key}`,
      method: 'GET',
      url: url
    }

    return {
      name: request.url,
      get: request,
      value: toLoadable(handleLatest(HTTP)(request.category)({
        success: simpleResult<JiraProjectInfo[]>,
        failure: {
          403: K(insufficientRights),
          409: simpleResult<WrongLevelProblem | JiraConfigurationInvalid>,
          500: resultOrError<UnhandledApiExceptionProblem | UnhandledJiraProblem>
        },
        error: handleError
      }))
    }
  }

const getJiraCredentialParameters = (secret: string, username?: string) =>
  username
    ? {
      user: username,
      password: secret
    } : {
      headers: {
        Authorization: 'Bearer ' + secret
      }
    }

export const jiraIssueFieldsFromJiraResource = (root: string) => (HTTP: HTTPSource) =>
  (jiraHost: string, project: string, secret: string, username?: string): GettableResource<JiraIssueFieldInfo[], RequestError | UnhandledApiExceptionProblem | UnhandledJiraProblem> => {
    const url = `${root}/jira/issuefields`

    const request = Request({
      category: `get-jira-issuefields-${url}`,
      method: 'GET',
      url: url,
      query: {
        host: jiraHost,
        project: project,
        username: username,
        token: secret
      },
      ...getJiraCredentialParameters(secret, username)
    })

    return {
      name: request.url,
      get: request,
      value: toLoadable(handleLatest(HTTP)(request.category)({
        success: simpleResult<JiraIssueFieldInfo[]>,
        failure: {
          500: resultOrError<UnhandledApiExceptionProblem | UnhandledJiraProblem>
        },
        error: handleError
      }))
    }
  }


export const jiraIssueLinksFromJiraResource = (root: string) => (HTTP: HTTPSource) =>
  (jiraHost: string, project: string, secret: string, username?: string): GettableResource<JiraIssueLinkInfo[], RequestError | UnhandledApiExceptionProblem | UnhandledJiraProblem> => {
    const url = `${root}/jira/issuelinks`

    const request = Request({
      category: `get-jira-issuelinks-${url}`,
      method: 'GET',
      url: url,
      query: {
        host: jiraHost,
        project: project,
        username: username,
        token: secret
      },
      ...getJiraCredentialParameters(secret, username)
    })

    return {
      name: request.url,
      get: request,
      value: toLoadable(handleLatest(HTTP)(request.category)({
        success: simpleResult<JiraIssueLinkInfo[]>,
        failure: {
          500: resultOrError<UnhandledApiExceptionProblem | UnhandledJiraProblem>
        },
        error: handleError
      }))
    }
  }

export const jiraIssueTypesFromJiraResource = (root: string) => (HTTP: HTTPSource) =>
  (jiraHost: string, project: string, secret: string, username?: string): GettableResource<JiraIssueTypeInfo[], RequestError | UnhandledApiExceptionProblem | UnhandledJiraProblem> => {
    const url = `${root}/jira/issuetypes`

    const request = Request({
      category: `get-jira-issuetypes-${url}`,
      method: 'GET',
      url: url,
      query: {
        host: jiraHost,
        project: project,
        username: username,
        token: secret
      },
      ...getJiraCredentialParameters(secret, username)
    })

    return {
      name: request.url,
      get: request,
      value: toLoadable(handleLatest(HTTP)(request.category)({
        success: simpleResult<JiraIssueTypeInfo[]>,
        failure: {
          500: resultOrError<UnhandledApiExceptionProblem | UnhandledJiraProblem>
        },
        error: handleError
      }))
    }
  }

export const jiraProjectsFromJiraResource = (root: string) => (HTTP: HTTPSource) =>
  (jiraHost: string, secret: string, username?: string): GettableResource<JiraProjectInfo[], RequestError | UnhandledApiExceptionProblem | UnhandledJiraProblem> => {
    const url = `${root}/jira/projects`

    const request = Request({
      category: `get-jira-projects-${url}`,
      method: 'GET',
      url: url,
      query: {
        host: jiraHost,
        username: username,
        token: secret
      },
      ...getJiraCredentialParameters(secret, username)
    })

    return {
      name: request.url,
      get: request,
      value: toLoadable(handleLatest(HTTP)(request.category)({
        success: simpleResult<JiraProjectInfo[]>,
        failure: {
          500: resultOrError<UnhandledApiExceptionProblem | UnhandledJiraProblem>
        },
        error: handleError
      }))
    }
  }

