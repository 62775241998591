import { ReturnType2 } from '@/generic'
import xs from 'xstream'
import { DriverFactorySinkType } from './driverUtil'
import {
  ColumnDef,
  CoreOptions,
  createTable,
  getCoreRowModel,
  getGroupedRowModel,
  getExpandedRowModel,
  GroupingState,
  Table,
  functionalUpdate} from '@tanstack/table-core'

export type CreateTableOptions<T> = {
  columns: ColumnDef<T, any>[]
  data?: T[]
  groupingState?: GroupingState
}

export const CREATE_TABLE = Symbol('CREATE_TABLE')
export const SET_DATA = Symbol('SET_DATA')
export const SET_GROUPING = Symbol('SET_GROUPING')

export type TanstackTableAction<T = any> =
  | { type: typeof CREATE_TABLE
    key: Record<string, unknown>
    options: CreateTableOptions<T> }
  | { type: typeof SET_DATA
    key: Record<string, unknown>
    data: T[] }
  | { type: typeof SET_GROUPING
    key: Record<string, unknown>
    groupingState: GroupingState }

function createCoreOptions<T>(options: CreateTableOptions<T>, onStateChange: CoreOptions<T>['onStateChange']): CoreOptions<T> {
  return {
    columns: options.columns,
    data: options.data || [],
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnPinning: {

      },
      grouping: options.groupingState || [],
      // pagination settings must be provided if auto page index reset is enabled
      // this triggers when calling rows for the first time?
      pagination: {
        pageIndex: 0,
        pageSize: 100
      }
    },
    renderFallbackValue: null,
    onStateChange: onStateChange
  }
}

export function makeTable<T>(options: CreateTableOptions<T>, onStateChange: CoreOptions<T>['onStateChange']) {
  return createTable({
    ...createCoreOptions(options, onStateChange),
    getGroupedRowModel: getGroupedRowModel(),
    getExpandedRowModel: getExpandedRowModel()
  })
}

export function makeTanstackTableDriver() {
  const tables = new WeakMap<Record<string, unknown>, Table<any>>()
  const streams = new WeakMap<Record<string, unknown>, xs<Table<any>>>()

  return (events$: xs<TanstackTableAction>) => {
    events$.addListener({
      next: action => {
        switch (action.type) {
          case CREATE_TABLE: {
            if (tables.has(action.key)) {
              return
            }

            const table: Table<any> = makeTable(
              action.options,
              updater => {

                table.setOptions(x => ({ ...x, state: functionalUpdate(updater as any, x.state || {}) }))
                if (streams.has(action.key)) {
                  streams.get(action.key)!.shamefullySendNext(table)
                }
              })

            tables.set(action.key, table)

            if (streams.has(action.key)) {
              streams.get(action.key)!.shamefullySendNext(table)
            }

            break
          }
          case SET_DATA: {
            if (!tables.has(action.key)) {
              throw new Error('A table with that key does not exists')
            }

            const table = tables.get(action.key)!
            if (table.options.data !== action.data) {
              table.setOptions(x => ({...x, data: action.data}))

              if (streams.has(action.key)) {
                streams.get(action.key)!.shamefullySendNext(table)
              }
            }

            break
          }
          case SET_GROUPING: {
            if (!tables.has(action.key)) {
              throw new Error('A table with that key does not exists')
            }

            const table = tables.get(action.key)!
            table.setGrouping(_x => action.groupingState)

            break
          }
          default:
            throw new Error('Unrecognised action')
        }
      }
    })

    return {
      table: (key: Record<string, unknown>) => {
        if (streams.has(key)) {
          return streams.get(key)!
        }

        const stream = xs.createWithMemory<Table<any>>()

        if (tables.has(key)) {
          stream.shamefullySendNext(tables.get(key)!)
        }

        streams.set(key, stream)

        return stream
      }
    }
  }
}

export type TanstackTableSink = DriverFactorySinkType<typeof makeTanstackTableDriver>
export type TanstackTableSource = ReturnType2<typeof makeTanstackTableDriver>
