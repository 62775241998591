import xs from 'xstream'
import { asSimple, caseValue, caseValues } from '../../../fsharp'
import { C, isLoaded, isLoadError, loadErrorMessage, Manager, mergeSinks, notNull, orderBy, removeAt, Unloaded, value } from '../../../generic'
import { jsx } from 'h'
import { DERIVED, dynamicModel, IGNORE, INIT, pageSinkTemplate, toOutput } from '../../../infrastructure'
import { Button, click, clickWithIndex, cn, Col, Container, Danger, events, Icon, indexedInputValue, Row, Select, Warning } from '../../../ui'
import { ProblemRenderConfig, TaskSettingsProblem, TimeTrackerConfigurationProblem, WrongLevelProblem, isLevel3OrHigher, isLevel5, overheadScopes } from '../../model'
import { taskSettingsConfiguration } from '../../resources'
import { timeTrackerTasksResource } from '../../resources/timeTrackerTasks'
import { ProjectPageSources } from '../common'
import { clockifyConfigInvalidRenderConfig, insufficientRightsRenderConfig, paymoConfigInvalidRenderConfig, renderProblem, renderProblemFor, requestErrorRenderConfig, responseRenderConfig, taskSettingsConfigurationProblemRenderConfig, timeTrackerConfigurationProblemRenderConfig, unhandledApiExceptionRenderConfig, unhandledClockifyProblemRenderConfig, unhandledPaymoProblemRenderConfig, wrongLevelProblemRenderConfig } from '../renderers'
import { ConfigurationContext, isTaskSettingItem, MissingTaskSetting, TaskSettingItem, TaskSettingsViewState } from './viewModel'
import { rolesResource } from '../../resources/roles'
import { taskDisplayName } from '../shared'

type TaskSettingsSources = ProjectPageSources<TaskSettingsViewState>

export const taskSettingsViewState = (context: ConfigurationContext): TaskSettingsViewState => ({
  context: context,
  settings: isLevel3OrHigher(context.configuration.levelConfiguration)
    ? caseValue(context.configuration.levelConfiguration).taskSettings.map((ts): TaskSettingItem => ({
      taskId: ts.taskId,
      taskName: '',
      backlogItemRequired: ts.backlogItemRequired,
      role: asSimple(ts.role),
      overheadScope: asSimple(ts.overheadScope)
    }))
    : [],
  tasks: Unloaded,
  roles: null,
  taskNotFound: false,
  otherTaskSettingsProblems: null,
  unknownResponseError: null,
  putTaskSettingsResponse: Unloaded
})

const intent = (sources: TaskSettingsSources) =>
  ({
    tasksResponse: timeTrackerTasksResource(sources.apiHost + '/api')(sources.HTTP)(sources.projectKey).value,
    receivePutResponse: taskSettingsConfiguration(sources.apiHost + '/api')(sources.HTTP)(sources.projectKey).putResponse,
    getRoles: rolesResource(sources.apiHost + '/api')(sources.HTTP).value,
    ...events({
      '.save': click,
      '.delete': clickWithIndex,
      '.select-required': indexedInputValue(v => !!+v),
      '.select-role': indexedInputValue(v => v.length ? v : null),
      '.select-overhead-scope': indexedInputValue(v => v.length ? +v : null)
    })(sources)
  })

const { set } = Manager<TaskSettingsViewState>()

const model = (intents: ReturnType<typeof intent>, sources: TaskSettingsSources) =>
  dynamicModel(sources.state.stream)(
    pageSinkTemplate,
    intents,
    {
      [INIT]: IGNORE,
      getRoles: response => state => {
        if(isLoaded(response)){
          return set(state => state.roles)(value(response))(state)
        }
        return state
      },
      tasksResponse: response => state => {
        const setTasks = set(s => s.tasks)(response)
        if (!isLoaded(response)) {
          return setTasks(state)
        }

        const tasks = value(response)

        const matchedSettings = state.settings.map(setting => {
          const settingTaskId = setting.taskId
          const maybeTask = tasks.find(t => t.id === settingTaskId)
          return maybeTask
            ? {
              taskId: maybeTask.id,
              taskName: taskDisplayName(maybeTask),
              backlogItemRequired: setting.backlogItemRequired,
              role: setting.role,
              overheadScope: setting.overheadScope
            } as TaskSettingItem
            : {
              taskId: settingTaskId,
              backlogItemRequired: setting.backlogItemRequired,
              role: setting.role,
              overheadScope: setting.overheadScope,
              missing: true
            } as MissingTaskSetting
        })

        const newTaskSettings =
          tasks
            .map(task => matchedSettings.some(s => s.taskId === task.id)
              ? null
              : { taskId: task.id, taskName: taskDisplayName(task), backlogItemRequired: null, role: null } as TaskSettingItem)
            .filter(notNull)

        const settings = orderBy(setting => setting.taskId, matchedSettings.concat(newTaskSettings))
        const missingTask = !!settings.find(s => !isTaskSettingItem(s))

        return setTasks
          .and(s => s.settings)(settings)
          .and(s => s.taskNotFound)(missingTask)(state)
      },
      '.select-required': ({ value: val, index }) => set(state => state.settings[index].backlogItemRequired)(val),
      '.select-role': ({ value, index }) => set(state => state.settings[index].role)(value),
      '.select-overhead-scope': ({ value, index }) => set(state => state.settings[index].overheadScope)(value),
      '.delete': index => C(removeAt, set(state => state.settings))(index),
      '.save': toOutput('HTTP')(state =>
        taskSettingsConfiguration(sources.apiHost + '/api')(sources.HTTP)(sources.projectKey)
          .put(state.settings.map(s => (
            {
              taskId: s.taskId,
              backlogItemRequired: s.backlogItemRequired || false,
              role: s.role || null,
              overheadScope: s.overheadScope
            })))),
      receivePutResponse: {
        state: response => set(state => state.putTaskSettingsResponse)(response)
          .and(state => state)(state => {
            if (!isLoadError(state.putTaskSettingsResponse)) {
              return {
                ...state,
                otherProblems: null,
                unknownResponseError: null,
                taskNotFound: false,
              }
            }

            const loadProblem = loadErrorMessage(state.putTaskSettingsResponse)

            if (loadProblem.case !== 'ConfigurationProblem') {
              return {
                ...state,
                unknownResponseError: loadProblem
              }
            }

            const configurationProblem  = caseValue(loadProblem)
            if (configurationProblem.case === 'TaskSettingsProblems') {
              const taskSettingsProblems = caseValue(configurationProblem)
              const taskNotFoundProblems = taskSettingsProblems.filter((p): p is TaskSettingsProblem & { case: 'TaskNotFound' } => p.case === 'TaskNotFound')
              const taskNotFound = taskNotFoundProblems.length > 0
              const otherProblems = taskSettingsProblems.filter(p => p.case !== 'TaskNotFound')
              return set(state => state.taskNotFound)(taskNotFound)
                .and(state => state.otherTaskSettingsProblems)(otherProblems.length ? otherProblems : null)(state)
            } else {
              return {
                ...state,
                unknownResponseError: caseValues(configurationProblem)[0]
              }
            }
          }),
        output: response => _ =>
          (isLoaded(response))
            ? {
              HTTP: [sources.primState.projectConfiguration(sources.projectKey).refresh]
            }
            : {}
      },
      [DERIVED]: IGNORE
    })

const roleIsValid = (state: TaskSettingsViewState, setting: (MissingTaskSetting | TaskSettingItem)) =>
  (state.roles && setting.role) ? state.roles.find(role => role === setting.role) : true

const missingTaskView = (sources: TaskSettingsSources) =>
  <Danger>
    <div>One or more tasks cannot be found for the selected time tracker projects. You can solve this in 3 possible ways:</div>
    <div>
      1. Check if you selected the right time tracker projects in <sources.Link href={'/timetracker'} className="alert-link">Time tracker configuration</sources.Link>
    </div>
    <div>2. Check if a task is missing in the time tracker</div>
    <div>3. Remove the line with the missing task</div>
  </Danger>

const wrongLevelAndTimeTrackerConfigurationProblemRenderConfig: ProblemRenderConfig<WrongLevelProblem | TimeTrackerConfigurationProblem> = {
  ...wrongLevelProblemRenderConfig,
  ...timeTrackerConfigurationProblemRenderConfig
}

const view = (sources: TaskSettingsSources) => (state: TaskSettingsViewState) =>
  <Container>
    { state.taskNotFound ? missingTaskView(sources) : '' }
    { state.unknownResponseError
      ? renderProblem(responseRenderConfig(wrongLevelAndTimeTrackerConfigurationProblemRenderConfig))(state.unknownResponseError)
      : null }
    { state.otherTaskSettingsProblems
      ? <Danger>{state.otherTaskSettingsProblems.map(p => renderProblem(taskSettingsConfigurationProblemRenderConfig)(p))}</Danger>
      : null
    }
    { isLoadError(state.tasks)
      ? <Danger>{renderProblemFor(loadErrorMessage(state.tasks))({
        ...requestErrorRenderConfig,
        ...insufficientRightsRenderConfig,
        ...unhandledApiExceptionRenderConfig,
        ...wrongLevelProblemRenderConfig,
        ...unhandledClockifyProblemRenderConfig,
        ...unhandledPaymoProblemRenderConfig,
        ...clockifyConfigInvalidRenderConfig,
        ...paymoConfigInvalidRenderConfig
      })}</Danger>
      : null }
    {!isLevel3OrHigher(state.context.configuration.levelConfiguration)
      ? <Warning>You cannot configure task settings when there is no time tracker configured.&#32;
        <sources.Link href={'/timetracker'} className="alert-link">Configure time tracker</sources.Link>
      </Warning>
      : <>
        <Row key="header">
          <Col><label className="required">Task</label></Col>
          <Col><label className="required">Backlog item mandatory?</label></Col>
          <Col><label>Role</label></Col>
          {isLevel5(state.context.configuration.levelConfiguration) ? <Col><label>Overhead scope</label></Col> : null }
          <Col></Col>
        </Row>
        {state.settings.map((setting, i) =>
          <Row key={setting.taskId} className="item-display-margin">
            <Col>{isTaskSettingItem(setting)
              ? setting.taskName
              : <div className="large-invalid-feedback">Missing task with id {setting.taskId}</div>}
            </Col>
            <Col>
              <Select className="select-required" dataset={{ index: i.toString() }}>
                {setting.backlogItemRequired === null ? <option selected={true} key="none"></option> : null}
                <option value="1" selected={setting.backlogItemRequired === true} key="yes">Yes</option>
                <option value="0" selected={setting.backlogItemRequired === false} key="no">No</option>
              </Select>
            </Col>
            <Col>
              <Select className={cn('select-role', {'is-invalid': !roleIsValid(state, setting)})} data-index={i.toString()} aria-describedby={`task-setting-role-${i}`}>
                <option key="empty role" value="" selected={setting.role === null}></option>
                {!roleIsValid(state, setting) ? <option key="not valid" value={setting.role} selected>{setting.role}</option> : ''}
                {state.roles?.map(r => <option key={r} value={r} selected={ r === setting.role }>{r}</option>)}
              </Select>
              <div id={`task-setting-role-${i}`} className='invalid-feedback'>
                Role "{setting.role}" was not found.
              </div>
            </Col>
            {isLevel5(state.context.configuration.levelConfiguration)
              ? <Col>
                <Select className='select-overhead-scope' data-index={i.toString()}>
                  <option key="empty overhead scope" value="" selected={setting.overheadScope === null}></option>
                  {overheadScopes.map(o => <option key={o.id} value={o.id} selected={ o.id === setting.overheadScope }>{o.name}</option>)}
                </Select>
              </Col>
              : null}
            <Col>
              {isTaskSettingItem(setting) ? null : <Button className="delete" data-index={i.toString()} context="danger"><Icon icon="x" /></Button>}
            </Col>
          </Row>)}
        <Row key="controls">
          <Col>
            <Button className="save" context="primary">Save</Button>
          </Col>
        </Row>
      </>
    }
  </Container>

export const TaskSettingsComponent = (sources: TaskSettingsSources) => {
  const sinks = model(intent(sources), sources)

  return mergeSinks({
    ...sinks,
    DOM: sources.state.stream.map(view(sources))
  }, {
    HTTP: xs.merge(
      xs.of(timeTrackerTasksResource(sources.apiHost + '/api')(sources.HTTP)(sources.projectKey).get),
      xs.of(rolesResource(sources.apiHost + '/api')(sources.HTTP).get))
  })
}
