import { ResponseCollection } from '@cycle/storage'
import xs, { MemoryStream, NO, Operator, Stream } from 'xstream'
import dropRepeats from 'xstream/extra/dropRepeats'
import sampleCombine from 'xstream/extra/sampleCombine'
import { fetch, maybeParse, StreamType } from '../../generic'
export * from './once'
export * from './pickMergeMap'

export const startWith = <T, U>(stream: xs<T>, x: U) => (stream as xs<T | U>).startWith(x)

// same as xs.startWith(null), but takes care of the type issues for TypeScript
export const withNull = <T>(stream: xs<T>) => startWith(stream, null)

export const triggerWith = <TTrigger, TData, TArg = TData>(triggers: xs<TTrigger>, data: xs<TData>, f: (args: [TTrigger, TData]) => TArg = ([_, x]) => (x as any) as TArg) =>
  triggers.compose(sampleCombine(data)).map(f)

// the drop 1 here is dubious, it's to prevent unnecessary initial triggers, but is it always correct on replay?
export const triggerChange = <T, TProp>(trigger: xs<T>, prop: (x: T) => TProp, compare?: (a: TProp, b: TProp) => boolean) =>
  trigger
    .compose(dropRepeats((a, b) => (compare ? compare(prop(a), prop(b)) : prop(a) === prop(b))))
    .drop(1)
    .map(x => [prop(x), x] as [TProp, T])

export const loadFromLocalStorage = <T>(storage: ResponseCollection, key: string, defaultValue: T) =>
  storage.local.getItem<string | null>(key).take(1).map(maybeParse(defaultValue))

export function getPageSink<T, K extends keyof T>(page$: xs<Partial<T>>, sinkName: K) {
  return ((page$
    .map(fetch(sinkName))
    .compose(dropRepeats()) // it's fine dropping repeat streams, we're not dropping repeat values
    .map(stream => stream || Stream.never()) as xs<xs<StreamType<T[K]>>>)
    .flatten()) as Required<T>[K]
}

let cnt = 0
type SpyHandler<T> = {
  onStart?: (count: number) => void
  onStop?: (count: number) => void
  onForgetting?: (value: T, count: number) => void
  onNext?: (value: T, isSyncOnSubscribe: boolean, count: number) => void
  onCancel?: (count: number) => void
  onError?: (error: any, count: number) => void
}

export class Spy<T> implements Operator<T, T> {
  public readonly type = 'spy'
  public ins: Stream<T>
  public out: Stream<T>
  private readonly cnt: number
  private adding = false

  private readonly isMemory: boolean
  private hasLastValue = false
  private lastValue: T | null = null

  constructor(private readonly spyHandler: SpyHandler<T>, ins: Stream<T>) {
    this.ins = ins
    this.isMemory = ins instanceof MemoryStream
    this.out = NO as Stream<T>
    this.cnt = cnt
    cnt += 1
  }

  _start(out: Stream<T>): void {
    if (this.spyHandler.onStart) {
      this.spyHandler.onStart(this.cnt)
    }
    this.out = out
    this.adding = true

    this.ins._add(this)
    this.adding = false
  }

  _stop(): void {
    if (this.spyHandler.onStop) {
      this.spyHandler.onStop(this.cnt)
    }
    if (this.isMemory && this.spyHandler.onForgetting && this.hasLastValue) {
      this.spyHandler.onForgetting(this.lastValue!, this.cnt)
    }
    this.ins._remove(this)
    this.out = NO as Stream<T>
  }

  _n(v: T): void {
    if (this.spyHandler.onNext) {
      this.spyHandler.onNext(v, this.adding, this.cnt)
    }
    this.lastValue = v
    this.hasLastValue = true
    this.out._n(v)
  }

  _c(): void {
    if (this.spyHandler.onCancel) {
      this.spyHandler.onCancel(this.cnt)
    }
    this.out._c()
  }

  _e(e: any): void {
    if (this.spyHandler.onError) {
      this.spyHandler.onError(e, this.cnt)
    }
    this.out._e(e)
  }

}

const streamConstructor = <TStream extends Stream<any>>(stream: TStream): TStream extends MemoryStream<any> ? typeof MemoryStream : typeof Stream =>
  (stream instanceof MemoryStream ? MemoryStream : Stream) as any

const defaultSpyHandler = <T>(stream: Stream<T>, label: string): SpyHandler<T> => ({
  onStart: cnt => console.log(label, `(${stream instanceof MemoryStream ? 'MEMORY': 'NONMEMORY'})`, 'start', `(${cnt})`),
  onStop: cnt => console.log(label, `(${stream instanceof MemoryStream ? 'MEMORY': 'NONMEMORY'})`, 'stop', `(${cnt})`),
  onForgetting: (lastValue, cnt) => console.log(label, `(${stream instanceof MemoryStream ? 'MEMORY': 'NONMEMORY'})`, 'forgot', lastValue, `(${cnt})`),
  onNext: (v, sync, cnt) => console.log(label, `(${stream instanceof MemoryStream ? 'MEMORY': 'NONMEMORY'})`, 'next', v, sync, `(${cnt})`),
  onCancel: cnt => console.log(label, `(${stream instanceof MemoryStream ? 'MEMORY': 'NONMEMORY'})`, 'cancel', `(${cnt})`),
  onError: (e, cnt) => console.log(label, `(${stream instanceof MemoryStream ? 'MEMORY': 'NONMEMORY'})`, 'error', e, `(${cnt})`)
})

export const spy = <T>(spyHandlerOrLabel: SpyHandler<T> | string = {}) => (stream: Stream<T>) =>
  new (streamConstructor(stream))(
    new Spy<T>(
      typeof spyHandlerOrLabel === 'string'
        ? defaultSpyHandler(stream, spyHandlerOrLabel)
        : spyHandlerOrLabel, stream))
