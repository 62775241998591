import { dummyCredential } from '../..'
import { fSome } from '../../../fsharp'
import { isLoaded, isLoading, Manager, mergeSinks, Unloaded } from '../../../generic'
import { jsx } from 'h'
import { DERIVED, dynamicModel, IGNORE, INIT, pageSinkTemplate } from '../../../infrastructure'
import { Button, capture, click, Col, Container, Danger, events, Input, inputs, Label, renderLoadable, Row, TextArea } from '../../../ui'
import { ProjectPageSources } from '../common'
import { projectProblemRenderConfig, renderConfigurationResponse } from '../renderers'
import { ConfigurationContext, ProjectDescriptionConfigurationViewState } from './viewModel'

type State = ProjectDescriptionConfigurationViewState
type ProjectSources = ProjectPageSources<State>

const intent = (sources: ProjectSources) => ({
  ...capture(
    inputs({
      '#project-name': null,
      '#project-description': null,
      '#project-tia-username': null,
      '#project-tia-password': null,
      '#project-tia-apikey': null
    }),
    events({
      '.set-project': click
    })
  )(sources),
  putProjectDescriptionConfigurationResponse: sources.primState.projectDescriptionConfiguration(sources.projectKey).putResponse
})

export const projectDescriptionConfigurationViewState = (context: ConfigurationContext): State => {
  return ({
    context: context,
    name: context.configuration.info.name,
    description: context.configuration.info.description,
    originalUsername: context.configuration.levelConfiguration.fields[0]!.tiaConfiguration.username,
    username: context.configuration.levelConfiguration.fields[0]!.tiaConfiguration.username,
    password: context.configuration.levelConfiguration.fields[0]!.tiaConfiguration.password,
    apiKey: context.configuration.levelConfiguration.fields[0]!.tiaConfiguration.apiKey,
    putProjectDescriptionConfigurationResponse: Unloaded
  })
}

const { get, set } = Manager<State>()

const canSave = get(state => !!(state.name && state.description))

const credentialsChanged = (state: State) =>
  state.originalUsername !== state.username
  || state.password !== dummyCredential
  || state.apiKey !== dummyCredential

const model = (intents: ReturnType<typeof intent>, sources: ProjectSources) =>
  dynamicModel(sources.state.stream)(
    pageSinkTemplate,
    intents,
    {
      [INIT]: IGNORE,
      '#project-description': set(s => s.description),
      '#project-name': set(s => s.name),
      '#project-tia-username': username =>
        set(s => s.username)(username)
          .and(s => s.apiKey)(v => v === dummyCredential ? '' : v)
          .and(s => s.password)(v => v === dummyCredential ? '' : v),
      '#project-tia-password': password => set(s => s.password)(password).and(s => s.apiKey)(v => v === dummyCredential ? '' : v),
      '#project-tia-apikey': apiKey => set(s => s.apiKey)(apiKey).and(s => s.password)(v => v === dummyCredential ? '' : v),
      '.set-project': {
        output: _ => state => {
          if (!canSave(state)) {
            return {}
          }

          return {
            HTTP: [sources.primState.projectDescriptionConfiguration(state.context.key).put({
              projectDescriptionInput: {
                name: state.name,
                description: state.description
              },
              tiaCredentials: credentialsChanged(state)
                ? fSome({
                  username: state.username,
                  password: state.password,
                  apiKey: state.apiKey
                })
                : null
            })]
          }
        }
      },
      putProjectDescriptionConfigurationResponse: {
        state: set(s => s.putProjectDescriptionConfigurationResponse),
        output: response => state =>
          isLoaded(response)
            ? {
              HTTP: [
                sources.primState.projectConfiguration(state.context.key).refresh,
                sources.primState.projects.refresh]
            }
            : {}
      },
      [DERIVED]: IGNORE
    })

const view = (state: State) =>
  <Container>
    <Row>
      <Col width={2}>
        <Label>Project level:</Label>
        <p>{state.context.configuration.levelConfiguration.case}</p>
      </Col>
      <Col width={2}>
        <Label>Project key:</Label>
        <p>{state.context.configuration.info.key}</p>
      </Col>
    </Row>
    <Row>
      <Col>
        <Label className="required" for="project-name">Project name</Label>
        <Input className="mb-3" id="project-name" value={state.name} />
        <Label className="required" for="project-description">Project description</Label>
        <TextArea className="mb-3" id="project-description" value={state.description} />
        <Label className="required" for="project-tia-username">TIA API username</Label>
        <Input className="mb-3" id="project-tia-username" value={state.username} />
        <Label className="required" for="project-tia-password">TIA API password</Label>
        <Input className="mb-3" id="project-tia-password" type="password" value={state.password} autocomplete="off" />
        <Label className="required" for="project-tia-apikey">TIA API key</Label>
        <Input className="mb-3" id="project-tia-apikey" type="password" value={state.apiKey} autocomplete="off" />
        {renderLoadable(state.putProjectDescriptionConfigurationResponse, {
          loaded: () => null,
          loading: () => null,
          error: err => <Danger className="mt-3">{renderConfigurationResponse(projectProblemRenderConfig)(err)}</Danger>
        })}
        <Button context="primary" className="set-project" disabled={!(canSave(state) || isLoading(state.putProjectDescriptionConfigurationResponse))}>Save</Button>
      </Col>
    </Row>
  </Container>

export const GeneralSettingsComponent = (sources: ProjectSources) => {
  const sinks = model(intent(sources), sources)

  return mergeSinks({
    ...sinks,
    HTTP: sinks.HTTP
  }, {
    DOM: sources.state.stream.map(view)
  })
}
