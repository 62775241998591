import { intent } from './intent'
import { InputOrderItem, InputOrderItemWithIndexedRates, OrderItem, OrdersSources, OrdersViewState, RateItemInputModel, RateItemViewModel } from './viewModel'
import xs from 'xstream'
import { BootstrapAction } from '../../../../drivers'
import { DERIVED, dynamicModel, IGNORE, INIT, pageSinkTemplate } from '../../../../infrastructure'
import { canAddRate, canSaveOrder, canSaveOrders, getMembersSuggestables, isValidNumber, KEY_NEW_ORDER, rateIsValidForDay, set, toRateItemViewModel } from './modelFunctions'
import { append, flatten, isArray, isLoaded, isLoadError, loadErrorMessage, mapLoaded, push, removeAt, setAt, Unloaded, value } from '../../../../generic'
import { getSuggestedItems, Suggestable } from '../../autoSuggest'
import { IndexedValidityDurationProblem, isLevel1OrHigher, OrderProblem, ProjectMember, RateItem, RateProblem } from '../../../model'
import { ConfigurationContext, isMissingProjectMember, isMissingTiaTimesheetCode, isMissingTiaUser, isProjectMember, MappingTiaTimesheetCode } from '../viewModel'
import { TiaTimesheetCode, tiaSearchCodesRequest } from '../../../resources/tiaTimesheetCodes'
import { InputOrder } from '../../../resources'
import { TiaUsersResponse } from '../../../resources/tiaUsers'
import { formatLocalDate } from '../../../../util/date'
import { asSimple, caseValue } from '@/fsharp'

const rateHasProblems = (rate: RateItemInputModel) => rate.problems.badDailyRate
  || rate.problems.overlappingRate
  || rate.problems.badFrom
  || rate.problems.badUntil
  || rate.problems.badTiaUser
  || rate.problems.badTiaCode
  || rate.problems.missingUntilDate
  || rate.problems.projectMemberNotFound
  || rate.problems.tiaCodeNotFound

const orderHasProblems = (order: OrderItem) => order.problems.badBudgetField
  || order.problems.badNameField
  || !!(order.rates.filter(rateHasProblems).length)

const stripRateErrors = (item: RateItemInputModel) : RateItemInputModel => ({
  tiaCode: item.tiaCode,
  projectMember: item.projectMember,
  dailyRate: item.dailyRate,
  from: item.from,
  until: item.until,
  problems: emptyRateProblems
})

const makeOrderInputItem = (o: OrderItem) : OrderItem => ({
  id: o.id,
  name: o.name,
  budget: o.budget,
  preventDistribution: o.preventDistribution,
  description: o.description,
  link: o.link,
  rates: o.rates.map(stripRateErrors),
  newRateItem: o.newRateItem,
  hasProblems: o.hasProblems,
  problems: emptyOrderProblems
})

const stripOrderErrors = makeOrderInputItem

const newRateItem = (projectMembers: ProjectMember[]): OrderItem['newRateItem'] => ({
  dailyRate: '',
  from: null,
  until: null,
  tiaCode: null,
  tiaCodeInput: {
    input: '',
    suggestables: Unloaded
  },
  memberInput: {
    input: '',
    suggestables: getMembersSuggestables(projectMembers)
  }
})

const newOrderItem = (key: string): InputOrderItem => ({
  isNew: true,
  key: key,
  id: '',
  name: '',
  budget: '',
  preventDistribution: false,
  description: '',
  link: '',
  hasBadBudget: false
})

const toRateItemInputModel =
  (members: ProjectMember[], tiaUsers: TiaUsersResponse, tiaCodes: Record<string, TiaTimesheetCode | undefined>) => (item: RateItem): RateItemInputModel => {
    const projectMember = members.find(m => m.tiaUserId === item.tiaUserId)
    const tiaUser = projectMember ? null : tiaUsers.find(u => u.tiaUserId === item.tiaUserId)

    const tiaCode = tiaCodes[item.tiaCodeId]
    return ({
      dailyRate: caseValue(item.dailyRate).toString(),
      from: item.from,
      until: asSimple(item.until),
      tiaCode: tiaCode
        ? {
          id: item.tiaCodeId,
          name: tiaCode.name,
          writable: tiaCode.writable
        }
        : {
          missingTiaCodeId: item.tiaCodeId,
          missingTiaCodeName: item.tiaCodeName
        },
      projectMember: projectMember || (tiaUser ? { missingProjectMemberTiaUser: tiaUser } : { missingTiaUserId: item.tiaUserId }),
      problems: emptyRateProblems
    })
  }

const emptyRateProblems : RateItemInputModel['problems'] = {
  badTiaCode: false,
  badTiaUser: false,
  badDailyRate: false,
  badFrom: false,
  badUntil: false,
  projectMemberNotFound: false,
  tiaCodeNotFound: false,
  missingUntilDate: false,
  overlappingRate: false
}

const emptyOrderProblems : OrderItem['problems'] = {
  badBudgetField: false,
  badNameField: false,
}

const sortRateItem = (a: RateItemInputModel, b: RateItemInputModel) => {
  const aIsInvalid = isMissingTiaTimesheetCode(a.tiaCode) || isMissingProjectMember(a.projectMember) || isMissingTiaUser(a.projectMember)
  const bIsInvalid = isMissingTiaTimesheetCode(b.tiaCode) || isMissingProjectMember(b.projectMember) || isMissingTiaUser(b.projectMember)

  if (aIsInvalid && !bIsInvalid) {
    return -1
  } else if (bIsInvalid && !aIsInvalid) {
    return 1
  } else if (aIsInvalid && bIsInvalid) {
    return 0
  }

  return +a.from - +b.from
    || ((a.until && b.until) ? +a.until - +b.until : 0)
    || (a.tiaCode as MappingTiaTimesheetCode).name.localeCompare((b.tiaCode as MappingTiaTimesheetCode).name)
    || ((a.projectMember as ProjectMember | null)?.displayName || '').localeCompare((b.projectMember as ProjectMember | null)?.displayName || '')
}

export const ordersViewState = (context: ConfigurationContext): OrdersViewState => {
  if (!isLevel1OrHigher(context.configuration.levelConfiguration) || !isArray(context.tiaUsers) || typeof context.tiaCodes.case === 'string') {
    return {
      putOrdersConfigurationResponse: Unloaded,
      unknownResponseError: null,
      tiaCodesProblem: null,
      context: context,
      orders: [],
      projectMembers: [],
      tiaUsers: [],
      selectedOrder: newOrderItem(KEY_NEW_ORDER),
      copyTiaUserDestination: null,
      copyTiaUserSource: null,
      today: new Date(0) }
  }

  const tiaUsers = context.tiaUsers
  const tiaCodes = context.tiaCodes
  return ({
    context: context,
    projectMembers: context.configuration.info.members,
    tiaUsers: tiaUsers,
    orders: caseValue(context.configuration.levelConfiguration).orders.map((order): OrderItem => {
      return ({
        id: order.id,
        name: order.name,
        budget: order.budget.toString(),
        preventDistribution: order.preventDistribution,
        description: order.description,
        link: order.link,
        rates: order.rates.map(toRateItemInputModel(context.configuration.info.members, tiaUsers, tiaCodes)).sort(sortRateItem),
        newRateItem: newRateItem(context.configuration.info.members),
        hasProblems: false,
        problems: emptyOrderProblems
      })
    }),
    selectedOrder: newOrderItem(KEY_NEW_ORDER),
    putOrdersConfigurationResponse: Unloaded,
    unknownResponseError: null,
    tiaCodesProblem: null,
    copyTiaUserDestination: null,
    copyTiaUserSource: null,
    today: new Date(0)
  })
}

const getOverlappingIndexFromValidityDurationProblem = (validityDurationProblem: IndexedValidityDurationProblem | undefined, currentIndex: number) => {
  return (validityDurationProblem && validityDurationProblem.case === 'OverlappingDateRange')
    ? validityDurationProblem.fields[0] === currentIndex
      ? validityDurationProblem.fields[1]
      : validityDurationProblem.fields[1] === currentIndex ? validityDurationProblem.fields[0] : null
    : null
}

const getBadFields = (problems: RateProblem[]) => {
  const badFields = problems.find((p): p is RateProblem & { case: 'BadRateField'} => p.case === 'BadRateField')
  return {
    badTiaCode: !!(badFields && badFields.fields[1].includes('TIA code id')),
    badTiaUser: !!(badFields && badFields.fields[1].includes('TIA user id')),
    badDailyRate: !!(badFields && badFields.fields[1].includes('daily rate')),
    badFrom: !!(badFields && badFields.fields[1].includes('from date')),
    badUntil: !!(badFields && badFields.fields[1].includes('until date'))
  }
}

const getRatesWithProblems = (
  rateProblems: RateProblem[],
  rates: readonly RateItemViewModel[]) => {
  return rates.map((rate) => {
    const index = rate.index
    const problems = rateProblems.filter(({ fields: [i] }) => i === index)
    const { badTiaCode, badTiaUser, badDailyRate, badFrom, badUntil } = getBadFields(problems)
    const projectMemberNotFound = !!problems.find(p => p.case === 'ProjectMemberNotFound')
    const tiaCodeNotFound = !!problems.find((p => p.case === 'TiaCodeNotFound'))
    const tiaValidityDurationProblem = rateProblems.find(p => p.case === 'RateDurationValidityProblem')?.fields[2]
    const overlappingRateIndex = getOverlappingIndexFromValidityDurationProblem(tiaValidityDurationProblem, index)
    const missingUntilDate =
      !!(tiaValidityDurationProblem && tiaValidityDurationProblem.case === 'MissingUntilDate' && tiaValidityDurationProblem.fields[0] === index)
    return {
      ...rate,
      problems: {
        badTiaCode: badTiaCode,
        badTiaUser: badTiaUser,
        badDailyRate: badDailyRate,
        badFrom: badFrom,
        badUntil: badUntil,
        projectMemberNotFound: projectMemberNotFound,
        tiaCodeNotFound: tiaCodeNotFound,
        missingUntilDate: missingUntilDate,
        overlappingRate: overlappingRateIndex !== null
      } as RateItemInputModel['problems']
    }
  })
}

export const model = (intents: ReturnType<typeof intent>, sources: OrdersSources, hide: xs<BootstrapAction>) =>
  dynamicModel(sources.state.stream)(
    pageSinkTemplate,
    intents,
    {
      [INIT]: IGNORE,
      date: set(state => state.today),
      '#order-name': set(state => state.selectedOrder.name),
      '#order-budget': value => set(state => state.selectedOrder)(order => ({
        ...order,
        budget: value,
        hasBadBudget: !isValidNumber(value)
      })),
      '#order-prevent-distribution': set(state => state.selectedOrder.preventDistribution),
      '#order-description': set(state => state.selectedOrder.description),
      '#order-link': set(state => state.selectedOrder.link),
      '.save-selected-order': {
        output: _ => _ => {
          return {
            bootstrap: hide.take(1)
          }
        },
        state: key => state => {
          if (!canSaveOrder(state.selectedOrder)) {
            return state
          }
          const k = key === KEY_NEW_ORDER ? key : key.substring(key.indexOf('-') + 1)
          const rates = key === KEY_NEW_ORDER ? [] : state.orders[+k].rates
          const newOrder: OrderItem = {
            id: state.selectedOrder.id,
            name: state.selectedOrder.name,
            budget: state.selectedOrder.budget,
            preventDistribution: state.selectedOrder.preventDistribution,
            description: state.selectedOrder.description,
            link: state.selectedOrder.link,
            rates: rates,
            newRateItem: newRateItem(state.projectMembers),
            hasProblems: false,
            problems: emptyOrderProblems
          }
          const orders = key === KEY_NEW_ORDER ? push(newOrder)(state.orders.slice()) : setAt(+k)(newOrder)(state.orders.slice())
          return set(state => state.orders)(orders)(state)
        }
      },
      '.open-order': key => state => {
        if (key === KEY_NEW_ORDER) {
          return set(state => state.selectedOrder)(newOrderItem(KEY_NEW_ORDER))(state)
        }
        const order = state.orders[+key]
        const inputOrder: InputOrderItem = {
          isNew: false,
          key: `order-${key}`,
          id: order.id,
          name: order.name,
          budget: order.budget,
          preventDistribution: order.preventDistribution,
          description: order.description,
          link: order.link,
          hasBadBudget: false
        }
        return set(state => state.selectedOrder)(inputOrder)(state)
      },
      '.delete-order': index => set(state => state.orders)(removeAt(index)),
      '.new-item-rate': ({ index, value }) => set(state => state.orders[index].newRateItem.dailyRate)(value),
      '.new-item-from': ({ index, value }) => set(state => state.orders[index].newRateItem.from)(s => value || s),
      '.new-item-until': ({ index, value }) => set(state => state.orders[index].newRateItem.until)(s => value || s),
      '#add-new-rate': index => state => {
        const order = state.orders[index]
        if(!canAddRate(order)) {
          return state
        }

        const { dailyRate, from, until } = order.newRateItem
        const suggestedTiaCodes = getSuggestedItems(order.newRateItem.tiaCodeInput)
        const applicableProjectMembers = getSuggestedItems(order.newRateItem.memberInput)
        if (!from || !isLoaded(suggestedTiaCodes) || !isLoaded(applicableProjectMembers)) {
          return state
        }

        const normalizedApplicableProjectMembers =
          value(applicableProjectMembers).length > 0
            ? value(applicableProjectMembers).map(suggestable => suggestable.value)
            : [null as ProjectMember | null]

        const newItems =
          flatten(
            value(suggestedTiaCodes)
              .map((suggestedCode): RateItemInputModel[] =>
                normalizedApplicableProjectMembers.map(maybeProjectMember =>
                  ({
                    dailyRate: isValidNumber(dailyRate) ? dailyRate : '0',
                    from: from,
                    until: until,
                    projectMember: maybeProjectMember,
                    tiaCode: suggestedCode.value,
                    tiaCodeInput: {
                      input: '',
                      suggestables: Unloaded
                    },
                    problems: emptyRateProblems
                  }))))

        return set(s => s.orders[index].rates)([...order.rates, ...newItems])
          .and(s => s.orders[index].newRateItem)(newRateItem(state.projectMembers))(state)
      },
      newInputCodeSearchResult: ({response, index}) =>
        isLoadError(response)
          ? set(state => state.tiaCodesProblem)(loadErrorMessage(response))
          : set(state => state.orders[index].newRateItem.tiaCodeInput.suggestables)(
            mapLoaded(response, codes => codes.map((code): Suggestable<TiaTimesheetCode> => ({ display: code.name, value: code })))),
      newInputCode: {
        output: ([query, index]) => () =>
          query.length >= 5
            ? { HTTP: [tiaSearchCodesRequest(sources.apiHost + '/api', sources.projectKey, `newTiaCodeSearch-${index}`, query)] }
            : {}
      },
      '.rate-member': ({ index, orderIndex, value }) => state => {
        const rate = state.orders[orderIndex].rates[index]
        const newRate = {
          ...rate,
          projectMember: state.projectMembers.find(m => m.tiaUserId === value) || null,
          problems: {
            ...rate.problems,
            projectMemberNotFound: false
          }
        }
        return set(state => state.orders[orderIndex].rates[index])(newRate)(state)
      },
      '.rate': ({ index, orderIndex, value }) => set(state => state.orders[orderIndex].rates[index])(rate => ({
        ...rate,
        dailyRate: value,
        problems: {
          ...rate.problems,
          badDailyRate: !isValidNumber(value)
        }
      })),
      '.rate-from': ({ index, orderIndex, value }) => set(state => state.orders[orderIndex].rates[index])(rate => ({
        ...rate,
        from: value || rate.from,
        problems: {
          ...rate.problems,
          badFrom: false,
          overlappingRate: false,
          overlappingRateInOrder: false
        }
      })),
      '.rate-until': ({ index, orderIndex, value }) => set(state => state.orders[orderIndex].rates[index])(rate => ({
        ...rate,
        until: value,
        problems: {
          ...rate.problems,
          badUntil: false,
          missingUntilDate: false,
          overlappingRate: false,
          overlappingRateInOrder: false,
          missingUntilDateInOrder: false
        }
      })),
      '.delete-rate': ({ index, orderIndex }) => set(state => state.orders[orderIndex].rates)(removeAt(index)),
      '.save-orders': {
        output: _ => state => {
          if (!canSaveOrders(state)) {
            return {}
          }

          const toInputRate = (rateItem: RateItemInputModel) => ({
            dailyRate: +rateItem.dailyRate,
            from: formatLocalDate(rateItem.from),
            until: rateItem.until ? formatLocalDate(rateItem.until) : null,
            tiaCodeId: isMissingTiaTimesheetCode(rateItem.tiaCode) ? rateItem.tiaCode.missingTiaCodeId : rateItem.tiaCode.id,
            tiaUserId: isMissingProjectMember(rateItem.projectMember)
              ? rateItem.projectMember.missingProjectMemberTiaUser.tiaUserId
              : isMissingTiaUser(rateItem.projectMember)
                ? rateItem.projectMember.missingTiaUserId
                : (rateItem.projectMember as ProjectMember).tiaUserId
          })

          return {
            HTTP: [sources.primState.ordersConfiguration(state.context.key).put(state.orders.map((o): InputOrder => ({
              id: o.id,
              name: o.name,
              budget: o.budget,
              preventDistribution: o.preventDistribution,
              description: o.description,
              link: o.link,
              rates: o.rates.map(toInputRate)
            })))]
          }
        }
      },
      '.copy-orders-for-members-source': value => state => ({
        ...state,
        copyTiaUserSource: value
      }),
      '.copy-orders-for-members-destination': value => state => {
        return set(state => state.copyTiaUserDestination)(value === 'null' ? '' : value)(state)
      },
      '.copy-orders-for-members': _ => set(state => state.orders)((orders, state) => {
        const targetProjectMember = state.projectMembers.find(m => m.tiaUserId === state.copyTiaUserDestination)
        if (!targetProjectMember) {
          throw new Error(`Copy TIA user source member with id ${state.copyTiaUserDestination} not found`)
        }
        return orders.map(order => {
          const sourceRate = order.rates.find(rate =>
            isProjectMember(rate.projectMember)
            && rate.projectMember.tiaUserId === state.copyTiaUserSource
            && rateIsValidForDay(state.today, rate))

          const existingRateForTargetMember = order.rates.find(rate =>
            isProjectMember(rate.projectMember)
            && rate.projectMember.tiaUserId === state.copyTiaUserDestination
            && rateIsValidForDay(state.today, rate))

          if (!sourceRate || existingRateForTargetMember) {
            return order
          }

          return {
            ...order,
            rates: [
              ...order.rates,
              {
                ...sourceRate,
                projectMember: targetProjectMember
              }
            ]
          }
        })
      }),
      putOrdersConfigurationResponse: {
        state: response =>
          set(state => state.putOrdersConfigurationResponse)(response)
            .and(state => state)(state => {
              if (!isLoadError(state.putOrdersConfigurationResponse)) {
                return {
                  ...state,
                  unknownResponseError: null,
                  orders: state.orders.map(stripOrderErrors)
                }
              }

              const loadProblem = loadErrorMessage(state.putOrdersConfigurationResponse)
              if (loadProblem.case !== 'ConfigurationProblem') {
                return {
                  ...state,
                  unknownResponseError: loadProblem
                }
              }
              const configurationProblem = caseValue(loadProblem)

              const orderProblems = configurationProblem.case === 'OrderProblems' ? caseValue(configurationProblem) : []
              const rateProblems = configurationProblem.case === 'RateProblems' ? caseValue(configurationProblem) : []

              const ordersWithIndexedRates = state.orders.reduce((acc, curr) => {
                const {previousItems, count} = acc
                const indexedRates = curr.rates.map((r, i) => toRateItemViewModel(r, i + count, state))
                const newOrder : InputOrderItemWithIndexedRates = {
                  ...curr,
                  rates: indexedRates
                }
                const newOrders = append(newOrder)(previousItems)
                return {previousItems: newOrders, count: (curr.rates.length + count)}
              }, ({previousItems: [] as any as readonly InputOrderItemWithIndexedRates[], count: 0})).previousItems

              return {
                ...state,
                orders:
                  ordersWithIndexedRates.map((order, index) => {
                    const problems = orderProblems.filter(({ fields: [i] }) => i === index)
                    const badFields = problems.find((p): p is OrderProblem & { case: 'BadOrderField'} => p.case === 'BadOrderField')
                    const badName = !!(badFields && badFields.fields[1].includes('name'))
                    const badBudget = !!(badFields && badFields.fields[1].includes('budget'))
                    const rates = getRatesWithProblems(rateProblems, order.rates)
                    const newOrder = {
                      ...order,
                      rates: rates,
                      problems: {
                        badNameField: badName,
                        badBudgetField: badBudget
                      }
                    }
                    return {
                      ...newOrder,
                      hasProblems: orderHasProblems(newOrder)
                    }
                  })
              }
            }),
        output: response => state => {
          if (!isLoaded(response)) {
            return {}
          }

          return {
            HTTP: [sources.primState.projectConfiguration(state.context.key).refresh]
          }
        }
      },
      [DERIVED]: IGNORE
    })

