import { caseValue } from '@/fsharp'
import format from 'date-fns/format'
import nl from 'date-fns/locale/nl'
import { TimeTrackerTask } from '../resources'

export const formatDate = (dateString: string) => format(new Date(dateString), 'P', { locale: nl })
export const formatMonth = (date: Date) => format(date, 'MM - yyyy', { locale: nl })

export const taskDisplayName = (task: TimeTrackerTask) =>
  task.group ? `${task.name} (${caseValue(task.group)})` : task.name
