import xs from 'xstream'
import { DriverFactorySinkType } from './driverUtil'

export function makeRedirectDriver() {
  return (events$: xs<string>) => {
    events$.addListener({
      next: event => {
        location.href = event
      }
    })
  }
}

export type RedirectSink = DriverFactorySinkType<typeof makeRedirectDriver>
