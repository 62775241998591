import xs from 'xstream'
import dropRepeats from 'xstream/extra/dropRepeats'
import sampleCombine from 'xstream/extra/sampleCombine'
import { C, exists, id, ignoreSyncValueOnSubscribe, isArray, K, keysOf, Lens, mapDict, mapObj, mergeSinks, notNull, StreamType } from '../generic'
import { once } from '../util'
import { mergePageSinks } from './pages'

type Case<TCaseValue extends string, T, CaseKey extends string = 'case'> = T extends { [key in CaseKey]: TCaseValue } ? T : never

type Content<T> = StreamType<T>
type StreamsObject = { [key: string]: xs<any> }
export type OutputFromSink<TSink> = Partial<{ [K in keyof TSink]: Array<Content<TSink[K]>> | TSink[K] }>

export const DEFAULT = Symbol('DEFAULT')
export type DEFAULT = typeof DEFAULT

export const IGNORE = Symbol('IGNORE')
export type IGNORE = typeof IGNORE

export const NOTHING = Symbol('NOTHING')
export type NOTHING = typeof NOTHING

export const INIT = Symbol('INIT')
export type INIT = typeof INIT

export const DERIVED = Symbol('DERIVED')
export type DERIVED = typeof DERIVED

type InitBehaviour<State, TSink> =
  | ((state?: State) => State)
  | {
    state?: (state?: State) => State
    output: (state: State) => OutputFromSink<TSink> | NOTHING
  }
  | IGNORE

type DerivedBehaviour<State, TSink> =
  | ((state: State) => State)
  | {
    state?: (state: State) => State
    output: (state: State) => OutputFromSink<TSink> | NOTHING
  }
  | IGNORE

type IntentHandler<Intent, State, TSink> =
  | ((intent: Intent) => (state: State) => State)
  | {
    state?: (intent: Intent) => (state: State) => State
    output: (intent: Intent) => (state: State) => OutputFromSink<TSink> | NOTHING
  }
  | IGNORE

type DeclarationInnerWithInit<Intent, State, TSink> = {
  [intentKey in keyof Intent | INIT | DERIVED]:
  intentKey extends keyof Intent
    ? IntentHandler<Content<Intent[intentKey]>, State, TSink>
    : intentKey extends keyof INIT
      ? InitBehaviour<State, TSink>
      : DerivedBehaviour<State, TSink>
}

type BehaviourDeclarationInner<Intent, State, TSink> = {
  [intentKey in keyof Intent]: IntentHandler<Content<Intent[intentKey]>, State, TSink>
}

export type BehaviourDeclaration<Intent, State, TSink> = BehaviourDeclarationInner<Intent, State, TSink>

export type DeclarationWithInit<Intent, State, TSink> = DeclarationInnerWithInit<Intent, State, TSink>

type CaseIntentHandler<Intent, CaseState, State, TSink> =
  | ((intent: Intent) => (state: CaseState) => State)
  | {
    state?: (intent: Intent) => (state: CaseState) => State
    output: (intent: Intent) => (state: CaseState) => OutputFromSink<TSink>
  }
  | IGNORE

type FullCaseIntentHandlers<Intent, State extends { [key in CaseKey]: string }, TSink, CaseKey extends string> = {
  [stateCase in State[CaseKey]]: CaseIntentHandler<Intent, Case<stateCase, State, CaseKey>, State, TSink>
}

type PartialIntentHandlersWithDefault<Intent, State extends { [key in CaseKey]: string }, TSink, CaseKey extends string> = Partial<
FullCaseIntentHandlers<Intent, State, TSink, CaseKey>
> & { [DEFAULT]?: CaseIntentHandler<Intent, State, State, TSink> }

type CaseIntentHandlers<Intent, State extends { [key in CaseKey]: string }, TSink, CaseKey extends string> =
  | FullCaseIntentHandlers<Intent, State, TSink, CaseKey>
  | PartialIntentHandlersWithDefault<Intent, State, TSink, CaseKey>

export type CaseBehaviourDeclaration<Intent, State extends { [key in CaseKey]: string }, TSink, CaseKey extends string = 'case'> = {
  [keyIntent in keyof Intent | INIT | DERIVED]:
  keyIntent extends keyof Intent
    ? CaseIntentHandlers<Content<Intent[keyIntent]>, State, TSink, CaseKey>
    : keyIntent extends INIT
      ? InitBehaviour<State, TSink>
      : DerivedBehaviour<State, TSink>
}

export const SINKS = Symbol('SINKS')

export type SimpleIntentDefinition<T> = xs<T>
export type IntentDefinitionWithSinks<T, SinkTemplate> = { [SINKS]: Partial<{ [K in keyof SinkTemplate]: xs<any> }>; intent: SimpleIntentDefinition<T> }
export type StaticIntentDefinition<T, SinkTemplate> = SimpleIntentDefinition<T> | IntentDefinitionWithSinks<T, SinkTemplate>
export type DynamicIntentDefinitionOutput<T, SinkTemplate> = StaticIntentDefinition<T, SinkTemplate>
export type DynamicIntentDefinition<T, State, SinkTemplate, Discriminiator> = {
  discriminator: (state: State) => Discriminiator
  value: (state: State, discriminatorValue: Discriminiator) => DynamicIntentDefinitionOutput<T, SinkTemplate>
}
export type IntentDefinition<T, State, SinkTemplate, Discriminiator> = StaticIntentDefinition<T, SinkTemplate> | DynamicIntentDefinition<T, State, SinkTemplate, Discriminiator>

export const isIntentDefinitionWithSinks = <T, SinkTemplate>(definition: IntentDefinition<T, any, SinkTemplate, any>): definition is IntentDefinitionWithSinks<T, SinkTemplate> =>
  SINKS in definition
export const isDynamicIntentDefinition = <T, State, SinkTemplate, Discriminiator>(
  definition: IntentDefinition<T, State, SinkTemplate, Discriminiator>
): definition is DynamicIntentDefinition<T, State, SinkTemplate, Discriminiator> => 'discriminator' in definition
export const isSimpleIntentDefinition = <T extends any>(definition: IntentDefinition<T, any, any, any>): definition is SimpleIntentDefinition<T> =>
  !isIntentDefinitionWithSinks(definition) && !isDynamicIntentDefinition(definition)

const entriesToObj = <T>(entries: [string, T][]) => entries.reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {}) as Record<string, T>

// // composability: output of 1 component becomes the intent of another? components that translate more "raw" input to more refined intents?
// // composability: state composition?

// shortcut to specify a single state-based output event
export const toOutput = (sink: string) => <T, U>(lens: Lens<T, U>) => ({ output: () => (state: T) => ({ [sink]: [lens(state)] }) })

export const toSinksOutputOnly = <Template extends Record<PropertyKey, any>>(template: Template, outputs: xs<OutputFromSink<Template>>) => {
  const sinks = {} as { [K in keyof Template]: Template[K] }

  const remembered = outputs.remember()

  keysOf(template).forEach(key => {
    sinks[key] = (remembered
      .map(output => output[key] as Array<StreamType<Template[typeof key]>> | Template[typeof key] | undefined)
      .filter(exists)
      .map(vals => isArray(vals) ? once(...(vals as StreamType<Template[keyof Template]>[])) as any : vals as any)
      .flatten() as unknown) as Template[typeof key]
  })

  return sinks
}

// works with with a behaviour that distinguishes state behaviour from other behaviour
// state behaviour produces state reducers based on only the event and does NOT produce output
// other behaviour produces output based the event and (stale) state and does NOT produce state
export const dynamicModel = <State>(state$: xs<State>) => <Intent extends StreamsObject, TSink extends StreamsObject>(
  sinkTemplate: TSink,
  intentFactory: ((state$: xs<State>) => { intent: Intent; sinks: TSink }) | Intent,
  behaviour: DeclarationWithInit<Intent, State, TSink>
): Omit<TSink, 'state'> & { state: xs<(state: State) => State> } => {
  const initBehaviour = behaviour[INIT] as InitBehaviour<State, TSink>
  const initStateReducer = initBehaviour !== IGNORE && (typeof initBehaviour === 'function' ? initBehaviour : initBehaviour.state)
  const initOutputInstructions = initBehaviour !== IGNORE && (typeof initBehaviour === 'function' ? null : initBehaviour.output)

  const derivedBehaviour = behaviour[DERIVED] as DerivedBehaviour<State, TSink>
  const derivedStateReducer =
    (derivedBehaviour !== IGNORE && (typeof derivedBehaviour === 'function' ? derivedBehaviour : derivedBehaviour.state))
    || (id as (state: State) => State)
  const derivedOutputInstructions = derivedBehaviour !== IGNORE && (typeof derivedBehaviour === 'function' ? null : derivedBehaviour.output)

  const myState = initStateReducer ? state$.compose(ignoreSyncValueOnSubscribe).remember() : state$
  const { intent, sinks: intentSinks } = typeof intentFactory === 'function' ? intentFactory(myState) : { intent: intentFactory, sinks: {} }

  const initIntent = myState.take(1)

  const stateReducers = xs.merge(
    ...keysOf(intent)
      .map(intentKey => {
        const intentHandler = behaviour[intentKey] as IntentHandler<Content<Intent[typeof intentKey]>, State, TSink>
        if (!intentHandler || intentHandler === IGNORE) {
          return null
        }
        const stateIntentHandler = typeof intentHandler === 'function' ? intentHandler : intentHandler.state
        if (!stateIntentHandler) {
          return null
        }

        return intent[intentKey].map((intentValue: StreamType<Intent[typeof intentKey]>) => (state: State) => stateIntentHandler(intentValue)(state))
      })
      .filter(notNull)
  )

  const outputInstructions = xs.merge(
    ...[
      ...keysOf(intent).map(intentKey => {
        const intentHandler = behaviour[intentKey] as IntentHandler<Content<Intent[typeof intentKey]>, State, TSink>
        if (!intentHandler || intentHandler === IGNORE) {
          return null
        }
        const outputIntentHandler = typeof intentHandler === 'function' ? null : intentHandler.output
        if (!outputIntentHandler) {
          return null
        }

        return intent[intentKey]
          .compose(sampleCombine(myState))
          .map(([intentValue, state]: [intent: StreamType<Intent[typeof intentKey]>, state: State]) => outputIntentHandler(intentValue)(state))
      }),
      // WARNING: this order matters in order to have the init output trigger before the first derived
      derivedOutputInstructions ? myState.map(derivedOutputInstructions) : null,
      initOutputInstructions ? initIntent.map(initOutputInstructions) : null
    ].filter(notNull),
  ).map(x => x === NOTHING ? {} : x)

  return {
    ...mergeSinks(intentSinks, toSinksOutputOnly(sinkTemplate, outputInstructions)),
    state: (initStateReducer ? xs.merge(xs.of(initStateReducer), stateReducers) : stateReducers).map(reducer => C(reducer, derivedStateReducer))
  } as any
}

// works with a behaviour that distinguishes state behaviour from other behaviour
// state behaviour produces state reducers based on only the event and does NOT produce output
// other behaviour produces output based the event and (stale) state and does NOT produce state
export const dynamicCaseModel = <State extends { [key in CaseKey]: string }, CaseKey extends string = 'case'>(state$: xs<State>, caseKey: CaseKey = 'case' as CaseKey) => <
  Intent extends StreamsObject,
  TSink extends StreamsObject
>(
  sinkTemplate: TSink,
  intentFactory: ((state$: xs<State>) => { intent: Intent; sinks: TSink }) | Intent,
  behaviour: CaseBehaviourDeclaration<Intent, State, TSink, CaseKey>
): Omit<TSink, 'state'> & { state: xs<(state: State) => State> } => {
  const initBehaviour = behaviour[INIT] as InitBehaviour<State, TSink>
  const initStateReducer = initBehaviour !== IGNORE && (typeof initBehaviour === 'function' ? initBehaviour : initBehaviour.state)
  const initOutputInstructions = initBehaviour !== IGNORE && (typeof initBehaviour === 'function' ? null : initBehaviour.output)

  const derivedBehaviour = behaviour[DERIVED] as DerivedBehaviour<State, TSink>
  const derivedStateReducer =
    (derivedBehaviour !== IGNORE && (typeof derivedBehaviour === 'function' ? derivedBehaviour : derivedBehaviour.state))
    || (id as (state: State) => State)
  const derivedOutputInstructions = derivedBehaviour !== IGNORE && (typeof derivedBehaviour === 'function' ? null : derivedBehaviour.output)

  const myState = initStateReducer ? state$.compose(ignoreSyncValueOnSubscribe).remember() : state$
  const { intent, sinks: intentSinks } = typeof intentFactory === 'function' ? intentFactory(myState) : { intent: intentFactory, sinks: {} }

  const initIntent = myState.take(1)

  const stateReducers = xs.merge(
    ...keysOf(intent).map(intentKey => {
      return intent[intentKey].map((intentValue: StreamType<Intent[typeof intentKey]>) => (state: State) => {
        const intentBehaviour = behaviour[intentKey] as CaseIntentHandlers<Content<Intent[typeof intentKey]>, State, TSink, CaseKey>
        const intentHandler =
          (intentBehaviour[state[caseKey]]
            || (behaviour[intentKey] as PartialIntentHandlersWithDefault<Intent, State, TSink, CaseKey>)[DEFAULT]) as
            | CaseIntentHandler<Intent, State, State, TSink>
            | undefined
        if (!intentHandler || intentHandler === IGNORE) {
          return state
        }
        const stateHandler = typeof intentHandler === 'function' ? intentHandler : intentHandler.state
        return stateHandler ? stateHandler(intentValue)(state) : state
      })
    })
  )

  const outputInstructions = xs.merge(
    ...[
      ...keysOf(intent).map(intentKey => {
        return intent[intentKey].compose(sampleCombine(myState)).map(([intentValue, state]: [intent: StreamType<Intent[typeof intentKey]>, state: State]) => {
          const intentBehaviour = behaviour[intentKey] as CaseIntentHandlers<Content<Intent[typeof intentKey]>, State, TSink, CaseKey>
          const intentHandler = (intentBehaviour[state[caseKey]] || (behaviour[intentKey] as PartialIntentHandlersWithDefault<Intent, State, TSink, CaseKey>)[DEFAULT]) as
            | CaseIntentHandler<Intent, State, State, TSink>
            | undefined
          if (!intentHandler || intentHandler === IGNORE) {
            return NOTHING
          }
          const outputIntentHandler = typeof intentHandler === 'function' ? null : intentHandler.output
          if (!outputIntentHandler) {
            return NOTHING
          }

          return outputIntentHandler(intentValue)(state)
        })
      }),
      initOutputInstructions ? initIntent.map(initOutputInstructions) : null,
      derivedOutputInstructions ? myState.map(derivedOutputInstructions) : null
    ].filter(notNull)
  ).map(x => x === NOTHING ? {} : x)

  return {
    ...mergeSinks(intentSinks, toSinksOutputOnly(sinkTemplate, outputInstructions)),
    state: (initStateReducer ? xs.merge(xs.of(initStateReducer), stateReducers) : stateReducers).map(reducer => C(reducer, derivedStateReducer))
  } as any
}

export const dynamicIntent = <State>() => <SinkTemplate extends Record<string, xs<any>>, Config extends { [key: string]: IntentDefinition<any, State, SinkTemplate, any> }>(
  sinkTemplate: SinkTemplate,
  config: Config
) => (state$: xs<State>) => {
  const keys = Object.keys(config)
  const staticIntents = keys
    .map(key => {
      const definition = config[key]
      if (isSimpleIntentDefinition(definition)) {
        return [
          key,
          {
            [SINKS]: {},
            intent: definition
          }
        ] as [string, IntentDefinitionWithSinks<any, SinkTemplate>]
      }
      if (isIntentDefinitionWithSinks(definition)) {
        return [key, definition] as [string, IntentDefinitionWithSinks<any, SinkTemplate>]
      }
      return null
    })
    .filter(notNull)

  const dynamicIntents = keys
    .map(key => {
      const definition = config[key]
      if (isDynamicIntentDefinition(definition)) {
        return [key, definition] as [string, DynamicIntentDefinition<any, State, SinkTemplate, any>]
      }
      return null
    })
    .filter(notNull)

  type DynamicIntentSnapshotItem = {
    discriminatorValue: any
    intent: StaticIntentDefinition<any, SinkTemplate>
  }

  type DynamicIntentSnapshot = {
    [key: string]: DynamicIntentSnapshotItem
  }

  const UNINITIALIZED = Symbol()

  const emptyInitialDynamicIntent: DynamicIntentSnapshot = entriesToObj(
    dynamicIntents.map(([key]) => [
      key,
      {
        discriminatorValue: UNINITIALIZED,
        intent: xs.never()
      }
    ])
  )

  const dynamicIntents$ = state$
    .fold((dynamicIntentSnapshot, state) => {
      const newIntents = dynamicIntents
        .map(([key, definition]): [string, DynamicIntentSnapshotItem] | null => {
          const discriminatorValue = definition.discriminator(state)

          if (discriminatorValue === IGNORE || discriminatorValue === dynamicIntentSnapshot[key].discriminatorValue) {
            return null
          }

          return [
            key,
            {
              discriminatorValue: discriminatorValue,
              intent: definition.value(state, discriminatorValue)
            }
          ]
        })
        .filter(notNull)

      if (!newIntents.length) {
        return dynamicIntentSnapshot
      }

      return { ...dynamicIntentSnapshot, ...entriesToObj(newIntents) }
    }, emptyInitialDynamicIntent)
    .drop(1)
    .compose(dropRepeats())
    .map(snapshot => mapDict(snapshot, snapshotItem => snapshotItem.intent))

  const staticIntentOutput: { [key: string]: StaticIntentDefinition<any, SinkTemplate> } = entriesToObj(staticIntents)

  const resolvedIntentAndSinks = dynamicIntents$
    .map(dynamicIntentOutput => ({ ...dynamicIntentOutput, ...staticIntentOutput }))
    .map(resolvedIntentsOutput => {
      const intent = mapDict(resolvedIntentsOutput, (x, _) => (isIntentDefinitionWithSinks(x) ? x.intent : x))
      const intentSinks = mapDict(resolvedIntentsOutput, (x, _) => (isIntentDefinitionWithSinks(x) ? x[SINKS] : {}))
      const sinks$ = Object.keys(intentSinks).reduce((sinks, key) => mergeSinks(sinks, intentSinks[key]), {})

      return {
        intent: intent,
        sinks: sinks$
      }
    })
    // this is a metastream (describe content streams)
    // remembering is this is like remembering the control flow
    // this is NOT the same as remembering the values within the streams described by this metastream
    .remember()

  const mergedIntents = mergePageSinks(
    resolvedIntentAndSinks.map(x => x.intent as Partial<typeof x.intent>),
    mapObj(config, K(null))
  )
  const mergedSinks = mergePageSinks(
    resolvedIntentAndSinks.map(x => x.sinks as Partial<typeof x.sinks>),
    sinkTemplate
  ) as any

  return {
    intent: mergedIntents as { [K in keyof Config]: Config[K] extends IntentDefinition<infer U, any, any, any> ? xs<U> : never },
    sinks: mergedSinks as SinkTemplate
  }
}
