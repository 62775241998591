import { TaskMappingSources } from './types'
import { intent } from './intent'
import isolate from '@cycle/isolate'
import format from 'date-fns/format'
import xs from 'xstream'
import { apply, C, flatten, groupBy, isLoaded, Loadable, loaded, Manager, mergeSinks, removeAt, Unloaded, value } from '@/generic'
import { jsx } from 'h'
import { DERIVED, dynamicModel, INIT, pageSinkTemplate } from '@/infrastructure'
import { Button, Col, dateInputFormat, Icon, Input, Row } from '@/ui'
import { formatLocalDate, fromSymmetric, magicMirror, omit, stateScope } from '@/util'
import { isLevel3OrHigher } from '../../../model'
import { InputTaskMappingItem, SetTaskMappingConfigurationResource, taskMappingConfiguration } from '../../../resources'
import { TimeTrackerTask, timeTrackerTasksResource, TimeTrackerTasksResponse } from '../../../resources/timeTrackerTasks'
import { autoSuggestScore, Suggestable, TypedAutoSuggest } from '../../autoSuggest'
import { ConfigurationContext, getProjectMembersForTiaCode, isMissingTiaTimesheetCode, isMissingTimeTrackerTask, MappingTiaTimesheetCode, TaskMappingItem, TaskMappingViewState } from './../viewModel'
import * as hideExpired from './../configurationTable/hideExpired'
import { asSimple } from '@/fsharp'
import { CREATE_TABLE, SET_DATA } from '@/drivers/tanstackTableDriver'
import { taskMappingsTableColumns } from './taskMappingsTable'
import { view } from './view'
import { taskDisplayName } from '../../shared'

const newItem =
  (suggestableTasks: Loadable<Suggestable<TimeTrackerTask>[]>, suggestableTiaCodes: Loadable<Suggestable<MappingTiaTimesheetCode>[]>): TaskMappingViewState['newItem'] =>
    ({
      from: null,
      until: null,
      task: null,
      taskInput: {
        input: '',
        suggestables: suggestableTasks
      },
      tiaCode: null,
      tiaCodeInput: {
        input: '',
        suggestables: suggestableTiaCodes
      }
    })

const { set: itemSet } = Manager<TaskMappingItem>()

const isExpired = (taskMappingItem: TaskMappingItem) =>
  taskMappingItem.until ? taskMappingItem.until < new Date() : false

export const taakAfleidingenViewState = (context: ConfigurationContext): TaskMappingViewState => {
  if (!isLevel3OrHigher(context.configuration.levelConfiguration) || typeof context.tiaCodes.case === 'string') {
    return {
      context: context,
      mappings: [],
      taskMappingsTableKey: {},
      table: Unloaded,
      tasks: Unloaded,
      newItem: newItem(Unloaded, Unloaded),
      putTaskMappingConfigurationResponse: Unloaded,
      settings: hideExpired.state
    }
  }

  const rates = flatten(context.configuration.levelConfiguration.fields[0].orders.map(o => o.rates))

  const tiaCodes = context.tiaCodes
  const mappingTiaTimesheetCodes =
    groupBy(
      rates,
      item => item.tiaCodeId,
      (([tiaCodeId, items]): MappingTiaTimesheetCode => ({ id: tiaCodeId, name: items[0].tiaCodeName, writable: tiaCodes[tiaCodeId]?.writable ?? false })))

  const mappings =
    context.configuration.levelConfiguration.fields[0].taskMapping.map((m): TaskMappingItem => ({
      from: m.from,
      until: asSimple(m.until),
      projectMembers: getProjectMembersForTiaCode(rates, context.configuration.info.members, m.tiaCodeId),
      tiaCode: mappingTiaTimesheetCodes.find(tm => m.tiaCodeId === tm.id) || { missingTiaCodeId: m.tiaCodeId, missingTiaCodeName: m.tiaCodeName },
      task: m.taskId
    }))

  return ({
    context: context,
    mappings: mappings,
    taskMappingsTableKey: {},
    table: Unloaded,
    tasks: Unloaded,
    newItem: newItem(
      Unloaded,
      loaded(mappingTiaTimesheetCodes.map((item): Suggestable<MappingTiaTimesheetCode> => ({ display: item.name, value: item })))),
    putTaskMappingConfigurationResponse: Unloaded,
    settings: hideExpired.state
  })
}

const taskMappingItemView = (item: TaskMappingItem) =>
  <Row>
    <Col>{
      typeof item.task === 'string' ? '' : isMissingTimeTrackerTask(item.task)
        ? <div className="large-invalid-feedback">Missing task with id {item.task.missingTimeTrackerTaskId}</div>
        : item.task.name}</Col>
    <Col>{
      isMissingTiaTimesheetCode(item.tiaCode)
        ? <span title={`Missing TIA code with id ${item.tiaCode.missingTiaCodeId}!`}>{item.tiaCode.missingTiaCodeName}<Icon icon="alert-triangle" color="red" /></span>
        : item.tiaCode.writable
          ? item.tiaCode.name
          : <span title="This TIA code is read only!">{item.tiaCode.name}<Icon icon="alert-triangle" color="orange" /></span>}
    </Col>
    <Col>{item.projectMembers.map(m => m.displayName).join(', ')}</Col>
    <Col><Input type="date" className="from" value={item.from ? format(item.from, dateInputFormat) : ''} /></Col>
    <Col><Input type="date" className="until" value={item.until ? format(item.until, dateInputFormat) : ''} /></Col>
    <Col width={1}><Button context="danger" className="delete"><Icon icon="x" /></Button></Col>
  </Row>

const orderTaskMappingItem = (a: TaskMappingItem, b: TaskMappingItem) => {
  if (typeof a.task === 'string' || typeof b.task === 'string') {
    return 0
  }

  const aIsInvalid = isMissingTiaTimesheetCode(a.tiaCode) || isMissingTimeTrackerTask(a.task)
  const bIsInvalid = isMissingTiaTimesheetCode(b.tiaCode) || isMissingTimeTrackerTask(b.task)

  if (aIsInvalid && !bIsInvalid) {
    return -1
  } else if (bIsInvalid && !aIsInvalid) {
    return 1
  } else if (aIsInvalid && bIsInvalid) {
    return 0
  }

  return (a.task as TimeTrackerTask).name.localeCompare((b.task as TimeTrackerTask).name)
    || +a.from - +b.from
    || (a.tiaCode as MappingTiaTimesheetCode).name.localeCompare((b.tiaCode as MappingTiaTimesheetCode).name)
}

const updateMappingItemsWithTasks = (tasks: TimeTrackerTasksResponse) =>
  (afleidingen: TaskMappingItem[]) => afleidingen.map(afleiding => {
    if (typeof afleiding.task !== 'string') {
      return afleiding
    }

    return ({
      ...afleiding,
      task: tasks.find(t => t.id === afleiding.task) || { missingTimeTrackerTaskId: afleiding.task }
    })
  })

const { get, set, setTo } = Manager<TaskMappingViewState>()

const model = (intents: ReturnType<typeof intent>, sources: TaskMappingSources, mappingResource: SetTaskMappingConfigurationResource) =>
  dynamicModel(sources.state.stream)(
    pageSinkTemplate,
    intents,
    {
      [INIT]:  {
        output: state => ({
          tanstackTable: [
            { type: CREATE_TABLE, key: state.taskMappingsTableKey, options: { columns: taskMappingsTableColumns, data: state.mappings }}
          ]
        })
      },
      updateTable: C(loaded, set(state => state.table)),
      chooseTimeTrackerTask: set(state => state.newItem.task),
      '.new-item-from': newValue => set(state => state.newItem.from)(val => newValue || val),
      '.new-item-until': newValue => set(state => state.newItem.until)(val => newValue || val),
      '.from': newValue => set(state => state.mappings[newValue.index])(itemSet(state => state.from)(val => newValue.value || val)),
      '.until': newValue => set(state => state.mappings[newValue.index])(itemSet(state => state.until)(newValue.value)),
      '.delete': index => set(state => state.mappings)(removeAt(index)),
      '#add-new-task-mapping': _ => state => {
        const { task, tiaCodeInput: { input }, from, until } = state.newItem
        if (!task || !input || !from || !isLoaded(state.newItem.tiaCodeInput.suggestables) || !isLevel3OrHigher(state.context.configuration.levelConfiguration)) {
          return state
        }

        const suggestables = value(state.newItem.tiaCodeInput.suggestables)
        const filteredSuggestables = suggestables.filter(suggestable => autoSuggestScore(state.newItem.tiaCodeInput.input, suggestable.display) >= 0)
        if (!filteredSuggestables.length) {
          return state
        }

        const rates = flatten(state.context.configuration.levelConfiguration.fields[0].orders.map(o => o.rates))

        const newItems =
          filteredSuggestables
            .map((suggestedCode): TaskMappingItem => ({
              task: task,
              tiaCode: suggestedCode.value,
              from: from,
              until: until,
              projectMembers: getProjectMembersForTiaCode(rates, state.context.configuration.info.members, suggestedCode.value.id),
            }))

        return set(s => s.mappings)([...state.mappings, ...newItems])
          .and(s => s.newItem)(newItem(state.newItem.taskInput.suggestables, state.newItem.tiaCodeInput.suggestables))(state)
      },
      receiveTimeTrackerTasks: response => state => {
        if (!isLoaded(response)) {
          return state
        }

        const tasks = value(response)

        return set(s => s.tasks)(response)
          .and(s => s.newItem.taskInput)(taskInput =>
            ({
              ...taskInput,
              suggestables:
                loaded(tasks.map((task): Suggestable<typeof task> => ({
                  value: task,
                  display: taskDisplayName(task)
                })))
            })
          )
          .and(s => s.mappings)(updateMappingItemsWithTasks(tasks))(state)
      },
      '#save-task-mapping': {
        output: _ => state => {
          if (!state.mappings.every(m => m.task && m.tiaCode && m.from)) {
            return {}
          }

          return {
            HTTP: [mappingResource.put(state.mappings.map((m): InputTaskMappingItem => ({
              from: formatLocalDate(m.from),
              until: m.until ? formatLocalDate(m.until) : null,
              tiaCodeName: isMissingTiaTimesheetCode(m.tiaCode) ? m.tiaCode.missingTiaCodeName : m.tiaCode.name,
              taskId: typeof m.task === 'string' ? m.task : isMissingTimeTrackerTask(m.task) ? m.task.missingTimeTrackerTaskId : m.task.id
            })))]
          }
        }
      },
      putTaskMappingConfigurationResponse: {
        state: set(state => state.putTaskMappingConfigurationResponse),
        output: response => state => {
          if (!isLoaded(response)) {
            return {}
          }

          return {
            HTTP: [sources.primState.projectConfiguration(state.context.key).refresh]
          }
        }
      },
      '.hide-checked':
        hide =>
          C(
            hideExpired.behaviour['.hide-checked'],
            setTo(s => s.settings))(hide),
      [DERIVED]: {
        output: state => ({
          tanstackTable: [{
            type: SET_DATA,
            key: state.taskMappingsTableKey,
            data: state.mappings
          }]
        })
      }
    })


export const TaskMappingComponent =  magicMirror((sources: TaskMappingSources) => {
  const timeTrackerTasks = timeTrackerTasksResource(sources.apiHost + '/api')(sources.HTTP)(sources.projectKey)
  const mappingResource = taskMappingConfiguration(sources.apiHost + '/api')(sources.HTTP)(sources.projectKey)

  const tiaCodeSuggest = isolate(TypedAutoSuggest<MappingTiaTimesheetCode>(), stateScope(fromSymmetric(get(state => state.newItem.tiaCodeInput)), 'tiaCodeSuggest'))(sources)
  const timeTrackerTaskSuggest = isolate(TypedAutoSuggest<TimeTrackerTask>(), stateScope(fromSymmetric(get(state => state.newItem.taskInput)), 'timeTrackerTaskSuggest'))(sources)

  const sinks = model(intent(sources, timeTrackerTasks, mappingResource), sources, mappingResource)

  return mergeSinks(
    sinks,
    omit(tiaCodeSuggest, 'DOM', 'autoSuggestChoose'),
    omit(timeTrackerTaskSuggest, 'DOM', 'autoSuggestChoose'),
    {
      DOM: xs.combine(sources.state.stream, tiaCodeSuggest.DOM, timeTrackerTaskSuggest.DOM).map(apply(view)),
      HTTP: xs.of(timeTrackerTasks.get),
      chooseTimeTrackerTask: timeTrackerTaskSuggest.autoSuggestChoose
    })
})

