import { InternalInstances } from '@cycle/state/lib/cjs/types'
import xs, { InternalListener, Operator, OutSender, Stream } from 'xstream'
import { StreamType } from '../../generic'

export type Sinks = { [key: string]: xs<any> }

class PickMergeMapListener<Si extends Sinks, K extends keyof Si, T extends StreamType<Si[K]>, U> implements InternalListener<T>, OutSender<U> {
  public ins: Stream<T>
  public out: Stream<U>
  public p: PickMergeMap<Si, K, T, U>

  constructor(out: Stream<U>, p: PickMergeMap<Si, K, T, U>, ins: Stream<T>) {
    this.ins = ins
    this.out = out
    this.p = p
  }

  public _n(t: T): void {
    const out = this.out
    if (out === null) {
      return
    }
    out._n(t)
  }

  public _e(err: any): void {
    const out = this.out
    if (out === null) {
      return
    }
    out._e(err)
  }

  public _c(): void {
    void 0
  }
}

class PickMergeMap<Si extends Sinks, K extends keyof Si, T extends StreamType<Si[K]>, U> implements Operator<InternalInstances<Si>, U> {
  public type = 'pickMergeMap'
  public ins: Stream<InternalInstances<Si>>
  public out: Stream<U>
  public sel: K
  public ils: Map<string, PickMergeMapListener<Si, K, T, U>>
  public inst: InternalInstances<Si>

  constructor(sel: K, ins: Stream<InternalInstances<Si>>, public map: (x: T, key: string, index: number) => U) {
    this.ins = ins
    this.out = null as any
    this.sel = sel
    this.ils = new Map()
    this.inst = null as any
  }

  public _start(out: Stream<U>): void {
    this.out = out
    this.ins._add(this)
  }

  public _stop(): void {
    this.ins._remove(this)
    const ils = this.ils
    ils.forEach((il, key) => {
      il.ins._remove(il)
      il.ins = null as any
      il.out = null as any
      ils.delete(key)
    })
    ils.clear()
    this.out = null as any
    this.ils = new Map()
    this.inst = null as any
  }

  public _n(inst: InternalInstances<Si>): void {
    this.inst = inst
    const arrSinks = inst.arr
    const ils = this.ils
    const out = this.out
    const sel = this.sel
    const n = arrSinks.length
    // add
    for (let i = 0; i < n; ++i) {
      const sinks = arrSinks[i]
      const key = sinks._key
      const sink: Stream<any> = xs.fromObservable(sinks[sel] || xs.never()).map(x => this.map(x as T, key, i))
      if (!ils.has(key)) {
        ils.set(key, new PickMergeMapListener(out, this, sink))
        sink._add(ils.get(key)!)
      }
    }
    // remove
    ils.forEach((il, key) => {
      if (!inst.dict.has(key) || !inst.dict.get(key)) {
        il.ins._remove(il)
        il.ins = null as any
        il.out = null as any
        ils.delete(key)
      }
    })
  }

  public _e(err: any) {
    const u = this.out
    if (u === null) {
      return
    }
    u._e(err)
  }

  public _c() {
    const u = this.out
    if (u === null) {
      return
    }
    u._c()
  }
}

export function pickMergeMap<Si extends Sinks, K extends keyof Si, T extends StreamType<Si[K]>, U>(selector: K, map: (x: T, key: string, index: number) => U) {
  return function pickMergeOperator(inst$: Stream<InternalInstances<Si>>) {
    return new Stream(new PickMergeMap(selector, inst$, map))
  }
}
