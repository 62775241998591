import { HTTPSource } from '@cycle/http'
import { GettableResource, handleLatest, toLoadable } from '../../dataServices'
import { handleError, resultOrError, simpleResult } from './resourceUtil'
import { RequestError, UnhandledApiExceptionProblem } from '../model'

export type RolesResponse = string[]
export type RolesResource = GettableResource<RolesResponse, RequestError | UnhandledApiExceptionProblem>

export const rolesResource = (root: string) => (HTTP: HTTPSource): RolesResource => {
  const category = 'get-roles'

  const request = {
    category: category,
    method: 'GET',
    url: `${root}/config/roles`
  }

  return {
    name: request.url,
    get: request,
    value: toLoadable(handleLatest(HTTP)(category)({
      success: simpleResult<RolesResponse>,
      failure: {
        500: resultOrError<UnhandledApiExceptionProblem>
      },
      error: handleError
    }))
  }
}
