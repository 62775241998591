import isolate from '@cycle/isolate'
import { VNode } from 'snabbdom'
import xs from 'xstream'
import { toIntent } from '../../../../dataServices'
import { arrayToMap, C, ErrorType, except, isLoaded, isLoading, Loadable, Manager, mapError, mapLoaded, mergeSinks, Unloaded, value } from '../../../../generic'
import { jsx } from 'h'
import { DERIVED, dynamicIntent, dynamicModel, IGNORE, INIT, pageSinkTemplate, StaticIntentDefinition } from '../../../../infrastructure'
import { Button, capture, change, Checkbox, CheckboxLabel, click, Danger, events, FormCheck, Input, inputs, inputTarget, Label, renderLoadable, Select, Warning } from '../../../../ui'
import { stateScope, omit } from '../../../../util'
import { ClockifyConfiguration, dummyCredential, emptyTimeEntryMappingConfig, TimeEntryMappingConfig } from '../../../model'
import { mapServiceItemValue, serviceItemToIntent } from '../../../primDataService'
import { timeTrackerConfiguration } from '../../../resources'
import { clockifyProjectsResource, clockifyWorkspacesResource, CLOCKIFY_AUTHENTICATION_FAILED } from '../../../resources/clockify'
import { ProjectPageSources } from '../../common'
import { clockifyConfigInvalidRenderConfig, insufficientRightsRenderConfig, renderConfigurationResponse, renderProblem, renderProblemFor, requestErrorRenderConfig, setTimeTrackerConfigurationProblemRenderConfig, timeTrackerConfigurationSettingWarningRenderConfig, unhandledApiExceptionRenderConfig, unhandledClockifyProblemRenderConfig, wrongLevelProblemRenderConfig } from '../../renderers'
import { ClockifyConfigurationViewState, ConfigurationContext } from '../viewModel'
import { TimeEntryMappingConfigurationComponent } from './timeEntryMappingConfiguration'
import { asSimple } from '@/fsharp'

type State = ClockifyConfigurationViewState
type Sources = ProjectPageSources<State>

const emptyState = (context: ConfigurationContext): State => ({
  context: context,
  timeEntryMappingConfig: emptyTimeEntryMappingConfig,
  apiKey: '',
  projects: Unloaded,
  selectedProjects: new Set(),
  lastConfiguredProjectInformation: new Map(),
  missingProjects: [],
  selectedWorkspace: null,
  timeTrackerConfigurationUpdateResponse: Unloaded,
  workspaces: Unloaded
})

export const clockifyConfigurationViewState =
  (context: ConfigurationContext, timeEntryMappingConfig: TimeEntryMappingConfig, clockifyConfig: ClockifyConfiguration | null): State => {
    return {
      ...emptyState(context),
      timeEntryMappingConfig: timeEntryMappingConfig,
      apiKey: clockifyConfig?.apiKey ?? '',
      selectedWorkspace: clockifyConfig?.workspace.workspaceId ?? null,
      selectedProjects: new Set(clockifyConfig?.projects.map(p => p.projectId) ?? []),
      lastConfiguredProjectInformation:
        arrayToMap(
          clockifyConfig?.projects ?? [],
          p => p.projectId,
          p => ({
            id: p.projectId,
            name: p.lastConfiguredName,
            colour: p.lastConfiguredColour,
            state: p.lastConfiguredStatus === 1 ? 'Active' : 'Archived'
          }))
    }
  }


const addClockifyAuthenticationFailureError =
  <TVal, TError>(loadable: Loadable<TVal, TError>) => mapError(loadable, e => e as (typeof e) | (typeof CLOCKIFY_AUTHENTICATION_FAILED))

const intent = (sources: Sources) =>
  dynamicIntent<State>()(
    pageSinkTemplate,
    {
      ...capture(
        inputs({
          '#clockify-workspace': input => input === '0' ? '' : input,
          '#clockify-apikey': null
        }),
        events({
          '.save': click,
          '[data-projectid]': C(change, es => es.map(C(inputTarget, el => [el.dataset['projectid'] as string, el.checked] as const))),
        })
      )(sources),
      clockifyWorkspaces: {
        discriminator: state => `${state.context.key} - ${state.apiKey}`,
        value: state =>
          (state.apiKey === dummyCredential
            ? serviceItemToIntent(mapServiceItemValue(
              sources.primState.clockify.workspaces(state.context.key),
              addClockifyAuthenticationFailureError))
            : state.apiKey.length > 1
              ? toIntent(clockifyWorkspacesResource(sources.HTTP)(state.apiKey))
              : xs.never()) as StaticIntentDefinition<State['workspaces'], { HTTP: null }>
      },
      clockifyProjects: {
        discriminator: state => state.selectedWorkspace,
        value: (state, workspaceId: string | null) =>
          (workspaceId
            ? state.apiKey === dummyCredential
              ? serviceItemToIntent(mapServiceItemValue(
                sources.primState.clockify.projects(state.context.key, workspaceId),
                addClockifyAuthenticationFailureError))
              : toIntent(clockifyProjectsResource(sources.HTTP)(state.apiKey)(workspaceId))
            : xs.never<State['projects']>()) as StaticIntentDefinition<State['projects'], { HTTP: null }>
      },
      receivePutResponse: timeTrackerConfiguration(sources.apiHost + '/api')(sources.HTTP)(sources.projectKey).putResponse
    })

const { set } = Manager<State>()

const model = (intents: ReturnType<typeof intent>, sources: Sources) =>
  dynamicModel(sources.state.stream)(
    pageSinkTemplate,
    intents,
    {
      [INIT]: IGNORE,
      '#clockify-apikey': key => set(state => state.apiKey)(key)
        .and(s => s.selectedWorkspace)(null)
        .and(s => s.workspaces)(Unloaded)
        .and(s => s.selectedProjects)(new Set())
        .and(s => s.projects)(Unloaded),
      '#clockify-workspace': set(state => state.selectedWorkspace),
      '[data-projectid]': ([projectId, selected]) => set(state => state.selectedProjects)(selectedProjects => {
        const newSet = new Set(selectedProjects)
        if (selected) {
          newSet.add(projectId)
        } else {
          newSet.delete(projectId)
        }
        return newSet
      }),
      clockifyProjects: response => set(state => state)(state => {
        if (!isLoaded(response)) {
          return {
            ...state,
            projects: response
          }
        }

        const projects = value(response)

        return {
          ...state,
          missingProjects:
            except(Array.from(state.lastConfiguredProjectInformation.keys()))(projects.map(p => p.id))
              .map(missingProjectId => state.lastConfiguredProjectInformation.get(missingProjectId)!),
          projects: mapLoaded(response, projects => projects.slice().sort((a, b) => a.name.localeCompare(b.name)))
        }
      }),
      clockifyWorkspaces: set(state => state.workspaces),
      '.save': {
        output: _ => state => ({
          HTTP: [
            timeTrackerConfiguration(sources.apiHost + '/api')(sources.HTTP)(sources.projectKey).put({
              timeEntryMappingConfig: {
                ignoreOverlap: state.timeEntryMappingConfig.ignoreOverlap,
                timeEntryPaddingAmount: asSimple(state.timeEntryMappingConfig.timeEntryPaddingAmount)
              },
              timeTrackerConfiguration: {
                case: 'ClockifyConfigInput',
                fields: [{
                  apiKey: state.apiKey,
                  workspaceId: state.selectedWorkspace!,
                  projectIds: Array.from(state.selectedProjects)}]}
            })
          ]
        })
      },
      receivePutResponse: {
        state: response => set(state => state.timeTrackerConfigurationUpdateResponse)(response),
        output: response => _ =>
          (isLoaded(response))
            ? {
              HTTP: [sources.primState.projectConfiguration(sources.projectKey).refresh]
            }
            : {}
      },
      [DERIVED]: IGNORE
    })

const renderClockifyResourceError = (err: ErrorType<State['workspaces'] | State['projects']>) =>
  err === CLOCKIFY_AUTHENTICATION_FAILED
    ? <Warning>The provided API key is not valid.</Warning>
    : typeof err === 'string'
      ? <Danger>{err}</Danger>
      : <Danger>{renderProblemFor(err)({
        ...requestErrorRenderConfig,
        ...insufficientRightsRenderConfig,
        ...unhandledApiExceptionRenderConfig,
        ...wrongLevelProblemRenderConfig,
        ...clockifyConfigInvalidRenderConfig,
        ...unhandledClockifyProblemRenderConfig
      })}</Danger>

const view = (state: State, timeEntryMappingConfigNode: VNode) =>
  <div>
    <div>
      <div className="d-inline-block">
        <Label className="required" for="clockify-apikey">Clockify API key</Label>
        <Input className="mb-3" id="clockify-apikey" type="password" value={state.apiKey} autocomplete="off" />

        {renderLoadable(state.workspaces, {
          loaded: workspaces =>
            <>
              <Label className="required" for="clockify-workspace">Workspace</Label>
              <Select className="mb-3" id="clockify-workspace">
                <option value="0">-- Clockify workspace --</option>
                {workspaces.map(workspace => <option selected={state.selectedWorkspace === workspace.id} value={workspace.id} key={workspace.id}>{workspace.name}</option>)}
              </Select>
              {renderLoadable(state.projects,
                {
                  loaded: projects =>
                    <div className="mb-3">
                      <label className="required">Projects</label>
                      {state.missingProjects.length
                        ? <Warning>
                          <label>
                                Missing projects: uncheck and save to resolve this issue
                          </label>
                          <div>
                            {state.missingProjects.map(project =>
                              <FormCheck>
                                <Checkbox
                                  id={`clockify-project-${project.id}`}
                                  dataset={{'projectid': project.id.toString()}}
                                  type="checkbox"
                                  checked={state.selectedProjects.has(project.id)} />
                                <CheckboxLabel for={`clockify-project-${project.id}`}>
                                  {project.name} ({state.lastConfiguredProjectInformation.get(project.id)!.state})
                                </CheckboxLabel>
                              </FormCheck>)}
                          </div>
                        </Warning>
                        : null}
                      {projects.map(project => <FormCheck>
                        <Checkbox id={`clockify-project-${project.id}`} dataset={{'projectid': project.id}} type="checkbox" checked={state.selectedProjects.has(project.id)} />
                        <CheckboxLabel for={`clockify-project-${project.id}`}>{project.name}</CheckboxLabel>
                      </FormCheck>)}
                    </div>,
                  error: renderClockifyResourceError
                })}
            </>,
          error: renderClockifyResourceError
        }
        )}
      </div>
      {timeEntryMappingConfigNode}
      {renderLoadable(state.timeTrackerConfigurationUpdateResponse, {
        loaded: warnings =>
          warnings.length
            ? <Warning className='mb-3'>
              <h4>The configuration was saved, but the following issues have been detected:</h4>
              {warnings.map(warning => <div>{renderProblem(timeTrackerConfigurationSettingWarningRenderConfig(state.context.configuration.info.members))(warning)}</div>)}</Warning>
            : null,
        loading: () => null,
        error: err => <Danger className='mb-3'>{renderConfigurationResponse(setTimeTrackerConfigurationProblemRenderConfig)(err)}</Danger>
      })}
      <Button
        context="primary"
        className="save"
        disabled={!(state.selectedWorkspace && state.selectedProjects.size) || isLoading(state.timeTrackerConfigurationUpdateResponse)}>
          Save
      </Button>
    </div>
  </div>

export const ClockifyConfigurationComponent = (sources: ProjectPageSources<State>) => {
  const sinks = model(intent(sources), sources)

  const timeEntryMappingConfigurationComponent =
    isolate(
      TimeEntryMappingConfigurationComponent,
      stateScope({
        get: (state: State) => ({...state.timeEntryMappingConfig, projectSettings: state.context.configuration.info.settings}),
        set: (state, childState) => set(s => s.timeEntryMappingConfig)(childState)(state)
      }))(sources)

  return mergeSinks(sinks, omit(timeEntryMappingConfigurationComponent, 'DOM'), {
    DOM: xs.combine(sources.state.stream, timeEntryMappingConfigurationComponent.DOM).map(([state, child]) => view(state, child))
  })
}
