import { jsx } from '@/h'
import { round2 } from '@/util'
import { createColumnHelper } from '@tanstack/table-core'
import { DayReportRecord } from './types'
import { renderDate } from './view'

const columnHelper = createColumnHelper<DayReportRecord>()

export const timesheetTableColumns = [
  columnHelper.accessor(row => row.workDate, {
    id: 'date',
    header: 'Date',
    cell: info => renderDate(info.getValue())
    // aggregatedCell: info => info.getValue(),
    // aggregationFn: '',
  }),
  columnHelper.accessor(row => row.hours, {
    id: 'hours',
    header: 'Hours',
    cell: info => {
      const renderValue = info.renderValue()
      return renderValue && round2(renderValue)
    },
    aggregatedCell: info => <strong>{info.renderValue()}</strong>,
    aggregationFn: (columnId, leafRows, _childRows) => round2(leafRows.reduce((sum, record) => sum + record.getValue<DayReportRecord['hours']>(columnId), 0))
  }),
  columnHelper.accessor(row => row.taskName, {
    id: 'task',
    header: 'Task',
    cell: info => info.renderValue()
  }),
  columnHelper.accessor(row => row.workItem, {
    id: 'workItem',
    header: 'Work item',
    cell: info => {
      const workItem = info.getValue()
      return workItem ? <a href={workItem.url} target="_blank" rel="noreferrer">#{workItem.id}</a> : ''
    }
  }),
  columnHelper.accessor(row => row.tiaCodeName, {
    id: 'tiaCode',
    header: 'TIA code'
  }),
  columnHelper.accessor(row => row.description, {
    id: 'description',
    header: 'Comment'
  })
]

