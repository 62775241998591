import { C, Manager } from '../../../../generic'
import { jsx } from 'h'
import { DERIVED, dynamicModel, IGNORE, INIT } from '../../../../infrastructure'
import { capture, change, Checkbox, CheckboxLabel, events, FormCheck, Input, inputs, inputTarget, numberInput } from '../../../../ui'
import { ProjectSettings, TimeEntryMappingConfig } from '../../../model'
import { ProjectPageSources } from '../../common'
import { asOption } from '@/fsharp'

type State = TimeEntryMappingConfig & { projectSettings: ProjectSettings }
type Sources = ProjectPageSources<State>

const intent = (sources: Sources) =>
  capture(inputs({
    '#time-entry-mapping-pading': numberInput
  }),
  events({'#time-entry-mapping-ignore-overlap': C(change, es => es.map(C(inputTarget, el => el.checked)))}))(sources)

const { set } = Manager<State>()

const model = (intents: ReturnType<typeof intent>, sources: Sources) =>
  dynamicModel(sources.state.stream)(
    {},
    intents, {
      [INIT]: IGNORE,
      '#time-entry-mapping-ignore-overlap': set(state => state.ignoreOverlap),
      '#time-entry-mapping-pading': C(asOption, set(state => state.timeEntryPaddingAmount)),
      [DERIVED]: IGNORE
    })

const view = (state: State) => <div className="mb-3">
  <div>
    <label>Other settings</label>
  </div>
  <div className="form-group">
    <FormCheck>
      <Checkbox id="time-entry-mapping-ignore-overlap" type="checkbox" checked={state.ignoreOverlap} />
      <CheckboxLabel for="time-entry-mapping-ignore-overlap" >Ignore overlap</CheckboxLabel>
    </FormCheck>
  </div>
  { state.projectSettings.timeEntryPaddingAllowed
    ? <div className="form-inline">
      <label for="time-entry-mapping-pading" className="mr-sm-2">Time entry padding minutes:</label>
      <Input id="time-entry-mapping-pading" type="number" size={2} value={(state.timeEntryPaddingAmount || 0).toString()} />
    </div>
    : null }
</div>

export const TimeEntryMappingConfigurationComponent = (sources: Sources) => ({
  ...model(intent(sources), sources),
  DOM: sources.state.stream.map(view)
})

