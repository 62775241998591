import { mapOption, None, some } from './option'
import { K } from './util'

export const LOADED = Symbol('LOADED')
const LOADING = Symbol('LOADING')
export const VALUE = Symbol('VALUE')
const STALE_VALUE = Symbol('STALE_VALUE')
const ERROR_MESSAGE = Symbol('ERROR_MESSAGE')

export const Unloaded = Symbol('UNLOADED')
interface LoadingWithoutStale {
  [LOADING]: true
}
type LoadingWithStale<T> = LoadingWithoutStale & { [STALE_VALUE]: T }

export type Loading<T> = LoadingWithoutStale | LoadingWithStale<T>
export interface LoadError<TError = string> {
  [ERROR_MESSAGE]: TError
}
export interface Loaded<T> {
  [VALUE]: T
  [LOADED]: true
}

export type Loadable<T, TError = string> = typeof Unloaded | Loading<T> | LoadError<TError> | Loaded<T>

export type ValueType<T extends Loadable<any, any>> =
  T extends Loaded<infer V> ? V : never

export type ErrorType<T extends Loadable<any, any>> =
  T extends LoadError<infer E> ? E : never

const empty = K({})

// constructors
export const loadError = <TE>(message: TE) =>
  ({ [ERROR_MESSAGE]: message })
export const loaded = <T, _TError = string>(loadedValue: T) =>
  ({ [LOADED]: true as const, [VALUE]: loadedValue })
// eslint-disable-next-line @typescript-eslint/no-use-before-define
export const loading = <T, TError = string>(loadable: Loadable<T, TError>) =>
  ({ [LOADING]: true as const, ...mapOption(maybeValue(loadable), val => ({ [STALE_VALUE]: val }), empty) })

// guards
export const isLoaded = <T, TError = string>(loadable: Loadable<T, TError>): loadable is Loaded<T> =>
  typeof loadable === 'object' && !!(loadable as Loaded<T>)[LOADED]
export const isLoading = <T, TError = string>(loadable: Loadable<T, TError>): loadable is Loading<T> =>
  typeof loadable === 'object' && !!(loadable as Loading<T>)[LOADING]
export const isLoadError = <T, TError = string>(loadable: Loadable<T, TError>): loadable is LoadError<TError> =>
  typeof loadable === 'object' && ERROR_MESSAGE in (loadable as LoadError<TError>)
export const isWithStale = <T, _TError = string>(loadable: Loading<T>): loadable is LoadingWithStale<T> =>
  typeof loadable === 'object' && STALE_VALUE in (loadable as LoadingWithStale<T>)
export const isUnloaded = <T, TError = string>(loadable: Loadable<T, TError>): loadable is typeof Unloaded =>
  loadable === Unloaded

// accessors
export const value = <T, _TError = string>({ [VALUE]: loadedValue }: Loaded<T>) => loadedValue
export const staleValue = <T, _TError = string>({ [STALE_VALUE]: val }: LoadingWithStale<T>) => val
export const loadErrorMessage = <TError = string>({ [ERROR_MESSAGE]: message }: LoadError<TError>) => message
export const valueOrStale = <T, TDefault = undefined, TError = string>(loadable: Loadable<T, TError>, def: TDefault | undefined = undefined) =>
  isLoaded(loadable) ? value(loadable) : isLoading(loadable) ? (isWithStale(loadable) ? staleValue(loadable) : def) : def
export const maybeValue = <T, TError = string>(loadable: Loadable<T, TError>) =>
  (isLoaded(loadable) ? some(value(loadable)) : isLoading(loadable) ? (isWithStale(loadable) ? some(staleValue(loadable)) : None) : None)
export const loadedOr = <T, U, TError = string>(loadable: Loadable<T, TError>, defaultValue: U) =>
  isLoaded(loadable) ? value(loadable) : defaultValue

export const combineLoadables = <T1, T2, TError>(loadable1: Loadable<T1, TError>, loadable2: Loadable<T2, TError>): Loadable<[T1, T2], TError[]> =>
  isUnloaded(loadable1) || isUnloaded(loadable2)
    ? Unloaded
    : isLoaded(loadable1) && isLoaded(loadable2)
      ? loaded([value(loadable1), value(loadable2)])
      : isLoadError(loadable1) || isLoadError(loadable2)
        ? isLoadError(loadable1) && isLoadError(loadable2)
          ? loadError([loadErrorMessage(loadable1), loadErrorMessage(loadable2)])
          : isLoadError(loadable1)
            ? loadError([loadErrorMessage(loadable1)])
            : loadError([loadErrorMessage(loadable2 as any)])
        : isLoaded(loadable1) && isLoading(loadable2)
          ? isWithStale(loadable2)
            ? loading(loaded([value(loadable1), staleValue(loadable2)] as [T1, T2]))
            : loading(Unloaded)
          : isLoading(loadable1) && isLoaded(loadable2)
            ? isWithStale(loadable1)
              ? loading(loaded([staleValue(loadable1), value(loadable2)] as [T1, T2]))
              : loading(Unloaded)
            : isWithStale(loadable1 as Loading<T1>) && isWithStale(loadable2 as Loading<T2>)
              ? loading(loaded([staleValue(loadable1 as LoadingWithStale<T1>), staleValue(loadable2 as LoadingWithStale<T2>)] as [T1, T2]))
              : loading(Unloaded)

export const mapLoaded = <T, T2, TError>(loadable: Loadable<T, TError>, map: (value: T) => T2): Loadable<T2, TError> =>
  isLoaded(loadable)
    ? loaded(map(value(loadable)))
    : (isLoading(loadable) && isWithStale(loadable))
      ? loading(loaded(map(staleValue(loadable))))
      : loadable

export const mapError = <T, TError, TError2>(loadable: Loadable<T, TError>, map: (error: TError) => TError2): Loadable<T, TError2> =>
  isLoadError(loadable) ? loadError(map(loadErrorMessage(loadable))) : loadable
