import { HTTPSource } from '@cycle/http'
import { handleLatest, receiveJson, toLoadable } from '../../dataServices'
import { GettableResource } from '../../dataServices/types'
import { FOption } from '../../fsharp'
import { handleError, resultOrError, simpleResult } from './resourceUtil'
import { InsufficientRights, RequestError, TiaConfigurationInvalid, UnhandledApiExceptionProblem, UnhandledTiaProblem, insufficientRights } from '../model'
import { K } from '@/generic'

export type CronosUserInfo = {
  displayName: string
  cronosUsername: string
  cronosAdObjectId: string
}

export type TiaUser = {
  tiaUsername: string
  tiaUserId: string
  tiaFirstName: string
  tiaLastName: string
  employerName: string
  cronosUserInfo: FOption<CronosUserInfo>
}

export type TiaUsersResponse = TiaUser[]

export type TiaUsersResource = GettableResource<TiaUsersResponse, RequestError | InsufficientRights | UnhandledApiExceptionProblem | UnhandledTiaProblem | TiaConfigurationInvalid>

export const tiaUsersResource = (root: string) => (HTTP: HTTPSource) => (key: string): TiaUsersResource => {
  const category = `tia-users-${key}`

  const request = {
    category: category,
    method: 'GET',
    url: `${root}/projects/${key}/tia/users`
  }

  return {
    name: request.url,
    get: request,
    value: toLoadable(handleLatest(HTTP)(category)({
      success: simpleResult<TiaUsersResponse>,
      failure: {
        403: K(insufficientRights),
        409: simpleResult<TiaConfigurationInvalid>,
        500: resultOrError<UnhandledApiExceptionProblem | UnhandledTiaProblem>
      },
      error: handleError
    }))
  }
}
